import camelize from 'camelcase-keys-deep';

export default class Maintenance {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    let response = null;
    if (this.entityId) {
      response = this.env().Entity.findBy('id', this.entityId);
    }
    return response;
  }

  get file() {
    let response = null;
    if (this.fileId) {
      response = this.env().File.findBy('id', this.fileId);
    }
    return response;
  }
}
