import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StarIcon from '@material-ui/icons/Star';

import TotalAmountBox from 'commons/components/TotalAmounBox/TotalAmounBox';
import ClickTooltip from '../ClickTooltip';
import SalePrice from '../SalePrice';
import classes from './styles';
import colors from '../../../utils/colors';

const { grey, primary } = colors;

const SHOW_BANK_TRANSFER_OPTION = false;

const PaymentMethodCard = ({
  bankTransferAvailable = true,
  bankTransferTotal,
  creditCardAvailable,
  creditCardTotal,
  creditCardWithFeesAvailable,
  disabledSalePrice = false,
  handleBlur = null,
  handleChange,
  isEditMode = true,
  isTransferFlow = false,
  logo = null,
  price,
  priceToShow,
  isStoreUser = false,
}) => (
  <Card>
    <CardContent>
      <Box display="flex" flexDirection="column" alignItems="center">
        {logo && (
          <Box style={{ width: '180px', marginBottom: '10px' }}>
            <img src={logo} alt="bikfy-logo" height="100%" width="100%" />
          </Box>
        )}
        <Typography style={{ textAlign: 'center', marginBottom: 15 }}>
          Habilita las
          {' '}
          <b style={{ color: primary }}>opciones de pago</b>
          {' '}
          a tu comprador.
        </Typography>
      </Box>
      <Box>
        <Grid item xs={12}>
          <SalePrice
            handleBlur={handleBlur}
            handleChange={handleChange}
            isEditMode={isEditMode}
            priceToShow={priceToShow}
            value={price}
            disabled={disabledSalePrice}
          />
        </Grid>
        <Box>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={creditCardAvailable}
                  onChange={handleChange}
                  name="creditCard"
                />
              )}
              label={<Typography>TARJETAS CRÉDITO/DÉBITO.</Typography>}
            />

            {!isStoreUser && (
              <FormControlLabel
                control={(
                  <Switch
                    checked={creditCardWithFeesAvailable}
                    onChange={handleChange}
                    color="primary"
                    name="creditCardWithFees"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={!creditCardAvailable}
                  />
                )}
                label={(
                  <Typography>
                    {isTransferFlow ? (
                      <span>
                        Hasta
                        <b> 9 cuotas sin interés con tarjeta de crédito.</b>
                      </span>
                    ) : (
                      <span>
                        Hasta
                        <b> 9 cuotas sin interés y mayor exposición.</b>
                        <StarIcon style={{ color: '#FFC700' }} />
                      </span>
                    )}
                  </Typography>
                )}
                style={{ marginLeft: '20px' }}
              />
            )}
          </Box>

          {!isStoreUser && (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <TotalAmountBox totalAmount={creditCardTotal} styles={{ marginRight: '20px' }} />
              <ClickTooltip
                title="En caso de que tu comprador relice el pago con tarjetas bancarias recibirás este valor."
                component={ErrorOutlineIcon}
              />
            </Box>
          )}
        </Box>
      </Box>

      {SHOW_BANK_TRANSFER_OPTION && (
        <Box>
          <Box display="inherit" alignItems="inherit">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={bankTransferAvailable}
                  name="withBackTransfer"
                  style={{ color: grey }}
                />
              )}
              label={<Typography>TRANSFERENCIA BANCARIA.</Typography>}
            />
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <TotalAmountBox
              className={`${classes.amountBoxContainer} ${classes.mTop10}`}
              totalAmount={bankTransferTotal}
              styles={{ marginRight: '20px' }}
            />
            <ClickTooltip
              title="En caso de que tu comprador relice el pago con transferencia recibirás este valor."
              component={ErrorOutlineIcon}
            />
          </Box>
        </Box>
      )}
    </CardContent>
  </Card>
);

export default PaymentMethodCard;
