const Years = (variant) => {
  const years = [];
  const min = 1980;
  const month = new Date().getMonth();
  let max = new Date().getFullYear();

  if (month >= 2) max += 1;

  if (variant) {
    for (let i = max; i >= min; i--) {
      years.push({ id: i, name: i.toString() });
    }
  } else {
    for (let i = max; i >= min; i--) {
      years.push({ value: i, label: i.toString() });
    }
  }

  return years;
};

export default Years;
