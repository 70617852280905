import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';

const ResponsiveFilters = (props) => {
  const { isMobileScreen, isMarketPlace, children } = props;
  const [showAppBar, setShowAppBar] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [isThresholdReached, setIsThresholdReached] = useState(false);
  const [threshold, setThreshold] = useState(null); // Initialize as null
  const appBarRef = useRef(null); // Reference to the AppBar element

  // Calculate the threshold once after the component mounts
  useEffect(() => {
    if (appBarRef.current && threshold === null) {
      const appBarOffsetTop = appBarRef.current.offsetTop + appBarRef.current.offsetHeight;
      setThreshold(appBarOffsetTop); // Set threshold dynamically based on AppBar position
    }
  }, [appBarRef.current, threshold]); // Only calculate once

  useEffect(() => {
    if (!isMobileScreen || !isMarketPlace) {
      return; // Only add scroll listener for mobile marketplace
    }

    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;

      if (threshold !== null) {
        // Check if we've scrolled past or below the threshold
        if (!isThresholdReached && currentScrollPosition > threshold) {
          // Activate auto-hide/show behavior
          setIsThresholdReached(true);
        } else if (isThresholdReached && currentScrollPosition <= threshold) {
          // Reset behavior if we scroll back above the threshold
          setIsThresholdReached(false);
          setShowAppBar(true); // Ensure AppBar is visible
        }
      }

      if (isThresholdReached) {
        if (currentScrollPosition > lastScrollPosition) {
          // Scrolling down
          setShowAppBar(false);
        } else if (currentScrollPosition < lastScrollPosition) {
          // Scrolling up
          setShowAppBar(true);
        }
      }

      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition, isThresholdReached, isMobileScreen, isMarketPlace, threshold]);

  if (isMobileScreen && isMarketPlace) {
    return (
      <AppBar
        ref={appBarRef} // Attach the ref to AppBar
        position="sticky"
        color="transparent"
        elevation={3}
        style={{
          padding: '10px',
          marginBottom: '20px',
          transform: showAppBar ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        {children}
      </AppBar>
    );
  }

  return children;
};

ResponsiveFilters.propTypes = {
  isMobileScreen: PropTypes.bool.isRequired,
  isMarketPlace: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ResponsiveFilters;
