import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'payment/';

// Global constants for saga
export const PAYMENT = {
  VALIDATE_STATUS: `${PATH}VALIDATE_STATUS`,
  VALIDATE_STATUS_SUCCESS: `${PATH}VALIDATE_STATUS_SUCCESS`,
  VALIDATE_STATUS_ERROR: `${PATH}VALIDATE_STATUS_ERROR`,
};

// actions
export const paymentActions = {
  validateStatus: createAction(PAYMENT.VALIDATE_STATUS, 'payload'),
};

const initialState = {
  loading: false,
  error: false,
  success: false,
};

const payment = createReducer(initialState, {
  [PAYMENT.VALIDATE_STATUS](state) {
    state.loading = true;
    state.error = false;
    state.success = false;
  },
  [PAYMENT.VALIDATE_STATUS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.success = true;
  },
  [PAYMENT.VALIDATE_STATUS_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.success = false;
  },
});

export default payment;
