import { put, select, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, { apiSuccess, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { BIKE_DETAIL } from '../reducer';

function* getPublishedBike() {
  yield takeLatest(BIKE_DETAIL.GET_PUBLISHED_BIKE, function* getPublishedBikeGenerator(action) {
    const { transferId, newPublication } = action.payload;
    const reportReasons = yield select((state) => state.app.objects.reportReasons);
    let url = reportReasons
      ? `api/v1/p/published-bikes/${transferId}?reportReasons=true`
      : `api/v1/p/published-bikes/${transferId}?`;
    url = newPublication ? `api/v1/p/published-bikes/${transferId}?newPublication=true` : url;
    const response = yield apiRequest(url, { method: 'GET' }, true);

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put({
        type: BIKE_DETAIL.GET_PUBLISHED_BIKE_SUCCESS,
      });
    } else {
      yield put({ type: APP.SET_ERROR, payload: { errorMsg: response.errorMsg } });
    }
  });
}

function* getCommissions() {
  yield takeLatest(BIKE_DETAIL.GET_COMMISSIONS, function* getCommissionsGenerator(action) {
    const url = 'api/v1/payments/get-comissions';
    const response = yield apiRequest(url, { method: 'GET' }, true);

    if (!response.error) {
      yield put({ type: BIKE_DETAIL.GET_COMMISSIONS_SUCCESS, payload: { commissions: response } });
    } else {
      yield put({ type: BIKE_DETAIL.GET_COMMISSIONS_FAIL });
      yield put({ type: APP.SET_ERROR, payload: { errorMsg: 'Error al cargar las comissiones' } });
    }
  });
}

function* setPublishBikeFormValues() {
  yield takeLatest(
    BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES,
    function* setPublishBikeFormValuesGenerator(action) {
      const { newPublishBikeFormValues, transferId, bikeMarketId } = action.payload;

      const formValues = Object.entries(newPublishBikeFormValues).reduce(
        (acum, current) => ({
          ...acum,
          [current[0]]: current[1].value,
        }),
        {},
      );

      const paramsApiRequest = {
        url: 'api/v1/bikes/market-place/publish-bike',
        method: 'POST',
      };

      if (bikeMarketId) {
        paramsApiRequest.url = `api/v1/bikes/market-place/update-publication/${bikeMarketId}`;
        paramsApiRequest.method = 'PATCH';
      }

      const body = JSON.stringify({ ...formValues, transferId });

      const response = yield apiRequest(
        paramsApiRequest.url,
        {
          method: paramsApiRequest.method, // change type to update
          body,
        },
        true,
      );

      if (!response.error) {
        yield put({ type: BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS });
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
        yield put({ type: APP.SET_SUCCESS, payload: 'Bike publicada correctamente' });
      } else {
        yield put({ type: BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES_FAIL });
        yield put({ type: APP.SET_ERROR, payload: { errorMsg: 'Error al publicar la bike' } });
      }
    },
  );
}

function* deletePublication() {
  yield takeLatest(BIKE_DETAIL.DELETE_PUBLICATION, function* deletePublicationGenerator(action) {
    const { bikeMarketId, deletedReasonId, history } = action.payload;

    const url = `api/v1/bikes/market-place/delete-publication/${bikeMarketId}`;

    const body = {
      deletedReasonId,
    };

    const response = yield apiRequest(url, { method: 'DELETE', body: JSON.stringify(body) }, true);

    if (!response.error) {
      yield put({ type: APP.SET_SUCCESS, payload: 'Publicacion Eliminada correctamente' });
      if (response?.reason?.code === 'sold') {
        history.replace(`/bike/${response.transferId}/transfer`);
        yield put({ type: BIKE_DETAIL.SET_REDIRECT, payload: { redirect: false } });
      }
      delete response?.reason;
      delete response?.transferId;
      yield put(apiSuccess(APP.DELETE_STATE_FROM_API_SUCCESS, response));
      yield put({ type: BIKE_DETAIL.DELETE_PUBLICATION_SUCCESS });
    } else {
      yield put({ type: BIKE_DETAIL.DELETE_PUBLICATION_FAIL });
      yield put({ type: APP.SET_ERROR, payload: 'Error al eliminar la publicación' });
    }
  });
}

function* reportPublish() {
  yield takeLatest(BIKE_DETAIL.REPORT_PUBLISH, function* reportPublishGenerator(action) {
    const { reportReasonId } = action.payload;
    const { bikeMarketId } = yield select((state) => state.bikeDetail.modals.report);

    const body = {
      reportReasonId,
      bikeMarketId,
    };

    const response = yield apiRequest(
      'api/v1/report/report-publication',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: BIKE_DETAIL.REPORT_PUBLISH_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: 'El reporte ha sido enviado' });
    } else {
      yield put({ type: BIKE_DETAIL.REPORT_PUBLISH_FAIL });
    }
  });
}

function* setSellerEmail() {
  yield takeLatest(BIKE_DETAIL.SET_SELLER_EMAIL, function* setSellerEmailGenerator(action) {
    const { formSellerEmailValues } = action.payload;
    const { transferId, bikeId } = yield select((state) => state.bikeDetail);

    const reducedFormSellerEmailValues = Object.entries(formSellerEmailValues).reduce(
      (acum, current) => ({
        ...acum,
        [current[0]]: current[1].value,
      }),
      {},
    );

    const body = {
      ...reducedFormSellerEmailValues,
      transferId,
    };

    const url = `api/v1/bikes/publish-bike/${bikeId}/send-seller-email`;

    const response = yield apiRequest(
      url,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      const { message: successMessage } = response;

      yield put({ type: BIKE_DETAIL.SET_SELLER_EMAIL_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: successMessage });
    } else {
      yield put({ type: BIKE_DETAIL.SET_SELLER_EMAIL_FAIL });
    }
  });
}
function* fetchTransferPreview() {
  yield takeLatest(BIKE_DETAIL.FETCH_TRANSFER_PREVIEW, function* (action) {
    const body = action.payload;
    const { id } = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, BIKE_DETAIL.FETCH_TRANSFER_PREVIEW));
    const response = yield post(`api/v1/transfers/${id}/purchase`, body);
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE_DETAIL.FETCH_TRANSFER_PREVIEW));
    if (!response.error) {
      yield put(apiSuccess(BIKE_DETAIL.FETCH_TRANSFER_PREVIEW_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response.errorMsg ?? 'Verifique los datos ingresados',
        }),
      );
      yield put(apiSuccess(BIKE_DETAIL.FETCH_TRANSFER_PREVIEW_ERROR));
    }
  });
}
export default function* bikeDetailSaga() {
  yield spawn(getPublishedBike);
  yield spawn(getCommissions);
  yield spawn(setPublishBikeFormValues);
  yield spawn(deletePublication);
  yield spawn(reportPublish);
  yield spawn(setSellerEmail);
  yield spawn(fetchTransferPreview);

}
