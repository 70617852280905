import React, { useMemo } from 'react';
import Paths from 'utils/paths';
import { Switch, Route, Redirect } from 'react-router-dom';
import PublicRoute from '../PublicRoute';
import PrivateRoute from '../PrivateRoute';

const mapRoute = ({
  component: Component, user, path, options,
}) => (
  <Component
    key={`route-${path}`}
    path={path}
    exact={options.exact ?? true}
    component={options.component}
    user={user}
    withLayout={options.withLayout ?? true}
  />
);

const Routes = ({ user }) => {
  const publicRoutes = useMemo(
    () => Object.keys(Paths.public)
      .map((path) => mapRoute({
        component: PublicRoute,
        user,
        path,
        options: Paths.public[path],
      })),
    [],
  );

  const userRoutes = useMemo(
    () => {
      const userRoute = Paths[user?.type];
      if (!userRoute) return [];
      return Object.keys(userRoute)
        .map((path) => mapRoute({
          component: PrivateRoute,
          user,
          path,
          options: userRoute[path],
        }));
    },
    [user],
  );

  return (
    <Switch>
      {userRoutes}
      {publicRoutes}
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
