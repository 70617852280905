import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';

const RememberPurchaseDialog = (props) => {
  const { open, onSubmit, setOpen } = props;

  const handleClickPublishButton = () => {
    setOpen(false);
    onSubmit();
  };

  const handleClose = () =>
    setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle style={{ textAlign: 'center' }} className="color-danger">
        ¡AVISO!
      </DialogTitle>

      <DialogContent style={{ textAlign: 'center' }} className="color-secondary">
        <Typography variant="body1">
          Has iniciado el proceso de compra. <br />
          Por favor asegúrate de que tus datos estén correctos en el siguiente contrato compraventa el cual te permitirá obtener la propiedad legal de esta bicicleta.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item />
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              onClick={handleClickPublishButton}
            >
              Continuar
            </Button>
          </Grid>
          <Grid item />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RememberPurchaseDialog;
