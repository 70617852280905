import colors from '../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    width: '100%',
    maxWidth: '1139px',
    margin: '0 auto',
  },
  paperContainer: {
    width: '100%',
    padding: '50px 10px 10px 10px',
    '& .title-container': {
      '& .title': {
        fontWeight: '700',
        fontSize: '36px',
        textAlign: 'center',
        marginTop: '10px',
      },
    },
    '& .message-container': {
      margin: '0 auto',
      maxWidth: '692px',
      marginTop: '25px',
      '& .paragraph': {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '400',
        color: '#808080',
        marginLeft: '15px',
        marginRight: '15px',
        '& .bikfy-color': {
          color: primary,
        },
      },
      '& :nth-child(2)': {
        marginTop: '10px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    paperContainer: {
      '& .title-container': {
        '& .title': {
          fontSize: '26px',
        },
      },
      '& .message-container': {
        '& .paragraph': {
          fontSize: '20px',
        },
      },
    },
  },
  checkBoxesContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    '& .show-bike-button': {
      padding: '10px 40px',
      borderRadius: '20px',
    },
  },
  buttonContainer: {
    marginTop: '10px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
    '& .show-bike-button': {
      padding: '10px 40px',
      borderRadius: '20px',
    },
  },
});

export default styles;
