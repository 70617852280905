const PATH = 'bankDataScreen';

const bankDataScreenTypes = {
  GET_BANK_DATA: `${PATH}/GET_BANK_DATA`,
  GET_BANK_DATA_SUCCESS: `${PATH}/GET_BANK_DATA_SUCCESS`,
  GET_BANK_DATA_FAILURE: `${PATH}/GET_BANK_DATA_FAILURE`,
  GET_STORE_BANK_DATA: `${PATH}/GET_STORE_BANK_DATA`,
  GET_STORE_BANK_DATA_SUCCESS: `${PATH}/GET_STORE_BANK_DATA_SUCCESS`,
  GET_STORE_BANK_DATA_FAILURE: `${PATH}/GET_STORE_BANK_DATA_FAILURE`,
  SET_STORE_DATA_BANK: `${PATH}/SET_STORE_DATA_BANK`,
  SET_STORE_DATA_BANK_SUCCESS: `${PATH}/SET_STORE_DATA_BANK_SUCCESS`,
  SET_STORE_DATA_BANK_FAILURE: `${PATH}/SET_STORE_DATA_BANK_FAILURE`,
  SET_USER_DATA_BANK: `${PATH}/SET_USER_DATA_BANK`,
  SET_USER_DATA_BANK_SUCCESS: `${PATH}/SET_USER_DATA_BANK_SUCCESS`,
  SET_USER_DATA_BANK_FAILURE: `${PATH}/SET_USER_DATA_BANK_FAILURE`,
  RESET_BANK_DATA_ID: `${PATH}/RESET_BANK_DATA_ID`,
  TOGGLE_ERROR: `${PATH}/TOGGLE_ERROR`,
};

export default bankDataScreenTypes;
