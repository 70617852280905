import { put, select, spawn, takeLatest } from 'redux-saga/effects';
import { SEARCH } from 'screens/Search/reducer';
import apiRequest, { apiSuccess, get } from 'utils/api';
import * as APP from 'commons/reducer';

function* fetchBrands() {
  yield takeLatest(SEARCH.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SEARCH.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, SEARCH.FETCH_BRANDS));

    if (!response.error) {
      yield put(apiSuccess(SEARCH.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(SEARCH.FETCH_BRANDS_ERROR, { errorMsg: 'Error al cargar las marcas' }));
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(SEARCH.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SEARCH.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, SEARCH.FETCH_VEHICLE_TYPES));

    if (!response.error) {
      yield put(apiSuccess(SEARCH.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(SEARCH.FETCH_VEHICLE_TYPES_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículo',
        }),
      );
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(SEARCH.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SEARCH.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, SEARCH.FETCH_MOTORBIKE_BRANDS));

    if (!response.error) {
      yield put(apiSuccess(SEARCH.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(SEARCH.FETCH_MOTORBIKE_BRANDS_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículo',
        }),
      );
    }
  });
}

function* validateBike() {
  yield takeLatest(SEARCH.VALIDATE_BIKE, function* (action) {
    const { brandId, serial } = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, SEARCH.VALIDATE_BIKE));
    const response = yield get(`api/v1/bikes/validate?brandId=${brandId}&serial=${serial}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, SEARCH.VALIDATE_BIKE));

    if (!response.error) {
      yield put(apiSuccess(SEARCH.VALIDATE_BIKE_SUCCESS));
    } else {
      yield put(apiSuccess(SEARCH.VALIDATE_BIKE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Bike no encontrada' }));
    }
  });
}

function* searchBike() {
  yield takeLatest(SEARCH.SEARCH_BIKE, function* (action) {
    const { brandId, serial } = action.payload;

    // este endpoint utiliza *
    const response = yield get(`api/v1/bikes/search?brandId=${brandId}&serial=${serial}`);
    if (!response.error) {
      yield put(apiSuccess(APP.RESET_OBJECT, 'disabledReasons'));
      yield put(apiSuccess(SEARCH.SEARCH_BIKE_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(SEARCH.SEARCH_BIKE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Bike no encontrada' }));
    }
  });
}

function* fetchMaintenance() {
  yield takeLatest(SEARCH.FETCH_MAINTENANCE, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SEARCH.FETCH_MAINTENANCE));
    const id = action.payload;
    const response = yield get(`api/v1/bike/${id}/maintenances`);
    yield put(apiSuccess(APP.REMOVE_LOADING, SEARCH.FETCH_MAINTENANCE));
    if (!response.error) {
      yield put(apiSuccess(SEARCH.FETCH_MAINTENANCE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las mantenciones' }));
      yield put(apiSuccess(SEARCH.FETCH_MAINTENANCE_ERROR));
    }
  });
}

function* setSellerEmail() {
  yield takeLatest(SEARCH.SET_SELLER_EMAIL, function* setSellerEmailGenerator(action) {
    const { formSellerEmailValues } = action.payload;

    const { bikeId, ...body } = Object.entries(formSellerEmailValues).reduce(
      (acum, current) => ({
        ...acum,
        [current[0]]: current[1].value,
      }),
      {},
    );

    const url = `api/v1/bikes/publish-bike/${bikeId}/send-seller-email`;

    const response = yield apiRequest(
      url,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      const { message: successMessage } = response;

      yield put({ type: SEARCH.SET_SELLER_EMAIL_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: successMessage });
    } else {
      yield put({ type: SEARCH.SET_SELLER_EMAIL_FAIL });
    }
  });
}

function* getPublishedBike() {
  yield takeLatest(SEARCH.GET_PUBLISHED_BIKE, function* getPublishedBikeGenerator(action) {
    const { transferId, publishBikeMode } = action.payload;

    let url = `api/v1/market_place/published-bikes/${transferId}`;

    if (publishBikeMode) {
      url = url.concat(`?publishBike=${publishBikeMode}`);
    }

    const response = yield apiRequest(url, { method: 'GET' }, true);

    if (!response.error) {
      let publishBikeForm = yield select((state) => state.search.publishBikeForm);

      if (response.objects?.bikesMarkets) {
        const bikeMarket = Object.values(response.objects?.bikesMarkets)[0];

        publishBikeForm = Object.keys(publishBikeForm).reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              value: bikeMarket[key],
              isValid: true,
              helperText: '',
            },
          }),
          {},
        );
      }

      yield put({
        type: SEARCH.GET_PUBLISHED_BIKE_SUCCESS,
        payload: {
          publishBikeForm,
        },
      });
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      const { message } = JSON.parse(response.errorMsg);

      yield put({ type: APP.SET_ERROR, payload: message });
    }
  });
}

function* setPublishBikeFormValues() {
  yield takeLatest(
    SEARCH.SET_PUBLISH_BIKE_FORM_VALUES,
    function* setPublishBikeFormValuesGenerator(action) {
      const { newPublishBikeFormValues, transferId, bikeMarketId } = action.payload;

      const formValues = Object.entries(newPublishBikeFormValues).reduce(
        (acum, current) => ({
          ...acum,
          [current[0]]: current[1].value,
        }),
        {},
      );

      const paramsApiRequest = {
        url: 'api/v1/bikes/market-place/publish-bike',
        method: 'POST',
      };

      if (bikeMarketId) {
        paramsApiRequest.url = `api/v1/bikes/market-place/update-publication/${bikeMarketId}`;
        paramsApiRequest.method = 'PATCH';
      }

      const body = JSON.stringify({ ...formValues, transferId });

      const response = yield apiRequest(
        paramsApiRequest.url,
        {
          method: paramsApiRequest.method, // change type to update
          body,
        },
        true,
      );

      if (!response.error) {
        yield put({ type: SEARCH.SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS });
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
        yield put({ type: APP.SET_SUCCESS, payload: 'Bike publicada correctamente' });
      } else {
        yield put({ type: SEARCH.SET_PUBLISH_BIKE_FORM_VALUES_FAIL });
        yield put({ type: APP.SET_ERROR, payload: { errorMsg: 'Error al publicar la bike' } });
      }
    },
  );
}

function* requestAccess() {
  yield takeLatest(SEARCH.REQUEST_ACCESS, function* generator(action) {
    const { transferId } = action.payload;

    const body = {
      transferId,
    };
    const response = yield apiRequest(
      'api/v1/bikes/store/request-access',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: APP.SET_SUCCESS, payload: response.message });
    } else {
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

function* getCommissions() {
  yield takeLatest(SEARCH.GET_COMMISSIONS, function* getCommissionsGenerator(action) {
    const url = 'api/v1/payments/get-comissions';
    const response = yield apiRequest(url, { method: 'GET' }, true);

    if (!response.error) {
      yield put({ type: SEARCH.GET_COMMISSIONS_SUCCESS, payload: { commissions: response } });
    } else {
      yield put({ type: SEARCH.GET_COMMISSIONS_FAIL });
      yield put({ type: APP.SET_ERROR, payload: 'Error al cargar las comissiones' });
    }
  });
}

function* deletePublication() {
  yield takeLatest(SEARCH.DELETE_PUBLICATION, function* deletePublicationGenerator(action) {
    const { bikeMarketId } = action.payload;

    const url = `api/v1/bikes/market-place/delete-publication/${bikeMarketId}`;

    const response = yield apiRequest(url, { method: 'DELETE' }, true);

    if (!response.error) {
      yield put({ type: SEARCH.DELETE_PUBLICATION_SUCCESS });
      yield put(apiSuccess(APP.DELETE_STATE_FROM_API_SUCCESS, response));
      yield put({ type: APP.SET_SUCCESS, payload: 'Publicacion Eliminada correctamente' });
    } else {
      yield put({ type: SEARCH.DELETE_PUBLICATION_FAIL });
      yield put({ type: APP.SET_ERROR, payload: 'Error al eliminar la publicación' });
    }
  });
}

function* reportPublish() {
  yield takeLatest(SEARCH.REPORT_PUBLISH, function* reportPublishGenerator(action) {
    const { reportReasonId } = action.payload;
    const { bikeMarketId } = yield select((state) => state.search.modals.report);

    const body = {
      reportReasonId,
      bikeMarketId,
    };

    const response = yield apiRequest(
      'api/v1/report/report-publication',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: SEARCH.REPORT_PUBLISH_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: 'El reporte ha sido enviado' });
    } else {
      yield put({ type: SEARCH.REPORT_PUBLISH_FAIL });
    }
  });
}

function* disableBike() {
  yield takeLatest(SEARCH.TOGGLE_DISABLE_BIKE, function* (action) {
    const { formValues, isDisable, bikeId } = action.payload;

    let url = `api/v1/bikes/disable-bike/${bikeId}`;

    if (isDisable) {
      url = `api/v1/bikes/remove-disable-bike/${bikeId}`;
    }

    const response = yield apiRequest(
      url,
      { method: 'put', body: JSON.stringify(formValues) },
      true,
    );

    if (!response.error) {
      const { message } = response;
      response.objects.bike = response.objects.bikes[bikeId];
      delete response.objects.bikes;

      const responseUpdatedBike = yield get(
        `api/v1/bikes/search?brandId=${response.objects.bike.brandId}&serial=${response.objects.bike.serial}`,
      );

      yield put(apiSuccess(SEARCH.TOGGLE_DISABLE_BIKE_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, message));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, responseUpdatedBike));
      yield put(apiSuccess(SEARCH.OPEN_CLOSE_DISABLE_BIKE_MODAL, { openDisableBikeModal: false }));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response.errorMsg,
        }),
      );
      yield put(
        apiSuccess(SEARCH.FETCH_BIKES_ERROR, {
          errorMsg: response.message,
        }),
      );
    }
  });
}

export default function* root() {
  yield spawn(disableBike);
  yield spawn(fetchBrands);
  yield spawn(validateBike);
  yield spawn(searchBike);
  yield spawn(fetchMaintenance);
  yield spawn(setSellerEmail);
  yield spawn(getPublishedBike);
  yield spawn(setPublishBikeFormValues);
  yield spawn(getCommissions);
  yield spawn(deletePublication);
  yield spawn(reportPublish);
  yield spawn(requestAccess);
  yield spawn(fetchVehicleTypes);
  yield spawn(fetchMotorbikeBrands);
}
