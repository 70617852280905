import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export function ResendEmailActivationDialog(props) {
  const { open, onSubmit, setOpen } = props;
  const [email, setEmail] = React.useState('');
  const handleClickPublishButton = () => {
    setOpen(false);
    onSubmit(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle style={{ textAlign: 'center' }} >
        INGRESA TU CORREO
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }} className="color-secondary">
        <TextField
          fullWidth
          variant="outlined"
          type="email"
          placeholder="Ingresa el correo con el cual te has registrado"
          onChange={handleChange}
          value={email}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item />
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              onClick={handleClickPublishButton}
            >
              Reenviar correo de activación
            </Button>
          </Grid>
          <Grid item />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
