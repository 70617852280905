import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const Title = (props) => {
  const { className } = props;

  return (
    <Typography component="h1" className={className}>
      ¿Cómo deseas <span className="bikfyColor"> pagar tu Bike?</span>
    </Typography>
  );
};

Title.propTypes = {
  className: PropTypes.string,
};

Title.defaultProps = {
  className: 'title',
};

export default Title;
