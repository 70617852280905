import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  overrideFlexWrapWhenFocused: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
    '& .Mui-focused': {
      flexWrap: 'wrap',
    },
  },
}));

const CustomMultiSelectComplete = ({
  label,
  name,
  value,
  options,
  onChange,
  error,
  errors,
  inputVariant,
  limitTags,
}) => {
  const selectValue = options.filter((o) => value.includes(o.value));
  const getIds = (data) => [].concat(...data.map((d) => d.value));
  const classes = useStyles();

  const handleChange = (e, newValue) => {
    const ids = getIds(newValue);
    onChange({ target: { name, value: ids } });
  };

  return (
    <Autocomplete
      multiple
      value={selectValue}
      limitTags={limitTags || 1}
      onChange={handleChange}
      options={options}
      className={classes.overrideFlexWrapWhenFocused}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={errors}
          variant={inputVariant || 'outlined'}
        />
      )}
    />
  );
};

export default CustomMultiSelectComplete;
