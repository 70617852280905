import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Fab } from '@material-ui/core';
import { PATHS } from 'utils/paths';

const CustomMenu = ({ logOut, user, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    history.push(PATHS.PROFILE);
    handleClose();
  };

  const goToMyBikes = () => {
    history.push(PATHS.PILOT);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = {
    pilot: [
      { label: 'Mis datos', onClick: handleProfile },
      { label: 'Mis Bikes', onClick: goToMyBikes },
      { label: 'Cerrar sesión', onClick: logOut },
    ],

    store: [{ label: 'Cerrar sesión', onClick: logOut }],

    ambassador: [{ label: 'Cerrar sesión', onClick: logOut }],

    admin: [{ label: 'Cerrar sesión', onClick: logOut }],
  };

  const name = !user.name ? '' : user.name.substr(0, 1);

  return (
    <div>
      <Fab onClick={handleMenu} size="small" style={{ backgroundColor: '#303e48', color: '#fff' }}>
        {name}
      </Fab>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {(menuOptions[user?.type] || []).map((m) => (
          <MenuItem onClick={m.onClick} key={m.label}>
            {m.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
