import colors from 'utils/colors';

const { primary, white, secondary } = colors;

export const styles = (theme) => ({
  bikfyColor: {
    color: primary,
  },
  bikfyModal: {
    border: '2px solid',
    borderBottom: 0,
    borderColor: '#45C6D9',
  },
  bikfyModalActions: {
    border: '2px solid',
    borderTop: 0,
    borderColor: '#45C6D9',
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    margin: '20px 0px',
    color: 'black',
  },
  marginBottom: {
    marginBottom: '20px',
  },
  subtitleText: {
    fontSize: '0.9rem',
  },
});

export default styles;
