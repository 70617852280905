import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const SalesMethodCard = ({ salesMethod, styles, handleClick }) => {
  return (
    <Card style={styles} onClick={() => handleClick(salesMethod.id)}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box style={{ width: '180px', minHeight: '40px' }}>{salesMethod.logo}</Box>
          <Divider light style={{ width: '100%', margin: '10px 0px' }} />
          <Typography
            variant="body2"
            align="justify"
            style={{ textAlign: 'center', padding: '10px 0px' }}>
            {salesMethod.detail}
          </Typography>
          <Divider light style={{ width: '100%', margin: '10px 0px' }} />
        </Box>
        <List>
          {salesMethod.items.map((bikypayItem) => (
            <ListItem key={bikypayItem.id} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <ListItemIcon style={{ minWidth: 'auto', marginRight: '10px' }}>
                <CheckIcon style={{ color: '#45C6D8' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className="title" variant="body2" align="justify">
                    <b>{bikypayItem.title}:</b> {bikypayItem.description}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default SalesMethodCard;
