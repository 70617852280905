import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'transfer/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const TRANSFER = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_TRANSFER: `${PATH}TRANSFER_BIKE`,
  FETCH_TRANSFER_SUCCESS: `${PATH}FETCH_TRANSFER_SUCCESS`,
  FETCH_TRANSFER_ERROR: `${PATH}FETCH_TRANSFER_ERROR`,
  FETCH_TRANSFER_PREVIEW: `${PATH}FETCH_TRANSFER_PREVIEW`,
  FETCH_TRANSFER_PREVIEW_SUCCESS: `${PATH}FETCH_TRANSFER_PREVIEW_SUCCESS`,
  FETCH_TRANSFER_PREVIEW_ERROR: `${PATH}FETCH_TRANSFER_PREVIEW_ERROR`,
  SEND_EMAIL_BUYER: `${PATH}SEND_EMAIL_BUYER`,
  SEND_EMAIL_BUYER_SUCCESS: `${PATH}SEND_EMAIL_BUYER_SUCCESS`,
  SEND_EMAIL_BUYER_ERROR: `${PATH}SEND_EMAIL_BUYER_ERROR`,
  TRANSFER_VALIDATION: `${PATH}TRANSFER_VALIDATION`,
  TRANSFER_VALIDATION_SUCCESS: `${PATH}TRANSFER_VALIDATION_SUCCESS`,
  TRANSFER_VALIDATION_ERROR: `${PATH}TRANSFER_VALIDATION_ERROR`,
  TRANSFER_CLEAR_ALERT: `${PATH}TRANSFER_CLEAR_ALERT`,

  SET_BIKEMARKET_VALUE: `${PATH}SET_BIKEMARKET_VALUE`,
};

export const transferActions = {
  resetControls: createAction(TRANSFER.RESET_CONTROLS),
  fetchTransfer: createAction(TRANSFER.FETCH_TRANSFER, 'payload'),
  fetchTransferPreview: createAction(TRANSFER.FETCH_TRANSFER_PREVIEW, 'payload'),
  sentEmailBuyer: createAction(TRANSFER.SEND_EMAIL_BUYER, 'id'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  transferValidation: createAction(TRANSFER.TRANSFER_VALIDATION, 'payload'),
  clearAlert: createAction(TRANSFER.TRANSFER_CLEAR_ALERT),
  setBikeMarketValue: createAction(TRANSFER.SET_BIKEMARKET_VALUE, 'payload'),
};

const initialState = {
  formControls: {
    amount: '',
    email: '',
    possessionCheckbox: false,
  },
  buyerStatus: { status: '', buyer: null },
  newTransferId: null,
  alert: false,
  loading: false,
  error: false,
};

const transfer = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [TRANSFER.RESET_CONTROLS](state) {
    state.formControls = { ...initialState.formControls };
    state.newTransferId = null;
    state.alert = false;
    state.error = false;
  },
  [TRANSFER.FETCH_TRANSFER](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFER.FETCH_TRANSFER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [TRANSFER.FETCH_TRANSFER_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFER.FETCH_TRANSFER_PREVIEW](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFER.FETCH_TRANSFER_PREVIEW_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.newTransferId = action.payload.newTransferId;
  },
  [TRANSFER.FETCH_TRANSFER_PREVIEW_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFER.SEND_EMAIL_BUYER](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFER.SEND_EMAIL_BUYER_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
  },
  [TRANSFER.SEND_EMAIL_BUYER_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFER.TRANSFER_VALIDATION](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFER.TRANSFER_VALIDATION_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.buyerStatus = action.payload;
    state.alert = true;
  },
  [TRANSFER.TRANSFER_VALIDATION_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFER.TRANSFER_CLEAR_ALERT](state) {
    state.buyerStatus = { status: '', buyer: null };
    state.alert = false;
  },
  [TRANSFER.SET_BIKEMARKET_VALUE](state, action) {
    state.formControls.amount = action.payload.bikeMarketValue;
  },
});

export default transfer;
