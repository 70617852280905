/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';
import { MAINTENANCE } from 'screens/Maintenance/reducer';

// Local constants
const PATH = 'search/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_DATA_FILE = `${PATH}CHANGE_DATA_FILE`;

// Global constants for saga
export const SEARCH = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_ERROR`,
  VALIDATE_BIKE: `${PATH}VALIDATE_BIKE`,
  VALIDATE_BIKE_SUCCESS: `${PATH}VALIDATE_BIKE_SUCCESS`,
  VALIDATE_BIKE_ERROR: `${PATH}VALIDATE_BIKE_ERROR`,
  SEARCH_BIKE: `${PATH}SEARCH_BIKE`,
  SEARCH_BIKE_SUCCESS: `${PATH}SEARCH_BIKE_SUCCESS`,
  SEARCH_BIKE_ERROR: `${PATH}SEARCH_BIKE_ERROR`,
  VIEW_MAINTENANCE: `${PATH}VIEW_MAINTENANCE`,
  CLOSE_MAINTENANCE: `${PATH}CLOSE_MAINTENANCE`,
  FETCH_MAINTENANCE: `${PATH}FETCH_MAINTENANCE`,
  FETCH_MAINTENANCE_SUCCESS: `${PATH}FETCH_MAINTENANCE_SUCCESS`,
  FETCH_MAINTENANCE_ERROR: `${PATH}FETCH_MAINTENANCE_ERROR`,
  OPEN_FILE_MODAL: `${PATH}OPEN_FILE_MODAL`,
  CLEAR_FILE_MODAL: `${PATH}CLEAR_FILE_MODAL`,

  // SELLER_EMAIL_DIALOG
  TOGGLE_SET_SELLER_EMAIL_DIALOG: `${PATH}TOGGLE_SET_SELLER_EMAIL_DIALOG`,
  SET_SELLER_EMAIL: `${PATH}SET_SELLER_EMAIL`,
  SET_SELLER_EMAIL_SUCCESS: `${PATH}SET_SELLER_EMAIL_SUCCESS`,
  SET_SELLER_EMAIL_FAIL: `${PATH}SET_SELLER_EMAIL_FAIL`,

  // BIKE DETAIL MARKETPLACE
  GET_PUBLISHED_BIKE: `${PATH}GET_PUBLISHED_BIKE`,
  GET_PUBLISHED_BIKE_SUCCESS: `${PATH}GET_PUBLISHED_BIKE_SUCCESS`,
  GET_PUBLISHED_BIKE_FAIL: `${PATH}GET_PUBLISHED_BIKE_FAIL`,

  // PUBLISH BIKE
  SET_PUBLISH_BIKE_FORM_VALUES: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES`,
  SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS`,
  SET_PUBLISH_BIKE_FORM_VALUES_FAIL: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES_FAIL`,

  // GET COMISSIONS
  GET_COMMISSIONS: `${PATH}GET_COMMISSIONS`,
  GET_COMMISSIONS_SUCCESS: `${PATH}GET_COMMISSIONS_SUCCESS`,
  GET_COMMISSIONS_FAIL: `${PATH}GET_COMMISSIONS_FAIL`,

  // SAVE_FORM_VALUES
  SAVE_PUBLISH_FORM_VALUES: `${PATH}SAVE_PUBLISH_FORM_VALUES`,

  // DELETE PUBLICATION
  DELETE_PUBLICATION: `${PATH}DELETE_PUBLICATION`,
  DELETE_PUBLICATION_SUCCESS: `${PATH}DELETE_PUBLICATION_SUCCESS`,
  DELETE_PUBLICATION_FAIL: `${PATH}DELETE_PUBLICATION_FAIL`,

  SET_SCREEN_MODE: `${PATH}SET_SCREEN_MODE`,

  SET_OPEN_REPORT_MODAL: `${PATH}SET_OPEN_REPORT_MODAL`,

  REPORT_PUBLISH: `${PATH}REPORT_PUBLISH`,
  REPORT_PUBLISH_SUCCESS: `${PATH}REPORT_PUBLISH_SUCCESS`,
  REPORT_PUBLISH_FAIL: `${PATH}REPORT_PUBLISH_FAIL`,

  REQUEST_ACCESS: `${PATH}REQUEST_ACCESS`,

  TOGGLE_DISABLE_BIKE: `${PATH}TOGGLE_DISABLE_BIKE`,
  TOGGLE_DISABLE_BIKE_SUCCESS: `${PATH}TOGGLE_DISABLE_BIKE_SUCCESS`,
  TOGGLE_DISABLE_BIKE_ERROR: `${PATH}TOGGLE_DISABLE_BIKE_ERROR`,

  OPEN_CLOSE_DISABLE_BIKE_MODAL: `${PATH}OPEN_CLOSE_DISABLE_BIKE_MODAL`,

  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,
  FETCH_VEHICLE_TYPES_ERROR: `${PATH}FETCH_VEHICLE_TYPES_ERROR`,

  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS_ERROR: `${PATH}FETCH_MOTORBIKE_BRANDS_ERROR`,

  RESET_BRAND_CONTROLS: `${PATH}RESET_BRAND_CONTROLS`,
};

// actions
export const searchActions = {
  // initState: createAction(),
  searchFetchBrands: createAction(SEARCH.FETCH_BRANDS),
  searchFetchVehicleTypes: createAction(SEARCH.FETCH_VEHICLE_TYPES),
  searchFetchMotorbikeBrands: createAction(SEARCH.FETCH_MOTORBIKE_BRANDS),
  searchChangeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetBrandControls: createAction(SEARCH.RESET_BRAND_CONTROLS),
  changeDataFile: createAction(CHANGE_DATA_FILE, 'payload'),
  validateBike: createAction(SEARCH.VALIDATE_BIKE, 'payload'),
  searchBike: createAction(SEARCH.SEARCH_BIKE, 'payload'),
  searchResetControls: createAction(SEARCH.RESET_CONTROLS),
  viewMaintenance: createAction(SEARCH.VIEW_MAINTENANCE),
  closeMaintenance: createAction(SEARCH.CLOSE_MAINTENANCE),
  fetchMaintenance: createAction(SEARCH.FETCH_MAINTENANCE, 'payload'),
  openFileModal: createAction(SEARCH.OPEN_FILE_MODAL),
  closeFileModal: createAction(SEARCH.CLEAR_FILE_MODAL),

  // SELLER_EMAIL_DIALOG
  toggleSetSellerEmailDialog: createAction(SEARCH.TOGGLE_SET_SELLER_EMAIL_DIALOG, 'payload'),
  setSellerEmail: createAction(SEARCH.SET_SELLER_EMAIL, 'payload'),
  setErrorsSellerEmail: createAction(SEARCH.SET_SELLER_EMAIL_FAIL, 'payload'),

  // BIKE DETAIL MARKETPLACE
  getPublishedBike: createAction(SEARCH.GET_PUBLISHED_BIKE, 'payload'),

  // PUBLISH BIKE
  setPublishBikeFormValues: createAction(SEARCH.SET_PUBLISH_BIKE_FORM_VALUES, 'payload'),

  // GET COMISSIONS
  getCommissions: createAction(SEARCH.GET_COMMISSIONS, 'payload'),

  savePublishFormValues: createAction(SEARCH.SAVE_PUBLISH_FORM_VALUES, 'payload'),

  deletePublication: createAction(SEARCH.DELETE_PUBLICATION, 'payload'),

  setScreenMode: createAction(SEARCH.SET_SCREEN_MODE, 'payload'),

  setOpenReportModal: createAction(SEARCH.SET_OPEN_REPORT_MODAL, 'payload'),

  reportPublish: createAction(SEARCH.REPORT_PUBLISH, 'payload'),

  requestAccess: createAction(SEARCH.REQUEST_ACCESS, 'payload'),

  //
  openDisableBikeModal: createAction(SEARCH.OPEN_CLOSE_DISABLE_BIKE_MODAL, 'payload'),
  toggleDisableBikeAction: createAction(SEARCH.TOGGLE_DISABLE_BIKE, 'payload'),
};

const initialState = {
  formControls: {
    serial: '',
    brandId: '',
    vehicleTypeId: '',
  },
  dataFile: {
    url: '',
    extension: '',
    name: '',
  },
  loading: false,
  loaders: {
    isLoading: false,
  },
  error: false,
  errors: {
    isErrors: false,
    message: '',
  },
  bikeSuccess: false,
  viewMaintenance: false,
  openModal: false,
  openDisableBikeModal: false,
  modals: {
    setSellerEmail: {
      open: false,
    },
    report: {
      open: false,
      bikeMarketId: 0,
    },
  },
  formSellerEmailValues: {
    buyerName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerLastName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerEmail: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerPhone: {
      value: '',
      isValid: true,
      helperText: '',
    },
    emailMessage: {
      value: 'Hola, ¿sigue disponible?',
      isValid: true,
      helperText: '',
    },
    acceptBikfyConditions: {
      value: false,
      isValid: true,
      helperText: '',
    },
    bikeId: {
      value: 0,
      isValid: true,
      helperText: '',
    },
    transferId: {
      value: 0,
      isValid: true,
      helperText: '',
    },
  },
  publishBikeForm: {
    description: {
      value: '',
      isValid: true,
      helperText: '',
    },
    withBikfyPay: {
      value: false,
      isValid: true,
      helperText: '',
    },
    showContact: {
      value: false,
      isValid: true,
      helperText: '',
    },
    withDelivery: {
      value: false,
      isValid: true,
      helperText: '',
    },
    price: {
      value: '',
      isValid: true,
      helperText: '',
    },
  },
  commissions: {
    buyer: 0,
    seller: 0,
    transbank: 0,
    amountPurchaseContract: 0,
  },
  redirect: false,
  screenMode: 'read-mode',
  reportPublishInfo: {
    transferId: 0,
  },
};

const search = createReducer(initialState, {
  [SEARCH.RESET_CONTROLS](state) {
    state.formControls = {
      ...state.formControls,
      ...state.formControls.initialState,
    };
    state.dataFile = {
      ...state.dataFile,
      ...state.dataFile.initialState,
    };
    state.loading = false;
    state.loaders = {
      ...state.loaders,
      ...state.loaders.initialState,
    };
    state.error = false;
    state.errors = {
      ...state.errors,
      ...state.errors.initialState,
    };
    state.bikeSuccess = false;
    state.viewMaintenance = false;
    state.openModal = false;
    state.modals = {
      ...state.modals,
      ...state.modals.initialState,
    };
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...state.formSellerEmailValues.initialState,
    };
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...initialState.publishBikeForm,
    };
    state.commissions = {
      ...state.commissions,
      ...state.commissions.initialState,
    };
    state.redirect = false;
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [CHANGE_DATA_FILE](state, action) {
    state.dataFile = {
      ...state.dataFile,
      ...action.payload,
    };
  },
  [SEARCH.FETCH_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [SEARCH.FETCH_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SEARCH.FETCH_BRANDS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [SEARCH.FETCH_VEHICLE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [SEARCH.FETCH_VEHICLE_TYPES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SEARCH.FETCH_VEHICLE_TYPES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [SEARCH.FETCH_MOTORBIKE_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [SEARCH.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SEARCH.FETCH_MOTORBIKE_BRANDS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [SEARCH.VALIDATE_BIKE](state) {
    state.loading = true;
    state.error = false;
    state.bikeSuccess = false;
  },
  [SEARCH.VALIDATE_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.bikeSuccess = true;
  },
  [SEARCH.VALIDATE_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.bikeSuccess = false;
  },
  [SEARCH.SEARCH_BIKE](state) {
    state.loading = true;
    state.error = false;
  },
  [SEARCH.SEARCH_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SEARCH.SEARCH_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.redirect = true;
  },
  [SEARCH.VIEW_MAINTENANCE](state) {
    state.viewMaintenance = true;
  },
  [SEARCH.CLOSE_MAINTENANCE](state) {
    state.viewMaintenance = false;
  },
  [SEARCH.FETCH_MAINTENANCE](state) {
    state.loading = true;
    state.error = false;
  },
  [SEARCH.FETCH_MAINTENANCE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [SEARCH.FETCH_MAINTENANCE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [SEARCH.OPEN_FILE_MODAL](state) {
    state.openModal = true;
  },
  [SEARCH.CLEAR_FILE_MODAL](state) {
    state.openModal = false;
  },
  [SEARCH.TOGGLE_SET_SELLER_EMAIL_DIALOG](state, action) {
    state.modals.setSellerEmail.open = action.payload.open;
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
  },
  [SEARCH.SET_SELLER_EMAIL](state, action) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
    state.loading = true;
    state.error = false;
  },
  [SEARCH.SET_SELLER_EMAIL_SUCCESS](state, action) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...initialState.formSellerEmailValues,
    };
    state.loading = false;
    state.modals.setSellerEmail.open = false;
  },
  [SEARCH.SET_SELLER_EMAIL_FAIL](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMessage;
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
  },

  // BIKE DETAIL MARKETPLACE
  [SEARCH.GET_PUBLISHED_BIKE](state) {
    state.loaders.isLoading = true;
    state.errors = initialState.errors;
  },
  [SEARCH.GET_PUBLISHED_BIKE_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.publishBikeForm = action.payload.publishBikeForm;
  },
  [SEARCH.GET_PUBLISHED_BIKE_FAIL](state, action) {
    state.loaders.isLoading = false;
    state.errors.isErrors = true;
    state.errors.message = action.payload.errorMessage;
  },
  [SEARCH.SET_PUBLISH_BIKE_FORM_VALUES](state, action) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
    state.errors.message = '';
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...action.payload.newPublishBikeFormValues,
    };
  },
  [SEARCH.SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.redirect = true;
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...initialState.publishBikeForm,
    };
  },
  [SEARCH.SET_PUBLISH_BIKE_FORM_VALUES_FAIL](state, action) {
    state.loaders.isLoading = false;
    state.errors.isErrors = true;
  },
  [SEARCH.GET_COMMISSIONS](state) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
  },
  [SEARCH.GET_COMMISSIONS_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.commissions = {
      ...state.commissions,
      ...action.payload.commissions,
    };
  },
  [SEARCH.GET_COMMISSIONS_ERROR](state, action) {
    state.errors.isErrors = true;
    state.errors.message = action.payload.errorMessage;
  },
  [SEARCH.SAVE_PUBLISH_FORM_VALUES](state, action) {
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...action.payload.publishBikeForm,
    };
  },
  [SEARCH.DELETE_PUBLICATION](state, action) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
  },
  [SEARCH.DELETE_PUBLICATION_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.redirect = true;
  },
  [SEARCH.DELETE_PUBLICATION_FAIL](state, action) {
    state.loaders.isLoading = false;
    state.errors.isErrors = true;
  },
  [SEARCH.SET_SCREEN_MODE](state, action) {
    state.screenMode = action.payload.screenMode;
  },
  [SEARCH.SET_OPEN_REPORT_MODAL](state, action) {
    state.modals.report.open = action.payload.open;
    state.modals.report.bikeMarketId = action.payload.bikeMarketId;
  },
  [SEARCH.REPORT_PUBLISH](state) {
    state.loaders.isLoading = true;
  },
  [SEARCH.REPORT_PUBLISH_SUCCESS](state) {
    state.loaders.isLoading = false;
    state.modals.report.open = false;
    state.modals.report.bikeMarketId = 0;
  },
  [SEARCH.REPORT_PUBLISH_FAIL](state) {
    state.loaders.isLoading = false;
  },
  [SEARCH.TOGGLE_DISABLE_BIKE_SUCCESS](state) {
    state.openDisableBikeModal = false;
  },
  [SEARCH.OPEN_CLOSE_DISABLE_BIKE_MODAL](state, action) {
    state.openDisableBikeModal = action.payload.openDisableBikeModal;
  },
  [SEARCH.RESET_BRAND_CONTROLS](state) {
    state.formControls.brandId = initialState.formControls.brandId;
  },
});

export default search;
