import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, get, post } from 'utils/api';

import { TRANSFER } from 'screens/Transfer/reducer';

function* fetchTransfer() {
  yield takeLatest(TRANSFER.FETCH_TRANSFER, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFER.FETCH_TRANSFER));
    const response = yield get(`api/v1/transfers/${action.payload}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFER.FETCH_TRANSFER));
    if (!response.error) {
      yield put(apiSuccess(TRANSFER.FETCH_TRANSFER_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar los datos' }));
      yield put(apiSuccess(TRANSFER.FETCH_TRANSFER_ERROR));
    }
  });
}

function* fetchTransferPreview() {
  yield takeLatest(TRANSFER.FETCH_TRANSFER_PREVIEW, function* (action) {
    const body = action.payload;
    const { id } = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFER.FETCH_TRANSFER_PREVIEW));
    const response = yield post(`api/v1/transfers/${id}`, body);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFER.FETCH_TRANSFER_PREVIEW));
    if (!response.error) {
      yield put(apiSuccess(TRANSFER.FETCH_TRANSFER_PREVIEW_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response.errorMsg ?? 'Verifique los datos ingresados',
        }),
      );
      yield put(apiSuccess(TRANSFER.FETCH_TRANSFER_PREVIEW_ERROR));
    }
  });
}

function* transferValidation() {
  yield takeLatest(TRANSFER.TRANSFER_VALIDATION, function* (action) {
    const body = action.payload;
    const { id } = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFER.TRANSFER_VALIDATION));
    const response = yield post(`api/v1/transfers/${id}/transfer-validation`, body);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFER.TRANSFER_VALIDATION));

    if (!response.error) {
      yield put(apiSuccess(TRANSFER.TRANSFER_VALIDATION_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: response.errorMsg }));
      yield put(apiSuccess(TRANSFER.TRANSFER_VALIDATION_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(fetchTransfer);
  yield spawn(fetchTransferPreview);
  yield spawn(transferValidation);
}
