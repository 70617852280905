const styles = (theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
  },
  img: {
    position: 'absolute',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  btn: {
    position: 'absolute',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '60%',
  },
  eye: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 1,
    },
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  rootReport: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  reportText: {
    position: 'absolute',
    top: '80%',
  },
  blur: {
    position: 'absolute',
    filter: 'blur(0.5rem)',
    opacity: 0.5,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});

export default styles;
