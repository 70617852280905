const styles = (theme) => ({
  alertContainer: {
    margin: '20px auto',
    marginTop: '10px',
    maxWidth: '1022px',
    '& .message': {
      fontWeight: 'bold',
    },
  },
  bikeAlreadySoldAlert: {
    margin: '20px auto',
    marginBottom: '0px',
    maxWidth: '1022px',
    '& .message': {
      fontWeight: 'bold',
    },
  },
  alertPublishOnSocialMedia: {
    marginTop: '0px',
    marginBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1440px',
    '& .message': {
      fontWeight: 'bold',
    },
  },
});

export default styles;
