import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, post } from 'utils/api';

import { PAYMENT } from 'screens/Payment/reducer';

function* validateStatus() {
  yield takeLatest(PAYMENT.VALIDATE_STATUS, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, PAYMENT.VALIDATE_STATUS));
    const response = yield post(`api/v1/transfers/${id}/payment-status`);
    yield put(apiSuccess(APP.REMOVE_LOADING, PAYMENT.VALIDATE_STATUS));

    if (!response.error) {
      yield put(apiSuccess(PAYMENT.VALIDATE_STATUS_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia realizada exitosamente'));
    } else {
      yield put(apiSuccess(PAYMENT.VALIDATE_STATUS_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(validateStatus);
}
