const PATH = 'enablePaymentsScreen';

const enablePaymentScreenTypes = {
  INIT_STATE: `${PATH}/INIT_STATE`,
  GET_TRANSFER: `${PATH}/GET_TRANSFER`,
  GET_TRANSFER_SUCCESS: `${PATH}/GET_TRANSFER_SUCCESS`,
  GET_TRANSFER_FAILURE: `${PATH}/GET_TRANSFER_FAILURE`,
  SET_IS_OPEN_CONFIRM_MODAL: `${PATH}/SET_IS_OPEN_CONFIRM_MODAL`,
  SET_USER_PAYMENT_METHOD: `${PATH}/SET_USER_PAYMENT_METHOD`,
  SET_USER_PAYMENT_METHOD_SUCCESS: `${PATH}/SET_USER_PAYMENT_METHOD_SUCCESS`,
  SET_USER_PAYMENT_METHOD_FAILURE: `${PATH}/SET_USER_PAYMENT_METHOD_FAILURE`,
  TOGGLE_ERROR: `${PATH}/TOGGLE_ERROR`,
  SET_IS_OPEN_INFORMATIVE_MODAL: `${PATH}/SET_IS_OPEN_INFORMATIVE_MODAL`,
};

export default enablePaymentScreenTypes;
