import { makeStyles } from '@material-ui/core';

const useNotLoggedUserMessage = makeStyles((theme) => ({
  margin0Auto: {
    margin: '0 auto',
  },
  mWidth600: {
    maxWidth: '600px',
  },
  tAlingCenter: {
    textAlign: 'center',
  },
  mTop20: {
    marginTop: '20px',
  },
  fSize20: {
    fontSize: '20px',
  },
  fWeight700: {
    fontWeight: 700,
  },
  lineHeight19: {
    lineHeight: '19px',
  },
}));

export default useNotLoggedUserMessage;
