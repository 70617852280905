import React from 'react';
import { Button, Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export function InsuranceProcesing() {
  return (
    <Card>
      <CardContent>

        <Grid container direction="row" justifyContent="center" style={{ paddingTop: '1.5em' }} spacing={1}>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
            >
              Tu
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h4"
              align="center" color='primary'
            >
              seguro
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
            >
              está en proceso
            </Typography>

          </Grid>
        </Grid>
        <Typography
          variant="h6"
          align="center"
          style={{ paddingTop: '3em' }}
        >
          Tu pago está siendo procesado por
          {' '}
          <strong>BCI Seguros</strong>
          ,
          <br />
          una vez sea confirmado se te enviará la póliza a tu correo electrónico.
        </Typography>
      </CardContent>
      <CardActionArea>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: '3em', paddingBottom: '3em' }}
        >
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" href="/home">
              Mis bikes
            </Button>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card >
  );
}
