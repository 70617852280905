import React, { useEffect } from 'react';
import MaintenancesTable from 'commons/components/MaintenancesTable';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import WorkshopBook from '../WorkshopBook/WorkshopBook';

const SearchMaintenance = ({
  fetchMaintenance,
  bikeId,
  maintenances,
  handleGetFile,
  closeMaintenance,
}) => {
  useEffect(() => {
    fetchMaintenance(bikeId);
  }, [bikeId]);

  return (
    <Grid container>
      <WorkshopBook
        maintenances={maintenances}
        handleGetFile={handleGetFile}
        closeMaintenance={closeMaintenance}
      />
    </Grid>
  );
};
export default SearchMaintenance;
