/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const BankDataForm = (props) => {
  const { className, banks, accountTypes, submitButtonRef, setBankData, stateFormValues } = props;

  const [formValues, setFormValues] = useState({
    userName: '',
    rut: '',
    email: '',
    bankId: 0,
    accountTypeId: 0,
    account: '',
  });

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      ...stateFormValues,
    }));
  }, [stateFormValues]);

  const { userName, rut, email, bankId, accountTypeId, account } = formValues;

  // TODO: add use callback
  const onChangeInputs = (event) => {
    const { value, name } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setBankData(formValues);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      <TextField
        label="Nombre completo"
        variant="outlined"
        onChange={onChangeInputs}
        name="userName"
        value={userName}
        className="formInput"
      />

      <TextField
        label="R.U.T"
        variant="outlined"
        onChange={onChangeInputs}
        name="rut"
        value={rut}
        className="formInput"
      />

      <TextField
        label="Correo"
        variant="outlined"
        onChange={onChangeInputs}
        name="email"
        value={email}
        className="formInput"
      />

      <FormControl className="formInput" variant="outlined">
        <InputLabel id="select-id-1">Banco</InputLabel>
        <Select
          labelId="select-id-1"
          value={bankId}
          onChange={onChangeInputs}
          label="Banco"
          name="bankId">
          <MenuItem value={0}>Selecciona una opcion ...</MenuItem>
          {banks.map((bank) => (
            <MenuItem key={bank.id} value={bank.id}>
              {bank.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="formInput" variant="outlined">
        <InputLabel id="select-id-2">Tipo Cuenta</InputLabel>
        <Select
          labelId="select-id-2"
          value={accountTypeId}
          onChange={onChangeInputs}
          label="Tipo Cuenta"
          name="accountTypeId">
          <MenuItem value={0}>Selecciona una opcion ...</MenuItem>
          {accountTypes.map((accountType) => (
            <MenuItem key={accountType.id} value={accountType.id}>
              {accountType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="N° de Cuenta"
        variant="outlined"
        className="formInput"
        onChange={onChangeInputs}
        name="account"
        value={account}
      />
      <input type="submit" value="TEST" style={{ display: 'none' }} ref={submitButtonRef} />
    </form>
  );
};

BankDataForm.propTypes = {
  className: PropTypes.string,
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  accountTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submitButtonRef: PropTypes.shape({}).isRequired,
  setBankData: PropTypes.func.isRequired,
  stateFormValues: PropTypes.shape({}).isRequired,
};

BankDataForm.defaultProps = {
  className: '',
};

export default BankDataForm;
