import { createAction, createReducer } from 'utils/reducer';

const PATH = 'salesMethod/';

// Action Types
export const salesMethodActionTypes = {
  RESET_STATE: `${PATH}RESET_STATE`,
  UPDATE_SALES_METHOD: `${PATH}UPDATE_SALES_METHOD`,
  UPDATE_SALES_METHOD_SUCCESS: `${PATH}UPDATE_SALES_METHOD_SUCCESS`,
  UPDATE_SALES_METHOD_FAILURE: `${PATH}UPDATE_SALES_METHOD_FAILURE`,
  CHECK_IF_BIKE_WAS_ALREADY_SOLD: `${PATH}CHECK_IF_BIKE_WAS_ALREADY_SOLD`,
  CHECK_IF_BIKE_WAS_ALREADY_SOLD_ERROR: `${PATH}CHECK_IF_BIKE_WAS_ALREADY_SOLD_ERROR`,
  CHECK_IF_BIKE_WAS_ALREADY_SOLD_SUCCESS: `${PATH}CHECK_IF_BIKE_WAS_ALREADY_SOLD_SUCCESS`,
};

// Initial State
const initState = {
  redirect: false,
  loaders: {
    isLoading: false,
  },
  errors: {
    isErrors: false,
    message: '',
  },
  bikeAlreadySold: false,
};

// Actions
export const salesMethodActions = {
  resetState: createAction(salesMethodActionTypes.RESET_STATE),
  updatePaymentMethod: createAction(salesMethodActionTypes.UPDATE_SALES_METHOD, 'payload'),
  checkIfBikeWasAlreadySold: createAction(
    salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD,
    'payload',
  ),
};

// Reducer
const salesMethodReducer = createReducer(initState, {
  [salesMethodActionTypes.RESET_STATE](state, action) {
    return {
      ...state,
      ...initState,
    };
  },
  [salesMethodActionTypes.UPDATE_SALES_METHOD](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
    };
  },
  [salesMethodActionTypes.UPDATE_SALES_METHOD_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      redirect: true,
    };
  },
  [salesMethodActionTypes.UPDATE_SALES_METHOD_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        isErrors: true,
        message: action.payload.message,
      },
      redirect: false,
    };
  },
  [salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
    };
  },
  [salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD_ERROR](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
    };
  },
  [salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      bikeAlreadySold: action.payload.bikePublicationDeletedSoldReason,
    };
  },
});

export default salesMethodReducer;
