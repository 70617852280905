import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const infoUrl = process.env.REACT_APP_INFO_URL;

const CustomCheckBox = ({
  label, onChange, name, value, disabled, link = false, textLink = '',
}) => {
  const handleChange = () => {
    onChange({ target: { name, value: !value } });
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={(
        <Checkbox
          checked={value}
          onChange={handleChange}
          name={name}
          color="primary"
        />
)}
      label={
        !link ? label : (
          <>
            {label}
            {' '}
            <a href={`${infoUrl}/terminos-y-condiciones2/`} target="_blank" rel="noreferrer">{textLink}</a>
          </>
        )
      }
    />
  );
};

export default CustomCheckBox;
