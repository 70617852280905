import React from 'react';
import Dialog from '@material-ui/core/Dialog';

const DialogVideo = ({ onClose, open, videoUrl }) => (
  <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
    <iframe
      width="100%"
      height="315"
      src={`${videoUrl}?autoplay=1`}
      title="Video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  </Dialog>
);

export default DialogVideo;
