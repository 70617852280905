import React from 'react';
import {
  CircularProgress, Grid, TextField, Button, useMediaQuery,
} from '@material-ui/core';
import { formatTaxNumber } from 'utils/functions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import CustomCheckBox from 'commons/components/CustomCheckBox';

const PilotForm = ({
  changeControls, controls, onSubmit, dataDisabled, errors, loading,
}) => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const avoidEvent = (e) => {
    e.preventDefault();
  };
  return (
    <form action="Post" onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="name"
            name="name"
            label="Nombres"
            variant="outlined"
            fullWidth
            value={controls.name ?? ''}
            onChange={changeControls}
            error={errors.name}
            helperText={errors.name && errors.name[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="firstName"
            name="firstName"
            label="Apellido Paterno"
            variant="outlined"
            fullWidth
            onChange={changeControls}
            value={controls.firstName ?? ''}
            error={errors.firstName}
            helperText={errors.firstName && errors.firstName[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="secondName"
            name="secondName"
            label="Apellido Materno"
            variant="outlined"
            fullWidth
            autoComplete="off"
            onChange={changeControls}
            value={controls.secondName ?? ''}
            error={errors.secondName}
            helperText={errors.secondName && errors.secondName[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            // required
            id="taxNumber"
            name="taxNumber"
            label="RUT"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 12 }}
            onChange={(e) => changeControls(e, formatTaxNumber)}
            value={formatTaxNumber(controls.taxNumber) ?? ''}
            error={errors.taxNumber}
            helperText={errors.taxNumber && errors.taxNumber[0]}
          />
        </Grid>

        <Grid item xs={12} sm={5} align="center">
          <TextField
            type="date"
            InputLabelProps={{ shrink: true }}
            id="birthday"
            name="birthday"
            label="Fecha de nacimiento"
            variant="outlined"
            fullWidth
            onChange={changeControls}
            value={controls.birthday ?? ''}
            error={errors.birthday}
            helperText={errors.birthday && errors.birthday[0]}
          />
        </Grid>
        {!isSmallScreen && <Grid item xs={12} sm={5} />}
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="email"
            name="email"
            label="Correo electrónico"
            variant="outlined"
            fullWidth
            onChange={changeControls}
            value={controls.email ?? ''}
            error={errors.email}
            helperText={errors.email && errors.email[0]}
            disabled={dataDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="verifyEmail"
            name="verifyEmail"
            label="Confirmar correo electrónico"
            variant="outlined"
            fullWidth
            onChange={changeControls}
            value={controls.verifyEmail ?? ''}
            error={errors.verifyEmail}
            helperText={errors.verifyEmail && errors.verifyEmail[0]}
            disabled={dataDisabled}
            onPaste={avoidEvent}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            type="password"
            id="password"
            name="password"
            label="Contraseña"
            variant="outlined"
            fullWidth
            onChange={changeControls}
            value={controls.password ?? ''}
            error={errors.password}
            helperText={errors.password && errors.password[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            id="verifyPassword"
            name="verifyPassword"
            label="Confirmar contraseña"
            variant="outlined"
            fullWidth
            autoComplete="off"
            type="password"
            onChange={changeControls}
            value={controls.verifyPassword ?? ''}
            error={errors.verifyPassword}
            helperText={errors.verifyPassword && errors.verifyPassword[0]}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <CustomCheckBox
            onChange={changeControls}
            label="He leído y acepto los"
            name="acceptTerms"
            value={controls.acceptTerms}
            link
            textLink="términos y condiciones"
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container>
            <Grid item xs={4} sm={4}>
              <IconButton component="span" onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4} sm={4} align="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                id="btn-register-user"
                disabled={!controls.acceptTerms}
              >
                {loading ? <CircularProgress size={24} /> : 'Registrar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PilotForm;
