import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const types = {
  'left_side': 'Lateral izquierdo',
  'front_side': 'Frente',
  'right_side': 'Lateral derecho',
  'own_invoice': 'Factura propia',
};

const ModalPhotos = ({ open, handleClose, photos }) => {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent >

        {photos.length === 0 ? <DialogContentText>No hay fotos</DialogContentText> :
          <Carousel interval={10000}>
            {
              photos.map((item) => (
                <div key={item.id}>
                  <DialogContentText>{types[item.type]}</DialogContentText>
                  {item.file.name.includes('.pdf') ?
                    <iframe
                      src={`${item.file.url}#toolbar=0&navpanes=0&scrollbar=0`}
                      height="390"
                      width="300"
                      frameBorder="0"
                    /> :
                    <img src={item.file.url} alt={item.file.name} width="100%" height="auto" />
                  }
                </div>
              ))
            }

          </Carousel>
        }

      </DialogContent>
    </Dialog>
  );
}

export default ModalPhotos;