const styles = (theme) => ({
  root: {
    marginBottom: 16,
  },
  item: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  img: {
    height: 500,
    width: '100%',
    objectFit: 'cover',
  },
  txt: {
    position: 'absolute',
    paddingLeft: '3rem',
  },
});

export default styles;
