import React, { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
// eslint-disable-next-line import/prefer-default-export
export function PaymentWindow(props) {
  const [windowRef, setWindowRef] = useState(null);

  const { setIsWindowOpen, url } = props;

  const openWindow = () => {
    const newWindow = window.open(url, '_blank', 'width=1000, height=800, top=80, left=100');
    setWindowRef(newWindow);
    setIsWindowOpen(true);

    const checkWindowStatus = () => {
      if (newWindow.closed) {
        clearInterval(checking);
        setIsWindowOpen(false);
      }
    };

    const checking = setInterval(checkWindowStatus, 1000);
  };
  useEffect(() => {
    openWindow();
  }, []);
  return (
    <>
      <Card>
        <CardContent>
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            // style={{ display: 'flex' }}
          >
            <Grid item xs={12} sm={9}>
              <LinearProgress />
              <Typography variant="h6" align="center">
                BCI Seguros se encargará de recibir tu pago.
                <br />
                No olvides cerrar la ventana externa cuando termines.
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />
        </CardContent>
      </Card>
    </>
  );
}
