import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogVideo from '../DialogVideo';

const HelpGoodPhotos = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography variant="body1">
        ¿Cómo tomar buenas fotos? Mira
        <Button color="primary" onClick={handleClickOpen}>
          AQUI
        </Button>
      </Typography>
      <DialogVideo videoUrl="https://www.youtube.com/embed/Xr7KBMOaYI4" open={open} onClose={handleClose} />
    </div>
  );
};

export default HelpGoodPhotos;
