import { createAction, createReducer } from 'utils/reducer';

const PATH = 'cancelBikeRegistration/';

export const CANCEL_BIKE_REGISTRATION = {
  CANCEL_REGISTRATION: `${PATH}CANCEL_REGISTRATION`,
  CANCEL_REGISTRATION_SUCCESS: `${PATH}CANCEL_REGISTRATION_SUCCESS`,
  CANCEL_REGISTRATION_ERROR: `${PATH}CANCEL_REGISTRATION_ERROR`,

  SET_ERROR: `${PATH}SET_ERROR`,
};

export const cancelBikeRegistratorActions = {
  cancelRegistration: createAction(CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION, 'payload'),
  setError: createAction(CANCEL_BIKE_REGISTRATION.SET_ERROR),
};

const initialState = {
  loading: false,
  message: '',
  error: false,
};

const activate = createReducer(initialState, {
  [CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION](state) {
    state.loading = true;
    state.error = false;
  },
  [CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION_SUCCESS](state, action) {
    state.loading = false;
    state.message = action.payload;
    state.error = false;
  },
  [CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION_ERROR](state, action) {
    state.loading = false;
    state.message = action.payload;
    state.error = true;
  },
  [CANCEL_BIKE_REGISTRATION.SET_ERROR](state) {
    state.error = true;
    state.message = 'Token inválido';
  },
});

export default activate;
