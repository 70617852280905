import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import SearchForm from 'screens/Search/components/SearchForm';
import SearchSummary from 'screens/Search/components/SearchSummary';
import { composedComponent, validateEmail } from 'utils/functions';
import { homeActions } from 'screens/Home/reducer';
import { searchActions } from 'screens/Search/reducer';
import { appActions } from 'commons/reducer';
import saga from 'screens/Search/saga';
import sagaHome from 'screens/Home/saga';
import { useForm } from 'utils/hooks/useForm';
import SearchMaintenance from 'screens/Search/components/SearchMaintenance';
import FileModal from 'commons/components/FileModal';

import { withStyles } from '@material-ui/core';
import BikeDetail from 'screens/Search/components/BikeDetail/BikeDetail';
import { useDispatch } from 'react-redux';
import { adminActions } from 'screens/Admin/reducer';
import bike from 'screens/Bike/reducer';
import SendEmailDialog from '../../commons/components/SendEmailDialog/SendEmailDialog';
import styles from './styles';

const Search = (props) => {
  const {
    classes,
    actions,
    search: searchState,
    objects,
    history,
    user,
    admin: adminState,
  } = props;

  const { formSellerEmailValues, modals, redirect, error, loaders } = searchState;

  const { isLoading } = loaders;

  const { setErrorsSellerEmail, setSellerEmail, toggleSetSellerEmailDialog, searchResetControls } =
    actions;

  const { buttonsContainer } = classes;

  const { transferId } = useParams();

  const [maintenanceId, setMaintenanceId] = useState(0);

  useEffect(() => {
    props.actions.searchFetchBrands();
    props.actions.searchFetchVehicleTypes();
    props.actions.searchFetchMotorbikeBrands();
  }, []);

  useEffect(() => {
    if (error && redirect) {
      history.push('');
      searchResetControls();
    }
  }, [error, redirect]);

  useEffect(() => {
    if (props.bikeSuccess) {
      history.push({
        pathname: `/search/${props.formControls.brandId}/${props.formControls.serial}`,
        state: { fromSearchView: true },
      });
      return props.actions.searchResetControls;
    }
  }, [props.bikeSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(() => props.actions.validateBike(props.formControls));
  };

  const changeControls = useCallback((name, value) => {
    props.actions.searchChangeControls({ [name]: value });
    if (name === 'vehicleTypeId') {
      props.actions.resetBrandControls();
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      vehicleTypeId: [
        {
          check: (value) => !!value,
          message: 'El tipo de vehículo es requerido',
        },
      ],
      brandId: [
        {
          check: (value) => !!value,
          message: 'La Marca es requerida',
        },
      ],
      serial: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Código de serie es requerido',
        },
      ],
    },
  });

  const handleToggleDisableBike = ({ formValues, isDisable, bikeId }) => {
    props.actions.toggleDisableBikeAction({
      formValues,
      isDisable,
      bikeId,
    });
  };

  const brands = props.order?.brands
    ?.map((b) => props.brands[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const vehicleTypes = props.order?.vehicleTypes
    ?.map((vt) => props.vehicleTypes[vt])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const maintenances = props.order?.maintenances
    ?.map((b) => props.maintenances[b])
    .map((a) => ({
      id: a?.id,
      type: a?.type,
      description: a?.description,
      date: a?.date,
    }));

  const motorbikeBrands = props.order?.motorbikeBrands
    ?.map((mb) => props.motorbikeBrands[mb])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const handleGetFile = (id) => {
    const { name, url } = props.objects.maintenances[id].file;
    setMaintenanceId(id);
    let extension = name.split('.');
    extension = extension[extension.length - 1];

    props.actions.changeDataFile({
      extension,
      url,
      name,
    });
    props.actions.openFileModal();
  };

  const handleSetSellerEmail = ({ formSellerEmailValues }) => {
    const validEmail = validateEmail(formSellerEmailValues.buyerEmail.value);

    if (!validEmail) {
      return setErrorsSellerEmail({
        formSellerEmailValues: {
          ...formSellerEmailValues,
          buyerEmail: {
            ...formSellerEmailValues.buyerEmail,
            isValid: false,
            helperText: 'El correo es invalido',
          },
        },
      });
    }

    setSellerEmail({
      formSellerEmailValues,
    });
  };

  const handleOpenDisableBikeModal = (boolean) => {
    props.actions.openDisableBikeModal({ openDisableBikeModal: boolean });
  };

  return (
    <Grid container justifyContent="center">
      <Switch>
        <Route
          exact
          path="/search"
          render={() => (
            <Grid item xs={10} sm={10}>
              <Card>
                <CardContent>
                  <SearchForm
                    brands={brands}
                    motorbikeBrands={motorbikeBrands}
                    vehicleTypes={vehicleTypes}
                    controls={props.formControls}
                    onControlChange={onChange}
                    onSubmit={handleSubmit}
                    loading={props.loading}
                    errors={errors}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        />
        <Route
          exact
          path="/search/:brandId/:serial"
          render={() => (
            <Grid item xs={10} sm={10}>
              <Card>
                <CardContent>
                  {props.viewMaintenance ? (
                    <SearchMaintenance
                      bikeId={props.bike.id}
                      fetchMaintenance={props.actions.fetchMaintenance}
                      closeMaintenance={props.actions.closeMaintenance}
                      maintenances={maintenances}
                      handleGetFile={handleGetFile}
                    />
                  ) : (
                    <SearchSummary
                      params={props.match.params}
                      searchBike={actions.searchBike}
                      requestAccess={actions.requestAccess}
                      bike={props.bike}
                      buyer={props.buyer}
                      viewMaintenance={props.actions.viewMaintenance}
                      user={props.user?.user || {}}
                      adminState={adminState}
                      styles={{ buttonsContainer }}
                      objects={objects}
                      actions={actions}
                      handleToggleDisableBike={handleToggleDisableBike}
                      handleOpenDisableBikeModal={handleOpenDisableBikeModal}
                      openDisableBikeModal={searchState.openDisableBikeModal}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        />
      </Switch>
      <FileModal
        open={props.openModal}
        clear={props.actions.closeFileModal}
        fileData={props.dataFile}
        maintenance={props.objects.maintenances[maintenanceId]}
      />
      <SendEmailDialog
        formSellerEmailValues={formSellerEmailValues}
        open={modals.setSellerEmail.open}
        toggleSetSellerEmailDialog={toggleSetSellerEmailDialog}
        handleSetSellerEmail={handleSetSellerEmail}
      />
    </Grid>
  );
};

export default composedComponent(Search, saga, {
  states: [
    'search.formControls',
    'search.bikeSuccess',
    'search.loading',
    'search.viewMaintenance',
    'search.openModal',
    'search.dataFile',
    'search',
    'admin',
    'app.objects',
    'app.objects.buyer',
    'app.objects.bike',
    'app.objects.brands',
    'app.objects.motorbikeBrands',
    'app.objects.vehicleTypes',
    'app.objects.maintenances',
    'app.order',
    'app.user',
  ],
  actions: [searchActions, appActions, adminActions],
  saga: 'searchSaga',
  middlewares: [withStyles(styles)],
});
