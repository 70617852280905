import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import produce from 'immer';
// Material
import {
  CardContent,
  CircularProgress,
  Typography,
  Snackbar,
  withStyles,
  CardMedia,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
// Project
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { getParameterByName, composedComponent, validateEmail } from 'utils/functions';
import { appActions } from 'commons/reducer';
import Alert from '@material-ui/lab/Alert';
import { useForm } from 'utils/hooks/useForm';
import logo from 'commons/assets/logo-bikfy-main.svg';
import { useQuery } from 'utils/hooks/useQuery';
import { PATHS } from 'utils/paths';
import styles from './styles';
import saga from './saga';
import { loginActions } from './reducer';
import { ResendEmailActivationDialog } from './components/ResendEmailActivationDialog';

const Login = (props) => {
  const {
    classes, actions, controls, modals,
  } = props;
  const [notification, setNotification] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log('Google Login Success:', credentialResponse.credential);

    // Dispatch the credential to a Redux saga or another handler
    actions.googleLogin(credentialResponse.credential);
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed', error);
    actions.showError('Google login failed. Please try again.');
  };

  const onChangeText = useCallback((id, value) => {
    if (id === 'password') {
      setState(
        produce((draft) => {
          draft[id] = value;
        }),
      );
    } else {
      setState(
        produce((draft) => {
          draft[id] = value?.trim();
        }),
      );
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Contraseña obligatoria',
        },
      ],
      email: [
        {
          check: (value) => value != null && value.trim().length > 0,
          message: 'Correo electrónico obligatorio',
        },
        {
          check: (value) => validateEmail(value),
          message: 'Correo electrónico inválido',
        },
      ],
    },
  });

  function onClickLogin(event) {
    event.preventDefault();
    onSubmit(() => actions.login(state));
  }

  useEffect(() => {
    const token = getParameterByName('token', props.location.search);
    if (token) {
      actions.verify(token);
    }
  }, [actions, props.location.seaprops, props.location.search]);

  useEffect(() => {
    const ref = getParameterByName('ref', location.search);
    if (ref === 'notification') setNotification(true);
  }, [notification]);

  useEffect(() => {
    if (props.user) {
      const redirectTo = query.get('href');
      if (redirectTo) return history.replace(redirectTo);

      const previousPath = history.location?.state?.from; // Assuming `state.from` is used

      const isRegisterRoute = previousPath?.startsWith('/register');

      if (!isRegisterRoute && history.length > 2) {
        return history.goBack(); // Only go back if not returning to the register route
      }

      return history.replace(PATHS.HOME); // Fallback to home
    }
  }, [props.user]);

  function toggleError() {
    props.actions.toggleError();
  }

  function toggleSuccess() {
    props.actions.toggleSuccess();
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID}>
      <div style={styles.container}>
        <div style={styles.containerContent}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ margin: 0, height: '100vh' }}
          >
            <Grid item md={4} lg={4} xs={12}>
              <Card>
                <CardMedia image={logo} />
                <CardContent style={styles.cardContent}>
                  <form onSubmit={onClickLogin}>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={10} align="center">
                        <img style={{ height: 100 }} src={logo} alt="" />
                      </Grid>
                      <Grid item xs={10} align="center">
                        <Typography variant="caption">
                          <p style={{ fontSize: '1rem' }}>Inicia sesión</p>
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        {notification && (
                        <Alert severity="info">
                          Se ha enviado un correo de confirmación para que actives tu cuenta o puedes iniciar sesión con Google utilizando el mismo correo electrónico.
                        </Alert>
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          className={classes.field}
                          autoFocus
                          label="Correo electrónico"
                          id="email"
                          name="email"
                          value={state.email}
                          onChange={onChange}
                          style={{ paddingBottom: '8px' }}
                          fullWidth
                          error={errors.email}
                          helperText={errors.email && errors.email[0]}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          className={classes.field}
                          label="Contraseña"
                          onChange={onChange}
                          value={state.password}
                          id="password"
                          name="password"
                          fullWidth
                          type="password"
                          error={errors.password}
                          helperText={errors.password}
                        />
                      </Grid>
                      {props.controls.error && (
                      <Grid item xs={10}>
                        <Typography style={{ color: 'red' }} align="center" variant="caption">
                          Credenciales inválidas
                        </Typography>
                      </Grid>
                      )}
                      <Grid item xs={10} className={classes.alignRight} style={{ marginTop: '24px' }}>
                        <Typography variant="caption" color="primary">
                          <Link
                            to="/register"
                            style={{ textDecoration: 'none', color: 'black', fontSize: '0,7rem' }}
                            variant="inherit"
                            color="primary"
                          >
                            ¿No tienes cuenta?
                            {' '}
                            <strong>Regístrate</strong>
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.alignRight} style={{ marginTop: '1px', paddingTop: '1px' }}>
                        <Typography variant="caption">
                          ¿Tu cuenta no ha sido activada?
                          <Button
                            variant="text"
                            onClick={() => { actions.setResendActivationDialog(true); }}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              textTransform: 'none',
                              fontSize: '0.75rem',
                            }}
                            color="primary"
                          >
                            <strong> Reenviar correo de activación </strong>
                          </Button>
                        </Typography>
                      </Grid>
                      <Grid item xs={10} align="center">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginTop: '5px' }}
                        >
                          {props.controls.loading ? <CircularProgress size={24} /> : 'Ingresar'}
                        </Button>
                      </Grid>
                      <Grid item xs={8} align="center" style={{ marginTop: '20px' }}>
                        <Typography variant="caption">O accede con Google:</Typography>
                        <GoogleLogin
                          onSuccess={handleGoogleLoginSuccess}
                          onError={handleGoogleLoginFailure}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="caption" color="primary">
                          <Link
                            to="/recovery-password"
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                              fontSize: '0.7rem',
                            }}
                            variant="inherit"
                            color="primary"
                          >
                            Restablecer contraseña
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={props.error}
          autoHideDuration={3000}
          onClose={toggleError}
        >
          <Alert onClose={toggleError} severity="error">
            {props.errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={props.success}
          autoHideDuration={3000}
          onClose={toggleSuccess}
        >
          <Alert onClose={toggleSuccess} severity="success">
            {props.successMsg}
          </Alert>
        </Snackbar>
        <ResendEmailActivationDialog
          open={modals.resendActivation.open}
          setOpen={actions.setResendActivationDialog}
          onSubmit={actions.resendEmailActivation}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default composedComponent(Login, saga, {
  saga: 'sagaLogin',
  middlewares: [withStyles(styles)],
  states: [
    'login.controls',
    'login.modals',
    'app.user',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
  ],
  actions: [loginActions, appActions],
});
