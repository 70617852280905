import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@material-ui/core';

const defaultOption = 'credit_card_with_fees|RANDOM';

const OrderBySelect = (props) => {
  const { sortBikes, storeValue } = props;
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    setSelectedOption(`${storeValue.field}|${storeValue.order}`);
  }, [storeValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    /* setSelectedOption(value); */
    sortBikes(value);
  };

  return (
    <TextField
      value={selectedOption}
      select
      variant="outlined"
      onChange={handleChange}
      size="small"
      style={{ width: '100%' }}
    >
      <MenuItem value={defaultOption}>Más Destacadas</MenuItem>
      <MenuItem value="price|DESC">Precio (mayor a menor)</MenuItem>
      <MenuItem value="price|ASC">Precio (menor a mayor)</MenuItem>
      {/* <MenuItem value="year|DESC">Año Bike (más reciente)</MenuItem>
      <MenuItem value="year|ASC">Año Bike (más antiguo)</MenuItem> */}
      <MenuItem value="created_at|DESC">Publicación (más reciente)</MenuItem>
      <MenuItem value="created_at|ASC">Publicación (más antiguo)</MenuItem>
    </TextField>
  );
};

OrderBySelect.propTypes = {
  sortBikes: PropTypes.func.isRequired,
};

export default OrderBySelect;
