import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import logo from 'commons/assets/logoNavbar.svg';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { useHistory } from 'react-router-dom';

const NavbarRegister = () => {
  const history = useHistory();
  return (
    <AppBar position="static" color="inherit" style={{ marginBottom: '24px' }}>
      <Toolbar>
        <IconButton onClick={() => history.push('/')}>
          <img style={{ height: '40px' }} src={logo} alt="" />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }} />
        <Button
          onClick={() => history.push('/login')}
          color="primary"
        >
          Inicia sesión
        </Button>

      </Toolbar>
    </AppBar>
  );
};

export default NavbarRegister;
