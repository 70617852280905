import { spawn, takeLatest, put } from 'redux-saga/effects';

import * as APP from 'commons/reducer';

import apiRequest from '../../utils/api';
import bankDataScreenTypes from './bankDataScreen.types';

function* getBankData(action) {
  yield takeLatest(bankDataScreenTypes.GET_BANK_DATA, function* getBankDataGenerator(action) {
    const { payload } = action;
    const { tranferId } = payload;

    const response = yield apiRequest(
      `api/v1/transfers/${tranferId}/get-bank-info`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      const { accountTypes, banks, bankData } = response;

      const formValues = bankData
        ? { ...bankData }
        : {
            userName: '',
            rut: '',
            email: '',
            bankId: 0,
            accountTypeId: 0,
            account: '',
          };

      yield put({
        type: bankDataScreenTypes.GET_BANK_DATA_SUCCESS,
        payload: {
          banks,
          accountTypes,
          formValues,
        },
      });
    } else {
      const { errorMessage } = JSON.parse(response.errorMsg);
      yield put({
        type: bankDataScreenTypes.GET_BANK_DATA_FAILURE,
        payload: { errorMessage },
      });
    }
  });
}

function* getStoreBankData(action) {
  yield takeLatest(
    bankDataScreenTypes.GET_STORE_BANK_DATA,
    function* getStoreBankDataGenerator(action) {
      const { userId } = action.payload;

      const response = yield apiRequest(`api/v1/bank-data/${userId}`, { method: 'GET' }, true);

      if (!response.error) {
        const { accountTypes, banks, bankData } = response;

        const formValues = bankData
          ? { ...bankData }
          : {
              userName: '',
              rut: '',
              email: '',
              bankId: 0,
              accountTypeId: 0,
              account: '',
            };

        yield put({
          type: bankDataScreenTypes.GET_BANK_DATA_SUCCESS,
          payload: {
            banks,
            accountTypes,
            formValues,
          },
        });
      } else {
        const { errorMessage } = JSON.parse(response.errorMsg);
        yield put({
          type: bankDataScreenTypes.GET_BANK_DATA_FAILURE,
          payload: { errorMessage },
        });
      }
    },
  );
}

function* setUserDataBank() {
  yield takeLatest(
    bankDataScreenTypes.SET_USER_DATA_BANK,
    function* setUserDataBankGenerator(action, state) {
      const { formValues, transferId } = action.payload;

      const body = JSON.stringify(formValues);

      const response = yield apiRequest(`api/v1/transfers/${transferId}/bank-data`, {
        method: 'POST',
        body,
      });

      if (!response.error) {
        const { bankDataId } = response;
        yield put({
          type: bankDataScreenTypes.SET_USER_DATA_BANK_SUCCESS,
          payload: { bankDataId },
        });
        yield put({ type: APP.SET_SUCCESS, payload: 'Datos Bancarios actualizados con éxito' });
      } else {
        const { errorMessage } = JSON.parse(response.errorMsg);
        yield put({
          type: bankDataScreenTypes.SET_USER_DATA_BANK_FAILURE,
          payload: { errorMessage },
        });
        yield put({
          type: APP.SET_ERROR,
          payload: { errorMsg: 'Ha ocurrido un error, por favor intentelo de nuevo' },
        });
      }
    },
  );
}

function* setStoreDataBank() {
  yield takeLatest(
    bankDataScreenTypes.SET_STORE_DATA_BANK,
    function* setStoreDataBankGenerator(action, state) {
      const { formValues, userId } = action.payload;

      const body = JSON.stringify(formValues);

      const response = yield apiRequest(`api/v1/bank-data/${userId}`, {
        method: 'POST',
        body,
      });

      if (!response.error) {
        const { bankDataId } = response;
        yield put({
          type: bankDataScreenTypes.SET_STORE_DATA_BANK_SUCCESS,
          payload: { bankDataId },
        });
        yield put({ type: APP.SET_SUCCESS, payload: 'Datos Bancarios actualizados con éxito' });
      } else {
        const { errorMessage } = JSON.parse(response.errorMsg);
        yield put({
          type: bankDataScreenTypes.SET_STORE_DATA_BANK_FAILURE,
          payload: { errorMessage },
        });
        yield put({
          type: APP.SET_ERROR,
          payload: { errorMsg: 'Ha ocurrido un error, por favor intentelo de nuevo' },
        });
      }
    },
  );
}

export default function* bankDataSaga() {
  yield spawn(getBankData);
  yield spawn(setUserDataBank);
  yield spawn(getStoreBankData);
  yield spawn(setStoreDataBank);
}
