import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import { formatNumberToCLP } from 'utils/functions';
// eslint-disable-next-line import/prefer-default-export, react/prop-types
export function PlanCard({ info }) {
  const {
    title, price, features, onClick, bciQouteId, downloadPdf, loadingPdf, loadingPurchase, disabled
  } = info;

  return (
    <Card raised style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      <CardHeader
        component={() => (
          <>
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={1}
            >
              <br />
              <Grid item xs>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '26px',
                    fontWeight: '500',
                    textAlign: 'center',
                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <br />
              <Grid item xs>
                {features.map((feature) => (
                  <>
                    <Grid container direction="row" alignItems="center" justifyContent="center" style={{ paddingLeft: '3em', paddingRight: '3em' }}>
                      <Grid item xs={2}>
                        <CheckIcon color="primary" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body2" >
                          <strong>
                            {feature.title}
                          </strong>
                          {': '}
                          {feature.description}
                        </Typography>
                      </Grid>
                    </Grid>
                    <br />
                  </>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      />

      <CardContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify-content='flex-end'
          spacing={1}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="body2">
                {loadingPdf
                  ? (
                    <Button variant="text" onClick={downloadPdf} startIcon={<CircularProgress size={20} />} color="primary"> Descargando cotización </Button>
                  )
                  : (
                    <Button variant="text" onClick={downloadPdf} startIcon={<GetAppIcon />} color="primary"> Descargar cotización </Button>
                  )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ width: '14em', height: '2px' }} />
          </Grid>
          <br />
          <Grid container justifyContent="center" alignItems="center" direction="column" spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" style={{ fontSize: '40px', fontWeight: '500' }}>
                {formatNumberToCLP(price)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Mensual Aproximado
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              style={{ textTransform: 'uppercase' }}
              startIcon={loadingPurchase ? <CircularProgress size={15} color="inherit" /> : null}
              disabled={loadingPurchase || disabled}
            >
              Contratar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}
