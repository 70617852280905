import React from 'react';
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

const ClickTooltip = ({ title, component: Component, styles = {} }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isMobileScreen) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
            placement="right"
            arrow>
            <Component onClick={handleTooltipOpen} />
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <Tooltip title={title} arrow>
      <Component style={styles} />
    </Tooltip>
  );
};

export default ClickTooltip;
