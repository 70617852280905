import React, { useState } from 'react';

import {
  Button, Menu, MenuItem, Tooltip,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { composedComponent } from 'utils/functions';
import { homeActions } from 'screens/Home/reducer';
import { appActions } from 'commons/reducer';
import saga from 'screens/Home/saga';
import { view } from 'utils/pathTypes';

const OptionButton = (props) => {
  const {
    transferId,
    brandId,
    isMozaicList,
    bikeSerial,
    isReportedBike,
    isCompleteBikeData,
    goToEditPage,
    goToReport,
    goToReportBikfy,
    classes,
    bikeMarket,
    handleToggleReportPublishModal,
    handleClickCopyEmailButton,
    handleClickWhatsAppButton,
    doNothing,
    disabled,
    user,
    bikeDisabled,
    transfer,
    handleRemoveStoreAccessAction,
    pathType,
    handleTransferButton,
    handleWorkshopButton,
    handlePublishButton,
    handleInsuranceButton,
    insuranceDisableHandler,
    motorbikeInformation,
    isBicycle,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const { isPublished, accessedBy } = transfer || {};

  const handleClickOptionButton = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEditOptionMenu = (event) => {
    event.stopPropagation();
    goToEditPage({ transferId });
  };

  const handleClickReportOptionMenu = (event) => {
    event.stopPropagation();
    goToReport({ transferId });
  };

  const handleClickBikfyReportOptionMenu = (event) => {
    event.stopPropagation();
    const serial = isBicycle ? bikeSerial : motorbikeInformation.chassisSerial;
    goToReportBikfy({ brandId, bikeSerial: serial });
  };

  const handleClickReportPublish = (event) => {
    event.stopPropagation();
    handleToggleReportPublishModal({ bikeMarketId: bikeMarket?.id });
  };

  const mozaicButtons = [
    {
      label: 'Enviar Correo',
      onClick: user ? handleClickCopyEmailButton : doNothing,
      id: 'btn-send-email',
    },
  ];

  if (bikeMarket?.showContact) {
    mozaicButtons.push({
      label: 'Enviar WhatsApp',
      onClick: handleClickWhatsAppButton,
      id: 'btn-send-whatsapp',
    });
  }

  const marketPlaceButtons = [
    {
      label: 'Reportar',
      onClick: user ? handleClickReportPublish : doNothing,
    },
  ];

  if (isMozaicList) {
    marketPlaceButtons.push(...mozaicButtons);
  }

  const pilotButtons = [
    {
      label: 'Taller',
      disabled: disabled || bikeDisabled || !isCompleteBikeData,
      onClick: handleWorkshopButton,
    },
    {
      label: transfer.isPublished ? 'Ver publicación' : 'Publicar',
      disabled: disabled || bikeDisabled || !isCompleteBikeData,
      onClick: handlePublishButton,
    },
    {
      label: 'Vender/Transferir',
      disabled: disabled || bikeDisabled || !isCompleteBikeData,
      onClick: handleTransferButton,
    },
    {
      label: 'Editar',
      disabled: false,
      onClick: handleClickEditOptionMenu,
    },
    {
      label: 'Denunciar',
      disabled: !(!isReportedBike && isCompleteBikeData) || bikeDisabled,
      onClick: handleClickReportOptionMenu,
    },
    {
      label: 'Informe BIKFY',
      disabled: bikeDisabled,
      onClick: handleClickBikfyReportOptionMenu,
    },
  ];

  if (transfer?.bike?.isBicycle) {
    pilotButtons.unshift({
      label: 'Asegurar',
      disabled: insuranceDisableHandler,
      onClick: handleInsuranceButton,
    });
  }

  if (accessedBy) {
    pilotButtons.push({
      label: 'Quitar Acceso a la tienda',
      disabled: false,
      onClick: (event) => {
        event.stopPropagation();
        handleRemoveStoreAccessAction();
      },
    });
  }

  return (
    <>
      <Button onClick={handleClickOptionButton} disabled={disabled}>
        <MoreVertIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseOptionMenu}>
        {pathType === view.MARKETPLACE
          && marketPlaceButtons.map(({
            label, disabled, onClick, id,
          }, index) => (
            <Tooltip key={index} title="">
              <MenuItem
                key={label}
                onClick={onClick}
                disabled={disabled}
                className={classes.bikfyGreyColor}
                id={id}
              >
                {label}
              </MenuItem>
            </Tooltip>
          ))}

        {pathType !== view.MARKETPLACE
          && pilotButtons.map(({ label, disabled, onClick }) => (
            <MenuItem key={label} onClick={onClick} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

// export default OptionButton;
export default composedComponent(OptionButton, saga, {
  saga: 'sagaHome',
  middlewares: [],
  states: ['app.user', 'home', 'home.pathType'],
  actions: [homeActions, appActions],
});
