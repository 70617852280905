import { createAction, createReducer } from 'utils/reducer';

const PATH = 'insurance/';

export const INSURANCE = {
  CHANGE_BENEFICIARY_CONTROLS: `${PATH}CHANGE_BENEFICIARY_CONTROLS`,
  CHANGE_BIKE_CONTROLS: `${PATH}CHANGE_BIKE_CONTROLS`,
  FETCH_BIKE: `${PATH}FETCH_BIKE`,
  FETCH_BIKE_SUCCESS: `${PATH}FETCH_BIKE_SUCCESS`,
  FETCH_BIKE_ERROR: `${PATH}FETCH_BIKE_ERROR`,
  CHANGE_STEP: `${PATH}CHANGE_STEP`,
  REGISTER_INSURANCE_QUOTES: `${PATH}REGISTER_INSURANCE_QUOTES`,
  REGISTER_INSURANCE_QUOTES_SUCCESS: `${PATH}REGISTER_INSURANCE_QUOTES_SUCCESS`,
  REGISTER_INSURANCE_QUOTES_ERROR: `${PATH}REGISTER_INSURANCE_QUOTES_ERROR`,
  SET_QUOTES: `${PATH}SET_QUOTES`,

  GENERATE_PDF_INSURANCE_BASIC_QUOTE: `${PATH}GENERATE_PDF_INSURANCE_BASIC_QUOTE`,
  GENERATE_PDF_INSURANCE_FULL_QUOTE: `${PATH}GENERATE_PDF_INSURANCE_FULL_QUOTE`,
  GENERATE_PDF_INSURANCE_QUOTE_SUCCESS: `${PATH}GENERATE_PDF_INSURANCE_QUOTE_SUCCESS`,
  GENERATE_PDF_INSURANCE_QUOTE_ERROR: `${PATH}GENERATE_PDF_INSURANCE_QUOTE_ERROR`,

  ACCEPT_BASIC_INSURANCE_QUOTE: `${PATH}ACCEPT_BASIC_INSURANCE_QUOTE`,
  ACCEPT_FULL_INSURANCE_QUOTE: `${PATH}ACCEPT_FULL_INSURANCE_QUOTE`,
  ACCEPT_INSURANCE_QUOTE_SUCCESS: `${PATH}ACCEPT_INSURANCE_QUOTE_SUCCESS`,
  ACCEPT_INSURANCE_QUOTE_ERROR: `${PATH}ACCEPT_INSURANCE_QUOTE_ERROR`,
  ACCEPT_INSURANCE_QUOTE_NOT_PROCESSED: `${PATH}ACCEPT_INSURANCE_QUOTE_NOT_PROCESSED`,

  STATUS_INSURANCE_QUOTE: `${PATH}STATUS_INSURANCE_QUOTE`,
  STATUS_INSURANCE_QUOTE_SUCCESS: `${PATH}STATUS_INSURANCE_QUOTE_SUCCESS`,
  STATUS_INSURANCE_QUOTE_ERROR: `${PATH}STATUS_INSURANCE_QUOTE_ERROR`,
};

export const insuranceActions = {
  changeBeneficiaryControls: createAction(INSURANCE.CHANGE_BENEFICIARY_CONTROLS, 'payload'),
  changeBikeControls: createAction(INSURANCE.CHANGE_BIKE_CONTROLS, 'payload'),
  fetchBike: createAction(INSURANCE.FETCH_BIKE, 'payload'),
  changeStep: createAction(INSURANCE.CHANGE_STEP, 'payload'),
  registerInsuranceQuotes: createAction(INSURANCE.REGISTER_INSURANCE_QUOTES, 'payload'),
  generatePdfInsuranceBasicQuote: createAction(
    INSURANCE.GENERATE_PDF_INSURANCE_BASIC_QUOTE,
    'payload',
  ),
  generatePdfInsuranceFullQuote: createAction(
    INSURANCE.GENERATE_PDF_INSURANCE_FULL_QUOTE,
    'payload',
  ),
  acceptInsuranceQuote: createAction(INSURANCE.ACCEPT_INSURANCE_QUOTE, 'payload'),
  fetchStatusInsuranceQuote: createAction(INSURANCE.STATUS_INSURANCE_QUOTE, 'payload'),
  acceptBasicInsuranceQuote: createAction(INSURANCE.ACCEPT_BASIC_INSURANCE_QUOTE, 'payload'),
  acceptFullInsuranceQuote: createAction(INSURANCE.ACCEPT_FULL_INSURANCE_QUOTE, 'payload'),
};

const initialState = {
  loading: false,
  loadingPdfBasic: false,
  loadingPdfFull: false,
  loadingPurchaseBasic: false,
  loadingPurchaseFull: false,
  step: '1',
  error: false,
  quotes: {
    basic: {
      bciQuoteId: 0,
      feeNet: 0,
      feeGross: 0,
      feeIva: 0,
    },
    full: {
      bciQuoteId: 0,
      feeNet: 0,
      feeGross: 0,
      feeIva: 0,
    },
    selected: {
      bciQuoteId: 0,
      paymentUrl: '',
      date: '',
    },
  },
  formControls: {
    bike: {
      transferId: 0,
      brand: '',
      brandId: 0,
      model: '',
      modelId: 0,
      year: '',
      version: '',
      useTypeId: 0,
      amountInsurable: 0,
    },
    beneficiary: {
      name: '',
      firstLastName: '',
      secondLastName: '',
      email: '',
      phone: '',
      birthdate: '',
      addressStreet: '',
      addressNumber: '',
      comune: '',
      comuneId: '',
      rut: '',
      dv: '',
    },
  },
};

const insurance = createReducer(initialState, {
  [INSURANCE.CHANGE_BIKE_CONTROLS](state, action) {
    const { payload } = action;
    state.formControls.bike = {
      ...state.formControls.bike,
      ...payload,
    };
  },
  [INSURANCE.CHANGE_BENEFICIARY_CONTROLS](state, action) {
    const { payload } = action;
    state.formControls.beneficiary = {
      ...state.formControls.beneficiary,
      ...payload,
    };
  },
  [INSURANCE.FETCH_BIKE](state) {
    state.loading = true;
    state.error = false;
  },
  [INSURANCE.FETCH_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [INSURANCE.FETCH_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [INSURANCE.CHANGE_STEP](state, action) {
    const { payload } = action;
    state.step = payload;
  },
  [INSURANCE.REGISTER_INSURANCE_QUOTES](state) {
    state.loading = true;
    state.error = false;
  },
  [INSURANCE.REGISTER_INSURANCE_QUOTES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [INSURANCE.REGISTER_INSURANCE_QUOTES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [INSURANCE.SET_QUOTES](state, action) {
    const { payload } = action;
    state.quotes.full = payload.full;
    state.quotes.basic = payload.basic;
  },
  [INSURANCE.GENERATE_PDF_INSURANCE_BASIC_QUOTE](state) {
    state.loadingPdfBasic = true;
    state.error = false;
  },
  [INSURANCE.GENERATE_PDF_INSURANCE_FULL_QUOTE](state) {
    state.loadingPdfFull = true;
    state.error = false;
  },
  [INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_SUCCESS](state) {
    state.loadingPdfBasic = false;
    state.loadingPdfFull = false;
    state.error = false;
  },
  [INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_ERROR](state) {
    state.loadingPdfBasic = false;
    state.loadingPdfFull = false;
    state.error = false;
  },
  [INSURANCE.ACCEPT_BASIC_INSURANCE_QUOTE](state) {
    state.loadingPurchaseBasic = true;
    state.error = false;
  },
  [INSURANCE.ACCEPT_FULL_INSURANCE_QUOTE](state) {
    state.loadingPurchaseFull = true;
    state.error = false;
  },
  [INSURANCE.ACCEPT_INSURANCE_QUOTE_SUCCESS](state, action) {
    state.loading = false;
    state.loadingPurchaseBasic = false;
    state.loadingPurchaseFull = false;
    state.error = false;
    state.quotes.selected.bciQuoteId = action.payload.bciQuoteId;
    state.quotes.selected.paymentUrl = action.payload.paymentUrl;
    state.quotes.selected.date = action.payload.date;
    state.step = '3';
  },
  [INSURANCE.ACCEPT_INSURANCE_QUOTE_ERROR](state) {
    state.loadingPurchaseBasic = false;
    state.loadingPurchaseFull = false;
    state.loading = false;
    state.error = true;
    state.quotes.full = initialState.quotes.full;
    state.quotes.basic = initialState.quotes.basic;
  },
  [INSURANCE.ACCEPT_INSURANCE_QUOTE_NOT_PROCESSED]: (state) => {
    state.loading = false;
    state.loadingPurchaseBasic = false;
    state.loadingPurchaseFull = false;
    state.error = false;
  },
  [INSURANCE.STATUS_INSURANCE_QUOTE]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [INSURANCE.STATUS_INSURANCE_QUOTE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [INSURANCE.STATUS_INSURANCE_QUOTE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
});

export default insurance;
