import colors from '../../../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    padding: '30px 20px',

    '& .buttons-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      margin: '60px auto',
    },
  },
  paymentTypeTitleContainer: {
    marginBottom: '30px',
    '& .general-title': {
      textAlign: 'center',
      fontWeight: '700',
      marginBottom: '20px',
      '& .bikfy-color': {
        color: primary,
      },
    },
  },
  paymentTypeContentContainer: {
    '& .paper-title': {
      fontWeight: '700',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  },
});

export default styles;
