import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'login/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
const CONTROLS_CHANGED = `${PATH}CONTROLS_CHANGED`;

// Global constants for saga
export const LOGIN = {
  LOGIN_USER_SUCCESS: `${PATH}FETCH_LOGIN_USER_SUCCESS`,
  LOGIN_USER_FAIL: `${PATH}FETCH_LOGIN_USER_FAIL`,
  LOGIN_USER: `${PATH}FETCH_LOGIN_USER`,
  GOOGLE_LOGIN: `${PATH}FETCH_GOOGLE_LOGIN`, // New constant for Google login
  GOOGLE_LOGIN_SUCCESS: `${PATH}FETCH_GOOGLE_LOGIN_SUCCESS`, // Success
  GOOGLE_LOGIN_FAIL: `${PATH}FETCH_GOOGLE_LOGIN_FAIL`, // Failure
  GET_USER_SUCCESS: `${PATH}GET_USER_SUCCESS`,
  GET_USER_FAIL: `${PATH}GET_USER_FAIL`,
  GET_USER: `${PATH}GET_USER`,
  VERIFY_SUCCESS: `${PATH}VERIFY_SUCCESS`,
  VERIFY_FAIL: `${PATH}VERIFY_FAIL`,
  VERIFY: `${PATH}VERIFY`,
  RESEND_EMAIL_ACTIVATION: `${PATH}RESEND_EMAIL_ACTIVATION`,
  RESEND_EMAIL_ACTIVATION_SUCCESS: `${PATH}RESEND_EMAIL_ACTIVATION_SUCCESS`,
  RESEND_EMAIL_ACTIVATION_FAIL: `${PATH}RESEND_EMAIL_ACTIVATION_FAIL`,
  SET_RESEND_ACTIVATION_DIALOG: `${PATH}SET_RESEND_ACTIVATION_DIALOG`,
};

// actions
export const loginActions = {
  disableErrors: createAction(DISABLE_ERRORS),
  // api
  getUser: createAction(LOGIN.GET_USER),
  login: createAction(LOGIN.LOGIN_USER, 'controls', 'redirectUrl'),
  googleLogin: createAction(LOGIN.GOOGLE_LOGIN, 'credential'), // New action for Google login
  verify: createAction(LOGIN.VERIFY, 'token'),
  controlsChanged: createAction(CONTROLS_CHANGED, 'controls'),
  resendEmailActivation: createAction(LOGIN.RESEND_EMAIL_ACTIVATION, 'email'),
  setResendActivationDialog: createAction(LOGIN.SET_RESEND_ACTIVATION_DIALOG, 'open'),
};

const initialState = {
  controls: {
    loading: false,
    message: '',
    error: false,
  },
  modals: {
    resendActivation: {
      open: false,
    },
  },
};

const login = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state) {
    state.error = false;
  },
  // api
  [LOGIN.LOGIN_USER](state) {
    state.controls.loading = true;
    state.controls.error = false;
  },
  [LOGIN.LOGIN_USER_SUCCESS](state) {
    state.controls.loading = false;
  },
  [LOGIN.GET_USER_FAIL](state, action) {
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg;
  },
  [LOGIN.LOGIN_USER_FAIL](state) {
    state.controls.loading = false;
  },
  [LOGIN.GOOGLE_LOGIN](state) { // New Google login action
    state.controls.loading = true;
    state.controls.error = false;
  },
  [LOGIN.GOOGLE_LOGIN_SUCCESS](state) { // New Google login success
    state.controls.loading = false;
  },
  [LOGIN.GOOGLE_LOGIN_FAIL](state, action) { // New Google login failure
    state.controls.loading = false;
    state.controls.error = true;
    state.controls.message = action.payload.errorMsg || 'Google login failed';
  },
  [CONTROLS_CHANGED](state, action) {
    state.controls = {
      ...state.controls,
      ...action.controls,
    };
  },
  [LOGIN.RESEND_EMAIL_ACTIVATION_SUCCESS](state) {
    state.controls.loading = false;
  },
  [LOGIN.RESEND_EMAIL_ACTIVATION_FAIL](state) {
    state.controls.loading = false;
  },
  [LOGIN.RESEND_EMAIL_ACTIVATION](state) {
    state.controls.loading = true;
  },
  [LOGIN.SET_RESEND_ACTIVATION_DIALOG](state, action) {
    state.modals.resendActivation.open = action.open;
  },
});

export default login;
