import React from 'react';
import { formatTaxNumber, serialInput } from 'utils/functions';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import CustomSelect from 'commons/components/CustomSelect';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomInputFile from 'commons/components/CustomInputFile';

const BikeForm = ({
  controls = {},
  errors = {},
  onControlChange,
  onSubmit = (e) => e.preventDefault(),
  brands = [],
  loading,
  motorbikeBrands = [],
  vehicleTypes = [],
  isBicycleSelected,
}) => {
  const inputs = [
    {
      name: 'vehicleTypeId',
      label: 'Tipo de vehículo',
      type: 'select',
      options: vehicleTypes,
      visible: true,
      sm: 10,
    },
    {
      name: 'email',
      label: 'E-Mail cliente',
      type: 'text',
      visible: true,
    },
    {
      name: 'taxNumber',
      label: 'RUT Cliente',
      type: 'text',
      visible: true,
      getValue: formatTaxNumber,
    },
    {
      name: 'phoneNumber',
      label: 'Número de teléfono',
      type: 'text',
      visible: true,
    },
    {
      name: 'serial',
      label: 'Código de serie',
      type: 'text',
      visible: isBicycleSelected,
      getValue: (s) => serialInput(s),
    },
    {
      name: 'engineSerial',
      label: 'Código de serie motor',
      type: 'text',
      visible: !isBicycleSelected,
      getValue: (s) => serialInput(s),
    },
    {
      name: 'chassisSerial',
      label: 'Código de serie chasis',
      type: 'text',
      visible: !isBicycleSelected,
      getValue: (s) => serialInput(s),
    },
    {
      name: 'brandId',
      label: 'Marca',
      type: 'select',
      options: isBicycleSelected ? brands : motorbikeBrands,
      visible: true,
    },
  ];
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {inputs.map((i) =>
          i.type === 'text' && i.visible ? (
            <Grid key={i.name} item xs={12} sm={5} align="center">
              <TextField
                name={i.name}
                label={i.label}
                variant="outlined"
                fullWidth
                value={i.getValue ? i.getValue(controls[i.name]) : controls[i.name]}
                onChange={(e) => onControlChange(e, i.getValue)}
                error={!!errors[i.name]}
                helperText={errors[i.name] && errors[i.name][0]}
              />
            </Grid>
          ) : i.type === 'select' && i.visible ? (
            <Grid key={i.name} item xs={12} sm={i.sm ? i.sm : 5} align="center">
              <CustomAutoComplete
                name={i.name}
                label={i.label}
                value={controls[i.name]}
                onChange={onControlChange}
                options={i.options}
                error={!!errors[i.name]}
                errors={errors[i.name]}
              />
            </Grid>
          ) : i.type === 'file' && i.visible ? (
            <Grid key={i.name} item xs={12} sm={12} align="center">
              <CustomSelect
                name={i.name}
                label={i.label}
                value={controls[i.name]}
                onChange={onControlChange}
                options={i.options}
                hasError={!!errors[i.name]}
                errors={errors[i.name]}
              />
            </Grid>
          ) : null,
        )}
        <Grid item xs={12} sm={10} align="center">
          <CustomInputFile
            title="Subir foto del código de serie"
            id="serialFile"
            name="serialFile"
            documentType="serialDocument"
            onChange={onControlChange}
            value={controls.serialFile}
            error={!!errors.serialFile}
            errors={errors.serialFile}
          />
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Button type="submit" variant="contained" color="primary">
            {loading ? <CircularProgress size={24} /> : 'Registrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BikeForm;
