import React, { useEffect } from 'react';
import { ClickAwayListener, Tooltip } from '@material-ui/core';

const TooltipButton = ({
  text1, text2, arrow, placement, children,
}) => {
  const [open, setOpen] = React.useState(false);
  const [timer, setTimer] = React.useState(null);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (!open) {
      const timeout = setTimeout(() => {
        setOpen(false);
      }, 8000);

      setTimer(timeout);

      setOpen(true);
    }
  };

  useEffect(() => () => {
    clearTimeout(timer);
  }, [timer]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip

        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={(
          <div
            style={{ maxWidth: '260px' }}
          >
            <p style={{ textAlign: 'justify', fontSize: '14px' }}>{text1}</p>
            <p style={{ textAlign: 'justify', fontSize: '14px' }}>{text2}</p>
          </div>
                  )}
        arrow={arrow}
        placement={placement}
      >
        <i
          size="small"
          onClick={handleTooltipOpen}
          onMouseEnter={handleTooltipOpen}
        >
          {children}
        </i>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipButton;
