import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { composedComponent } from 'utils/functions';
import saga from 'screens/Payment/saga';
import { paymentActions } from 'screens/Payment/reducer';
import { appActions } from 'commons/reducer';
import { Alert } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Card } from '@material-ui/core';

const Payment = (props) => {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    props.actions.validateStatus(params.id);
  }, []);

  useEffect(() => {
    if (props.success) {
      history.push('/');
    }
  }, [props.success]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        {
          props.error
          && (
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Alert severity="error">Ocurrió un error en la transferencia. Vuelva a intentarlo</Alert>
                  </Grid>
                  <Grid item xs={12} sm={12} align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push('/')}
                    >
                      Inicio
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        }
      </Grid>
    </Grid>
  );
};

export default composedComponent(Payment, saga,
  {
    states: [
      'payment.loading',
      'payment.error',
      'payment.success',
    ],
    actions: [paymentActions, appActions],
    saga: 'paymentSaga',
  });
