import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { Card, Container, Typography, useMediaQuery } from '@material-ui/core';
import { composedComponent } from 'utils/functions';
import saga from 'screens/Maintenance/saga';
import { maintenanceActions } from 'screens/Maintenance/reducer';
import { appActions } from 'commons/reducer';
import { useParams } from 'react-router-dom';
import CustomButtonFloat from 'commons/components/CustomButtonFloat';
import { makeStyles } from '@material-ui/core/styles';
import FormModal from 'screens/Maintenance/components/Modal';
import { useForm } from 'utils/hooks/useForm';
import FileModal from 'commons/components/FileModal';
import MaintenancesTable from 'commons/components/MaintenancesTable';
import CustomModalConfirmation from 'commons/components/CustomModalConfirmation';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Maintenance = (props) => {
  const params = useParams();
  const bikeId = params.id;
  const classes = useStyles();
  const [maintenanceId, setMaintenanceId] = useState(0);

  useEffect(() => {
    props.actions.fetchMaintenance(bikeId);
    props.actions.fetchMaintenanceTypes();
    props.actions.fetchBikeInformation(bikeId);
  }, [
    props.actions.fetchMaintenance,
    props.actions.fetchMaintenanceTypes,
    props.actions.fetchBikeInformation,
  ]);

  useEffect(() => {
    if (props.success) {
      props.actions.clearModal();
      props.actions.fetchMaintenance(bikeId);
      props.actions.resetControls();
    }
  }, [props.success]);

  const maintenances = props.order?.maintenances
    ?.map((b) => props.maintenances[b])
    .map((a) => ({
      id: a?.id,
      type: a?.type,
      description: a?.description,
      date: a?.date,
    }));

  const maintenancesTypes = props.order?.maintenancesTypes
    ?.map((b) => props.maintenancesTypes[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      vehicleTypeId: a?.vehicleTypeId,
    }));

  const handleMaintenancesTypesOptions = () => {
    if (props?.bike?.isBicycle) {
      return maintenancesTypes?.filter((mt) => mt.vehicleTypeId === 1 || mt.vehicleTypeId === null);
    }
    return maintenancesTypes
      ?.filter((mt) => mt.vehicleTypeId === 2 || mt.vehicleTypeId === null)
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      types: [
        {
          check: (value) => value.length > 0,
          message: 'Campo requerido',
        },
      ],
      date: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'Campo requerido',
        },
      ],
      voucherFile: [
        {
          check: (value) => value,
          message: 'Campo requerido',
        },
      ],
    },
  });

  const handleClose = () => {
    props.actions.clearModal();
  };

  const handleCreate = (e) => {
    e.preventDefault();
    onSubmit(() =>
      props.actions.create({
        bikeId,
        body: props.formControls,
      }),
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    onSubmit(() =>
      props.actions.update({
        id: props.maintenanceId,
        body: props.formControls,
      }),
    );
  };

  const handleGetFile = (id) => {
    setMaintenanceId(id);
    const { name, url } = props.objects.maintenances[id].file;
    let extension = name.split('.');
    extension = extension[extension.length - 1];

    props.actions.changeData({
      extension,
      url,
      name,
    });
    props.actions.openFileModal();
  };

  const handleOpenUpdate = (id) => {
    const types = props.objects.maintenances[id].type.map((m) => m.id);
    const date = props.objects.maintenances[id].date.split('T')[0];
    const voucherFile = props.objects.maintenances[id].file.name;
    const { description } = props.objects.maintenances[id];

    props.actions.openModalUpdate();
    props.actions.changeControls({
      types,
      description,
      date,
      voucherFile: { name: voucherFile },
    });
    props.actions.changeMaintenance(id);
  };

  const handleOpenCreate = () => {
    props.actions.resetControls();
    props.actions.openModalCreate();
  };

  const handleOpenDelete = (id) => {
    props.actions.openDeleteModal();
    setMaintenanceId(id);
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" component="h6" align="center">
                    Listado de mantenciones de la Bike
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MaintenancesTable
                    data={maintenances}
                    handleGetFile={handleGetFile}
                    handleOpenUpdate={handleOpenUpdate}
                    handleOpenDelete={handleOpenDelete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <FormModal
        open={props.modalOpen}
        clear={handleClose}
        onChange={onChange}
        onSubmit={props.edit ? handleUpdate : handleCreate}
        controls={props.formControls}
        errors={errors}
        maintenancesTypes={handleMaintenancesTypesOptions()}
        loading={props.loading}
        edit={props.edit}
        bike={props?.bike}
      />
      <FileModal
        open={props.fileData.fileModal}
        clear={props.actions.closeFileModal}
        fileData={props.fileData}
        maintenance={props.objects.maintenances[maintenanceId]}
      />
      <CustomModalConfirmation
        alert={props.modalDelete}
        clearAlert={props.actions.closeDeleteModal}
        text={'Al pulsar el botón "CONTINUAR" la mantención será eliminada'}
        loading={props.loading}
        handleSubmit={() => props.actions.deleteMaintenance(maintenanceId)}
      />
      <CustomButtonFloat className={classes.fab} handleSubmit={handleOpenCreate} />
    </Container>
  );
};

export default composedComponent(Maintenance, saga, {
  states: [
    'maintenance.formControls',
    'maintenance.alert',
    'maintenance.loading',
    'maintenance.modalOpen',
    'maintenance.edit',
    'maintenance.fileData',
    'maintenance.formControls',
    'maintenance.maintenanceId',
    'maintenance.success',
    'maintenance.modalDelete',
    'app.objects.maintenances',
    'app.objects.maintenancesTypes',
    'app.objects.bike',
    'app.objects',
    'app.order',
  ],
  actions: [maintenanceActions, appActions],
  saga: 'maintenanceSaga',
});
