import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import apiRequest, { apiSuccess, destroy, post, get } from 'utils/api';

import { CANCEL_BIKE_REGISTRATION } from 'screens/CancelBikeRegistration/reducer';

function* cancelBikeRegistration() {
  yield takeLatest(CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION, function* (action) {
    const { transferId } = action.payload;

    const response = yield apiRequest(
      `api/v1/p/transfers/${transferId}/delete-record`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION_SUCCESS, response.message));
    } else {
      yield put(apiSuccess(CANCEL_BIKE_REGISTRATION.CANCEL_REGISTRATION_ERROR, response.errorMsg));
    }
  });
}

export default function* CancelBikeRegistrationSaga() {
  yield spawn(cancelBikeRegistration);
}
