const colors = {
  red: '#e53935',
  green: '#4CAF50',
  grey: '#babdbe',
  white: '#ffffff',
  yellow: '#FFC700',
  // use these colors please
  // revisar en styles.scss sean los mismos colores
  primary: '#45C6D8',
  secondary: '#1d242d',
  danger: '#E64678',
};

export default colors;
