import {
  put, spawn, takeLatest, select, call,
  takeEvery,
} from 'redux-saga/effects';
import { HOME } from 'screens/Home/reducer';
import { PATHS } from 'utils/paths';
import * as APP from 'commons/reducer';
import apiRequest, {
  apiSuccess, destroy, get, post,
} from 'utils/api';
import { view } from 'utils/pathTypes';

// Pasa a helpers *
function serialize(obj = {}) {
  const str = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== '') {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  return str.join('&');
}

export const normalizeObj = (arr) => (Array.isArray(arr) ? arr : [arr]).reduce((prev, current) => {
  prev[current.id] = current;
  return prev;
}, {});

function* fetchBikes() {
  yield takeLatest(HOME.FETCH_BIKES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_BIKES));

    const { filterForm } = yield select((state) => state.home);

    const queryParams = {
      bikeState: 'current-bikes',
      test: [1],
      ...filterForm,
    };

    const apiUrl = `api/v1/bikes?${serialize(queryParams)}`;

    const response = yield get(apiUrl);
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_BIKES));

    if (!response.error) {
      yield put(apiSuccess(HOME.FETCH_BIKES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(HOME.FETCH_BIKES_ERROR, { errorMsg: 'Error al cargar las Bikes' }));
    }
  });
}

function* fetchBikesTransferred() {
  yield takeLatest(HOME.FETCH_BIKES_TRANSFERRED, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_BIKES_TRANSFERRED));
    const response = yield get('api/v1/bikes?bikeState=transferred-bikes');
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_BIKES_TRANSFERRED));

    if (!response.error) {
      yield put(apiSuccess(HOME.FETCH_BIKES_TRANSFERRED_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(HOME.FETCH_BIKES_TRANSFERRED_ERROR, { errorMsg: 'Error al cargar las Bikes' }),
      );
    }
  });
}
function* fetchBikesPending() {
  yield takeLatest(HOME.FETCH_BIKES_PENDING, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_BIKES_PENDING));
    const response = yield get('api/v1/bikes?bikeState=pending-bikes');
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_BIKES_PENDING));

    if (!response.error) {
      const { isSellerBikes, isExternalPayment } = response;
      const showAlert = isSellerBikes;
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(
        apiSuccess(HOME.FETCH_BIKES_PENDING_SUCCESS, {
          showAlert,
          isExternalPayment,
          isSellerBikes,
        }),
      );
    } else {
      yield put(
        apiSuccess(HOME.FETCH_BIKES_PENDING_ERROR, { errorMsg: 'Error al cargar las Bikes' }),
      );
    }
  });
}

function* fetchInsurableBikes() {
  yield takeLatest(HOME.FETCH_INSURABLE_BIKES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_INSURABLE_BIKES));
    const response = yield get('api/v1/bikes?bikeState=insurable-bikes');
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_INSURABLE_BIKES));

    if (!response.error) {
      yield put(apiSuccess(HOME.FETCH_INSURABLE_BIKES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(HOME.FETCH_INSURABLE_BIKES_ERROR, { errorMsg: 'Error al cargar las Bikes' }),
      );
    }
  });
}
function* fetchAds() {
  yield takeEvery(HOME.FETCH_ADS, function* (action) {
    try {
      yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_ADS));
      const response = yield call(post, 'api/v1/ads/random', { type: action.payload.type, numberOfAds: 1 });
      yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_ADS));

      if (!response.error) {
        if (action.payload.callback) {
          action.payload.callback(null, response);
        }
      } else {
        if (action.payload.callback) {
          action.payload.callback(response.error);
        }
        yield put(apiSuccess(HOME.FETCH_ADS_ERROR, { errorMsg: 'Error fetching ad' }));
      }
    } catch (error) {
      if (action.payload.callback) {
        action.payload.callback(error);
      }
      yield put(apiSuccess(HOME.FETCH_ADS_ERROR, { errorMsg: 'Error fetching ad' }));
    }
  });
}

function* clickAd() {
  yield takeEvery(HOME.CLICK_AD, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.CLICK_AD));
    const response = yield call(post, `api/v1/ads/${action.payload.adId}/increment-clicked`);
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.CLICK_AD));

    if (!response.error) {
      yield put(apiSuccess(HOME.CLICK_AD_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(HOME.CLICK_AD_ERROR, { errorMsg: 'Error al cargar las Bikes' }),
      );
    }
  });
}

// function* fetchAds(action) {
//   try {
//     yield put(apiSuccess(APP.ADD_LOADING, HOME.FETCH_ADS));
//     const response = yield call(post, 'api/v1/ads/random', { type: action.payload.type });
//     yield put(apiSuccess(APP.REMOVE_LOADING, HOME.FETCH_ADS));

//     if (!response.error) {
//       if (action.payload.callback) {
//         action.payload.callback(null, response.data);
//       }
//     } else {
//       if (action.payload.callback) {
//         action.payload.callback(response.error);
//       }
//       yield put(apiSuccess(HOME.FETCH_ADS_ERROR, { errorMsg: 'Error fetching ad' }));
//     }
//   } catch (error) {
//     if (action.payload.callback) {
//       action.payload.callback(error);
//     }
//     yield put(apiSuccess(HOME.FETCH_ADS_ERROR, { errorMsg: 'Error fetching ad' }));
//   }
// }

function* validateTransfer() {
  yield takeLatest(HOME.VALIDATE_TRANSFERS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.VALIDATE_TRANSFERS));
    const response = yield post('api/v1/transfers/pending');
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.VALIDATE_TRANSFERS));

    if (!response.error) {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_SUCCESS));
    } else {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_ERROR));
    }
  });
}

function* validateTransferBuyer() {
  yield takeLatest(HOME.VALIDATE_TRANSFERS_BUYER, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, HOME.VALIDATE_TRANSFERS_BUYER));
    const response = yield post('api/v1/transfers/pending-buyer');
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.VALIDATE_TRANSFERS_BUYER));

    if (!response.error) {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_BUYER_SUCCESS));
    } else {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_BUYER_ERROR));
    }
  });
}

function* validateTransferSeller() {
  yield takeLatest(HOME.VALIDATE_TRANSFERS_SELLER, function* (action) {
    const response = yield apiRequest(
      'api/v1/transfers/pending/pending-seller',
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_SELLER_SUCCESS));
    } else {
      yield put(apiSuccess(HOME.VALIDATE_TRANSFERS_SELLER_FAILURE));
    }
  });
}

function* deleteTransfer() {
  yield takeLatest(HOME.DELETE_TRANSFER, function* (action) {
    const { id, type } = action.payload;
    let successMessage = '';
    let errorMsg = '';
    if (type === 'delete') {
      successMessage = 'La transferencia ha sido cancelada';
      errorMsg = 'Error al cancelar la transferencia ';
    }
    if (type === 'reject') {
      successMessage = 'La transferencia ha sido rechazada';
      errorMsg = 'Error al cancelar la transferencia ';
    }

    yield put(apiSuccess(APP.ADD_LOADING, HOME.DELETE_TRANSFER));
    const response = yield destroy(`api/v1/transfers/${id}/delete-transfer`);
    yield put(apiSuccess(APP.REMOVE_LOADING, HOME.DELETE_TRANSFER));

    if (!response.error) {
      yield put(apiSuccess(HOME.DELETE_TRANSFER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, successMessage));
    } else {
      errorMsg = response?.errorMsg !== '{}' ? response?.errorMsg : errorMsg;
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg }));
      yield put(apiSuccess(HOME.DELETE_TRANSFER_ERROR));
    }
  });
}

function* getPublishedBikes() {
  yield takeLatest(HOME.GET_PUBLISHED_BIKES, function* getPublishedBikesGenerator(action) {
    // MARKETPLACE VIEW
    const { getAllData, pathType } = yield select((state) => state.home);
    const { lastMarketPlaceOrder, lastMarketPlaceObjects } = yield select((state) => state.app);

    const {
      filterForm,
      sortBy,
      pagination: { offset, limit },
      lastYScroll,
    } = yield select((state) => state.home);

    const persistLastMarketplaceCondition = pathType === view.MARKETPLACE
      && Object.keys(lastMarketPlaceOrder).length
      && Object.values(lastMarketPlaceOrder.transfers).length
      && action?.payload?.useLastMarketOrder;

    if (persistLastMarketplaceCondition) {
      const { pagination } = yield select((state) => state.home);
      yield put(apiSuccess(HOME.GET_PUBLISHED_BIKES_SUCCESS, { pagination }));
      yield put(apiSuccess(HOME.SET_LAST_MARKETPLACE_LIST_TYPE));
      const response = {
        order: { ...lastMarketPlaceOrder },
        objects: { ...lastMarketPlaceObjects },
        pagination,
      };
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      window.scrollTo(0, lastYScroll);
      return;
    }

    const queryParams = {
      ...filterForm,
      offset,
      limit: pathType === view.LANDING ? 15 : limit,
      orderField: sortBy.field,
      orderBy: sortBy.order,
      bikeState: 'published-bikes',
      getAllData,
    };

    const response = yield apiRequest(
      `api/v1/p/published-bikes?${serialize(queryParams)}`,
      {
        method: 'GET',
      },
      true,
    );

    if (!response.error) {
      const { pagination, reportsReasons } = response;
      if ([PATHS.HOME].includes(window.location.pathname) || pathType === 'marketplace') {
        yield put(apiSuccess(HOME.GET_PUBLISHED_BIKES_SUCCESS, { pagination, reportsReasons }));
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
        if (response?.objects?.brands && response?.objects?.models) {
          yield put(apiSuccess(HOME.SET_GET_ALL_DATA, false)); // datos ya cargados
        }
      }
    } else {
      yield put(
        apiSuccess(HOME.GET_PUBLISHED_BIKES_FAIL, {
          errorMessage: 'Error hable con el administrador',
        }),
      );
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error' }));
    }
  });
}

function* getBikesForStore() {
  yield takeLatest(HOME.GET_BIKES_FOR_STORE, function* getBikesForStoreGenerator(action) {
    const {
      storeHomeFilterForm,
      storeHomeSortBy,
      getAllData,
      paginationStoreHome: { offset, limit },
    } = yield select((state) => state.home);

    const queryParams = {
      ...storeHomeFilterForm,
      offset,
      limit,
      bikeState: storeHomeSortBy.field,
      getAllData,
    };

    const response = yield apiRequest(
      `api/v1/bikes?${serialize(queryParams)}`,
      {
        method: 'GET',
      },
      true,
    );

    if (!response.error) {
      const { pagination } = response;
      yield put(apiSuccess(HOME.GET_BIKES_FOR_STORE_SUCCESS, { paginationStoreHome: pagination }));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      if (response?.objects?.brands && response?.objects?.models) {
        yield put(apiSuccess(HOME.SET_GET_ALL_DATA, false)); // datos ya cargados
      }
    } else {
      yield put(
        apiSuccess(HOME.GET_BIKES_FOR_STORE_FAIL, {
          errorMessage: 'Error hable con el administrador',
        }),
      );
    }
  });
}

function* setSellerEmail() {
  yield takeLatest(HOME.SET_SELLER_EMAIL, function* setSellerEmailGenerator(action) {
    const { formSellerEmailValues } = action.payload;
    const { transferId, bikeId } = yield select((state) => state.home);

    const reducedFormSellerEmailValues = Object.entries(formSellerEmailValues).reduce(
      (acum, current) => ({
        ...acum,
        [current[0]]: current[1].value,
      }),
      {},
    );

    const body = {
      ...reducedFormSellerEmailValues,
      transferId,
    };

    const response = yield apiRequest(
      `api/v1/bikes/publish-bike/${bikeId}/send-seller-email`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      const { message: successMessage } = response;

      yield put({ type: HOME.SET_SELLER_EMAIL_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: successMessage });
    } else {
      yield put({ type: HOME.SET_SELLER_EMAIL_FAIL });
    }
  });
}

function* reportPublish() {
  yield takeLatest(HOME.REPORT_PUBLISH, function* reportPublishGenerator(action) {
    const { reportReasonId } = action.payload;
    const { bikeMarketId } = yield select((state) => state.home.modals.reportPublish);

    const body = {
      reportReasonId,
      bikeMarketId,
    };

    const response = yield apiRequest(
      'api/v1/report/report-publication',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: HOME.REPORT_PUBLISH_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: 'El reporte ha sido enviado' });
    } else {
      yield put({ type: HOME.REPORT_PUBLISH_FAIL });
    }
  });
}

function* requestAccess() {
  yield takeLatest(HOME.REQUEST_ACCESS, function* generator(action) {
    const { transferId } = action.payload;

    const body = {
      transferId,
    };

    const response = yield apiRequest(
      'api/v1/bikes/store/request-access',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: HOME.REQUEST_ACCESS_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: response.message });
    } else {
      yield put({ type: HOME.REQUEST_ACCESS_ERROR });
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

function* rememberRegisterBike() {
  yield takeLatest(HOME.REMEMBER_REGISTER_BIKE, function* generator(action) {
    const { transferId } = action.payload;

    const body = {
      transferId,
    };

    const response = yield apiRequest(
      'api/v1/bikes/store/remember-register',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: HOME.REMEMBER_REGISTER_BIKE_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: response.message });
    } else {
      yield put({ type: HOME.REMEMBER_REGISTER_BIKE_ERROR });
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}
function* rememberAccessForBike() {
  yield takeLatest(HOME.REMEMBER_ACCESS_FOR_BIKE, function* generator(action) {
    const { transferId } = action.payload;

    const body = {
      transferId,
    };

    const response = yield apiRequest(
      'api/v1/bikes/store/remember-access',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put({ type: HOME.REMEMBER_ACCESS_FOR_BIKE_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: response.message });
    } else {
      yield put({ type: HOME.REMEMBER_ACCESS_FOR_BIKE_ERROR });
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

function* removeStoreAccess() {
  yield takeLatest(HOME.REMOVE_STORE_ACCESS, function* generator(action) {
    const { accessedId } = action.payload;

    const url = `api/v1/bikes/store/${accessedId}`;

    const response = yield apiRequest(
      url,
      {
        method: 'DELETE',
      },
      true,
    );

    if (!response.error) {
      yield put({ type: HOME.REMOVE_STORE_ACCESS_SUCCESS });
      yield put({ type: APP.SET_SUCCESS, payload: response.message });
      yield put({ type: HOME.FETCH_BIKES });
    } else {
      yield put({ type: HOME.REMOVE_STORE_ACCESS_ERROR });
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

function* exportToCsvBikeData() {
  yield takeLatest(HOME.EXPORT_TO_CSV_BIKE_DATA, function* exportToCsvBikeDataGenerator() {
    yield put({ type: APP.ADD_LOADING });
    const { filterForm, storeHomeSortBy } = yield select((state) => state.home);

    const queryParams = {
      ...filterForm,
      bikeState: storeHomeSortBy.field,
    };

    const url = `api/v1/bikes/store/generate-excel?${serialize(queryParams)}`;

    const response = yield apiRequest(
      url,
      {
        method: 'GET',
      },
      true,
    );

    if (!response.error) {
      const { message, csvData } = response;
      yield put({
        type: HOME.EXPORT_TO_CSV_BIKE_DATA_SUCCESS,
        payload: { csvData },
      });
      yield put({ type: APP.SET_SUCCESS, payload: message });
    } else {
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }

    yield put({ type: APP.REMOVE_LOADING });
  });
}
function* getUsersCount() {
  yield takeLatest(HOME.GET_USERS_COUNT, function* getUsersCountGenerator() {
    const response = yield get('api/v1/p/home');

    if (!response.error) {
      const { users } = response;
      yield put(apiSuccess(HOME.GET_USERS_COUNT_SUCCESS, users));
    } else {
      yield put({
        type: HOME.GET_USERS_COUNT_ERROR,
      });
    }
  });
}

function* getBikesCount() {
  yield takeLatest(HOME.GET_BIKES_COUNT, function* getBikesCountGenerator() {
    const response = yield get('api/v1/p/home/bikes');

    if (!response.error) {
      const { bikes } = response;
      yield put(apiSuccess(HOME.GET_BIKES_COUNT_SUCCESS, bikes));
    } else {
      yield put({
        type: HOME.GET_BIKES_COUNT_ERROR,
      });
    }
  });
}

function* updateUserAlertedInLanding() {
  yield takeLatest(
    HOME.UPDATE_USER_ALERTED_IN_LANDING,
    function* updateUserAlertedInLandingGenerator(action) {
      const { userId } = action.payload;
      const response = yield apiRequest(
        `api/v1/users/update-landing-alert/${userId}`,
        {
          method: 'POST',
        },
        true,
      );

      if (!response.error) {
        yield put(apiSuccess(HOME.UPDATE_USER_ALERTED_IN_LANDING_SUCCESS));
      } else {
        yield put({
          type: HOME.UPDATE_USER_ALERTED_IN_LANDING_ERROR,
        });
      }
    },
  );
}

export default function* saga() {
  yield spawn(fetchBikes);
  yield spawn(fetchBikesTransferred);
  yield spawn(fetchBikesPending);
  yield spawn(fetchInsurableBikes);
  yield spawn(validateTransfer);
  yield spawn(validateTransferBuyer);
  yield spawn(deleteTransfer);
  yield spawn(validateTransferSeller);
  yield spawn(getPublishedBikes);
  yield spawn(setSellerEmail);
  yield spawn(reportPublish);
  yield spawn(requestAccess);
  yield spawn(rememberRegisterBike);
  yield spawn(rememberAccessForBike);
  yield spawn(removeStoreAccess);
  yield spawn(exportToCsvBikeData);
  yield spawn(getBikesForStore);
  yield spawn(getUsersCount);
  yield spawn(getBikesCount);
  yield spawn(updateUserAlertedInLanding);
  yield spawn(fetchAds);
  yield spawn(clickAd);
}
