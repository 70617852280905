/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomInputFile from 'commons/components/CustomInputFile';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import CheckboxInvoice from 'screens/Bike/components/checkboxInvoice';
import CustomInputAdornment from 'commons/components/CustomInputAdornment';
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import HelpGoodPhotos from 'commons/components/HelpGoodPhotos';
import ImagesSection from 'commons/components/ImagesSection';
import { base64ToImageAsync, getDefaultCrop, IMG_CROP_RATIO } from 'utils/functions';

const CustomToggleButton = ({
  label, onChange, name, value, disabled, option1, option2,
}) => {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <Grid item xs={12} sm={10} align="center">
      <Typography variant="body1" gutterBottom>{label}</Typography>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={label}
        disabled={disabled}
      >
        <ToggleButton
          value
          aria-label="yes"
          style={value === true ? {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          } : {}}
        >
          {option1}
        </ToggleButton>
        <ToggleButton
          value={false}
          aria-label="no"
          style={value === false ? {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          } : {}}
        >
          {option2}
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};
const CustomToggleButton2 = ({
  label, onChange, name, value, disabled, options,
}) => {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange({ target: { name, value: newValue } });
    }
  };

  return (
    <Grid item xs={12} sm={10} align="center">
      <Typography variant="body1" gutterBottom>{label}</Typography>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        aria-label={label}
      >
        {options.map((option) => (
          <ToggleButton
            value={option.value}
            aria-label="yes"
            style={value === option.value ? {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            } : {}}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
};
const EditForm = ({
  onSubmit,
  inputs,
  controls,
  actions,
  imagesData,
  onControlChange,
  errors,
  loading,
  transfer,
  changeControls,
  transferId,
  openAlert,
  openAlertSerial,
  profileComplete,
  isStoreUser,
  isBicycleSelected,
  objects,
  vehicleTypeFetched,
  isMobileScreen,
  validateAll,
}) => {
  const [serialUrl, setSerialUrl] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');

  useEffect(() => {
    if (transferId) {
      actions.resetControls();
      actions.fetchBike(transferId);
    }
  }, [transferId]);

  useEffect(() => {
    let serialFileName = '';
    let invoiceFileName = '';
    let invoiceCheckbox = false;
    const optionalPhotos = false;
    let documentInvoiceId = 0;
    let documentSerialId = 0;
    const invoicePropertyNumber = transfer?.invoiceInMyName ? 1 : transfer?.invoiceInCompanyName ? 3 : 2;
    invoiceCheckbox = invoicePropertyNumber !== 2 && true;

    if (transfer && transfer.bike) {
      if (transfer.registerBy) {
        invoiceCheckbox = true;
      }
      transfer.bike.document.forEach((d) => {
        if (d.type === 'serial') {
          serialFileName = d.file.name;
          documentSerialId = d.id;
          setSerialUrl(d.file.url);
        }
        if (['invoice', 'own_invoice', 'bikfy_sale'].includes(d.type)) {
          invoiceCheckbox = true;
          invoiceFileName = d.file.name;
          documentInvoiceId = d.id;
          setInvoiceUrl(d.file.url);
        }
        if (['own_invoice', 'bikfy_sale'].includes(d.type)) {
          invoiceCheckbox = true;
        }
      });
      const motorbike = objects?.motorbikes
        ? Object.values(objects?.motorbikes).find((mi) => mi.bikeId === transfer.bike.id)
        : {};

      const chassisSerial = motorbike?.chassisSerial?.includes('SERIALTEMPORAL') ? '' : motorbike?.chassisSerial;
      const engineSerial = motorbike?.engineSerial?.includes('SERIALTEMPORAL') ? '' : motorbike?.engineSerial;
      invoiceCheckbox = !!transfer?.currencyId || transfer.invoiceInMyName;

      // To do

      changeControls({
        id: transfer.bike.id,
        vehicleTypeId: transfer.bike.vehicleTypeId,
        year: transfer.bike.year,
        version: transfer.bike.version,
        sizeId: transfer.bike.sizeId,
        rimId: transfer.bike.rimId,
        currencyId: transfer.currencyId,
        color: transfer.bike.color,
        serial: transfer?.bike?.serial?.includes('SERIALTEMPORAL') ? '' : transfer?.bike?.serial,
        chassisSerial,
        engineSerial,
        categoryId: transfer.bike.categoryId,
        brandId: transfer.bike.brandId,
        modelId: transfer.bike.modelId,
        reported:
          typeof transfer.bike.reported === 'string'
            ? transfer.bike.reported === 'Si'
            : transfer.bike.reported,
        amount: Number(transfer.amount) === 0 ? '' : Number(transfer.amount),
        invoiceTaxNumber: transfer.invoiceTaxNumber ?? '',
        invoiceNumber: transfer.invoiceNumber,
        engineCapacityId: objects?.motorbikes
          ? Object.values(objects?.motorbikes)[0]?.engineCapacityId
          : '',
        invoiceCheckbox,
        serialFile: serialFileName ? { name: serialFileName } : '',
        invoiceFile: invoiceFileName ? { name: invoiceFileName } : '',
        documentInvoiceId,
        documentSerialId,
        actualInvoiceId: documentInvoiceId,
        actualSerialId: documentSerialId,
        foreignCheckbox: transfer.foreignInvoice,
        invoiceInMyName: invoicePropertyNumber || false,
        companyName: transfer.companyName,
        companyAddress: transfer.companyAddress,
        companyTaxNumber: transfer.companyTaxNumber,
        optionalPhotos,
        possessionCheckbox: true,
        purchasedAt: transfer.bike.purchasedAt ? transfer.bike.purchasedAt.split('T')[0] : '',
      });
      // validateAll();
    }
  }, [transfer?.id, transfer?.currencyId, transfer?.bike?.id, transfer?.bike?.document.length]);

  useEffect(() => {
    async function setImages() {
      const documents = (transfer.bike?.document || [])
        .filter((doc) => doc.index !== null)
        .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0));

      const filesNames = documents.map((doc) => doc.file.name);
      // eslint-disable-next-line max-len
      const images = await Promise.all(
        filesNames.map((_, idx) => base64ToImageAsync(imagesData[idx])),
      );
      const crops = images.map((img) => getDefaultCrop(img, IMG_CROP_RATIO));
      const meta = images.map((_, idx) => ({
        id: documents[idx]?.id ?? 0,
        state: 'save',
        index: idx,
      }));
      actions.setFilesNames(filesNames);
      actions.setImages(images);
      actions.setCrops(crops);
      actions.setCroppedImages(images);
      actions.setImagesMeta(meta);
    }
    if (transfer && imagesData.length) {
      setImages();
    }
  }, [actions, transfer?.id, imagesData.length]);

  useEffect(() => {
    if (vehicleTypeFetched) {
      validateAll();
    }
  }, [vehicleTypeFetched]);

  const steps = [
    // Step 1 fields
    ['vehicleTypeId', 'color', 'version', 'year', 'brandId', 'modelId', 'sizeId', 'categoryId', 'rimId'],
    // Step 2 fields
    // ['invoiceCheckbox', 'foreignCheckbox'],
    [],
    // Step 3 fields
    ['companyName', 'companyTaxNumber', 'companyAddress'],
    // Step 4 fields
    // [],
    ['amount', 'invoiceTaxNumber', 'invoiceNumber', 'invoiceFile', 'serialFile', 'possessionCheckbox', 'purchasedAt', 'currencyId', 'serial', 'chassisSerial', 'engineSerial'],
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" component="h6" align="center">
                  Edita tu Bike
                </Typography>
              </Grid>
              {!profileComplete && (
                <Grid item xs={12} sm={10}>
                  <Alert severity="info">
                    Debes completar tu
                    {' '}
                    <Link to="/profile">perfil</Link>
                    {' '}
                    para poder editar tu Bike.
                  </Alert>
                </Grid>
              )}
              {vehicleTypeFetched && (
                <Grid item xs={12} sm={12}>
                  <form onSubmit={onSubmit} autoComplete="off">
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ color: steps[0].some((field) => errors[field] && errors[field].length > 0) ? 'red' : '' }}>Información general y fotos de la Bike</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                          <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                            style={{ marginRight: '2.9rem', marginLeft: '2.9rem' }}
                          >
                            {inputs.slice(0, 10).map((i) => (i.type === 'blankSpace' && !isMobileScreen ? (
                              <Grid item xs={6} />
                            ) : i.type === 'text' && !i.status && i.visible ? (
                              <Grid key={i.name} item xs={12} sm={i.last ? 12 : 6} md={i.md ? i.md : 6} align="center">
                                <TextField
                                  disabled={!profileComplete}
                                  name={i.name}
                                  label={i.label}
                                  variant="outlined"
                                  fullWidth
                                  value={
                        i.getValue && !controls.foreignCheckbox
                          ? i.getValue(controls[i.name])
                          : controls[i.name]
                      }
                                  onChange={(e) => onControlChange(e, i.getValue, !controls.foreignCheckbox)}
                                  error={!!errors[i.name]}
                                  helperText={errors[i.name] && errors[i.name][0]}
                                  InputProps={
                        i.inputProp && {
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }
                      }
                                />
                              </Grid>
                            ) : i.type === 'adornment' && !i.status && i.visible ? (
                              <Grid key={i.name} item xs={i?.xs ? i.xs : 12} sm={i.last ? 12 : 6} md={i?.md ? i.md : 6} align="center">
                                <CustomInputAdornment
                                  disabled={!profileComplete}
                                  name={i.name}
                                  label={i.label}
                                  value={
                        i.getValue && !controls.foreignCheckbox
                          ? i.getValue(controls[i.name])
                          : controls[i.name]
                      }
                                  onChange={(e) => onControlChange(e, i.getValue, !controls.foreignCheckbox)}
                                  error={!!errors[i.name]}
                                  helperText={errors[i.name] && errors[i.name][0]}
                                  openAlert={openAlertSerial}
                                />
                              </Grid>
                            ) : i.type === 'number' && !i.status && i.visible ? (
                              <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                                <CustomInputNumber
                                  disabled={!profileComplete}
                                  label={i.label}
                                  name={i.name}
                                  onChange={onControlChange}
                                  value={controls[i.name]}
                                  error={!!errors[i.name]}
                                  helperText={errors[i.name]}
                                />
                              </Grid>
                            ) : i.type === 'select' && !i.status && i.visible ? (
                              <Grid key={i.name} item xs={12} sm={i.sm ? i.sm : 6} md={i.md ? i.md : 6} align="center">
                                <CustomAutoComplete
                                  info={i.info}
                                  disabled={!profileComplete}
                                  name={i.name}
                                  label={i.label}
                                  value={controls[i.name]}
                                  onChange={onControlChange}
                                  options={i.options}
                                  error={!!errors[i.name]}
                                  errors={errors[i.name]}
                                />
                              </Grid>
                            ) : i.type === 'vehicleTypeSelect' && !i.status && i.visible ? (
                              <Grid key={i.name} item xs={12} sm={i.sm ? i.sm : 6} md={i.md ? i.md : 6} align="center">
                                <CustomAutoComplete
                                  info={i.info}
                                  disabled={!profileComplete}
                                  name={i.name}
                                  label={i.label}
                                  value={controls[i.name]}
                                  onChange={onControlChange}
                                  options={i.options}
                                  error={!!errors[i.name]}
                                  errors={errors[i.name]}
                                />
                              </Grid>
                            ) : i.type === 'date' && !i.status && i.visible ? (
                              <>
                                <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                                  <TextField
                                    name={i.name}
                                    label={i.label}
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={controls[i.name]}
                                    onChange={onControlChange}
                                    disabled={controls.reported || !profileComplete || i.disabled}
                                    error={!!errors[i.name]}
                                    helperText={errors[i.name] && errors[i.name][0]}
                                  />
                                </Grid>
                                <Grid xs={12} sm={6} md={6} align="center" />
                              </>
                            ) : null))}
                          </Grid>
                          {Boolean(controls?.vehicleTypeId) && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} align="center">
                                <HelpGoodPhotos />
                              </Grid>
                              <Grid item xs={12} align="center">
                                <ImagesSection controls={controls} actions={actions} disabled={controls.reported || !profileComplete} />
                              </Grid>
                            </Grid>
                          </>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ color: steps[1].some((field) => errors[field] && errors[field].length > 0) ? 'red' : '' }}>Origen de la Bike</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                          <Grid item xs={12} sm={12} align="center">
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                              <Grid item xs={12} sm={12} align="center">
                                {Boolean(controls?.vehicleTypeId) && (
                                <CustomToggleButton
                                  disabled={!profileComplete}
                                  label="¿Tienes la factura o boleta original de la Bike?"
                                  onChange={onControlChange}
                                  name="invoiceCheckbox"
                                  value={controls.invoiceCheckbox}
                                  option1="SI"
                                  option2="NO"
                                />
                                )}
                              </Grid>
                              {controls.invoiceCheckbox && (
                              <>
                                <CustomToggleButton2
                                  label="¿A nombre de quién fue emitida la boleta o factura?"
                                  onChange={onControlChange}
                                  name="invoiceInMyName"
                                  value={controls.invoiceInMyName}
                                  options={[
                                    { text: 'A MI NOMBRE', value: 1 },
                                    { text: 'DUEÑO ANTERIOR', value: 2 },
                                    { text: 'EMPRESA', value: 3 },
                                  ]}
                                />

                                <CustomToggleButton
                                  label="¿La factura o boleta fue emitida en el extranjero?"
                                  onChange={onControlChange}
                                  name="foreignCheckbox"
                                  value={controls.foreignCheckbox}
                                  option1="SI"
                                  option2="NO"
                                />
                              </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {(controls.invoiceInMyName === 3 && controls.invoiceCheckbox) && (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ color: steps[2].some((field) => errors[field] && errors[field].length > 0) ? 'red' : '' }}>Información empresa</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                          <Grid item xs={8}>
                            <Typography style={{ padding: 5 }}>Ingresa la razón social</Typography>
                            <TextField
                              disabled={!profileComplete}
                              name={inputs.find((el) => el.name === 'companyName').name}
                              label={inputs.find((el) => el.name === 'companyName').label}
                              variant="outlined"
                              fullWidth
                              value={
                  inputs.find((el) => el.name === 'companyName').getValue && !controls.foreignCheckbox
                    ? inputs.find((el) => el.name === 'companyName').getValue(controls[inputs.find((el) => el.name === 'companyName').name])
                    : controls[inputs.find((el) => el.name === 'companyName').name]
                }
                              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyName').getValue, !controls.foreignCheckbox)}
                              error={!!errors[inputs.find((el) => el.name === 'companyName').name]}
                              helperText={errors[inputs.find((el) => el.name === 'companyName').name] && errors[inputs.find((el) => el.name === 'companyName').name][0]}
                              InputProps={
                  inputs.find((el) => el.name === 'companyName').inputProp && {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }
                }
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ padding: 5 }}>Ingresa el RUT de la empresa</Typography>
                            <TextField
                              disabled={!profileComplete}
                              name={inputs.find((el) => el.name === 'companyTaxNumber').name}
                              label={inputs.find((el) => el.name === 'companyTaxNumber').label}
                              variant="outlined"
                              fullWidth
                              value={
                  inputs.find((el) => el.name === 'companyTaxNumber').getValue && !controls.foreignCheckbox
                    ? inputs.find((el) => el.name === 'companyTaxNumber').getValue(controls[inputs.find((el) => el.name === 'companyTaxNumber').name])
                    : controls[inputs.find((el) => el.name === 'companyTaxNumber').name]
                }
                              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyTaxNumber').getValue, !controls.foreignCheckbox)}
                              error={!!errors[inputs.find((el) => el.name === 'companyTaxNumber').name]}
                              helperText={errors[inputs.find((el) => el.name === 'companyTaxNumber').name] && errors[inputs.find((el) => el.name === 'companyTaxNumber').name][0]}
                              InputProps={
                  inputs.find((el) => el.name === 'companyTaxNumber').inputProp && {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }
                }
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography style={{ padding: 5 }}>Ingresa la dirección de la empresa</Typography>
                            <TextField
                              disabled={!profileComplete}
                              name={inputs.find((el) => el.name === 'companyAddress').name}
                              label={inputs.find((el) => el.name === 'companyAddress').label}
                              variant="outlined"
                              fullWidth
                              value={
                  inputs.find((el) => el.name === 'companyAddress').getValue && !controls.foreignCheckbox
                    ? inputs.find((el) => el.name === 'companyAddress').getValue(controls[inputs.find((el) => el.name === 'companyAddress').name])
                    : controls[inputs.find((el) => el.name === 'companyAddress').name]
                }
                              onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'companyAddress').getValue, !controls.foreignCheckbox)}
                              error={!!errors[inputs.find((el) => el.name === 'companyAddress').name]}
                              helperText={errors[inputs.find((el) => el.name === 'companyAddress').name] && errors[inputs.find((el) => el.name === 'companyAddress').name][0]}
                              InputProps={
                  inputs.find((el) => el.name === 'companyAddress').inputProp && {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }
                }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    )}

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{ color: steps[3].some((field) => errors[field] && errors[field].length > 0) ? 'red' : '' }}>Código de serie y documentación de la Bike</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Ingresa el código de serie y su foto</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            {!(!isBicycleSelected && Boolean(controls.vehicleTypeId)) ? (
                              <CustomInputAdornment
                                disabled={!profileComplete}
                                name={inputs.find((el) => el.name === 'serial').name}
                                label={inputs.find((el) => el.name === 'serial').label}
                                value={
                          inputs.find((el) => el.name === 'serial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'serial').getValue(controls[inputs.find((el) => el.name === 'serial').name])
                            : controls[inputs.find((el) => el.name === 'serial').name]
                        }
                                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'serial').getValue, !controls.foreignCheckbox)}
                                error={!!errors[inputs.find((el) => el.name === 'serial').name]}
                                helperText={errors[inputs.find((el) => el.name === 'serial').name] && errors[inputs.find((el) => el.name === 'serial').name][0]}
                                openAlert={openAlertSerial}
                              />
                            ) : (
                              <>
                                <div style={{ marginBottom: '16px' }}>
                                  <CustomInputAdornment
                                    disabled={!profileComplete}
                                    name={inputs.find((el) => el.name === 'chassisSerial').name}
                                    label={inputs.find((el) => el.name === 'chassisSerial').label}
                                    value={
                          inputs.find((el) => el.name === 'chassisSerial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'chassisSerial').getValue(controls[inputs.find((el) => el.name === 'chassisSerial').name])
                            : controls[inputs.find((el) => el.name === 'chassisSerial').name]
                        }
                                    onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'chassisSerial').getValue, !controls.foreignCheckbox)}
                                    error={!!errors[inputs.find((el) => el.name === 'chassisSerial').name]}
                                    helperText={errors[inputs.find((el) => el.name === 'chassisSerial').name] && errors[inputs.find((el) => el.name === 'chassisSerial').name][0]}
                                    openAlert={openAlertSerial}
                                  />
                                </div>
                                <CustomInputAdornment
                                  disabled={!profileComplete}
                                  name={inputs.find((el) => el.name === 'engineSerial').name}
                                  label={inputs.find((el) => el.name === 'engineSerial').label}
                                  value={
                          inputs.find((el) => el.name === 'engineSerial').getValue && !controls.foreignCheckbox
                            ? inputs.find((el) => el.name === 'engineSerial').getValue(controls[inputs.find((el) => el.name === 'engineSerial').name])
                            : controls[inputs.find((el) => el.name === 'engineSerial').name]
                        }
                                  onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'engineSerial').getValue, !controls.foreignCheckbox)}
                                  error={!!errors[inputs.find((el) => el.name === 'engineSerial').name]}
                                  helperText={errors[inputs.find((el) => el.name === 'engineSerial').name] && errors[inputs.find((el) => el.name === 'engineSerial').name][0]}
                                  openAlert={openAlert}
                                />

                              </>
                            )}
                          </Grid>
                          <Grid item xs={3} container alignItems="center" justifyContent="center">
                            <CustomInputFile
                              title={!isMobileScreen ? `Foto del ${
                                isBicycleSelected
                                  ? 'Código de serie'
                                  : `Número de chasis ${controls.invoiceCheckbox ? '(OPCIONAL)' : ''}`
                              }` : ''}
                              id="serialFile"
                              name="serialFile"
                              onChange={onControlChange}
                              documentType="documentSerialId"
                              value={controls.serialFile}
                              error={!!errors.serialFile}
                              errors={errors.serialFile}
                              disabled={controls.reported || !profileComplete}
                            />
                          </Grid>
                          {controls.invoiceCheckbox && (
                          <>
                            <Grid item xs={12}>
                              <Typography variant="h6">Ingresa la información de tu factura o boleta y su foto</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <TextField
                                fullWidth
                                name={inputs.find((el) => el.name === 'invoiceTaxNumber').name}
                                label={inputs.find((el) => el.name === 'invoiceTaxNumber').label}
                                value={
                      inputs.find((el) => el.name === 'invoiceTaxNumber').getValue && !controls.foreignCheckbox
                        ? inputs.find((el) => el.name === 'invoiceTaxNumber').getValue(controls[inputs.find((el) => el.name === 'invoiceTaxNumber').name])
                        : controls[inputs.find((el) => el.name === 'invoiceTaxNumber').name]
                    }
                                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'invoiceTaxNumber').getValue, !controls.foreignCheckbox)}
                                error={!!errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name]}
                                helperText={errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name] && errors[inputs.find((el) => el.name === 'invoiceTaxNumber').name][0]}
                              />
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justifyContent="center">
                              <CustomInputFile
                                title={!isMobileScreen ? 'Foto de la Factura o Boleta.' : ''}
                                id="invoiceFile"
                                name="invoiceFile"
                                onChange={onControlChange}
                                documentType="documentInvoiceId"
                                value={controls.invoiceFile}
                                error={!!errors.invoiceFile}
                                errors={errors.invoiceFile}
                                disabled={controls.reported || !profileComplete}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                name={inputs.find((el) => el.name === 'purchasedAt').name}
                                label={inputs.find((el) => el.name === 'purchasedAt').label}
                                variant="outlined"
                                fullWidth
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={controls[inputs.find((el) => el.name === 'purchasedAt').name]}
                                onChange={onControlChange}
                                disabled={controls.reported || !profileComplete || inputs.find((el) => el.name === 'purchasedAt').disabled}
                                error={!!errors[inputs.find((el) => el.name === 'purchasedAt').name]}
                                helperText={errors[inputs.find((el) => el.name === 'purchasedAt').name] && errors[inputs.find((el) => el.name === 'purchasedAt').name][0]}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomAutoComplete
                                info={inputs.find((el) => el.name === 'currencyId').info}
                                disabled={!profileComplete}
                                name={inputs.find((el) => el.name === 'currencyId').name}
                                label={inputs.find((el) => el.name === 'currencyId').label}
                                value={controls[inputs.find((el) => el.name === 'currencyId').name]}
                                onChange={onControlChange}
                                options={inputs.find((el) => el.name === 'currencyId').options}
                                error={!!errors[inputs.find((el) => el.name === 'currencyId').name]}
                                errors={errors[inputs.find((el) => el.name === 'currencyId').name]}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInputNumber
                                disabled={!profileComplete}
                                label={inputs.find((el) => el.name === 'amount').label}
                                name={inputs.find((el) => el.name === 'amount').name}
                                onChange={onControlChange}
                                value={controls[inputs.find((el) => el.name === 'amount').name]}
                                error={!!errors[inputs.find((el) => el.name === 'amount').name]}
                                helperText={errors[inputs.find((el) => el.name === 'amount').name]}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={!profileComplete}
                                name={inputs.find((el) => el.name === 'invoiceNumber').name}
                                label={inputs.find((el) => el.name === 'invoiceNumber').label}
                                variant="outlined"
                                fullWidth
                                value={
                      inputs.find((el) => el.name === 'invoiceNumber').getValue && !controls.foreignCheckbox
                        ? inputs.find((el) => el.name === 'invoiceNumber').getValue(controls[inputs.find((el) => el.name === 'invoiceNumber').name])
                        : controls[inputs.find((el) => el.name === 'invoiceNumber').name]
                    }
                                onChange={(e) => onControlChange(e, inputs.find((el) => el.name === 'invoiceNumber').getValue, !controls.foreignCheckbox)}
                                error={!!errors[inputs.find((el) => el.name === 'invoiceNumber').name]}
                                helperText={errors[inputs.find((el) => el.name === 'invoiceNumber').name] && errors[inputs.find((el) => el.name === 'invoiceNumber').name][0]}
                                InputProps={
                      inputs.find((el) => el.name === 'invoiceNumber').inputProp && {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }
                    }
                              />
                            </Grid>
                          </>
                          )}
                          <Grid item xs={12} sm={12} align="center">
                            <CustomCheckBox
                              disabled={!profileComplete}
                              label={`Declaro ser el legítimo propietario y/o representante autorizado del propietario, con facultades suficientes y vigentes para registrar esta ${
                                isBicycleSelected ? 'bicicleta' : 'motocicleta'
                              }, y me comprometo a efectuar su transferencia en el momento de la venta`}
                              onChange={onControlChange}
                              name="possessionCheckbox"
                              value={controls.possessionCheckbox}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    {!controls.reported && (
                    <Grid item xs={12} sm={12} align="center" style={{ margin: '50px' }}>
                      <Button
                        disabled={loading || !profileComplete}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {loading ? <CircularProgress size={24} /> : 'Guardar'}
                      </Button>
                      {!!Object.values(errors).length
                            && !Object.values(errors).includes(undefined) && (
                              <Typography style={{ color: 'red', marginTop: '5px' }}>
                                Se encontraron errores en la información ingresada
                              </Typography>
                      )}
                    </Grid>
                    )}
                  </form>
                  {controls.reported && (
                    <Box mt={5}>
                      <Grid container spacing={3} mt={3}>
                        <Grid item xs={12} sm={12} align="center">
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={openAlert}
                          >
                            Eliminar denuncia
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              )}
              {!vehicleTypeFetched && (
                <Box style={{ marginTop: '15px', marginBottom: '15px' }}>
                  <Grid container justifyContent="center">
                    <CircularProgress />
                  </Grid>
                </Box>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditForm;
