import { createReducer } from '../../../utils/reducer';
import adminSaleTypes from '../types';

const initState = {
  loading: false,
  importSaleReport: [],
};

const adminSaleReducer = createReducer(initState, {
  [adminSaleTypes.UPLOAD_SALES_EXCEL](state) {
    return {
      ...state,
      loading: true,
      importSaleReport: [],
    };
  },
  [adminSaleTypes.UPLOAD_SALES_EXCEL_SUCCESS](state, action) {
    return {
      ...state,
      importSaleReport: action.payload,
      loading: false,
    };
  },
  [adminSaleTypes.UPLOAD_SALES_EXCEL_FAILURE](state) {
    return {
      ...state,
      loading: false,
      importSaleReport: [],
    };
  },
});

export default adminSaleReducer;
