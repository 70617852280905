import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import CustomModalConfirmation from 'commons/components/CustomModalConfirmation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PurchaseButton = (props) => {
  const {
    id,
    user,
    acceptSeller,
    acceptBuyer,
    loading,
    alertDeny,
    openAlertDeny,
    clearAlertDeny,
    deleteTransfer,
    createTransferPDF,
    increaseStep
  } = props;
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAcceptBuyer = () => {
    acceptBuyer(id);
  };
  const handleRejectContract = () => {
    deleteTransfer(id);
    history.push('/');

  };
  const handleClickContinueButton = () => {
    increaseStep();
    acceptBuyer(id);
    createTransferPDF({ transferId: id });
  };

  const text = (
    <>
      Al pulsar el botón
      <span style={{ fontWeight: 'bold' }}> &quot;CONTINUAR&quot; </span>
      con esta transferencia, se generará y
      <br />
      firmará electrónicamente, de acuerdo a lo establecido en la
      <span style={{ fontWeight: 'bold' }}> Ley 19.799</span>
      ,
      <br />
      el contrato de compraventa respectivo con el vendedor de la Bike  , el que será vinculante con
      este último para todos los efectos legales a que hubiere lugar.
    </>
  );

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={6} sm={3} md={2}>
          <Button onClick={handleRejectContract} type="submit" variant="contained" color="secondary">
            Rechazar
          </Button>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Button onClick={handleClickOpen} type="submit" variant="contained" color="primary">
            Aceptar
          </Button>
        </Grid>
      </Grid>
      <CustomModalConfirmation
        alert={alertDeny}
        clearAlert={clearAlertDeny}
        text={'Al pulsar el botón "CONTINUAR" esta transferencia será rechazada'}
        loading={loading}
        handleSubmit={() => deleteTransfer(id)}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText>{text}</DialogContentText>
          <DialogContentText>¿Estás seguro que deseas continuar?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={loading}>
            Cancelar
          </Button>
          <Button
            id="id_button_accept_contract"
            onClick={handleClickContinueButton}
            // onClick={increaseStep}
            color="primary"
            autoFocus
            disabled={loading}>
            Continuar
            {loading && (
              <CircularProgress style={{ height: '20px', width: '20px', marginLeft: '10px' }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PurchaseButton;
