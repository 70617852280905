import { createReducer } from '../../utils/reducer';

import selectedInsuranceTypes from './selectedInsurance.types';

const initState = {
  isErrors: false,
  errorMessage: '',
  loaders: {
    isLoading: false,
  },
};

const selectedInsuranceReducer = createReducer(initState, {
  [selectedInsuranceTypes.INIT_STATE](state) {
    return {
      ...state,
      ...initState,
    };
  },
});

export default selectedInsuranceReducer;
