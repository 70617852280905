import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CustomMultipleSelect from 'commons/components/CustomMultipleSelect';
import InputVoucher from 'screens/Maintenance/components/InputVoucher';
import { CircularProgress } from '@material-ui/core';
import CustomMultiSelectComplete from 'commons/components/CustomMultiSelectComplete';

const FormModal = ({
  open,
  clear,
  onSubmit,
  onChange,
  errors,
  controls,
  maintenancesTypes,
  edit,
  loading,
  bike,
}) => (
  <div>
    <Dialog
      open={open}
      // onClose={clear}
      fullWidth>
      <DialogTitle>{edit ? 'Editar' : 'Agregar'} mantención</DialogTitle>
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <CustomMultiSelectComplete
                name="types"
                label="Mantenciones"
                options={maintenancesTypes}
                onChange={onChange}
                value={controls.types}
                error={!!errors.types}
                errors={errors.types}
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              <TextField
                name="description"
                variant="outlined"
                label="Detalle"
                placeholder="(opcional)"
                value={controls.description}
                onChange={onChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              <TextField
                name="date"
                variant="outlined"
                label="Fecha"
                InputLabelProps={{ shrink: true }}
                value={controls.date}
                type="date"
                fullWidth
                onChange={onChange}
                error={!!errors.date}
                helperText={errors.date}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputVoucher
                id="voucherFile"
                name="voucherFile"
                onChange={onChange}
                value={controls.voucherFile}
                error={!!errors.voucherFile}
                errors={errors.voucherFile}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={clear} variant="contained" color="secondary">
            Cancelar
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress size={24} /> : 'Guardar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  </div>
);

export default FormModal;
