import { makeStyles } from '@material-ui/core';
import colors from 'utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    maxWidth: '1440px',
    width: '100%',
    // margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  commonsPaper: {
    maxWidth: '1351px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '20px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fWeight700: {
    fontWeight: 700,
  },
  colorPrimary: {
    color: primary,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    padding: theme.spacing(2),
  },
  content: {
    flex: '1 0 auto',
    paddingBottom: '5px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  bikeInfoPaper: {
    '& .container': {
      minHeight: '538px',
      '& .bike-img-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .img': {
          height: '100%',
          objectFit: 'cover',
        },
      },
      '& .bike-info-container': {
        '& .price-container': {
          '& .price': {
            color: primary,
            fontWeight: '700',
            fontSize: '36px',
          },
        },
        '& .props-bike-container': {
          '& .props-bike': {
            fontWeight: '700',
            fontSize: '20px',
          },
        },
        '& .bike-location-container': {
          display: 'flex',
          marginTop: '15px',
          '& .bike-location': {
            fontWeight: '700',
            fontSize: '20px',
          },
        },
        '& .bike-description-container': {
          margin: '20px 0px 0px 10px',
          minHeight: '231px',
          '& .title': {
            fontWeight: '700',
            fontSize: '14px',
          },
          '& .description': {
            fontSize: '14px',
          },
        },
        '& .buttons-container': {
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
          '& .btn': {
            color: primary,
            borderColor: primary,
            padding: '0px 15px',
            '& .icon': {
              marginRight: '10px',
            },
            '&:last-of-type': {
              marginLeft: '10px',
            },
          },
        },
      },
    },
  },
  tabsPaper: {
    borderRadius: '10px',
    backgroundColor: 'transparent',
  },
  amountBoxContainer: {
    '& .amountBox': {
      padding: '10px',
    },
  },
  mTop10: {
    marginTop: '10px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  authorizedPublicationCheck: {
    '& svg': {
      fontSize: '1rem',
    },
  },
  authorizedPublicationLabel: {
    fontSize: '0.8rem',
    '& svg': {
      fontSize: '1rem',
    },
  },
  root: {
    position: 'relative',
    height: 500,
    overflow: 'hidden',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
  },
  radioGroupStyles: {
    width: '70%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '8px',
    marginLeft: 0,
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    bikeInfoPaper: {
      '& .container': {
        '& .bike-img-container': {
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    root: {
      height: '95vw',
    },
  },
  [theme.breakpoints.down('xs')]: {
    details: {
      '& .centeredContent': {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    radioGroupStyles: {
      width: '95%',
      marginTop: '10px',
      fontSize: '14px',
    },
  },
  blur: {
    filter: 'blur(0.5rem)',
    opacity: 0.5,
    height: '100%',
  },
  eye: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 1,
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  btn: {
    position: 'absolute',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '60%',
  },
  loadingBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '60%',
  },
  fullWidth: {
    width: '100%',
  },
  deletePublicationTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  deletePublicationTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '10px',
  },
});

export default styles;
