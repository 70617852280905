import React from 'react';
import PropTypes from 'prop-types';

// REACT_ROUTER_DOM
import { Link } from 'react-router-dom';

// MATERIAL
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { PATHS } from 'utils/paths';
import styles from './styles';

const useStyles = makeStyles(styles);

const GeneralAlert = (props) => {
  const {
    alertType,
    severity,
    handleChangeFilterPending,
    bikeBrandName,
    bikeModelName,
    transferId,
  } = props;

  const classes = useStyles();

  const { alertContainer, alertPublishOnSocialMedia, bikeAlreadySoldAlert } = classes;
  const alertClass = (alertType) => {
    switch (alertType) {
      case 'publishOnSocialMedia':
        return alertPublishOnSocialMedia;
      case 'bikePublicationDeletedBecauseSoldReason':
        return bikeAlreadySoldAlert;
      default:
        return alertContainer;
    }
  };

  const alertMessages = {
    completeBikeInfo: (
      <>
        Debes completar eI registro de tu Bike para protegerte, acceder a nuestras funciones y
        obtener los beneficios.
      </>
    ),
    publishOnSocialMedia: (
      <>
        Dale mayor exposición a tu Bicicleta publicando en nuestro canal de
        {' '}
        <a
          href="https://www.facebook.com/groups/bikfyciclismo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
      </>
    ),
    pendingTransfers: (
      <>
        Hay una transferencia esperando. Completa tu
        <Link to="/profile"> perfil </Link>
        para poder finalizar el proceso
      </>
    ),
    pendingTransfersByBuyer: (
      <>
        Tienes una Bike en proceso de transferencia. Puedes verla
        {' '}
        <Link to={`${PATHS.PILOT}?filter=3`} onClick={handleChangeFilterPending}>
          aquí.
        </Link>
      </>
    ),
    pendingTransfersBySeller: (
      <>
        Tu perfil fue completado con éxito. Se le notificará al vendedor para que reanude el proceso
        de Transferencia.
      </>
    ),
    pendingTransfersSeller: (
      <>
        Tienes una transferencia en proceso. Recuerda no entregar o despachar tu Bike hasta ser
        notificado vía correo electrónico de que el pago ha sido realizado.
      </>
    ),
    pendingTransfersExternalSeller: (
      <>Tienes una transferencia pendiente a la espera de ser finalizada por el comprador.</>
    ),
    pendingUserData: (
      <>
        Para registrar, recibir transferencias, comprar o vender Bikes, debes completar tu perfil
        {' '}
        <Link to={PATHS.PROFILE}>aquí.</Link>
      </>
    ),
    bikePublicationDeletedBecauseSoldReason: (
      <>
        Recuerda que de acuerdo a nuestros
        {' '}
        <a
          target="_blank"
          href={`${process.env.REACT_APP_INFO_URL}/terminos-y-condiciones2/`}
          rel="noreferrer"
        >
          Términos & Condiciones
        </a>
        , debes transferir legalmente tu Bike
        {' '}
        {bikeBrandName || ''}
        {' '}
        {bikeModelName || ''}
        {' '}
        al nuevo
        dueño para mantener su trazabilidad en el tiempo.
        {' '}
        <Link to={`bike/${transferId}/transfer`}>Hazlo aquí</Link>
      </>
    ),
  };

  return (
    <Box className={alertClass(alertType)}>
      <Alert severity={severity}>
        <Typography align="center" className="message">
          {alertMessages[alertType]}
        </Typography>
      </Alert>
    </Box>
  );
};

GeneralAlert.propTypes = {
  alertType: PropTypes.oneOf([
    'pendingTransfers',
    'pendingTransfersByBuyer',
    'pendingTransfersBySeller',
    'pendingTransfersSeller',
    'pendingTransfersExternalSeller',
    'pendingUserData',
  ]).isRequired,
  severity: PropTypes.string,
  handleChangeFilterPending: PropTypes.func.isRequired,
};

GeneralAlert.defaultProps = {
  severity: 'info',
};

export default GeneralAlert;
