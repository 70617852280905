/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomTitleDefault from 'commons/components/CustomTitleDefault';
import BenefitsTable from 'commons/components/BenefitsTable';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Project
import saga from 'screens/Subscription/saga';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardActions,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { composedComponent, formatDateToString } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { useParams } from 'react-router-dom';
import fullBikeLogo from '../../commons/assets/banner_insurance/banner0_d.png';
import fullBikeCard from '../../commons/assets/providers/fullBikeCard.png';

import { subscriptionActions } from './reducer';

const MENU = {
  SUBSCRIPTION: 'subscription',
  PAYMENT_METHOD: 'payment_method',
};

const useStyles = makeStyles((theme) => ({

  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${fullBikeCard})`,
    backgroundSize: 'contain', // Ensure the image fits within the card
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white',
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(2),
    aspectRatio: '10.7 / 16', // Maintain a specific aspect ratio
    height: 'auto',
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    width: '90%', // Ensure the overlay fits within the card boundaries
  },
  authorizedButton: {
    backgroundColor: 'green',
    color: 'white',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

}));
const Subscription = (props) => {
  const {
    user,
    actions,
    subscription,
    error,
    loading,
    loadingCancel,
    loadingAddCard,
  } = props;
  const params = useParams();
  const { subscriptionId } = params;
  const theme = useTheme();
  const classes = useStyles();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selected, setSelected] = useState(MENU.SUBSCRIPTION);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [expanded, setExpanded] = useState(false);

  const handleChange = (menu) => {
    setSelected(menu);
    if (isMobileScreen) {
      setExpanded(false);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // useEffect(() => {
  //   if (oneclickSubscriptionUrl && shouldRedirect) {
  //     window.location.href = oneclickSubscriptionUrl; // Redirects the user
  //     setShouldRedirect(false);
  //   }
  // }, [oneclickSubscriptionUrl]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    actions.fetchSubscription(subscriptionId);
  }, [subscriptionId]);

  // const handleChange = (panel) => {
  //   setSelected(panel);
  // };
  if (loading) {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}
      >
        <CircularProgress size={60} />
      </div>
    );
  }

  if (!subscription || error || user.id !== subscription.userId || subscription.status !== 'active') {
    return <p> Subscripción no encontrada, agrega una desde el Club Bikfy</p>;
  }
  const renderList = () => (
    <List component="nav">
      <ListItem
        button
        selected={selected === MENU.SUBSCRIPTION}
        onClick={() => handleChange(MENU.SUBSCRIPTION)}
      >
        <ListItemIcon>
          <LoyaltyIcon />
        </ListItemIcon>
        <ListItemText primary="Mi suscripción" />
      </ListItem>
      <ListItem
        button
        selected={selected === MENU.PAYMENT_METHOD}
        onClick={() => handleChange(MENU.PAYMENT_METHOD)}
      >
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>
        <ListItemText primary="Mi medio de pago" />
      </ListItem>
    </List>
  );

  return (
    <div>

      {/* <img alt="logo" src={fullBikeLogo} width="100%" height="100px" /> */}

      <Grid container spacing={2} alignItems="flex-start" direction={isMobileScreen ? 'column' : 'row'}>
        <Grid item xs={12} md={3} style={{ alignSelf: isMobileScreen ? 'center' : 'auto' }}>
          {/* <List component="nav">
            <ListItem
              button
              selected={selected === MENU.SUBSCRIPTION}
              onClick={() => handleChange(MENU.SUBSCRIPTION)}
            >
              <ListItemIcon>
                <LoyaltyIcon />
              </ListItemIcon>
              <ListItemText primary="Mi suscripción" />
            </ListItem>
            <ListItem
              button
              selected={selected === MENU.PAYMENT_METHOD}
              onClick={() => handleChange(MENU.PAYMENT_METHOD)}
            >
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Mi medio de pago" />
            </ListItem>
          </List> */}
          {isMobileScreen ? (
            <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {selected === MENU.SUBSCRIPTION ? 'Mi suscripción' : 'Mi medio de pago'}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{renderList()}</AccordionDetails>
            </Accordion>
          ) : (
            renderList()
          )}
        </Grid>

        {/* Description */}
        <Grid item xs={12} md={9} style={{ alignSelf: 'center' }}>
          {selected === MENU.PAYMENT_METHOD && (subscription.tbkUser ? (
            <Card>
              <CardContent>
                <Typography variant="h6">
                  Tarjeta de crédito
                  {' '}
                  {` ${subscription.cardType}`}
                </Typography>
                <Typography>{subscription.cardNumber.replace(/(.{4})/g, '$1-').slice(0, -1)}</Typography>
                <Typography>
                  Fecha inscripción tarjeta:
                  {' '}
                  {/* {` ${formatDateToString(subscription.createdAt)}`} */}
                  {new Date(subscription.createdAt).toLocaleDateString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Typography>
              </CardContent>
              <CardActions>
                <Box mt={2} display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingCancel}
                    onClick={() => actions.addCard({
                      userId: user.id,
                      providerId: subscription.providerId,
                    })}
                    style={{ marginRight: '8px' }}
                  >
                    Cambiar Tarjeta
                  </Button>
                  {!loadingCancel ? (
                    <Button
                      onClick={() => actions.cancelSubscription(subscription.id)}
                      disabled={loadingCancel}
                      color="secondary"
                    >
                      Eliminar Tarjeta
                    </Button>
                  ) : (
                    <CircularProgress size={24} />
                  )}
                </Box>
              </CardActions>
            </Card>
          ) : (
            <Box>
              <Typography variant="h6">
                No tienes tarjeta inscrita
              </Typography>
              <Typography>XXXXXXXXXXX</Typography>
              {/* <Typography>
                Fecha inscripción tarjeta:
                {` ${subscription.createdAt}`}
              </Typography> */}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => actions.addCard({
                    userId: user.id,
                    providerId: subscription.providerId,
                  })}
                  disabled={loadingAddCard}
                >
                  Agregar Tarjeta
                </Button>
                {/* <Button
                  onClick={() => actions.cancelSubscription(subscription.id)}
                  disabled={loadingCancel}
                >
                  Eliminar Tarjeta
                </Button> */}

              </Box>
            </Box>
          ))}
          {selected === MENU.SUBSCRIPTION && (

          <Card className={classes.card}>
            <CardContent className={classes.overlay}>
              <Typography variant="h6">Pase diario FullWheels</Typography>
              <Box className={classes.authorizedButton}>Autorizado</Box>
              {/* <Typography variant="h6">{`${formatDateToString(currentTime)}`}</Typography> */}
              <Typography variant="h6">{`${currentTime.getUTCDate()}-${currentTime.getMonth().toString().length === 1 ? '0' : ''}${currentTime.getMonth() + 1}-${currentTime.getFullYear()}`}</Typography>

              <Typography variant="h6">{`${currentTime.getHours().toString().length === 1 ? '0' : ''}${currentTime.getHours()}:${currentTime.getMinutes().toString().length === 1 ? '0' : ''}${currentTime.getMinutes()}:${currentTime.getSeconds().toString().length === 1 ? '0' : ''}${currentTime.getSeconds().toString()}`}</Typography>
              <Typography variant="body1">
                {`${user.name}  ${user.firstLastName}  ${user.secondLastName}`}

              </Typography>
              <Typography variant="body1">
                RUT:
                {` ${user.taxNumber}`}
              </Typography>
            </CardContent>
          </Card>

          )}

        </Grid>

        {/* Button */}
        {/* <Grid item xs={3}>
          <Button variant="contained" color="primary" fullWidth onClick={() => handleButtonClick(provider?.id)}>
            VER PASE VIRTUAL
          </Button>
        </Grid> */}
      </Grid>
    </div>

  );
};

export default composedComponent(Subscription, saga,
  {
    saga: 'sagaSubscription',
    states:
      [
        'subscription.subscription',
        'subscription.error',
        'subscription.loading',
        'subscription.loadingCancel',
        'subscription.loadingAddCard',
        'app.user.user',
      ],
    actions: [subscriptionActions, appActions],
  });
