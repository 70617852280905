// @ts-nocheck
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
// import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

// Utils
import configureStore, { ReactReduxContext } from './config/configureStore';
// components
import App from './App';
// styles
import './commons/assets/styles.scss';
// sentry

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_KEY,
//   environment: process.env.REACT_APP_SENTRY_ENV ?? 'NOT_SET',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/.*\.bikfy\.com\//],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const store = configureStore();
const persistor = persistStore(store);

const renderApp = () => render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App context={ReactReduxContext} />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);

renderApp();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    module.hot.accept('./App', () => {
      renderApp();
    });
  }
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
} else {
  console.log('serviceWorker not available');
}
