/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

// MATERIAL
import { withStyles, Grid, Button, Paper, Typography, Box, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Carousel from 'react-material-ui-carousel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// seguimos usando esto ?
import { TOTAL_SELLER_FLOW_STEPS } from 'utils/consts';
import BikeDetailImage from 'commons/components/BikeDetailImage';
import ModalPhotos from 'commons/components/ModalPhotos/ModalPhotos';
import PaymentMethodCard from 'commons/components/PaymentMethodCard';
import SimpleStepper from 'commons/components/SimpleStepper';
import { composedComponent, validateRut, formatNumberToCLP } from '../../utils/functions';
import enablePaymentSaga from './enablePaymentScreen.saga';
import enablePaymentScreen from './enablePaymentScreen.actions';
import styles from './styles';

import BankDataForm from './components/BankDataForm';
import ConfirmModal from './components/confirmModal';
import InformativeModal from './components/InformativeModal';

const EnablePaymentScreen = (props) => {
  const {
    classes,
    enablePayment: enablePaymentState,
    actions,
    history,
    objects,
    publishBikeForm,
    user,
  } = props;

  const {
    initState,
    setIsOpenConfirmModal,
    setUserPaymentMethod,
    getTransaction,
    toggleError,
    setIsOpenInformativeModal,
  } = actions;

  const {
    redirect,
    accountTypes,
    banks,
    formValues,
    errors,
    modals,
    transfer: completeTransfer,
  } = enablePaymentState; // esperar algo en el state para redireccionar *

  const { confirmModal, informativeModal: informativeModalState } = modals;

  const { user: currentUser } = user;

  const {
    generalContainer,
    cardContainer,
    buttonsContainer,
    formContainer,
    formPaper,
    formTitleContainer,
    alertFormContainer,
    bankDataForm,
    informativeModal,
    confirmModalStyle,
    containerMargin,
  } = classes;

  // hooks
  const [enableBikfyPay, setEnableBikfyPay] = useState(true);
  const [bikfyForm, setBikfyForm] = useState({ ...publishBikeForm });
  const [salePrices, setSalePrices] = useState({ creditCard: 0, bankTransfer: 0 });
  const [photos, setPhotos] = useState([]);
  const [openModalPhotos, setOpenModalPhotos] = useState(false);

  const theme = useTheme();

  const isDownSxScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const is393pxWidthScreen = useMediaQuery('(max-width:393px)');

  const submitButtonRef = useRef(null);

  const { transferId } = useParams();

  const transfer = objects?.transfers[transferId];
  const buyer = transfer?.buyer;
  const bike = transfer?.bike;
  const amountBike = Number(transfer?.amount);
  const files = transfer?.bike?.document?.map((document) => document.file);
  const commission = Object.values(objects?.commissions)[0];
  const isBicycle = objects?.bikes[bike?.id]?.isBicycle;

  const toggleConfirmModal = (isShowButtons = true) => {
    setIsOpenConfirmModal({
      isOpen: !confirmModal.isOpen,
      isShowButtons,
    });
  };

  const toggleInformativeModal = () => {
    setIsOpenInformativeModal({
      isOpen: !informativeModalState.isOpen,
    });
  };

  useEffect(() => {
    getTransaction({
      transferId,
      formValues: {
        userName: `${currentUser.name} ${currentUser.firstLastName}`,
        email: currentUser.email,
        rut: currentUser.taxNumber,
      },
    });
  }, []);

  useEffect(() => {
    if (confirmModal.isOpen && enableBikfyPay) {
      toggleConfirmModal();
    }
  }, [enableBikfyPay]);

  useEffect(() => {
    if (redirect) {
      history.push(`/transfer/${transferId}`);
    }
    return () => {
      initState();
    };
  }, [redirect]);

  useEffect(() => {
    let finalPrice = 0;

    if (bikfyForm.creditCard.value && transfer?.amount) {
      finalPrice = calculatePrice(commission?.creditCard);
    }

    if (bikfyForm.creditCardWithFees.value && transfer?.amount) {
      finalPrice = calculatePrice(commission?.creditCardWithFees);
    }

    setSalePrices((prevState) => ({
      ...prevState,
      creditCard: finalPrice,
      bankTransfer: transfer?.amount ? transfer?.amount - commission?.bankTransfer : 0,
    }));
  }, [transfer?.amount]);

  const handleClickContinueButton = () => toggleConfirmModal();

  const handleClickGoBackButton = () => history.goBack();

  const handleClickAcceptModalButton = () => submitButtonRef?.current.click();

  const calculatePrice = (commissionToApply) => {
    const bikePrice = transfer?.amount;
    const discount = (bikePrice * commissionToApply) / 100;

    return bikePrice - discount;
  };

  const handleOpenPhotos = (documents) => {
    setOpenModalPhotos(true);

    const docs = documents?.filter((doc) =>
      ['left_side', 'front_side', 'right_side'].includes(doc.type),
    );
    setPhotos(docs);
  };

  const handleclosePhotos = () => setOpenModalPhotos(false);

  const handleChange = ({ target }) => {
    const { name, checked } = target;

    if (name === 'creditCard') {
      const finalPrice = checked ? calculatePrice(commission?.creditCard) : 0;

      setSalePrices((prevState) => ({ ...prevState, creditCard: finalPrice }));

      if (!checked) {
        setBikfyForm((prevState) => ({
          ...prevState,
          creditCardWithFees: {
            ...prevState.creditCardWithFees,
            value: checked,
          },
        }));
      }
    }

    if (name === 'creditCardWithFees') {
      const finalPrice = checked
        ? calculatePrice(commission?.creditCardWithFees)
        : calculatePrice(commission?.creditCard);

      setSalePrices((prevState) => ({ ...prevState, creditCard: finalPrice }));
    }

    setBikfyForm((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: checked,
      },
    }));
  };

  const setBankData = (newFormValues) => {
    let isErrors = false;
    let errorMessage = '';
    const onlyNumbersRegExp = new RegExp('^[0-9]+$');

    if (!onlyNumbersRegExp.test(newFormValues.account)) {
      isErrors = true;
      errorMessage = 'El campo "N° de Cuenta" solo acepta números';
    }

    if (!validateRut(newFormValues.rut)) {
      isErrors = true;
      errorMessage = 'El rut no es valido';
    }

    if (
      newFormValues.bankId === 0 ||
      newFormValues.accountTypeId === 0 ||
      newFormValues.account === '' ||
      newFormValues.userName === '' ||
      newFormValues.rut === '' ||
      newFormValues.email === ''
    ) {
      isErrors = true;
      errorMessage = 'Para continuar debes completar todos tus datos bancarios';
    }

    const { creditCard, creditCardWithFees, bankTransfer } = bikfyForm;

    return !isErrors
      ? setUserPaymentMethod({
          creditCard: creditCard.value,
          creditCardWithFees: creditCardWithFees.value,
          bankTransfer: bankTransfer.value,
          enableBikfyPay,
          newFormValues,
          transferId,
        })
      : toggleError({
          isErrors,
          errorMessage,
        });
  };

  return (
    <Grid container className={`${generalContainer}`}>
      <Grid container>
        <SimpleStepper activeStep={3} stepTotal={TOTAL_SELLER_FLOW_STEPS} />
      </Grid>
      <Grid container className={`${cardContainer}`}>
        <Grid container className={`${formContainer}`} spacing={2}>
          <Grid item md={6} xs={12}>
            <Carousel interval={10000}>
              {files?.map((doc) => (
                <Box key={doc.id} style={{ height: '75vh' }}>
                  <BikeDetailImage
                    url={doc?.url}
                    openModal={() => handleOpenPhotos(transfer?.bike?.documents)}
                  />
                </Box>
              ))}
            </Carousel>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container className={`${containerMargin}`}>
              <Grid item>
                <Typography variant="h4">
                  <Box fontWeight="fontWeightBold">{`${bike?.brand?.name} ${bike?.model?.name}`}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {isBicycle && (
                  <Typography variant="h6">
                    <Box fontWeight={500}>
                      Talla {bike?.size?.name} | Año {bike?.year} | Aro {bike?.rim?.name}
                    </Box>
                  </Typography>
                )}
                {!isBicycle && (
                  <Typography variant="h6">
                    <Box fontWeight={500}>
                      Año {bike?.year} | Cilindraje {bike?.motorbike?.engineCapacity?.capacity}
                    </Box>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={`${containerMargin}`}>
              <Typography className="title">Email del comprador</Typography>
              <TextField
                fullWidth
                name="buyerEmail"
                value={buyer?.email}
                variant="outlined"
                disabled
              />
            </Grid>
            <PaymentMethodCard
              bankTransferAvailable={bikfyForm?.bankTransfer?.value}
              bankTransferTotal={Number(salePrices.bankTransfer)}
              creditCardAvailable={bikfyForm?.creditCard?.value}
              creditCardTotal={Number(salePrices.creditCard)}
              creditCardWithFeesAvailable={bikfyForm?.creditCardWithFees?.value}
              disabledSalePrice
              handleChange={handleChange}
              isTransferFlow
              price={amountBike}
              priceToShow={formatNumberToCLP(Number(1))}
            />
          </Grid>
        </Grid>
        <Grid item md={12} className={`${formContainer}`}>
          <Paper className={`${formPaper}`}>
            <Box className={`${formTitleContainer}`}>
              <Typography component="h2" className="formTitle">
                Datos
                <span className="bikfyColor"> Bancarios </span>
              </Typography>
            </Box>
            <Box className={`${alertFormContainer}`}>
              <Alert severity="info" className="alert">
                Ingresa tus datos para transferirte el valor de tu Bike descontada la comisión
              </Alert>
            </Box>
            <Box>
              <BankDataForm
                banks={banks}
                accountTypes={accountTypes}
                submitButtonRef={submitButtonRef}
                setBankData={setBankData}
                stateFormValues={formValues}
                className={`${bankDataForm}`}
              />
              {errors.isErrors && (
                <Alert severity="warning">
                  <AlertTitle>Advertencia</AlertTitle>
                  {errors.errorMessage}
                </Alert>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid container className={`${buttonsContainer}`}>
        <Button
          variant="contained"
          color="secondary"
          className="goBackButton"
          onClick={handleClickGoBackButton}>
          Volver
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickContinueButton}
          disabled={!bikfyForm?.creditCard?.value}>
          Continuar
        </Button>
      </Grid>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        setEnableBikfyPay={setEnableBikfyPay}
        className={`${confirmModalStyle}`}
        handleClickAcceptModalButton={handleClickAcceptModalButton}
        toggleConfirmModal={toggleConfirmModal}
        isShowButtons={confirmModal.isShowButtons}
        fullScreen={isDownSxScreen}
        is393pxWidthScreen={is393pxWidthScreen}
      />
      <InformativeModal
        isOpen={informativeModalState.isOpen}
        className={`${informativeModal}`}
        toggleInformativeModal={toggleInformativeModal}
        fullScreen={isDownSxScreen}
      />

      <ModalPhotos open={openModalPhotos} handleClose={handleclosePhotos} photos={photos} />
    </Grid>
  );
};

export default composedComponent(EnablePaymentScreen, enablePaymentSaga, {
  saga: 'sagaEnablePaymentScreen',
  middlewares: [withStyles(styles)],
  states: ['enablePayment', 'app.objects', 'app.user', 'bikeDetail.publishBikeForm'],
  actions: [enablePaymentScreen],
});
