import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';

const BikfyPolicy = () => (
  <Box
    p={3}
    style={{
      backgroundColor: '#f9f9f9', borderRadius: '8px', maxWidth: '800px', margin: 'auto',
    }}
  >
    <Typography variant="h5" gutterBottom>
      Política de Venta Bicicletas Seminuevas BIKFY
    </Typography>
    <Typography variant="body1" paragraph>
      En BIKFY nos comprometemos a ofrecerte bicicletas seminuevas certificadas y respaldadas por nuestras políticas de venta, diseñadas para garantizar tu satisfacción y tranquilidad en cada compra. A continuación, detallamos las condiciones aplicables:
    </Typography>

    <Typography variant="h6" gutterBottom>
      1. Revisión y Certificación de Taller BIKFY
    </Typography>
    <Typography variant="body1" paragraph>
      Todas nuestras bicicletas seminuevas pasan por una minuciosa revisión (más de 30 puntos críticos) y certificación realizada por nuestro equipo de técnicos expertos en el taller BIKFY. Este proceso garantiza que cada bicicleta está en óptimas condiciones mecánicas y estructurales antes de ser puesta a la venta.
    </Typography>

    <Typography variant="h6" gutterBottom>
      2. Garantía del Fabricante Extendida al Segundo Dueño
    </Typography>
    <Typography variant="body1" paragraph>
      Tu compra en BIKFY incluye una garantía extendida de al menos un año desde el momento de compra (política diferenciada por marca) para el segundo dueño, que cubre fallas de fábrica y fatiga de material en el cuadro o culata de la bicicleta. En caso de que experimentes algún problema cubierto por esta garantía, nos encargaremos de tramitar el reclamo directamente con el fabricante para ti, asegurando una solución efectiva.
    </Typography>

    <Typography variant="h6" gutterBottom>
      3. Garantía de Taller para Imperfecciones Mecánicas
    </Typography>
    <Typography variant="body1" paragraph>
      En caso de que tu bicicleta presente desperfectos mecánicos* dentro de los primeros 60 días posteriores a la compra, podrás reportarlo y llevarla a cualquier taller con el que tengamos convenio (revísalos aquí). Nuestros socios te brindarán una solución gratuita y rápida para asegurar que puedas disfrutar de tu bicicleta sin interrupciones.
    </Typography>
    <Typography variant="body2" color="textSecondary" paragraph>
      *Hace referencia al mal funcionamiento de alguna parte de la bicicleta. Se excluyen los daños causados por golpes accidentales o manipulación incorrecta.
    </Typography>

    <Typography variant="h6" gutterBottom>
      4. Política de Crash Replacement para Motores Eléctricos
    </Typography>
    <Typography variant="body1" paragraph>
      Para bicicletas con motores eléctricos, en caso de que sufras un daño irreparable en el motor (habiendo sido revisado y diagnosticado por nuestros talleres convenidos), contamos con una política de crash replacement*. Tramitaremos la sustitución de tu motor de acuerdo con nuestros convenios con las marcas para que tu bicicleta vuelva a estar en funcionamiento lo antes posible. Ten presente que los tiempos de sustitución podrán variar dependiendo de la marca de la bicicleta o de la marca del motor.
    </Typography>
    <Typography variant="body2" color="textSecondary" paragraph>
      *El crash replacement es un programa ofrecido por las marcas o fabricantes de motores que permiten al propietario de la bicicleta obtener un remplazo del motor a un precio reducido. Las condiciones y precios varían según la marca.
    </Typography>

    <Typography variant="h6" gutterBottom>
      5. Compra Protegida para Compras Online
    </Typography>
    <Typography variant="body1" paragraph>
      Si realizas una compra online y, al recibir la bicicleta, no estás satisfecho por alguna razón o detectas un desperfecto no informado, puedes hacer uso de nuestra política de compra protegida. Esto implica que puedes solicitar la devolución de tu dinero dentro de las primeras 24 horas desde que recibiste la bicicleta. Para proceder, deberás ponerte en contacto con nosotros, informar la razón de la devolución y devolver la bicicleta en las mismas condiciones en que la recibiste.
    </Typography>
    <Typography variant="body1" paragraph>
      La bicicleta debe ser empacada nuevamente en su caja (puedes consultar nuestro tutorial en línea para más detalles). Dependiendo de tu ubicación, podrás solicitar el retiro o deberás llevarla a la oficina de Chilexpress más cercana para devolverla al remitente original. Una vez recibida la bicicleta, revisaremos la razón de la devolución y comenzaremos el proceso de reembolso, que puede tardar algunos días. En caso de que la razón de la devolución no coincida con el estado de la bicicleta o no pueda ser contrastada con la realidad, el costo de envío correrá por cuenta del comprador, y el reembolso se realizará descontando este costo.
    </Typography>

    <Typography variant="body1" paragraph>
      En BIKFY, queremos que cada compra sea una experiencia confiable y transparente. Por ello, nuestras garantías y políticas están diseñadas para protegerte y asegurar que disfrutes de tu bicicleta con total tranquilidad. Queremos que gastes menos y pedalees más.
    </Typography>

    {/* <Link href="/path/to/terms" color="primary" underline="hover" disabled>
      Conoce nuestra Política de venta y envíos
    </Link> */}
  </Box>
);

export default BikfyPolicy;
