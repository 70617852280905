/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react';

function deconstructEvent(event, date) {
  event.persist && event.persist();
  let value = null;
  if (event.target.type === 'file') {
    value = event.target.files[0];
  } else if (event.target.type === 'checkbox') {
    value = event.target.checked;
  } else {
    // value = event.target.type === 'date' || event.target.type === 'time' ? date : event.target.value;
    value = event.target.value;
  }
  const name = event.target?.name ? event.target.name : event.currentTarget.name;
  return { name, value };
}

export const useForm = (controls, actions, options) => {
  const [errors, setErrors] = useState({});
  if (actions?.controlsChanged == null && options?.onChange == null) {
    throw new Error('Parameters are missing change callback');
  }

  const isValid = useCallback(
    (id, value) => options.validations[id] == null
      || options.validations[id].every((validation) => validation.check(value)),
    [options],
  );

  const validate = useCallback(
    (id, value) => {
      let isValid = true;
      const newErrors = [];

      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValid = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        newErrors.push(validation.message ?? 'Campo inválido');
      }
      setErrors((state) => ({ ...state, [id]: newErrors.length === 0 ? undefined : newErrors }));

      return isValid;
    },
    [options.validations],
  );

  const validateAll = useCallback(() => {
    const ids = Object.keys(options.validations);
    let isValid = true;
    const newErrors = {};

    for (const id of ids) {
      const value = controls[id];
      for (const validation of options.validations[id]) {
        if (validation.check(value)) {
          continue;
        }
        isValid = false;
        if (newErrors[id] == null) {
          newErrors[id] = [];
        }
        newErrors[id].push(validation.message ?? 'Campo inválido');
      }
    }
    setErrors(newErrors);

    return isValid;
  }, [options.validations, controls]);

  const validateFields = useCallback(
    (fieldIds) => {
      let isValid = true;
      const newErrors = {};

      for (const id of fieldIds) {
        const value = controls[id];
        for (const validation of options.validations[id]) {
          if (validation.check(value)) {
            continue;
          }
          isValid = false;
          if (newErrors[id] == null) {
            newErrors[id] = [];
          }
          newErrors[id].push(validation.message ?? 'Campo inválido');
        }
      }
      setErrors((state) => ({ ...state, ...newErrors }));

      return isValid;
    },
    [options.validations, controls],
  );
  const validateFieldsWithoutSettingErrors = useCallback(
    (fieldIds) => {
      let isValid = true;
      const newErrors = {};

      for (const id of fieldIds) {
        const value = controls[id];
        for (const validation of options.validations[id]) {
          if (validation.check(value)) {
            continue;
          }
          isValid = false;
          if (newErrors[id] == null) {
            newErrors[id] = [];
          }
          newErrors[id].push(validation.message ?? 'Campo inválido');
        }
      }
      // setErrors((state) => ({ ...state, ...newErrors }));

      return isValid;
    },
    [options.validations, controls],
  );

  const onChange = useCallback(
    (event, formater, useFormater = true) => {
      const hasControlsChanged = actions && 'controlsChanged' in actions;
      const { name, value } = deconstructEvent(event, null); // TODO: Pass date
      const formattedValue = useFormater && formater ? formater(value) : value;

      if (name == null) {
        throw new Error('Input is missing name');
      }

      // Either validate on change or clear errors if it is valid
      if (options?.validateOnChange) {
        validate(name, formattedValue);
      } else if (isValid(name, formattedValue)) {
        setErrors((errors) => ({ ...errors, [name]: undefined }));
      }

      if (hasControlsChanged) {
        actions.controlsChanged({ [name]: formattedValue });
      } else {
        options.onChange(name, formattedValue);
      }
    },
    [options, actions, isValid, validate],
  );

  const onSubmit = useCallback(
    (callback) => {
      const valid = validateAll();
      if (valid) {
        callback();
      }
    },
    [validateAll],
  );

  return {
    errors, onChange, onSubmit, validateFields, validateAll, validateFieldsWithoutSettingErrors,
  };
};
