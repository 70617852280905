/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@material-ui/core';

const WarningModal = (props) => {
  const { isOpen, className, toggleSetOpenModal, setBikfyPayWarningModalButton, fullScreen } =
    props;

  const handleOnClose = () => {
    toggleSetOpenModal();
  };

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} className={className} onClose={handleOnClose}>
      <Box className="generalContentContainer">
        <DialogTitle className="title">¡ Atención !</DialogTitle>
        <DialogContent className="content">
          <Typography>¿Estás seguro quieres pagar tu Bike por fuera de BIKFY?</Typography>
          <Typography className="list-title">
            Recuerda que pagando con <span className="bikfy-color">BIKFY</span> podrás:
          </Typography>
          <ul className="list">
            <li className="list-item">
              <Typography>
                Realizar una Transferencia <span className="bold"> segura </span>.
              </Typography>
            </li>
            <li className="list-item">
              <Typography>
                Pagar el valor de la Bike con Tarjetas de <span className="bold"> Crédito </span> y{' '}
                <span className="bold"> Débito </span>.
              </Typography>
            </li>
          </ul>
        </DialogContent>

        <DialogActions className="buttonsContainer">
          <Button
            variant="contained"
            color="primary"
            className="enable-bikfy-pay"
            onClick={() => setBikfyPayWarningModalButton(true)}>
            Habilitar Pago Con BIKFY
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="continue-button"
            onClick={() => setBikfyPayWarningModalButton(false)}>
            Continuar de todos modos
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

WarningModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  toggleSetOpenModal: PropTypes.func.isRequired,
  setBikfyPayWarningModalButton: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
};

WarningModal.defaultProps = {
  isOpen: false,
  className: '',
  fullScreen: false,
};

export default WarningModal;
