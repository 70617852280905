import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'recoveryPassword/';
const DISABLE_ERRORS = `${PATH}DISABLE_ERRORS`;
// Global constants for saga
export const RECOVERYPASSWORD = {
  POST_VERIFY_EMAIL: `${PATH}POST_VERIFY_EMAIL`,
  POST_VERIFY_EMAIL_SUCCESS: `${PATH}POST_VERIFY_EMAIL_SUCCESS`,
  POST_UPDATE_PASSWORD: `${PATH}POST_UPDATE_PASSWORD`,
  POST_UPDATE_PASSWORD_SUCCESS: `${PATH}POST_UPDATE_PASSWORD_SUCCESS`,
  POST_UPDATE_PASSWORD_ERROR: `${PATH}POST_UPDATE_PASSWORD_ERROR`,
  SET_CONTROLS: `${PATH}SET_CONTROLS`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  SHOW_SUCCESS_SNACKBAR: `${PATH}SHOW_SUCCESS_SNACKBAR`,
};

// actions
export const recoveryPasswordActions = {
  verifyEmail: createAction(RECOVERYPASSWORD.POST_VERIFY_EMAIL, 'controls'),
  updatePassword: createAction(RECOVERYPASSWORD.POST_UPDATE_PASSWORD, 'controls'),
  setControls: createAction(RECOVERYPASSWORD.SET_CONTROLS, 'payload'),
  resetControls: createAction(RECOVERYPASSWORD.RESET_CONTROLS),
  disableErrors: createAction(DISABLE_ERRORS),
  showSuccessSnackbar: createAction(RECOVERYPASSWORD.SHOW_SUCCESS_SNACKBAR, 'payload'),
};

const initialState = {
  controls: {
    password: '',
    verifyPassword: '',
    email: '',
    token: '',
  },
  successChangePassword: false,
  loading: false,
  message: '',
  error: false,
  showSuccessSnackbar: false,
};

const recoveryPassword = createReducer(initialState, {
  [`${DISABLE_ERRORS}`](state, action) {
    state.error = false;
    state.message = '';
  },
  // api
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL](state) {
    state.loading = false;
    state.error = false;
    state.message = '';
  },
  [RECOVERYPASSWORD.POST_VERIFY_EMAIL_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.message =
      'Si su e-mail se encuentra registrado, recibirá un correo con instrucciones para reiniciar su contraseña';
  },
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD](state) {
    state.loading = true;
    state.error = false;
  },
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.successChangePassword = true;
    state.message = 'Su contraseña ha sido actualizada correctamente, por favor inicie sesión';
    state.showSuccessSnackbar = true;
  },
  [RECOVERYPASSWORD.POST_UPDATE_PASSWORD_ERROR](state, action) {
    state.loading = false;
    state.error = false;
    state.successChangePassword = false;
  },
  [RECOVERYPASSWORD.SET_CONTROLS](state, action) {
    state.controls = { ...state.controls, ...action.payload };
  },
  [RECOVERYPASSWORD.RESET_CONTROLS](state, action) {
    state.controls = { ...action.controls, ...initialState.controls };
  },
  [RECOVERYPASSWORD.SHOW_SUCCESS_SNACKBAR](state, action) {
    state.showSuccessSnackbar = action.payload.showSuccessSnackbar;
    state.message = action.payload.message;
  },
});

export default recoveryPassword;
