import { makeStyles } from '@material-ui/core';
import colors from '../../../utils/colors';

const { primary } = colors;

const useTotalAmountStyles = makeStyles((theme) => ({
  amountBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    margin: '20px 0',
    '& .amountTitleContainer': {
      position: 'absolute',
      padding: '0px 4px',
      top: '0%',
      transform: 'translate(0%, -50%)',
      backgroundColor: '#f9fafb',
      '& .amountTitle': {
        color: primary,
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    '& .amountBox': {
      width: '280px',
      padding: '20px',
      textAlign: 'center',
      border: `2px solid ${primary}`,
      borderRadius: '10px',
      color: primary,
      '& .amount': {
        fontWeight: 'bold',
        fontSize: '24px',
      },
    },
  },
}));

export default useTotalAmountStyles;
