import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { Card, Typography } from '@material-ui/core';
import { composedComponent, validateEmail, validateRut } from 'utils/functions';
import saga from 'screens/Ambassador/saga';
import { ambassadorActions } from 'screens/Ambassador/reducer';
import { appActions } from 'commons/reducer';
import BikeForm from 'screens/Ambassador/components/BikeForm';
import { useForm } from 'utils/hooks/useForm';

const Ambassador = (props) => {
  const [isBicycleSelected, setIsBicycleSelected] = useState(true);
  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchMotorbikeBrands();
    props.actions.fetchVehicleTypes();
  }, [
    props.actions.fetchBrands,
    props.actions.fetchMotorbikeBrands,
    props.actions.fetchVehicleTypes,
  ]);

  useEffect(() => {
    const vehicleTypeSelected = Object.values(props?.vehicleTypes)
      .filter((vehicleType) => vehicleType.id === props?.formControls?.vehicleTypeId)
      .pop();
    if (vehicleTypeSelected?.name && vehicleTypeSelected?.name !== 'Bicicleta') {
      setIsBicycleSelected(false);
    } else {
      setIsBicycleSelected(true);
    }
  }, [props.formControls.vehicleTypeId, props?.vehicleTypes]);

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      vehicleTypeId: [
        {
          check: (value) => !!value,
          message: 'El tipo de vehículo es obligatorio',
        },
      ],
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'EL Correo electrónico es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'EL Correo electrónico es inválido',
        },
      ],
      taxNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El R.U.T. es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 15,
          message: 'El maximo es de 15 caracteres',
        },
        {
          check: (value) => validateRut(value),
          message: 'El R.U.T es inválido',
        },
      ],
      phoneNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El número de teléfono es requerido',
        },
      ],
      serial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || !isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || !isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      engineSerial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      chassisSerial: [
        {
          check: (value) => (!!value && value.trim().length > 0) || isBicycleSelected,
          message: 'El C. de serie es requerido',
        },
        {
          check: (value) => (!!value && value.trim().length <= 150) || isBicycleSelected,
          message: 'El maximo es de 150 caracteres',
        },
      ],
      brandId: [
        {
          check: (value) => !!value,
          message: 'La Marca es requerida',
        },
      ],
      serialFile: [
        {
          check: (value) => value,
          message: 'El Archivo es requerido',
        },
      ],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(() => props.actions.registerBike(props.formControls));
  };

  // get brands
  const brands = useMemo(
    () =>
      props.order?.brands?.map((b) => props.brands[b]).map((a) => ({ value: a.id, label: a.name })),
    [props.brands, props.order?.brands],
  );

  const vehicleTypes = useMemo(
    () =>
      props.order?.vehicleTypes
        ?.map((vt) => props.vehicleTypes[vt])
        .map((a) => ({ value: a.id, label: a.name })),
    [props.vehicleTypes, props.order?.vehicleTypes],
  );

  const motorbikeBrands = useMemo(
    () =>
      props.order?.motorbikeBrands
        ?.map((mb) => props.motorbikeBrands[mb])
        .map((a) => ({ value: a.id, label: a.name })),
    [props.motorbikeBrands, props.order?.motorbikeBrands],
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" align="center">
                  Registrar Bike de cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BikeForm
                  errors={errors}
                  brands={brands}
                  onControlChange={onChange}
                  controls={props.formControls}
                  onSubmit={handleSubmit}
                  loading={props.loading}
                  motorbikeBrands={motorbikeBrands}
                  vehicleTypes={vehicleTypes}
                  isBicycleSelected={isBicycleSelected}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default composedComponent(Ambassador, saga, {
  saga: 'sagaAmbassador',
  states: [
    'ambassador.loading',
    'ambassador.formControls',
    'app.objects.brands',
    'app.objects.motorbikeBrands',
    'app.objects.vehicleTypes',
    'app.order',
  ],
  actions: [ambassadorActions, appActions],
});
