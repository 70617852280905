import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const CustomModalConfirmation = ({
  alert, clearAlert, text, handleSubmit, loading,
}) => (
  <Dialog
    open={alert}
    onClose={clearAlert}
  >
    <DialogContent style={{ textAlign: 'center' }}>
      <DialogContentText>
        {text}
      </DialogContentText>
      <DialogContentText>
        ¿Estás seguro que deseas continuar?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={clearAlert}
        color="secondary"
      >
        Cancelar
      </Button>
      <Button
        onClick={handleSubmit}
        color="primary"
        autoFocus
        disabled={loading}
      >
        Continuar
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomModalConfirmation;
