import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, post } from 'utils/api';
import { REPORT } from 'screens/Report/reducer';

function* reportBike() {
  yield takeLatest(REPORT.REPORT_BIKE, function* (action) {
    const body = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, REPORT.REPORT_BIKE));
    const response = yield post('api/v1/report/create', body);
    yield put(apiSuccess(APP.REMOVE_LOADING, REPORT.REPORT_BIKE));
    if (!response.error) {
      yield put(apiSuccess(REPORT.REPORT_BIKE_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Bike denunciada correctamente'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Verifique los datos ingresados' }));
      yield put(apiSuccess(REPORT.REPORT_BIKE_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(reportBike);
}
