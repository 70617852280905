const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: theme.spacing(2),
    fontFamily: ['Roboto'],
  },
  textTransformInitial: {
    textTransform: 'initial',
  },
  textTransformUppperCase: {
    textTransform: 'uppercase',
  },
  dNone: {
    display: 'none',
  },
  link: {
    marginRight: 5,
    minWidth: '50px',
  },

  insuranceButton: {
    backgroundColor: '#303E48',
    marginRight: 10,
  },
  [theme.breakpoints.down('md')]: {
    link: {
      marginRight: theme.spacing(1),
      minWidth: '50px',
    },
  },
});

export default styles;
