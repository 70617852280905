import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'activate/';

// Global constants for saga
export const ACTIVATE = {
  ACTIVATE_USER: `${PATH}ACTIVATE_USER`,
  ACTIVATE_USER_SUCCESS: `${PATH}ACTIVATE_USER_SUCCESS`,
  ACTIVATE_USER_ERROR: `${PATH}ACTIVATE_USER_ERROR`,
};

// actions
export const activateActions = {
  activateUser: createAction(ACTIVATE.ACTIVATE_USER, 'payload'),
};

const initialState = {
  loading: false,
  error: false,
  success: false,
};

const activate = createReducer(initialState, {
  [ACTIVATE.ACTIVATE_USER](state) {
    state.loading = true;
    state.error = false;
    state.success = false;
  },
  [ACTIVATE.ACTIVATE_USER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.success = true;
  },
  [ACTIVATE.ACTIVATE_USER_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.success = false;
  },
});

export default activate;
