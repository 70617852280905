import { createAction } from 'utils/reducer';

import buyerPayMethodScreenTypes from './buyerPayMethodScreen.types';

const buyerPayMethodScreenActions = {
  setInitState: createAction(buyerPayMethodScreenTypes.INIT_STATE),
  getTransfer: createAction(buyerPayMethodScreenTypes.GET_TRANSFER, 'payload'),
  createPayment: createAction(buyerPayMethodScreenTypes.CREATE_PAYMENT, 'payload'),
  setOpenWarningModal: createAction(buyerPayMethodScreenTypes.SET_OPEN_WARNING_MODAL, 'payload'),
};

export default buyerPayMethodScreenActions;
