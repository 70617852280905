/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
// MATERIAL
import {
  Typography,
  Grid,
  useTheme,
  withStyles,
  useMediaQuery,
  Box,
  Button,
  Container,
  Drawer,
  Paper,
  IconButton,
} from '@material-ui/core';

import TuneIcon from '@material-ui/icons/Tune';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AppsIcon from '@material-ui/icons/Apps';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import Carousel from 'react-material-ui-carousel';

// UTILS
import ModalPhotos from 'commons/components/ModalPhotos/ModalPhotos';
import CustomPagination from 'commons/components/CustomPagination';
import SendEmailDialog from 'commons/components/SendEmailDialog/SendEmailDialog';
import ReportPublishDialog from 'commons/components/ReportPublishDialog/ReportPublishDialog';
import { PATHS } from 'utils/paths';
import { useQuery } from 'utils/hooks/useQuery';
import pathTypes, { view } from 'utils/pathTypes';
import Banner from 'commons/components/Banner';
import { WindowScroller } from 'react-virtualized';
import CustomMultipleSelect from 'commons/components/CustomMultipleSelect';
import CustomMultiSelectComplete from 'commons/components/CustomMultiSelectComplete';
import { STORE_BIKE_STATUS } from 'utils/consts';
import MarketingBanner from 'commons/components/MarketingBanner';
import { Alert } from '@material-ui/lab';
import whatsAppIconSvg from '../../commons/assets/icons8-whatsapp-96.svg';
import { composedComponent, validateEmail } from '../../utils/functions';

// COMMONS
import CustomModalConfirmation from '../../commons/components/CustomModalConfirmation';
import CustomButtonFloat from '../../commons/components/CustomButtonFloat';
import NotBike from '../../commons/components/NotBike';
import { appActions } from '../../commons/reducer';

// HOME
import SelectBike from './components/SelectBike';
import StatusPaymentDialog from './components/statusPaymentDialog';
import GeneralAlert from './components/GeneralAlert/GeneralAlert';
import GeneralCard from './components/GeneralCard/GeneralCard';
import FilterCard from './components/FilterCard';

import saga from './saga';
import { homeActions } from './reducer';
// Estilos
import styles from './styles';
import OrderBySelect from './components/OrderBySelect';
import ResponsiveFilters from './components/ResponsiveFilters/ResponsiveFilters';
import GeneralCardSkeleton from './components/GeneralCardSkeleton/GeneralCardSkeleton';
import BikeSelectorByState from './components/BikeSelectorByState/BikeSelectorByState';
import { getUserType } from './utils';
import LandingItems from './components/LandingItems/LandingItems';
import OfferBikePilotRedirect from './components/OfferBikePilotRedirect/OfferBikePilotRedirect';
import FilterBikfyPublicationsButton from './components/FilterBikfyPublicationsButton/FilterBikfyPublicationsButton';
import InformativeModal from './components/InformativeModal';
import AdCard from './components/AdCard/AdCard';
import MarketingBanner2 from './components/MarketingBanner';
import CenteredTextWithButtons from './components/MarkeplaceLanding';
import PhoneWithList from './components/TutorialLanding';
import ReviewsCarousel from './components/ReviewsLanding';
import MarketplaceTitle from './components/MarketplaceTitle';

const VIEW_TYPE = {
  'mozaic-list': <FormatListBulletedIcon fontSize="large" />,
  'normal-list': <AppsIcon fontSize="large" />,
};

const BIKES_OPTION = 1;
const TRANSFERRED_BIKES_OPTION = 2;
const PENDING_BIKES_OPTION = 3;
const INSURABLE_BIKES_OPTION = 4;

const Home = (props) => {
  const {
    classes, actions, home: homeState, user, objects, order, lastMarketPlaceOrder,
  } = props;
  const loggedUser = user?.user;
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const userType = getUserType(user?.user);
  const queryFilter = query.get('filter');
  const showMessage = query.get('showMessage');
  const bikfyWhatsappUrl = 'https://api.whatsapp.com/send/?phone=56956365938&text&type=phone_number&app_absent=0';
  // const queryBikfyPublications = new URLSearchParams(location.search);
  // const initialSearchBikfyPublications = queryBikfyPublications.get('searchBikfyPublications') === 'True';

  const { pathname } = location;

  const { pathType } = useMemo(() => pathTypes({ pathname, userType }), [pathname, userType]);

  const {
    modals,
    isSellerBikes,
    isExternalPayment,
    transfersPendingSeller,
    loading,
    formControls,
    transfersPending,
    transfersPendingBuyer,
    alert,
    alertDelete,
    deleteSuccess,
    deleteError,
    pilotAlert,
    listType,
    filterForm,
    storeHomeFilterForm,
    pagination,
    paginationStoreHome,
    showFilters,
    sortBy,
    storeHomeSortBy,
    formSellerEmailValues,
    csvData,
    usersCount,
    bikesCount,
    isUserAlertedInLanding,
    marketplaceFilterFormChanged,
  } = homeState;

  const {
    toggleModalPaymentStatus,
    changeListType,
    setShowFilters,
    setSellerEmail,
    toggleSetSellerEmailDialog,
    setErrorsSellerEmail,
    reportPublish,
    togglePilotAlert,
    removeStoreAccess,
    exportToCsvBikeData,
    persistMarketPlaceOrder,
    updateUserAlertedInLanding,
  } = actions;

  const theme = useTheme();
  const isDownXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const hideIconBreakpoint = useMediaQuery(theme.breakpoints.between(960, 1050));

  const [photos, setPhotos] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [openModalPhotos, setOpenModalPhotos] = useState(false);

  const showValidBikes = formControls.bikeFilter === BIKES_OPTION;
  const showTransferedBikes = formControls.bikeFilter === TRANSFERRED_BIKES_OPTION;
  const showPendingBikes = formControls.bikeFilter === PENDING_BIKES_OPTION;
  const showInsurableBikes = formControls.bikeFilter === INSURABLE_BIKES_OPTION;

  const transfers = order?.transfers?.map((t) => objects?.transfers[t]);

  const isAnyTransferBikeDataNotCompleted = transfers?.some((t) => !t?.bike?.dataCompletedAt) && formControls.bikeFilter === 1;

  const transfersWithPublicationDeletedSoldReason = transfers?.filter(
    (transfer) => transfer?.deletedPublicationSoldReason,
  );

  const isUserPilotOrNotLogged = loggedUser?.type === 'pilot' || !loggedUser;

  const status = useMemo(
    () => transfers.filter((t) => {
      if (t.sellerId === loggedUser?.id || t.buyerId === loggedUser?.id) {
        return t.paymentStatus === null || t.paymentStatus === '0';
      }
      return false;
    }),
    [formControls.bikeFilter, loggedUser?.id, transfers],
  );

  useEffect(() => {
    actions.getUsersCount();
    actions.getBikesCount();
  }, []);
  useEffect(() => {
    actions.setPathType(pathType);
  }, [pathType]);
  useEffect(() => {
    const bikeFilter = Number(queryFilter) || 1;
    actions.changeControls({ bikeFilter });
  }, [queryFilter]);
  useEffect(() => {
    if (userType !== 'pilot') return;
    if (loggedUser) {
      actions.validateTransfer();
      actions.validateTransferBuyer();
      actions.validateTransferSeller();
    }

    if (showMessage) {
      actions.setSuccess(
        `La solicitud de acceso ha sido ${showMessage === 'done' ? 'aceptada' : 'rechazada'}`,
      );
    }
  }, [userType]);

  const showPilotAlert = () => {
    let optionKey = 'default';

    if (transfersPendingSeller) {
      optionKey = isExternalPayment ? 'pendingTransfersExternalSeller' : 'transfersPendingSeller';
    }
    if (transfersPendingSeller && !loggedUser.dataComplete) {
      optionKey = 'transfersPendingSellerWithIncompleteData';
    }
    if (transfersPendingBuyer) optionKey = 'transfersPendingBuyer';
    if (!loggedUser.dataComplete) optionKey = 'pendingUserData';

    const options = {
      transfersPendingSeller: {
        showAlert: true,
        alertType: 'pendingTransfersSeller',
        severity: 'error',
      },
      pendingTransfersExternalSeller: {
        showAlert: true,
        alertType: 'pendingTransfersExternalSeller',
        severity: 'error',
      },
      transfersPendingSellerWithIncompleteData: {
        showAlert: true,
        alertType: 'pendingTransfers',
        severity: 'info',
      },
      transfersPendingBuyer: {
        showAlert: true,
        alertType: 'pendingTransfersByBuyer',
        severity: 'info',
      },
      pendingUserData: {
        showAlert: true,
        alertType: 'pendingUserData',
        severity: 'info',
      },
      default: {},
    };

    return togglePilotAlert(options[optionKey || 'default']);
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (pathname === PATHS.MARKETPLACE) {
        actions.setLastYScroll({ lastYScroll: window.scrollY });
        actions.persistMarketplaceListType();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // useEffect(() => {
  //   // Set the initial filter form based on the query parameter
  //   if (initialSearchBikfyPublications) {
  //     actions.setFilterForm({
  //       filterForm: {
  //         ...filterForm,
  //         searchBikfyPublications: true,
  //       },
  //     });
  //     actions.getPublishedBikes();
  //   }
  // }, [initialSearchBikfyPublications]);

  // useEffect(() => {
  //   // Check for the initial query string once, on component mount
  //   const initialSearchBikfyPublications = query.get('searchBikfyPublications') === 'True';

  //   if (initialSearchBikfyPublications && !filterForm.searchBikfyPublications) {
  //     actions.setFilterForm({
  //       filterForm: {
  //         ...filterForm,
  //         searchBikfyPublications: true,
  //       },
  //     });
  //     actions.getPublishedBikes();
  //   }
  // }, []);
  // useEffect(() => {
  //   // Set the filter form based on the query parameter, whether true or false
  //   if (filterForm.searchBikfyPublications !== initialSearchBikfyPublications) {
  //     actions.setFilterForm({
  //       filterForm: {
  //         ...filterForm,
  //         searchBikfyPublications: initialSearchBikfyPublications,
  //       },
  //     });
  //     actions.getPublishedBikes();
  //   }
  // }, []);

  useEffect(() => {
    if ((isMobileScreen && pathType === view.MARKETPLACE) || pathType === view.LANDING) {
      actions.changeListType({ listType: 'mozaic-list' });
    }
  }, [isMobileScreen, pathType]);

  useEffect(() => {
    if (loggedUser) showPilotAlert();
  }, [loggedUser, transfersPending, transfersPendingSeller, transfersPendingBuyer, isSellerBikes]);

  useEffect(() => {
    if (deleteSuccess || deleteError) {
      if (pathType !== view.STORE_HOME) {
        actions.resetControls('delete');
        actions.fetchBikesPending();
      } else {
        actions.resetControls();
        actions.getBikesForStore();
      }
    }
  }, [deleteSuccess, deleteError]);
  useEffect(() => {
    actions.resetObject('transfers');
    if (userType === 'pilot' && pathType === view.PILOT_HOME) {
      if (showValidBikes && (location.search === '' || location.search === '?filter=1')) {
        actions.fetchBikes();
      }
      if (showTransferedBikes) {
        actions.fetchBikesTransferred();
      }
      if (showPendingBikes) {
        actions.fetchBikesPending();
      }
      if (showInsurableBikes) {
        console.log('fetchInsurableBikes');
        actions.fetchInsurableBikes();
      }
    }
    if (pathType === view.STORE_HOME) {
      actions.getBikesForStore();
    }

    if (pathType === view.LANDING || pathType === view.MARKETPLACE) {
      actions.getPublishedBikes({ useLastMarketOrder: true });
    } else {
      actions.changeListType({ listType: 'normal-list' });
    }

    return () => {
      actions.clearObjectsState({ pathType });
      actions.setGetAllData(true);
    };
  }, [formControls.bikeFilter, pathType]);

  useEffect(() => {
    if (csvData.length > 0) {
      const worksheet = xlsx.utils.json_to_sheet(csvData);
      const csv = `\uFEFF${xlsx.utils.sheet_to_csv(worksheet)}`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8',
      });
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
      actions.clearCsvData();
    }
  }, [csvData]);

  useEffect(() => {
    if (location?.search === '?filter=4' && loggedUser.type !== 'pilot') {
      history.push('/');
    }
  }, [location?.search]);

  // HANDLERS
  const handleclosePhotos = () => {
    setOpenModalPhotos(false);
  };

  const handleOpenPhotos = (documents) => {
    if (!documents) return setPhotos([]);

    setOpenModalPhotos(true);
    const docs = documents
      ?.sort((a, b) => (a.type > b.type ? -1 : 1))
      ?.filter((doc) => ['left_side', 'front_side', 'right_side', 'extra'].includes(doc.type));
    setPhotos(docs);
  };

  const handleToggleModalPaymentStatus = ({ transferId }) => {
    const transfer = objects?.transfers[transferId];

    let transferStep = 0;

    if (transfer?.paymentStatus === '1') {
      transferStep = 2;
    }

    if (transfer?.bikfyPaidStatus === 'success') {
      transferStep = 3;
    }

    setActiveStep(transferStep);

    toggleModalPaymentStatus({ show: !modals.paymentStatus.show });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(PATHS.BIKE_REGISTER);
  };

  const onChangeFilter = (e) => {
    switch (e.target.value) {
      case 1:
        history.push('/home?filter=1');
        break;
      case 2:
        history.push('/home?filter=2');
        break;
      case 3:
        history.push('/home?filter=3');
        break;
      case 4:
        history.push('/home?filter=4');
        break;
      default:
        history.push('/home');
    }
    actions.changeControls({ [e.target.name]: e.target.value });
  };

  const handleClickChangeTypeListButton = (e) => {
    const newListType = listType === 'normal-list' ? 'mozaic-list' : 'normal-list';
    changeListType({ listType: newListType });
  };

  const handleChangeFilterPending = (e) => {
    actions.changeControls({ bikeFilter: 3 });
  };

  const goToEditPage = ({ transferId }) => history.push(`/bike/${transferId}`);

  const goToSearchPage = ({ brandId, bikeSerial }) => history.push(`/search/${brandId}/${bikeSerial}`);

  const goToTransfer = ({ transferId }) => history.push(`/bike/${transferId}/transfer`);

  const goToInsurance = ({ transferId }) => history.push(`/bike/${transferId}/insurance`);

  const goToAcceptTransfer = ({ transferId }) => history.push(`/transfer/${transferId}`);

  const goToWorkshop = ({ bikeId }) => history.push(`/maintenance/${bikeId}`);

  const goToReport = ({ transferId }) => history.push(`/bike/${transferId}/report`);

  const goToReportBikfy = ({ brandId, bikeSerial }) => {
    history.push(`/search/${brandId}/${bikeSerial}`);
  };
  const goToBankDataInfo = ({ transferId }) => history.push(`/transfer/${transferId}/bank-data`);

  const handleRedirectWithState = ({ pathname, state }) => {
    history.push({ pathname, state });
  };

  const handleRedirect = (p) => history.push(p);

  const handleSetPagination = (newPage) => {
    if (pathType === view.STORE_HOME) {
      actions.setStoreHomePagination({
        pagination: {
          ...pagination,
          ...newPage,
        },
      });
      actions.getBikesForStore();
    } else {
      if (marketplaceFilterFormChanged) {
        actions.setPagination({
          pagination: {
            ...pagination,
            page: 0,
            limit: 15,
            offset: 0,
          },
        });
      } else {
        actions.setPagination({
          pagination: {
            ...pagination,
            ...newPage,
          },
        });
        window.scrollTo({
          top: 100,
          left: 0,
          behavior: 'smooth',
        });
      }
      actions.getPublishedBikes();
    }
  };

  const sortBikes = (value) => {
    const [field, orderBy = ''] = value.split('|');
    if (pathType === view.STORE_HOME) {
      actions.resetStoreHomePagination();
      actions.setStoreHomeSortBy({ field, orderBy });
      actions.getBikesForStore();
    } else {
      actions.resetMarketplacePagination();
      actions.setSortBy({ field, orderBy });
      actions.getPublishedBikes();
    }
  };

  const handleSetSellerEmail = ({ formSellerEmailValues }) => {
    const validEmail = validateEmail(formSellerEmailValues.buyerEmail.value);

    if (!validEmail) {
      return setErrorsSellerEmail({
        formSellerEmailValues: {
          ...formSellerEmailValues,
          buyerEmail: {
            ...formSellerEmailValues.buyerEmail,
            isValid: false,
            helperText: 'El correo es invalido',
          },
        },
      });
    }

    setSellerEmail({ formSellerEmailValues });
  };

  const handleToggleSetSellerEmailDialog = ({ open, bikeId, transferId }) => {
    toggleSetSellerEmailDialog({
      open,
      bikeId,
      transferId,
    });
  };

  const handleToggleReportPublishModal = ({ bikeMarketId = 0 }) => {
    actions.toggleReportPublish({ open: !modals.reportPublish.open, bikeMarketId });
  };

  const handlePublishReport = ({ reportReasonId }) => {
    reportPublish({ reportReasonId });
  };

  const handleSetShowFilters = () => {
    setShowFilters({ showFilters: !showFilters });
  };

  const smGridCardPilot = 11;
  const smGridCardMarketPlace = listType === 'normal-list' ? 12 : 4;

  const containerClasses = isMobileScreen ? `${classes.margin0}, ${classes.padding0}` : '';

  const orderByButtonGridContainerClasses = pathType === view.LANDING || pathType === view.STORE_HOME
    ? `${classes.positionSticky} ${classes.top0} ${classes.bottom20} ${classes.zIndex1}`
    : '';

  const orderByButtonComponent = pathType === view.LANDING ? Paper : 'div';

  const idMosaicMode = listType === 'mozaic-list';

  const vehicleTypeOptions = Object.values(objects?.vehicleTypes)?.map((vt) => ({
    value: vt?.id,
    label: vt?.name,
  }));

  const changeVehicleTypeControls = (event) => {
    if (event.target.value?.length) {
      actions.changeControls({ [event.target.name]: event.target.value });
    }
  };

  let filteredTransfersByVehicleType = [];

  const filteredTransfers = () => {
    if (transfers?.length && formControls?.vehicleTypes?.length && pathType === view.PILOT_HOME) {
      filteredTransfersByVehicleType = transfers.filter((t) => formControls?.vehicleTypes?.includes(t?.bike?.vehicleTypeId));
      return filteredTransfersByVehicleType;
    }
    return transfers;
  };

  const handleCloseInformativeModal = () => {
    actions.updateUserAlertedInLanding({ userId: loggedUser.id });
  };

  return (
    <>
      {(pathType === view.MARKETPLACE) && <MarketplaceTitle />}
      <Container
        maxWidth="lg" // Or dynamically adjust for mobile
        disableGutters // Removes default padding
        style={
    isMobileScreen
      ? {
        width: '100%', // Ensure it spans the full width
        maxWidth: '100vw', // Prevent overflow beyond viewport
        // overflowX: 'hidden', // Disable horizontal scrolling
      }
      : {}
  }
      >

        {pilotAlert.showAlert && pathType !== view.MARKETPLACE && (
        <GeneralAlert
          alertType={pilotAlert.alertType}
          severity={pilotAlert.severity}
          handleChangeFilterPending={handleChangeFilterPending}
        />
        )}

        {pathType === view.LANDING && (
        <LandingItems
          isMobile={isMobileScreen}
          usersCount={usersCount}
          isXsScreen={isDownXsScreen}
          user={user}
        />
        )}

        <br />
        {/* {(pathType === view.MARKETPLACE) && <MarketplaceTitle />} */}
        {/* {pathType === view.LANDING && <MarketingBanner isMobileScreen={isMobileScreen} />} */}
        {/* {pathType === view.LANDING && <MarketingBanner2 isMobileScreen={isMobileScreen} />} */}

        {/* EVALUATE ALERT OF PENDING TRANSFER */}

        {!(pathType === view.LANDING) && (
        <ResponsiveFilters
          isMobileScreen={isMobileScreen}
          isMarketPlace={pathType === view.MARKETPLACE}
        >
          {!showInsurableBikes && (
          <Grid
            container
            justifyContent={pathType === view.PILOT_HOME ? 'center' : 'flex-end'}
            className={`${classes.padding08px}${orderByButtonGridContainerClasses}`}
          >

            {!isMobileScreen && pathType === view.MARKETPLACE && (

            <Grid container item xs={3} alignItems="center" justifyContent="center">
              <FilterBikfyPublicationsButton
                classes={classes}
                actions={actions}
                filterForm={filterForm}
                hideIconBreakpoint={hideIconBreakpoint}
              />
            </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={9}
              direction="row"
              component={Paper}
              alignContent="center"
              justifyContent="center" // Horizontal alignment
              className={`${classes.padding8px}`}
              style={{
                background: 'cyan1',
                ...(!isMobileScreen && {
                  flexGrow: 0,
                  height: '100px',
                  marginTop: 'auto', // Push the Grid to the bottom
                }),
              }}
            >

              {/* Selectors */}
              <Grid
                container
                alignItems="center"
                item
                xs={12}
                sm={11}
                spacing={isMobileScreen ? 1 : 0}
                style={{ background: 'gray1' }}
              >
                {isMobileScreen
              && (pathType === view.MARKETPLACE || pathType === view.STORE_HOME) && (
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleSetShowFilters}>
                    <TuneIcon fontSize="large" />
                  </Button>
                </Grid>
                )}
                {isMobileScreen && pathType === view.MARKETPLACE && isUserPilotOrNotLogged && (
                <Grid item xs sm={5} alignItems="center" justifyContent="left">
                  <OfferBikePilotRedirect
                    handleRedirect={handleRedirect}
                    classes={classes}
                    isMobileScreen={isMobileScreen}
                  />
                </Grid>
                )}
                {isMobileScreen && pathType === view.MARKETPLACE && (
                <Grid item xs={12} sm={5} alignItems="center" justifyContent="center">
                  <FilterBikfyPublicationsButton
                    classes={classes}
                    actions={actions}
                    filterForm={filterForm}
                    hideIconBreakpoint={hideIconBreakpoint}
                  />
                </Grid>
                )}
                {/* disabled for production bikfy sales 655-665 */ }

                {pathType === view.STORE_HOME && (
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  style={{ background: 'red1' }}
                >
                  {!isMobileScreen && (
                  <Grid item>
                    <Typography component="div" variant="button">
                      <Box fontWeight="fontWeightBold" className="color-secondary">
                        ESTADO BIKES:
                      </Box>
                    </Typography>
                  </Grid>
                  )}
                  <Grid container item xs={5}>
                    <BikeSelectorByState sortBikes={sortBikes} storeValue={storeHomeSortBy} />
                  </Grid>
                </Grid>
                )}

                {pathType === view.MARKETPLACE && (
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  style={{ background: 'green1' }}
                >

                  {!isMobileScreen && (
                  <Grid item>
                    <Typography component="div" variant="button">
                      <Box fontWeight="fontWeightBold" className="color-secondary">
                        ORDENAR POR:
                      </Box>
                    </Typography>
                  </Grid>
                  )}
                  <Grid container item xs={12} sm={12} md={4}>
                    <OrderBySelect sortBikes={sortBikes} storeValue={sortBy} />
                  </Grid>
                </Grid>
                )}
                {pathType === view.PILOT_HOME && (
                <>
                  <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      marginBottom: '5px',
                      marginLeft: '10px',
                      maxWidth: '100%',
                    }}
                  >
                    <Grid item xs={6} sm={8} className={classes.vehicleTypeInput}>
                      {!loading && (
                      <CustomMultipleSelect
                        name="vehicleTypes"
                        label="Tipo de vehículo"
                        options={vehicleTypeOptions}
                        value={formControls.vehicleTypes}
                        onChange={changeVehicleTypeControls}
                        inputVariant="standard"
                      />
                      )}
                    </Grid>
                    <Grid item xs={5} sm={3}>
                      <SelectBike value={formControls.bikeFilter} onChange={onChangeFilter} />
                    </Grid>
                  </Grid>
                </>
                )}
              </Grid>
              {/* Icon buttons */}
              <Grid container item xs={12} sm={1}>
                {(pathType === view.MARKETPLACE || pathType === view.STORE_HOME)
              && !isMobileScreen && (
                <Grid container justifyContent="center" style={{ background: 'yellow1' }}>
                  <Button onClick={handleClickChangeTypeListButton}>
                    {VIEW_TYPE[listType]}
                  </Button>
                </Grid>
                )}
                {pathType === view.STORE_HOME
              && ![STORE_BIKE_STATUS['9']].includes(storeHomeSortBy?.field) && (
                <Grid container justifyContent="center" style={{ background: 'purple1' }}>
                  <IconButton style={{ color: '#000' }} onClick={() => exportToCsvBikeData()}>
                    <GetAppIcon fontSize="large" />
                  </IconButton>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          )}
          {pathType === view.PILOT_HOME && isAnyTransferBikeDataNotCompleted && (
          <Grid
            container
            xs={12}
            style={{ background: 'blue1' }}
            justifyContent="center"
            alignContent="center"
          >
            <Grid item>
              <GeneralAlert alertType="completeBikeInfo" severity="error" />
            </Grid>
          </Grid>
          )}
          {pathType === view.PILOT_HOME
              && Boolean(transfersWithPublicationDeletedSoldReason?.length)
              && transfersWithPublicationDeletedSoldReason.map((item) => (
                <Grid
                  container
                  id="tagging"
                  xs={12}
                  style={{ background: 'blue1' }}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item>
                    <GeneralAlert
                      alertType="bikePublicationDeletedBecauseSoldReason"
                      severity="error"
                      bikeBrandName={item?.bike?.brand?.name}
                      bikeModelName={item?.bike?.model?.name}
                      transferId={item?.id}
                    />
                  </Grid>
                </Grid>
              ))}
        </ResponsiveFilters>
        )}

        {isMobileScreen && (
        <Drawer anchor="top" open={showFilters} onClose={handleSetShowFilters}>
          {pathType === view.MARKETPLACE && (
            <FilterCard
              state={filterForm}
              objects={objects}
              order={order}
              actions={actions}
              isMobileScreen={isMobileScreen}
              pathType={pathType}
              handleSetShowFilters={handleSetShowFilters}
            />
          )}
          {pathType === view.STORE_HOME && (
            <FilterCard
              state={storeHomeFilterForm}
              objects={objects}
              order={order}
              actions={actions}
              isMobileScreen={isMobileScreen}
              pathType={pathType}
              handleSetShowFilters={handleSetShowFilters}
            />
          )}
        </Drawer>
        )}

        <Grid
          container
          id="problematic"
          spacing={2}
          style={{
            margin: 0, // Remove any default margins
            width: '100%', // Constrain to parent width
            maxWidth: '100%', // Prevent overflow
            overflowX: 'hidden', // Prevent horizontal scrolling
            boxSizing: 'border-box', // Include padding in width calculations
          }}
        >
          {pathType === view.MARKETPLACE && !isMobileScreen && (
          <Grid item xs={12} md={3} style={{ zIndex: 2 }}>
            {!isMobileScreen && pathType === view.MARKETPLACE && isUserPilotOrNotLogged && (
              <OfferBikePilotRedirect
                handleRedirect={handleRedirect}
                classes={classes}
                isMobileScreen={isMobileScreen}
              />
            )}
            <FilterCard
              state={filterForm}
              objects={objects}
              order={order}
              actions={actions}
              isMobileScreen={isMobileScreen}
              pathType={pathType}
              handleSetShowFilters={handleSetShowFilters}
            />
          </Grid>
          )}
          {pathType === view.STORE_HOME && !isMobileScreen && (
          <Grid item xs={12} md={3} style={{ zIndex: 2 }}>
            <FilterCard
              state={storeHomeFilterForm}
              objects={objects}
              order={order}
              actions={actions}
              isMobileScreen={isMobileScreen}
              pathType={pathType}
              handleSetShowFilters={handleSetShowFilters}
            />
          </Grid>
          )}

          <Grid
            item
            xs={12}
            md={((pathType === view.MARKETPLACE || pathType === view.STORE_HOME)) ? 9 : 12}
            // style={{ marginTop: '10px' }}
            id="problem 4"
            style={{
              margin: 0, // Remove any default margins
              width: '100%', // Constrain to parent width
              maxWidth: '100%', // Prevent overflow
              overflowX: 'hidden', // Prevent horizontal scrolling
              boxSizing: 'border-box', // Include padding in width calculations
            }}
          >

            {(pathType === view.LANDING) && <CenteredTextWithButtons actions={actions} />}

            {(() => {
              if (loading) {
                return (
                  <Grid container id="container20" spacing={2}>
                    <GeneralCardSkeleton idMosaicMode={idMosaicMode} />
                    <GeneralCardSkeleton idMosaicMode={idMosaicMode} />
                    <GeneralCardSkeleton idMosaicMode={idMosaicMode} />
                  </Grid>
                );
              } if (!filteredTransfers()?.length || (status.length === 0 && showPendingBikes)) {
                return (
                  <NotBike
                    loading={loading}
                    filter={formControls.bikeFilter}
                    isMarketPlace={pathType === view.MARKETPLACE}
                    isStoreHome={pathType === view.STORE_HOME}
                  />
                );
              }
              return (
                <div>
                  <Grid
                    id="div-market-1"
                    container
                    direction="column"
                    spacing={8}
                    style={{
                      margin: 0, // Remove any default margins
                      width: '100%', // Constrain to parent width
                      maxWidth: '100%', // Prevent overflow
                      overflowX: 'hidden', // Prevent horizontal scrolling
                      boxSizing: 'border-box', // Include padding in width calculations
                    }}
                  >
                    <Grid item id="problwem2" style={{ padding: '8px' }}>
                      {(pathType === view.LANDING) ? (
                        <Carousel animation="slide">
                          {Array.from({ length: Math.ceil(transfers.length / (isMobileScreen ? 1 : 3)) }).map((_, stepIndex) => (
                            <Grid
                              container
                              spacing={2}
                              key={stepIndex}
                              style={{
                                margin: 0,
                                width: 'auto',
                              }}
                            >
                              {transfers.slice(stepIndex * (isMobileScreen ? 1 : 3), stepIndex * (isMobileScreen ? 1 : 3) + (isMobileScreen ? 1 : 3)).map((subTransfer) => (
                                <Grid item xs={12} sm={4} key={subTransfer.id}>
                                  <GeneralCard
                                    transfer={subTransfer}
                                    transferId={subTransfer.id}
                                    categoryName={subTransfer?.bike?.category?.name}
                                    isReportedBike={subTransfer?.bike?.reported}
                                    showValidBikes={showValidBikes}
                                    showPendingBikes={showPendingBikes}
                                    showTransferedBikes={showTransferedBikes}
                                    setOpenPhotosModal={() => handleOpenPhotos(subTransfer?.bike?.documents)}
                                    brandName={subTransfer?.bike?.brand?.name}
                                    brandId={subTransfer?.bike?.brand?.id}
                                    modelName={subTransfer?.bike?.model?.name}
                                    version={subTransfer?.bike?.version}
                                    sizeName={subTransfer?.bike?.size?.name}
                                    bikeYear={subTransfer?.bike?.year}
                                    bikeSerial={subTransfer?.bike?.serial}
                                    bikeId={subTransfer?.bike?.id}
                                    bikeType={subTransfer?.bike?.vehicleTypeId}
                                    bike={subTransfer?.bike}
                                    rimName={subTransfer?.bike?.rim?.name}
                                    buyerStatus={subTransfer?.buyerStatus}
                                    paymentStatus={subTransfer?.paymentStatus}
                                    userId={loggedUser?.id}
                                    alert={alert}
                                    buyerId={subTransfer?.buyerId}
                                    sellerId={subTransfer?.sellerId}
                                    isBikfyPaid={subTransfer?.isBikfyPaid}
                                    openAlertDelete={actions.openAlertDelete}
                                    onChange={actions.changeControls}
                                    handleToggleModalPaymentStatus={handleToggleModalPaymentStatus}
                                    goToBankDataInfo={goToBankDataInfo}
                                    goToEditPage={goToEditPage}
                                    goToSearchPage={goToSearchPage}
                                    goToTransfer={goToTransfer}
                                    goToWorkshop={goToWorkshop}
                                    goToReport={goToReport}
                                    goToReportBikfy={goToReportBikfy}
                                    goToAcceptTransfer={goToAcceptTransfer}
                                    goToInsurance={goToInsurance}
                                    listType={listType}
                                    bikeMarket={subTransfer?.bikesMarket}
                                    handleToggleSetSellerEmailDialog={handleToggleSetSellerEmailDialog}
                                    handleToggleReportPublishModal={handleToggleReportPublishModal}
                                    handlePublishReport={handlePublishReport}
                                    handleRedirectWithState={handleRedirectWithState}
                                    handleRedirect={handleRedirect}
                                    requestAccess={actions.requestAccess}
                                    removeStoreAccess={removeStoreAccess}
                                    isPilotBikesPath={pathType === view.PILOT_HOME}
                                    isStoreBikesPath={pathType === view.STORE_HOME}
                                    isMarketPlace={pathType === view.LANDING}
                                    status={subTransfer?.statusLabel}
                                    view={view}
                                    motorbikeInformation={
                                      objects?.motorbikes && subTransfer?.bike?.id
                                        ? Object.values(objects?.motorbikes)?.filter(
                                          (mb) => mb.bikeId === subTransfer?.bike?.id,
                                        )[0]
                                        : {}
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ))}
                        </Carousel>
                      ) : (
                        <Grid
                          container
                          id="div-market-2"
                          spacing={2}
                          style={{
                            margin: 0,
                            maxWidth: '100%',
                          }}
                          className={`${
                            pathType === view.MARKETPLACE || pathType === view.STORE_HOME
                              ? classes.justifyContentFlexStart
                              : classes.justifyContentCenter
                          }`}
                        >
                          {transfers?.map((transfer, index) => {
                            const showAdCard = pathType === 'marketplace' && (index + 1) % 6 === 0;
                            return (
                              <React.Fragment key={transfer.id}>
                                {showAdCard && (
                                <Grid
                                  item
                                  sm={
                                    pathType === view.MARKETPLACE || pathType === view.STORE_HOME
                                      ? smGridCardMarketPlace
                                      : smGridCardPilot
                                  }
                                  xs={12}
                                >
                                  <AdCard listType={listType} fullImage />
                                </Grid>
                                )}

                                <Grid
                                  item
                                  sm={
                                    pathType === view.MARKETPLACE || pathType === view.STORE_HOME
                                      ? smGridCardMarketPlace
                                      : smGridCardPilot
                                  }
                                  xs={12}
                                >
                                  <GeneralCard
                                    transfer={transfer}
                                    transferId={transfer.id}
                                    categoryName={transfer?.bike?.category?.name}
                                    isReportedBike={transfer?.bike?.reported}
                                    showValidBikes={showValidBikes}
                                    showPendingBikes={showPendingBikes}
                                    showTransferedBikes={showTransferedBikes}
                                    setOpenPhotosModal={() => handleOpenPhotos(transfer?.bike?.documents)}
                                    brandName={transfer?.bike?.brand?.name}
                                    brandId={transfer?.bike?.brand?.id}
                                    modelName={transfer?.bike?.model?.name}
                                    version={transfer?.bike?.version}
                                    sizeName={transfer?.bike?.size?.name}
                                    bikeYear={transfer?.bike?.year}
                                    bikeSerial={transfer?.bike?.serial}
                                    bikeId={transfer?.bike?.id}
                                    bikeType={transfer?.bike?.vehicleTypeId}
                                    bike={transfer?.bike}
                                    rimName={transfer?.bike?.rim?.name}
                                    buyerStatus={transfer?.buyerStatus}
                                    paymentStatus={transfer?.paymentStatus}
                                    userId={loggedUser?.id}
                                    alert={alert}
                                    buyerId={transfer?.buyerId}
                                    sellerId={transfer?.sellerId}
                                    isBikfyPaid={transfer?.isBikfyPaid}
                                    openAlertDelete={actions.openAlertDelete}
                                    onChange={actions.changeControls}
                                    handleToggleModalPaymentStatus={handleToggleModalPaymentStatus}
                                    goToBankDataInfo={goToBankDataInfo}
                                    goToEditPage={goToEditPage}
                                    goToSearchPage={goToSearchPage}
                                    goToTransfer={goToTransfer}
                                    goToWorkshop={goToWorkshop}
                                    goToReport={goToReport}
                                    goToReportBikfy={goToReportBikfy}
                                    goToAcceptTransfer={goToAcceptTransfer}
                                    goToInsurance={goToInsurance}
                                    listType={listType}
                                    bikeMarket={transfer?.bikesMarket}
                                    handleToggleSetSellerEmailDialog={handleToggleSetSellerEmailDialog}
                                    handleToggleReportPublishModal={handleToggleReportPublishModal}
                                    handlePublishReport={handlePublishReport}
                                    handleRedirectWithState={handleRedirectWithState}
                                    handleRedirect={handleRedirect}
                                    requestAccess={actions.requestAccess}
                                    removeStoreAccess={removeStoreAccess}
                                    isPilotBikesPath={pathType === view.PILOT_HOME}
                                    isStoreBikesPath={pathType === view.STORE_HOME}
                                    isMarketPlace={pathType === view.MARKETPLACE}
                                    status={transfer?.statusLabel}
                                    view={view}
                                    motorbikeInformation={
                                      objects?.motorbikes && transfer?.bike?.id
                                        ? Object.values(objects?.motorbikes)?.filter(
                                          (mb) => mb.bikeId === transfer?.bike?.id,
                                        )[0]
                                        : {}
                                    }
                                  />
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      id="phone"
                      style={{
                        maxWidth: '100%', // Prevent overflow
                        // margin: '0 auto', // Center if needed
                      }}
                    >
                      {(pathType === view.LANDING) && <PhoneWithList user={user} isMobileScreen={isMobileScreen} />}
                    </Grid>
                  </Grid>
                </div>
              );
            })()}

            {pathType === view.MARKETPLACE && (
            <CustomPagination pagination={pagination} setPagination={handleSetPagination} />
            )}

            {pathType === view.STORE_HOME && (
            <CustomPagination
              pagination={paginationStoreHome}
              setPagination={handleSetPagination}
            />
            )}

            {pathType === view.PILOT_HOME && (
            <CustomButtonFloat className={classes.fab} handleSubmit={handleSubmit} />
            )}
            <CustomModalConfirmation
              alert={alertDelete}
              clearAlert={actions.clearAlertDelete}
              text={`Al oprimir Continuar la transferencia será ${formControls.message}`}
              handleSubmit={() => actions.deleteTransfer({
                id: formControls.idTransferDelete,
                type: formControls.type,
              })}
              loading={loading}
            />
            <ModalPhotos open={openModalPhotos} handleClose={handleclosePhotos} photos={photos} />
            <StatusPaymentDialog
              className={`${classes.statusPaymentDialog}`}
              open={modals.paymentStatus.show}
              handleToggleModalPaymentStatus={handleToggleModalPaymentStatus}
              activeStep={activeStep}
              fullScreen={isDownXsScreen}
            />
            <SendEmailDialog
              formSellerEmailValues={formSellerEmailValues}
              open={modals.setSellerEmail.open}
              toggleSetSellerEmailDialog={toggleSetSellerEmailDialog}
              handleSetSellerEmail={handleSetSellerEmail}
              user={loggedUser}
            />
            <ReportPublishDialog
              open={modals.reportPublish.open}
              handleToggleReportPublishModal={handleToggleReportPublishModal}
              reportReasons={Object.values(objects.reportReasons)}
              handlePublishReport={handlePublishReport}
            />
            {pathType === view.LANDING && (
            <Button target="_blank" component="a" href={bikfyWhatsappUrl}>
              <img src={whatsAppIconSvg} className={classes.whatsappFab} />
            </Button>
            )}
          </Grid>
        </Grid>
        {pathType === view.LANDING && !loading && (
        <InformativeModal
          open={loggedUser?.alertedInLanding || isUserAlertedInLanding || !loggedUser}
          classes={classes}
          closeInformativeModal={handleCloseInformativeModal}
        />
        )}
      </Container>
    </>
  );
};

export default composedComponent(Home, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
  states: [
    'app.objects',
    'app.objects.transfers',
    'app.order',
    'app.user',
    'home',
    'app.lastMarketPlaceOrder',
  ],
  actions: [homeActions, appActions],
});
