// @ts-nocheck
import React from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Box, Grid } from '@material-ui/core';

import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
// styles
// import styles from './styles';

// const useStyles = makeStyles(styles);

const DeletePublicationModal = (props) => {
  const {
    isOpen,
    handleOpenOrCloseDeleteModal,
    deletedPublicationReasons,
    setDeletePublicationReason,
    deletePublicationReasonId,
    handleDeletePublication,
    classes,
  } = props;

  const handleChange = (event) => {
    setDeletePublicationReason({ deletePublicationReasonId: event.target.value });
  };
  return (
    <Dialog
      fullWidth
      onClose={handleOpenOrCloseDeleteModal}
      aria-labelledby="simple-dialog-title"
      open={isOpen}>
      <DialogTitle className={classes.deletePublicationTitleContainer}>
        <Typography align="center" className={classes.deletePublicationTitle} display="inline">
          Cuéntanos por qué deseas{' '}
          <Typography
            display="inline"
            align="center"
            className={classes.deletePublicationTitle}
            color="secondary">
            eliminar
          </Typography>{' '}
          tu publicación
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.deletePublicationTitleContainer}>
        <FormControl component="fieldset" className={classes.fullWidth}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={deletePublicationReasonId}
            onChange={handleChange}>
            {deletedPublicationReasons.map((reason) => (
              <Box textAlign="center" justifyContent="center">
                <FormControlLabel
                  value={reason.id}
                  control={<Radio checked={deletePublicationReasonId === String(reason?.id)} />}
                  label={reason.name}
                  className={classes.radioGroupStyles}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.justifyCenter}>
        <Button
          onClick={() => handleDeletePublication()}
          autoFocus
          color="secondary"
          variant="contained"
          style={{ marginBottom: '20px', marginTop: '20px' }}
          disabled={!deletePublicationReasonId}>
          Eliminar publicación
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePublicationModal;
