import { createReducer } from 'utils/reducer';

import buyerPayMethodScreenTypes from './buyerPayMethodScreen.types';

const initState = {
  loaders: {
    isLoading: false,
  },
  errors: {
    isErrors: false,
    errorMessage: '',
  },
  redirect: false,
  transbank: {
    url: '',
    token: '',
  },
  modals: {
    warningModal: {
      isOpen: false,
    },
  },
};

const buyerPayMethodScreenReducer = createReducer(initState, {
  [buyerPayMethodScreenTypes.INIT_STATE](state) {
    return {
      ...state,
      ...initState,
    };
  },
  [buyerPayMethodScreenTypes.CREATE_PAYMENT](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
    };
  },
  [buyerPayMethodScreenTypes.CREATE_PAYMENT_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      transbank: {
        ...state.transbank,
        url: action.payload.url,
        token: action.payload.token,
      },
    };
  },
  [buyerPayMethodScreenTypes.CREATE_PAYMENT_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [buyerPayMethodScreenTypes.GET_TRANSFER](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
      errors: {
        ...state.errors,
        ...initState.errors,
      },
    };
  },
  [buyerPayMethodScreenTypes.GET_TRANSFER_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
    };
  },
  [buyerPayMethodScreenTypes.GET_TRANSFER_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [buyerPayMethodScreenTypes.SET_OPEN_WARNING_MODAL](state, action) {
    return {
      ...state,
      modals: {
        ...state.modals,
        warningModal: {
          ...state.modals.warningModal,
          isOpen: action.payload.isOpen,
        },
      },
    };
  },
});

export default buyerPayMethodScreenReducer;
