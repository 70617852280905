import { takeLatest, spawn, put } from 'redux-saga/effects';

import { PILOT } from '../reducer';
import { apiSuccess, post, update } from 'utils/api';

function* addPilot() {
  yield takeLatest(PILOT.POST_ADD_PILOT, function* (action) {
    let response = null;
    const body = {};

    const { formControls, token } = action.payload;

    for (let key in formControls) {
      let value = formControls[key];
      if (typeof value === 'string') {
        if (!['password', 'verifyPassword'].includes(key)) {
          value = value.trim();
        }
      }
      body[key] = value;
    }

    if (token) {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      // response = yield apiRequest('api/v1/register/', options, false);
      response = yield update('api/v1/register/', body, options);
    } else {
      response = yield post('api/v1/register/', body);
    }

    if (!response.error) {
      yield put(apiSuccess(PILOT.POST_ADD_PILOT_SUCCESS));
    } else {
      yield put(apiSuccess(PILOT.POST_ADD_PILOT_ERROR, response));
    }
  });
}

export default function* root() {
  yield spawn(addPilot);
}
