// REACT
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  FormControl,
  FormLabel,
} from '@material-ui/core';

const DisableDialog = (props) => {
  const {
    open,
    handleToggleShowDisableDialog,
    disabledReasons,
    disableFormDialog,
    handleToggleDisableBike,
    isDisable,
    actions,
  } = props;

  const handleCloseDialog = () => {
    handleToggleShowDisableDialog({ bikeId: 0 });
  };

  const handleClickDisableButton = () => {
    handleToggleDisableBike({ formValues: disableFormDialog, isDisable });
  };

  const handleChangeCheckBox = (event) => {
    const { value, checked } = event.target;

    const numberValue = Number(value);

    let newDisabledReasonIds = [...disableFormDialog.disabledReasonIds];

    if (checked) {
      newDisabledReasonIds.push(numberValue);
    } else {
      newDisabledReasonIds = newDisabledReasonIds.filter((id) => numberValue !== id);
    }
    actions.setDisableFormDialog({ ...disableFormDialog, disabledReasonIds: newDisabledReasonIds });
    // setFormValues((prevFormValues) => ({
    //   ...prevFormValues,
    //   disabledReasonIds: newDisabledReasonIds,
    // }
    // ));
  };

  const handleCheckTextField = (event) => {
    const { name, value } = event.target;
    actions.setDisableFormDialog({ ...disableFormDialog, [name]: value });
    // setFormValues((prevFormValues) => ({
    //   ...prevFormValues,
    //   [name]: value,
    // }));
  };

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>Inhabilitar Bike</DialogTitle>
      <DialogContent>
        <Box>
          {/* TODO: memorizas values ? */}
          <FormControl>
            <FormLabel component="legend">Motivos</FormLabel>
            {Object.values(disabledReasons).map((disabledReason) => (
              <FormControlLabel
                style={{ margin: '5px 0px' }}
                key={disabledReason?.id}
                label={disabledReason?.name}
                control={
                  <Checkbox
                    name="disabledReasonIds"
                    value={disabledReason?.id}
                    onChange={handleChangeCheckBox}
                    checked={disableFormDialog.disabledReasonIds.some(
                      (idsValue) => disabledReason?.id === idsValue,
                    )}
                  />
                }
              />
            ))}
          </FormControl>
          <TextField
            fullWidth
            multiline
            label="Razon"
            minRows={3}
            name="reason"
            onChange={handleCheckTextField}
            style={{ margin: '20px 0px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={handleCloseDialog}>
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={handleClickDisableButton}>
          {isDisable ? 'Habilitar' : ' Inhabilitar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DisableDialog.propTypes = {
  open: PropTypes.bool,
  disabledReasons: PropTypes.shape({}),
  disableFormDialog: PropTypes.shape({}),
  handleToggleDisableBike: PropTypes.func,
  handleToggleShowDisableDialog: PropTypes.func,
  isDisable: PropTypes.bool,
  disabledReasonIds: PropTypes.arrayOf(PropTypes.number),
};

DisableDialog.defaultProps = {
  open: false,
  disabledReasons: {},
  disableFormDialog: {},
  handleToggleDisableBike: () => {},
  handleToggleShowDisableDialog: () => {},
  isDisable: false,
  disabledReasonIds: [],
};

export default DisableDialog;
