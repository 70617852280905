import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import {
  TextField, FormControlLabel, Checkbox, Grid, Select,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Years from 'utils/years';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import { view } from 'utils/pathTypes';
import CustomMultiSelectComplete from 'commons/components/CustomMultiSelectComplete';
import { ControlPointSharp } from '@material-ui/icons';
import CustomAccordion from '../CustomAccordion/CustomAccordion';

const getValues = ({ order, objects, name }) => order[name]?.map((t) => objects[name][t]) || Object.values(objects[name]);

const FilterForm = (props) => {
  const {
    objects = {},
    order = {},
    formValues,
    setFormValues,
    setStoreHomeFormValues,
    pathType,
    changeVehicleTypeControl,
    changeVehicleTypeStoreFilterForm,
    changeControls,
  } = props;

  const { brandId, vehicleTypeId } = formValues;

  const categories = getValues({ order, objects, name: 'categories' });
  const brands = getValues({ order, objects, name: 'brands' });
  const models = getValues({ order, objects, name: 'models' });
  const sizes = getValues({ order, objects, name: 'sizes' });
  const rims = getValues({ order, objects, name: 'rims' });
  const regions = getValues({ order, objects, name: 'regions' });
  const comunes = getValues({ order, objects, name: 'comunes' });
  const vehicleTypes = getValues({ order, objects, name: 'vehicleTypes' });

  const formattedEngineCapacities = props.order?.engineCapacities
    ?.map((mm) => objects.engineCapacities[mm])
    .map((a) => ({
      id: a?.id,
      name: a?.capacity,
    }));

  const isVehicleTypeBicycle = vehicleTypes.filter((vehicleType) => vehicleType.id === vehicleTypeId)[0]?.name === 'Bicicleta';

  const comuneOptions = comunes
    ? comunes.map((a) => ({
      value: a?.id,
      label: a?.name,
    }))
    : [];

  const filteredbrands = vehicleTypeId
    ? brands
      .filter((brand) => brand.vehicleTypeId === vehicleTypeId || brand.vehicleTypeId === null)
      .map((a) => ({
        value: a?.id,
        label: a?.name,
        vehicleTypeId: a?.vehicleTypeId,
      }))
    : brands.map((a) => ({
      value: a?.id,
      label: a?.name,
      vehicleTypeId: a?.vehicleTypeId,
    }));

  const filteredModels = brandId?.length
    ? models
      .filter((model) => brandId.includes(model.brandId))
      .map((a) => ({
        value: a?.id,
        label: a?.name,
        brandId: a?.brandId,
      }))
    : models
      .filter((model) => model.vehicleTypeId === vehicleTypeId)
      .map((a) => ({
        value: a?.id,
        label: a?.name,
        brandId: a?.brandId,
      }));

  const filteredCategories = vehicleTypeId
    ? categories.filter(
      (category) => category.vehicleTypeId === vehicleTypeId || category.vehicleTypeId === null,
    )
    : categories;

  const filterInputsByVehicleType = (item) => {
    if (isVehicleTypeBicycle) {
      return !item.disabled;
    }
    return !item.disabled;
  };
  const memoCustomAccordions = useMemo(() => {
    let generalFields = [
      {
        title: 'Tipo de vehículo',
        name: 'vehicleTypeId',
        type: 'autocomplete',
        options: [...vehicleTypes],
        disableClearable: true,
        disableKeyPress: true,
        disableClearButton: true,
        alwaysExpanded: Boolean(vehicleTypes.length),
      },
      {
        title: 'Código de serie',
        name: 'serialCode',
        type: 'text',
        // disabled: vehicleTypes.length ? !isVehicleTypeBicycle : false,
        disabled: true,
      },
      {
        title: 'Código de serie motor',
        name: 'engineSerialCode',
        type: 'text',
        // disabled: vehicleTypes.length ? isVehicleTypeBicycle : true,
        disabled: true,

      },
      {
        title: 'Código de serie chasis',
        name: 'chassisSerialCode',
        type: 'text',
        // disabled: vehicleTypes.length ? isVehicleTypeBicycle : true,
        disabled: true,

      },
      {
        title: 'Cilindraje Motor',
        name: 'engineCapacityId',
        type: 'autocomplete',
        options: formattedEngineCapacities ? [...formattedEngineCapacities] : [],
        disabled: vehicleTypes.length ? isVehicleTypeBicycle : true,
      },
      {
        title: 'Categoría',
        name: 'categoryId',
        type: 'autocomplete',
        options: [...filteredCategories],
      },
      {
        title: 'Marca',
        name: 'brandId',
        type: 'multicomplete',
        options: [...filteredbrands],
      },
      {
        title: 'Modelo',
        name: 'modelId',
        type: 'multicomplete',
        options: [...filteredModels],
      },
      {
        title: 'Año',
        name: { min: 'minYear', max: 'maxYear' },
        label: { min: 'Desde', max: 'Hasta' },
        type: 'range',
        isRangeAutoComplete: true,
        options: Years(true),
      },
      {
        title: 'Talla',
        name: 'sizeId',
        type: 'autocomplete',
        options: [...sizes],
        disabled: vehicleTypes.length ? !isVehicleTypeBicycle : false,
      },
      {
        title: 'Aro',
        name: 'rimId',
        type: 'autocomplete',
        options: [...rims],
        disabled: vehicleTypes.length ? !isVehicleTypeBicycle : false,
      },
    ];

    if (pathType !== view.STORE_HOME) {
      generalFields.push(
        {
          title: 'Precio',
          name: { min: 'minPrice', max: 'maxPrice' },
          // @ts-ignore
          placeholder: {
            min: 'CLP Mínimo',
            max: 'CLP Máximo',
          },
          type: 'range',
        },
        {
          title: 'Región',
          name: 'regionId',
          type: 'autocomplete',
          options: [...regions],
        },
        {
          title: 'Comuna',
          name: 'communeId',
          type: 'multicomplete',
          options: [...comuneOptions],
        },
        {
          title: 'Opciones de pago',
          name: 'paymentOption',
          type: 'autocomplete',
          options: [
            {
              id: 'bikfyPay',
              name: 'Con BIKFYPAY',
            },
            {
              id: 'externalPayment',
              name: 'Sin BIKFYPAY',
            },
          ],
        },
        {
          title: 'Libro Taller',
          name: 'workshopBook',
          type: 'autocomplete',
          disabled: true,

          options: [
            {
              id: 'withMaintenance',
              name: 'Con Libro Taller',
            },
            {
              id: 'withoutMaintenance',
              name: 'Sin Libro Taller',
            },
          ],
        },
        {
          title: 'Documentación',
          name: 'documentation',
          type: 'autocomplete',
          disabled: true,

          options: [
            {
              id: 'withDocument',
              name: 'Con Documentación',
            },
            {
              id: 'withoutDocument',
              name: 'Sin Documentación',
            },
          ],
        },
        {
          title: 'Opciones de envío',
          name: 'shippingType',
          type: 'autocomplete',
          disabled: true,

          options: [
            {
              id: 'withDelivery',
              name: 'Con envio',
            },
            {
              id: 'withoutDelivery',
              name: 'Sin envio',
            },
          ],
        },
        {
          title: 'Tipo de Vendedor',
          name: 'vendorType',
          disabled: true,

          type: 'autocomplete',
          options: [
            {
              id: 'store',
              name: 'Tienda',
            },
            {
              id: 'pilot',
              name: 'Piloto',
            },
          ],
        },
      );
    }
    if (vehicleTypeId) {
      generalFields = generalFields.filter(filterInputsByVehicleType);
    }

    return generalFields;
  }, [categories, brands, models, pathType]);

  /* const handleChangeMultiComplete = (name, value) => {

  } */

  const handleChangeAutoComplete = ({ name, id }) => {
    const value = id ?? '';
    if (pathType === view.STORE_HOME) {
      setStoreHomeFormValues({
        storeHomeFilterForm: {
          ...formValues,
          [name]: value,
        },
      });
      if (name === 'vehicleTypeId') {
        changeVehicleTypeStoreFilterForm();
      }
    } else {
      setFormValues({
        filterForm: {
          ...formValues,
          [name]: value,
        },
      });
      if (name === 'vehicleTypeId') {
        changeVehicleTypeControl();
      }
    }
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    if (pathType === view.STORE_HOME) {
      setStoreHomeFormValues({
        storeHomeFilterForm: {
          ...formValues,
          [name]: value,
        },
      });
    } else {
      setFormValues({
        filterForm: {
          ...formValues,
          [name]: value,
        },
      });
    }
  };

  const handleResetMinMaxInput = (inputs) => {
    const filtersUpdated = {
      ...formValues,
    };
    inputs.forEach((input) => {
      filtersUpdated[`${input.name}`] = input.value;
    });
    if (pathType === view.STORE_HOME) {
      setStoreHomeFormValues({
        storeHomeFilterForm: {
          ...filtersUpdated,
        },
      });
    } else {
      setFormValues({
        filterForm: {
          ...filtersUpdated,
        },
      });
    }
  };

  const handleChangeCheckbox = (event) => {
    const { target } = event;
    const { name, value, checked } = target;

    if (pathType === view.STORE_HOME) {
      setStoreHomeFormValues({
        storeHomeFilterForm: {
          ...formValues,
          [name]: {
            ...formValues[name],
            [value]: checked,
          },
        },
      });
    } else {
      setFormValues({
        filterForm: {
          ...formValues,
          [name]: {
            ...formValues[name],
            [value]: checked,
          },
        },
      });
    }
  };

  const onChangeMultiSelect = (event) => {
    const { name, value } = event.target;
    if (pathType === view.STORE_HOME) {
      setStoreHomeFormValues({ storeHomeFilterForm: { [name]: value } });
    } else {
      setFormValues({ filterForm: { [name]: value } });
    }
  };

  return (
    <form>
      {memoCustomAccordions.map(
        ({
          title,
          name,
          placeholder,
          options,
          type,
          checkOptions,
          isRangeAutoComplete,
          label,
          disableClearable,
          disableKeyPress,
          disableClearButton,
          alwaysExpanded,
        }) => (
          <CustomAccordion
            title={title}
            key={title}
            formValues={formValues}
            handleChange={handleChange}
            fieldName={name}
            handleResetMinMaxInput={handleResetMinMaxInput}
            alwaysExpanded={alwaysExpanded}
            disableClearButton={disableClearButton}
          >
            {type === 'multicomplete' && (
              <Grid item xs={12}>
                <CustomMultiSelectComplete
                  name={name}
                  label={title}
                  options={options}
                  onChange={onChangeMultiSelect}
                  value={formValues[name]}
                />
              </Grid>
            )}
            {type === 'autocomplete' && (
              <Autocomplete
                options={options}
                disableClearable={disableClearable}
                getOptionLabel={(option) => option.name}
                value={options?.find((item) => item.id === formValues[name]) ?? null}
                fullWidth
                onChange={(event, newValue) => handleChangeAutoComplete({ name, id: newValue?.id })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    onKeyDown={(e) => {
                      if (disableKeyPress) e.preventDefault();
                    }}
                  />
                )}
                size="small"
              />
            )}

            {type === 'text' && (
              <TextField
                variant="outlined"
                fullWidth
                name={name}
                value={formValues[name]}
                onChange={handleChange}
                size="small"
              />
            )}

            {type === 'range' && (
              <Grid container spacing={2}>
                {isRangeAutoComplete ? (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.name}
                        value={options?.find((item) => item.id === formValues[name.min]) ?? null}
                        fullWidth
                        onChange={(event, newValue) => handleChangeAutoComplete({
                          name: name.min,
                          id: newValue?.id,
                        })}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label={label.min} />
                        )}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={
                          formValues.minYear
                            ? options.filter((item) => item.id > parseInt(formValues.minYear, 10))
                            : options
                        }
                        getOptionLabel={(option) => option.name}
                        value={options?.find((item) => item.id === formValues[name.max]) ?? null}
                        fullWidth
                        onChange={(event, newValue) => handleChangeAutoComplete({
                          name: name.max,
                          id: newValue?.id,
                        })}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label={label.max} />
                        )}
                        size="small"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <CustomInputNumber
                        disabled={false}
                        label={placeholder.min}
                        onChange={handleChange}
                        value={formValues[name.min]}
                        name={name.min}
                        error={false}
                        helperText=""
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomInputNumber
                        disabled={false}
                        label={placeholder.max}
                        onChange={handleChange}
                        value={formValues[name.max]}
                        name={name.max}
                        error={false}
                        helperText=""
                        size="small"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {type === 'checkbox'
              && checkOptions.map((checkOption) => (
                <FormControlLabel
                  control={<Checkbox name={name} />}
                  label={checkOption.label}
                  value={checkOption.value}
                  onChange={handleChangeCheckbox}
                />
              ))}
          </CustomAccordion>
        ),
      )}
    </form>
  );
};

FilterForm.propTypes = {
  objects: PropTypes.shape({}).isRequired,
};

export default React.memo(FilterForm);
