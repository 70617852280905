import React, { useEffect, useRef, useState } from 'react';
import { getItemInStorage } from 'utils/functions';
import { Document, Page, pdfjs } from 'react-pdf';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const Contract = ({ id }) => {
  const [pdf, setPdf] = useState('');
  const [width, setWidth] = useState('');
  const ref = useRef();

  useEffect(() => {
    const session = getItemInStorage('user');
    const authorizationHeader = { Authorization: `Bearer ${session && session.token}` };

    fetch(`/api/v1/transfers/pdf/${id}`, { headers: authorizationHeader })
      .then((response) => response.blob())
      .then((myBlob) => {
        const reader = new FileReader();
        reader.onload = function () {
          setPdf(this.result);
        };
        reader.readAsDataURL(myBlob);
      });
  }, [id]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [pdf]);

  const handleDownloadPdf = () => {
    const session = getItemInStorage('user');

    fetch(`/api/v1/transfers/pdf/${id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${session.token}`,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Contrato.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <div ref={ref} style={{ maxWidth: '80vw', overflow: 'auto' }}>
      <Document
        file={pdf}
        onLoadError={(e) => {
          console.log(e);
        }}>
        <Page pageNumber={1} width={width} />
      </Document>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <IconButton color="inherit" onClick={handleDownloadPdf}>
          <GetAppIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Contract;
