import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

const CustomTab = (props) => {
  const { children, value, style, index, className } = props;
  return (
    <Box hidden={value !== index} className={className} style={{ ...style }}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

CustomTab.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

CustomTab.defaultProps = {
  style: {},
  className: '',
};

export default CustomTab;
