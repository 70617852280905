import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import TransferForm from 'screens/Transfer/components/transferForm';
import Categories from '../../../../utils/categories';

const TransferCard = ({
  title,
  category,
  size,
  year,
  rim,
  serial,
  chassisSerial,
  engineSerial,
  isBicycle,
  errors,
  onControlChange,
  controls,
  onSubmit,
  profileComplete,
  engineCapacity,
}) => (
  <Card variant="outlined">
    <CardContent>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={3}>
          <img src={Categories[category]} alt="" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography component="h5" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            <b>Categoría:</b> {category}
          </Typography>
          {isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Talla:</b> {size}
            </Typography>
          )}
          <Typography variant="subtitle1" color="textSecondary">
            <b>Año:</b> {year}
          </Typography>
          {isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Aro:</b> {rim}
            </Typography>
          )}
          {isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Código de serie:</b> {serial}
            </Typography>
          )}
          {!isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Número de chasis:</b> {chassisSerial}
            </Typography>
          )}
          {!isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Número de motor:</b> {engineSerial}
            </Typography>
          )}
          {!isBicycle && (
            <Typography variant="subtitle1" color="textSecondary">
              <b>Cilindraje motor:</b> {engineCapacity}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TransferForm
            errors={errors}
            onControlChange={onControlChange}
            controls={controls}
            onSubmit={onSubmit}
            profileComplete={profileComplete}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TransferCard;
