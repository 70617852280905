const styles = (theme) => ({
  stepper: {
    width: '517px',
    backgroundColor: 'transparent',
    margin: '0 auto',
    marginBottom: '20px',
    padding: '10px 20px',

    '& svg': {
      fontSize: '35px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    stepper: {
      width: '100%',
      '& svg': {
        fontSize: '30px',
      },
    },
  },
});

export default styles;
