import { takeLatest, spawn, call, put } from 'redux-saga/effects';

import * as APP from 'commons/reducer';
import adminSaleTypes from '../types';
import apiRequest, { apiSuccess } from '../../../utils/api';

function* uploadSalesExcel() {
  yield takeLatest(adminSaleTypes.UPLOAD_SALES_EXCEL, function* uploadExcelGenerator(action) {
    const { sales } = action.payload;

    const body = { sales };

    const response = yield apiRequest(
      `api/v1/transfers/upload-sales-excel`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      true,
    );

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, response.message));
      yield put(apiSuccess(adminSaleTypes.UPLOAD_SALES_EXCEL_SUCCESS, response.response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: 'Ocurrió un error al intentar cargar las ventas de las tiendas.',
        }),
      );
      yield put(apiSuccess(adminSaleTypes.UPLOAD_SALES_EXCEL_FAILURE));
    }
  });
}

export default function* adminPaySaga() {
  yield spawn(uploadSalesExcel);
}
