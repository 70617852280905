import { createAction } from 'utils/reducer';

import bankDataScreenTypes from './bankDataScreen.types';

export const bankDataScreenActions = {
  getBankData: createAction(bankDataScreenTypes.GET_BANK_DATA, 'payload'),
  getBankDataSuccess: createAction(bankDataScreenTypes.GET_BANK_DATA_SUCCESS, 'payload'),
  getBankDataFailure: createAction(bankDataScreenTypes.GET_BANK_DATA_FAILURE, 'payload'),
  getStoreBankData: createAction(bankDataScreenTypes.GET_STORE_BANK_DATA, 'payload'),
  getStoreBankDataSuccess: createAction(bankDataScreenTypes.GET_STORE_BANK_DATA_SUCCESS, 'payload'),
  getStoreBankDataFailure: createAction(bankDataScreenTypes.GET_STORE_BANK_DATA_FAILURE, 'payload'),
  setStoreBankData: createAction(bankDataScreenTypes.SET_STORE_DATA_BANK, 'payload'),
  setStoreBankDataSuccess: createAction(bankDataScreenTypes.SET_STORE_DATA_BANK_SUCCESS, 'payload'),
  setStoreBankDataFailure: createAction(bankDataScreenTypes.SET_STORE_DATA_BANK_FAILURE, 'payload'),
  setUserDataBank: createAction(bankDataScreenTypes.SET_USER_DATA_BANK, 'payload'),
  setUserDataBankSuccess: createAction(bankDataScreenTypes.SET_USER_DATA_BANK_SUCCESS, 'payload'),
  setUserDataBankFailure: createAction(bankDataScreenTypes.SET_USER_DATA_BANK_FAILURE, 'payload'),
  resetBankDataId: createAction(bankDataScreenTypes.RESET_BANK_DATA_ID),
  toggleError: createAction(bankDataScreenTypes.TOGGLE_ERROR, 'payload'),
};

export default bankDataScreenActions;
