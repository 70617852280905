import React from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import { formatTaxNumber } from 'utils/functions';

const Form = ({
  user, controls, onChange, onSubmit, comunes, errors, classes, loading,
}) => {
  let birthday = '';
  if (controls.birthday) {
    birthday = controls.birthday.split('T');
    birthday = birthday[0];
  }

  const history = useHistory();
  const genders = [
    { value: 'Femenino', label: 'Femenino' },
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Otro', label: 'Otro' },
  ];

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="name"
            name="name"
            label="Nombre"
            variant="outlined"
            fullWidth
            value={controls.name ?? ''}
            onChange={onChange}
            error={errors.name}
            helperText={errors.name && errors.name[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="firstLastName"
            name="firstLastName"
            label="Apellido Paterno"
            variant="outlined"
            fullWidth
            value={controls.firstLastName ?? ''}
            onChange={onChange}
            error={errors.firstLastName}
            helperText={errors.firstLastName && errors.firstLastName[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            id="secondLastName"
            name="secondLastName"
            label="Apellido Materno"
            variant="outlined"
            value={controls.secondLastName ?? ''}
            onChange={onChange}
            error={errors.secondLastName}
            helperText={errors.secondLastName && errors.secondLastName[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            // required
            id="taxNumber"
            name="taxNumber"
            label="RUT"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 12 }}
            onChange={(e) => onChange(e, formatTaxNumber)}
            value={formatTaxNumber(controls.taxNumber) ?? ''}
            error={errors.taxNumber}
            helperText={errors.taxNumber && errors.taxNumber[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            id="birthday"
            name="birthday"
            label="Fecha de nacimiento"
            variant="outlined"
            fullWidth
            type="date"
            InputLabelProps={{ shrink: true }}
            value={birthday}
            onChange={onChange}
            error={errors.birthday}
            helperText={errors.birthday && errors.birthday[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomAutoComplete
            name="gender"
            label="Género"
            onChange={onChange}
            value={controls.gender}
            options={genders}
            error={!!errors.gender}
            errors={errors.gender}
          />

        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Teléfono celular"
            variant="outlined"
            value={controls.phone}
            onChange={onChange}
            error={!!errors.phone}
            helperText={errors.phone && errors.phone[0]}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomAutoComplete
            name="comuneId"
            label="Comuna"
            value={controls.comuneId}
            onChange={onChange}
            options={comunes}
            error={!!errors.comuneId}
            errors={errors.comuneId}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            id="addressStreet"
            name="addressStreet"
            label="Calle"
            variant="outlined"
            value={controls.addressStreet ?? ''}
            onChange={onChange}
            error={!!errors.addressStreet}
            helperText={errors.addressStreet}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            id="addressNumber"
            name="addressNumber"
            label="Número de calle"
            variant="outlined"
            value={controls.addressNumber ?? ''}
            onChange={onChange}
            error={!!errors.addressNumber}
            helperText={errors.addressNumber}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            id="addressDetail"
            name="addressDetail"
            label="Depto, Casa u Oficina"
            variant="outlined"
            value={controls.addressDetail ?? ''}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={5} align="center">
          <TextField
            fullWidth
            disabled
            id="email"
            name="email"
            label="Correo electrónico"
            variant="outlined"
            value={user?.email ?? ''}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          container
          alignItems="center"
          className={classes.textAlign}
        >
          <IconButton edge="start" onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            id="btn-complete-user"
            style={{ position: 'relative', margin: 'auto' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
