import Login from 'screens/Login';
import RecoveryPassword from 'screens/RecoveryPassword';
import Home from 'screens/Home';
import BikeDetail from 'screens/BikeDetail';
import Admin from 'screens/Admin';
import Register from 'screens/Register';
import Profile from 'screens/Profile';
import Store from 'screens/Store';
import Bike from 'screens/Bike';
import Transfer from 'screens/Transfer';
import TransferPreview from 'screens/TransferPreview';
import TransferSalesMethod from 'screens/TransferSalesMethod';
import Report from 'screens/Report';
import Search from 'screens/Search';
import Benefit from 'screens/Benefit';
import Payment from 'screens/Payment';
import Contract from 'screens/Contract';
import Activate from 'screens/Activate';
import Maintenance from 'screens/Maintenance';
import Ambassador from 'screens/Ambassador';
import CancelBikeRegistration from 'screens/CancelBikeRegistration';
import Subscription from 'screens/Subscription';
import AdminSale from 'screens/AdminSale';
import Insurance from 'screens/Insurance';
import PilotOfferBike from 'screens/PilotOfferBike';
import StoreDownloadBikeData from 'screens/StoreDownloadBikeData';
import BuyYourBike from 'screens/BuyYourBike';
import PurchasePreview from 'screens/PurchasePreview';
import BikfyPolicy from 'screens/bikfylabPolicy';
import EnablePaymentScreen from '../screens/EnablePayment/EnablePaymentScreen';
import BankDataScreen from '../screens/BankData/BankDataScreen';
import BuyerPayMethodScreen from '../screens/BuyerPayMethod/BuyerPayMethodScreen';
import AdminPay from '../screens/AdminPay/AdminPay';
import RedeemCoupon from '../screens/RedeemCoupon';

const insurableFilter = 4;
export const PATHS = {
  LOGIN: '/login',
  RECOVERY_PASSWORD: '/recovery-password',
  UPDATE_PASSWORD: '/update-password',
  REGISTER: '/register',
  HOME: '/',
  PROFILE: '/profile',
  BIKE_REGISTER: '/register-bike',
  BIKE_DETAIL: '/bike/:id',
  BIKE_TRANSFER: '/bike/:id/transfer',
  BIKE_PURCHASE: '/bike/:id/purchase',
  TRANSFER_DETAIL: '/transfer/:id',
  BIKE_REPORT: '/bike/:id/report',
  SEARCH: '/search',
  SEARCH_BIKE: '/search/:brandId/:serial',
  CLUB_BIKFY: '/club-bikfy',
  TRANSFER_SALES_METHOD: '/transfer/:id/sales-method',
  TRANSFER_CONFIRMATION: '/transfer/:id/confirmation',
  TRANSFER_CONTRACT: '/transfer/:id/contract',
  MAINTENANCE_DETAIL: '/maintenance/:id',
  TRANSFER_PAY_METHOD: '/transfer/:transferId/buyer-pay-method',
  TRANSFER_ENABLE_PAYMENT: '/transfer/:transferId/enable-payment',
  TRANSFER_BANK_DATA: '/transfer/:transferId/bank-data',
  MARKETPLACE_DETAIL: '/publish-bike/:vehicleType/:bikeString/:transferId',
  USER_ACTIVATION: '/activation',
  ADMIN_PAY: '/admin-pay/:state',
  CANCEL_BIKE_REGISTRATION: '/transfer/delete-record',
  INSURABLE: `/home?filter=${insurableFilter}`,
  INSURANCE: '/bike/:transferId/insurance',
  OFFER_FOR_BIKE: '/offer-for-bike',
  BUY_YOUR_BIKE: '/buy-your-bike',
  STORE_DOWNLOAD_BIKE_DATA: '/download-store-data',
  STORE_BANK_DATA: '/store-bank-data',
  SUBSCRIPTION: '/subscription/:subscriptionId',
  REDEEM_COUPON: '/redeem-coupon',
  COUPONS: '/coupons',
  PILOT: '/home',
  STORE: '/home',
  STORE_BIKE_REGISTER: '/pre-register',
  AMBASSADOR: '/home',
  ADMIN: '/home',
  BIKFY_POLICY: '/bikfylab-policy',
  NOT_FOUND: '*',

  // TODO: estas rutas no estan en ningun rol
  PAYMENTS_IMPORT: '/admin-pay/import',
  PAYMENTS_EXPORT: '/admin-pay/export',
  IMPORT_SALES: '/sales/import',
  MARKETPLACE: '/publish-bike',
};

const Paths = {
  public: {
    [PATHS.HOME]: { component: Home },
    [PATHS.LOGIN]: { component: Login, withLayout: false },
    [PATHS.RECOVERY_PASSWORD]: { component: RecoveryPassword, withLayout: false },
    [PATHS.UPDATE_PASSWORD]: { component: RecoveryPassword, withLayout: false },
    [PATHS.REGISTER]: { component: Register, withLayout: false },
    [PATHS.MARKETPLACE_DETAIL]: { component: BikeDetail },
    [PATHS.MARKETPLACE]: { component: Home },
    [PATHS.BIKFY_POLICY]: { component: BikfyPolicy },

    [PATHS.CANCEL_BIKE_REGISTRATION]: { component: CancelBikeRegistration },
    [PATHS.OFFER_FOR_BIKE]: { component: PilotOfferBike },
    [PATHS.BUY_YOUR_BIKE]: { component: BuyYourBike },
    // Disabled for production
    [PATHS.INSURANCE]: { component: Insurance },
    // always keep the last one
    [PATHS.NOT_FOUND]: { component: Login, withLayout: false },

  },
  pilot: {
    [PATHS.PILOT]: { component: Home },
    [PATHS.PROFILE]: { component: Profile },
    [PATHS.BIKE_REGISTER]: { component: Bike },
    [PATHS.BIKE_DETAIL]: { component: Bike },
    [PATHS.BIKE_TRANSFER]: { component: Transfer },
    [PATHS.TRANSFER_DETAIL]: { component: TransferPreview },
    [PATHS.BIKE_REPORT]: { component: Report },
    [PATHS.SEARCH]: { component: Search },
    [PATHS.SEARCH_BIKE]: { component: Search },
    [PATHS.CLUB_BIKFY]: { component: Benefit },
    [PATHS.TRANSFER_SALES_METHOD]: { component: TransferSalesMethod },
    [PATHS.TRANSFER_CONFIRMATION]: { component: Payment },
    [PATHS.TRANSFER_CONTRACT]: { component: Contract },
    [PATHS.MAINTENANCE_DETAIL]: { component: Maintenance },
    [PATHS.TRANSFER_PAY_METHOD]: { component: BuyerPayMethodScreen },
    [PATHS.TRANSFER_ENABLE_PAYMENT]: { component: EnablePaymentScreen },
    [PATHS.TRANSFER_BANK_DATA]: { component: BankDataScreen },
    [PATHS.USER_ACTIVATION]: { component: Activate },
    [PATHS.OFFER_FOR_BIKE]: { component: PilotOfferBike },
    [PATHS.SUBSCRIPTION]: { component: Subscription },
    [PATHS.COUPONS]: { component: RedeemCoupon },
    [PATHS.BIKE_PURCHASE]: { component: PurchasePreview },
  },
  store: {
    [PATHS.STORE]: { component: Home },
    [PATHS.STORE_BIKE_REGISTER]: { component: Store },
    [PATHS.MAINTENANCE_DETAIL]: { component: Maintenance },
    [PATHS.BIKE_DETAIL]: { component: Bike },
    [PATHS.SEARCH]: { component: Search },
    [PATHS.SEARCH_BIKE]: { component: Search },
    [PATHS.STORE_DOWNLOAD_BIKE_DATA]: { component: StoreDownloadBikeData },
    [PATHS.STORE_BANK_DATA]: { component: BankDataScreen },
    [PATHS.BIKE_TRANSFER]: { component: Transfer },
    [PATHS.TRANSFER_DETAIL]: { component: TransferPreview },
    [PATHS.TRANSFER_SALES_METHOD]: { component: TransferSalesMethod },
    [PATHS.TRANSFER_CONFIRMATION]: { component: Payment },
    [PATHS.TRANSFER_CONTRACT]: { component: Contract },
    [PATHS.TRANSFER_ENABLE_PAYMENT]: { component: EnablePaymentScreen },
    [PATHS.TRANSFER_BANK_DATA]: { component: BankDataScreen },
    [PATHS.REDEEM_COUPON]: { component: RedeemCoupon },

  },
  ambassador: {
    [PATHS.AMBASSADOR]: { component: Ambassador },
  },
  admin: {
    [PATHS.ADMIN]: { component: Admin },
    [PATHS.SEARCH]: { component: Search },
    [PATHS.SEARCH_BIKE]: { component: Search },
    [PATHS.TRANSFER_CONTRACT]: { component: Contract },
    [PATHS.ADMIN_PAY]: { component: AdminPay },
    [PATHS.IMPORT_SALES]: { component: AdminSale },
    [PATHS.COUPONS]: { component: RedeemCoupon },

  },
};

export default Paths;
