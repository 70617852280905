import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {
  Grid, Typography, Card, CardContent, CardMedia, makeStyles, Box,
} from '@material-ui/core';
import imageReview from '../../../../commons/assets/reviews/review1.png';

const useStyles = makeStyles((theme) => ({
  carousel: {
    marginTop: theme.spacing(4),
  },
  card: {
    maxWidth: 345,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  media: {
    height: 140,
  },
  reviewText: {
    marginTop: theme.spacing(2),
  },
}));

const reviews = [
  {
    image: 'path/to/image1.jpg',
    title: 'Review Title 1',
    text: 'This is the review text for review 01.',
  },
  {
    image: 'path/to/image2.jpg',
    title: 'Review Title 2',
    text: 'This is the review text for review 02.',
  },
  {
    image: 'path/to/image3.jpg',
    title: 'Review Title 3',
    text: 'This is the review text for review 03.',
  },
  {
    image: 'path/to/image4.jpg',
    title: 'Review Title 4',
    text: 'This is the review text for review 04.',
  },
  {
    image: 'path/to/image5.jpg',
    title: 'Review Title 5',
    text: 'This is the review text for review 05.',
  },
  {
    image: 'path/to/image6.jpg',
    title: 'Review Title 6',
    text: 'This is the review text for review 06.',
  },
];

const ReviewsCarousel = ({ isMobileScreen }) => {
  const classes = useStyles();

  const chunk = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const chunkSize = isMobileScreen ? 1 : 4;
  const chunkedReviews = chunk(reviews, chunkSize);

  return (
    <div className={classes.carousel}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        align="center"
        style={{
          fontWeight: '500',
          fontSize: '30px',
          paddingBottom: '12px',
          color: '#303E48',
        }}
      >
        Reseñas
      </Typography>
      <Carousel>
        {chunkedReviews.map((chunk, chunkIndex) => (
          <Grid container spacing={2} key={chunkIndex}>
            {chunk.map((review, reviewIndex) => (
              <Grid item xs={12} sm={isMobileScreen ? 12 : 6} md={isMobileScreen ? 12 : 3} key={reviewIndex}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image={imageReview}
                    title={review.title}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {review.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.reviewText}>
                      {review.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Carousel>
    </div>
  );
};

export default ReviewsCarousel;
