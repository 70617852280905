import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const ReportForm = ({
  changeControls, controls, onSubmit, errors, profileComplete,
}) => {
  const defaultValue = 'Soy propietario y me han robado la Bike.';

  const handleChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;

    changeControls({
      target: {
        name: 'description',
        value: value === 'defaultOption' ? defaultValue : '',
      },
    });

    changeControls({ target: { name, value } });
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
          <FormControl component="fieldset">
            <FormLabel focused={false} component="legend" style={{ marginBottom: '10px' }}>
              Cuéntanos el motivo de tu
              denuncia
            </FormLabel>
            <RadioGroup name="radioOption" value={controls.radioOption} onChange={handleChange}>
              <FormControlLabel
                value="defaultOption"
                control={<Radio disabled={!profileComplete} />}
                label={defaultValue}
              />
              <FormControlLabel
                value="otherOption"
                control={<Radio disabled={!profileComplete} />}
                label={(
                  <TextField
                    disabled={controls.radioOption !== 'otherOption'}
                    name="description"
                    variant="outlined"
                    label="Otra razón"
                    color="secondary"
                    value={controls.description === defaultValue ? '' : controls.description}
                    onChange={changeControls}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                )}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Button
            disabled={!profileComplete}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Denunciar
          </Button>
        </Grid>

      </Grid>
    </form>
  );
};

export default ReportForm;
