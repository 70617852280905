import colors from "utils/colors";

const styles = (theme) => ({
  root: {
    minHeight: 200,
    width: '100%',
    backgroundColor: colors.secondary,
    color: colors.white,
    '& p': {
      marginBottom: theme.spacing(1),
    },
    '& h2': {
      marginBottom: theme.spacing(2),
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  logo: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

export default styles;
