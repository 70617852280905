import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Typography,
  Grid,
  Box,
  Button,
  Paper,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  CircularProgress,
} from '@material-ui/core';

const UserVehicleInterestsModal = (props) => {
  const {
    vehicleTypes,
    classes,
    onVehicleTypeChange,
    vehicleTypesSelected,
    handleUpdateUserVehicleInterests,
    openModal,
    loading,
  } = props;

  const handleGenerateTransfer = (e) => {
    e.preventDefault();
    generateTransfer({ id, ...formControl });
  };

  return (
    <Dialog open={openModal} fullWidth maxWidth="md">
      <DialogContent style={{ textAlign: 'center' }} className={classes.paperContainer}>
        <Grid className={classes.generalContainer}>
          <Box className="title-container">
            <Typography className="title">🎉 ¡BIENVENIDO! 🎉</Typography>
          </Box>
          <Box className="message-container">
            <Typography align="center" className="paragraph">
              Ahora eres parte de nuestra comunidad, te invitamos a registrar gratis tu Bike para
              acceder a todas nuestras funciones y beneficios
            </Typography>
          </Box>
          <Box className="message-container">
            <Typography className="paragraph" align="center">
              Queremos conocerte más, cuéntanos qué tipo de Bikes te gustan 🤩
            </Typography>
          </Box>
          <Grid container justifyContent="center" className={classes.checkBoxesContainer}>
            <FormGroup column>
              {vehicleTypes?.map((vt) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={vehicleTypesSelected?.includes(vt.value)}
                      onChange={() => onVehicleTypeChange(vt)}
                      name={vt.label}
                      style={{ color: '#45C6D9' }}
                    />
                  )}
                  label={vt.label}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            disabled={!vehicleTypesSelected?.length}
            className={classes.buttonContainer}
            onClick={handleUpdateUserVehicleInterests}
            id="btn-continue-interests"
          >
            {loading ? <CircularProgress size={24} /> : 'Continuar'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserVehicleInterestsModal;
