const styles = (theme) => ({
  root: {
    marginBottom: 0,
    width: '100%',
    overflow: 'hidden',
  },
  item: {
    display: 'flex',
    position: 'relative',
    alignItems: 'start',
  },
  img: {
    height: 600,
    width: '100%',
    objectFit: 'cover',
  },
  txt: {
    position: 'absolute',
    paddingLeft: '3rem',
  },
  bannerImageBackground: {
    display: 'flex',
    position: 'relative',
    alignItems: 'start',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '600px',
    maxHeight: '600px',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: '600px',
    },
    [theme.breakpoints.down('md')]: {
      height: '500px',
    },
    [theme.breakpoints.between(0, 390)]: {
      height: '550px',
    },
  },
  bannerTitleText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '3.9rem',
    marginTop: '110px',
    marginLeft: '10px',
    marginRight: '10px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    lineHeight: 1.2,
    [theme.breakpoints.between(1480, 3860)]: {
      fontSize: '3.4remm',
    },
    [theme.breakpoints.between(1200, 1480)]: {
      fontSize: '3.9rem',
      marginTop: '120px',
    },
    [theme.breakpoints.between(763, 1200)]: {
      fontSize: '2.9rem',
      marginTop: '70px',
    },
    [theme.breakpoints.between(568, 763)]: {
      fontSize: '2.9rem',
      marginTop: '50px',
    },
    [theme.breakpoints.between(0, 568)]: {
      fontSize: '1.5rem',
      marginTop: '52px',
      marginLeft: '0px',
      marginRight: '0px',
      marginBottom: '39px',
      lineHeight: 1.2,
    },
  },
  bannerSubtitleText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '2.6rem',
    marginTop: '30px',
    marginBottom: '16px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.7rem',
      marginTop: '15px',
    },
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '1.4rem',
      marginTop: '5px',
      marginLeft: '5px',
      marginRight: '5px',
      marginBottom: '10px',
      lineHeight: 1.1,
    },
  },
  bannerThirdTitleText: {
    color: 'white',
    fontSize: '2rem',
    paddingRight: '10vw',
    paddingLeft: '10vw',
    marginBottom: '25px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    lineHeight: 0.9,
    [theme.breakpoints.between(1480, 3860)]: {
      paddingRight: '345px',
      paddingLeft: '345px',
    },
    [theme.breakpoints.between(1280, 1480)]: {
      paddingRight: '285px',
      paddingLeft: '285px',
    },
    [theme.breakpoints.between(996, 1280)]: {
      paddingRight: '255px',
      paddingLeft: '255px',
      fontSize: '1.6rem',
    },
    [theme.breakpoints.between(600, 996)]: {
      paddingRight: '10vw',
      paddingLeft: '10vw',
      fontSize: '1.4rem',
      marginBottom: '40px',
    },
    [theme.breakpoints.between(390, 600)]: {
      paddingRight: '20px',
      paddingLeft: '20px',
      fontSize: '1.1rem',
      marginBottom: '15px',
      marginTop: '10px',
    },
    [theme.breakpoints.between(0, 390)]: {
      paddingRight: '20px',
      paddingLeft: '20px',
      fontSize: '1.4rem',
      marginBottom: '0px',
      marginTop: '10px',
    },
  },
  bannerRegisterButton: {
    width: '49px',
    height: '30px',
    marginRight: '5px',
    marginLeft: '10px',
    [theme.breakpoints.between(0, 600)]: {
      width: '44px',
      height: '27px',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  bannerRegisterButtonMotorbike: {
    width: '54px',
    height: '35px',
    marginRight: '10px',
    marginLeft: '5px',
    [theme.breakpoints.between(0, 600)]: {
      width: '44px',
      height: '30px',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  bannerRegisterButtonText: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '0.8rem',
    },
  },
  usersCountText: {
    color: 'white',
    fontSize: '2.1rem',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.between(600, 750)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.between(0, 600)]: {
      fontSize: '1.2rem',
    },
  },
  usersCountContainer: {
    // backgroundColor: '#45C6D8',
    backgroundColor: '#303E48',

    height: '80px',
  },
});

export default styles;
