import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Typography } from '@material-ui/core';
import MaintenancesTable from '../../../../commons/components/MaintenancesTable';

const WorkshopBook = (props) => {
  const { maintenances, handleGetFile, closeMaintenance, showGobackButton } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" component="h6" align="center">
          Listado de mantenciones de la Bike
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <MaintenancesTable viewActions={false} data={maintenances} handleGetFile={handleGetFile} />
      </Grid>
      {showGobackButton && (
        <Grid item xs={12} sm={12} align="center">
          <Button type="submit" variant="outlined" onClick={() => closeMaintenance()}>
            Volver
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default WorkshopBook;
