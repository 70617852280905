import React from 'react';
import { Box, CircularProgress, Link, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

const BenefitsTable = ({ data = [], loading }) => {
  const columns = [
    {
      field: 'brandName',
      headerName: 'Marca',
      filterable: false,
      sortable: false,
      width: 160,
      renderCell: (params) => (params.row.storeBrand?.path
        ? (
          <Link href={params.row.storeBrand.path || '#'} target="_blank">
            {params.row.storeBrand?.name || ''}
          </Link>
        )
        : <span>{params.row.storeBrand?.name || ''}</span>),
    },
    {
      field: 'category',
      headerName: 'Categoría',
      filterable: false,
      sortable: false,
      width: 160,
      renderCell: (params) => {
        if (params.row.category?.length > 20) {
          return (
            <Tooltip title={params.row.category} placement="top" arrow>
              <span>{`${params.row.category.slice(0, 20)}...`}</span>
            </Tooltip>
          );
        }
        return <span>{params.row.category || ''}</span>;
      },
    },
    {
      field: 'discount',
      headerName: 'Beneficio CB',
      filterable: false,
      sortable: false,
      width: 160,
      align: 'center',
      renderCell: (params) => {
        if (params.row.discount?.length > 20) {
          return (
            <Tooltip title={params.row.discount} placement="top" arrow>
              <span>{`${params.row.discount.slice(0, 20)}...`}</span>
            </Tooltip>
          );
        }
        return <span>{params.row.discount || '0'}</span>;
      },
    },
    {
      field: 'online',
      headerName: 'Web',
      filterable: false,
      sortable: false,
      width: 160,
      align: 'center',
      renderCell: (params) => {
        if (params.row.online?.length > 20) {
          return (
            <Tooltip title={params.row.online} placement="top" arrow>
              <span>{`${params.row.online.slice(0, 20)}...`}</span>
            </Tooltip>
          );
        }
        return <span>{params.row.online || '-'}</span>;
      },
    },
    {
      field: 'onSite',
      headerName: 'Presencial',
      filterable: false,
      sortable: false,
      width: 160,
      align: 'center',
      renderCell: (params) => {
        if (params.row.onSite?.length > 20) {
          return (
            <Tooltip title={params.row.onSite} placement="top" arrow>
              <span>{`${params.row.onSite.slice(0, 20)}...`}</span>
            </Tooltip>
          );
        }
        return <span>{params.row.onSite || '-'}</span>;
      },
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        disableMultipleSelection
        // pageSize={30}
        columns={columns}
        rows={data}
        loading={loading}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              No cuentas con beneficios
            </div>
          ),
        }}
      />
    </div>
  );
};

export default BenefitsTable;
