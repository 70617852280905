import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';

import { TOTAL_SELLER_FLOW_STEPS } from 'utils/consts';
import logoBikfyPay from 'commons/assets/bikfypay-logo.png';
import SimpleStepper from 'commons/components/SimpleStepper';
import { composedComponent } from '../../utils/functions';
import { salesMethodActions } from './reducer';
import SalesMethodSaga from './saga/index';
import SalesMethodCard from './components/SalesMethodCard';

const BIKFY_PAYMENT_ID = 1;
const EXTERNAL_PAYMENT_ID = 2;

const salesMethods = [
  {
    id: 1,
    logo: <img src={logoBikfyPay} alt="bikfy-logo" height="100%" width="100%" />,
    detail: 'Obtén tu dinero de forma segura y ofrécele facilidades a tu comprador',
    items: [
      {
        id: 1,
        title: 'Transferencia Legal',
        description: 'Transfiere la propiedad y titularidad de tu Bike a su nuevo dueño.',
      },
      {
        id: 2,
        title: 'Tarjeta de Crédito y Débito',
        description:
          'Ofrece a tu comprador el uso de tarjetas bancarias y cuotas sin interés en el pago de la Bike.',
      },
      // {
      //   id: 3,
      //   title: 'Transferencia Bancaria',
      //   description: 'Ofrece a tu comprador pagar con transferencia bancaria.',
      // },
      {
        id: 4,
        title: 'Pago Seguro',
        description:
          'Intermediamos el pago para facilitarte la venta con despachos y protegerte de fraudes.',
      },
    ],
  },
  {
    id: 2,
    logo: (
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          EXTERNO
        </Typography>
      </Grid>
    ),
    detail: 'Coordina el pago de tu Bike directamente con el comprador',
    items: [
      {
        id: 1,
        title: 'Transferencia Legal',
        description: 'Transfiere la propiedad y titularidad de tu Bike a su nuevo dueño.',
      },
    ],
  },
];

const SpecialText = () => (
  <Typography variant="h6" component="span" color="primary" style={{ fontWeight: '700' }}>
    método de venta
  </Typography>
);

const cardStyles = {
  display: 'flex',
  boxShadow: '0px 0px 6px 5px rgba(69,198,216,0.50)',
  WebkitBoxShadow: ' 0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
  MozBoxShadow: '0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
  order: '1px solid rgba(0,0,0,0.1)',
};

const TransferSalesMethod = ({ actions, salesMethod, objects }) => {
  const [card, setCard] = useState(null);

  const { id: transferId } = useParams();
  const history = useHistory();

  const { resetState, updatePaymentMethod, checkIfBikeWasAlreadySold } = actions;
  const { redirect, bikeAlreadySold, loaders } = salesMethod;
  const [filteredSalesMethods, setFilteredSalesMethods] = useState([]);

  useEffect(() => {
    checkIfBikeWasAlreadySold({ transferId });
  }, []);

  useEffect(() => {
    if (bikeAlreadySold) {
      setFilteredSalesMethods(salesMethods.filter((item) => item.id !== BIKFY_PAYMENT_ID));
    } else {
      setFilteredSalesMethods(salesMethods);
    }
  }, [bikeAlreadySold]);

  useEffect(() => {
    if (redirect) {
      history.push(`/transfer/${transferId}`);
    }
    return () => {
      resetState();
    };
  }, [redirect]);

  const handleCardClick = (methodId) => setCard(methodId);

  const handleClick = () => {
    if (card === BIKFY_PAYMENT_ID) history.push(`/transfer/${transferId}/enable-payment`);
    if (card === EXTERNAL_PAYMENT_ID) updatePaymentMethod({ transferId, enableBikfyPay: false });
  };

  const handleClickGoBackButton = () => history.goBack();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={11} lg={10}>
        <Card>
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Grid container>
              <SimpleStepper activeStep={2} stepTotal={TOTAL_SELLER_FLOW_STEPS} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                color="textSecondary"
                variant="h6"
                align="center"
                style={{ fontWeight: '700', padding: '30px' }}>
                Selecciona el <SpecialText /> que deseas realizar.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={filteredSalesMethods?.length === 1 ? 12 : 8}
              lg={filteredSalesMethods?.length === 1 ? 12 : 8}
              spacing={4}
              justifyContent={filteredSalesMethods?.length === 1 ? 'center' : 'space-between'}
              style={{ position: 'relative' }}>
              {!loaders.isLoading &&
                filteredSalesMethods?.length &&
                filteredSalesMethods?.map((salesMethod) => (
                  <Grid
                    key={salesMethod.id}
                    item
                    lg={6}
                    style={{ display: 'flex', marginBottom: '2rem', justifyContent: 'center' }}>
                    <SalesMethodCard
                      salesMethod={salesMethod}
                      handleClick={handleCardClick}
                      styles={card === salesMethod.id ? { ...cardStyles } : { cursor: 'pointer' }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid container item xs={12} sm={12} justifyContent="center">
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                justifyContent="space-between"
                style={{ padding: '10px' }}>
                <Button variant="contained" color="secondary" onClick={handleClickGoBackButton}>
                  Volver
                </Button>
                <Button
                  id="id_button_sales_method"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  disabled={!card}>
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

// export default TransferSalesMethod;

export default composedComponent(TransferSalesMethod, SalesMethodSaga, {
  saga: 'transferSalesMethod',
  states: ['salesMethod', 'app.objects'],
  actions: [salesMethodActions],
});
