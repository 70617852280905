import { put, spawn, takeLatest } from 'redux-saga/effects';

import * as APP from 'commons/reducer';
import enablePaymentTypes from './enablePaymentScreen.types';

import apiRequest, { post, apiSuccess } from '../../utils/api';

function* initState() {
  yield takeLatest(enablePaymentTypes.INIT_STATE, function* (action) {
    // TODO: traer los bancos de la base datos (faltar seeders *)
    // console.log('SAGA ACTIVADO');
  });
}

function* setUserPaymentMethod() {
  yield takeLatest(enablePaymentTypes.SET_USER_PAYMENT_METHOD, function* (action) {
    const {
      bankTransfer,
      creditCard,
      creditCardWithFees,
      enableBikfyPay,
      newFormValues,
      transferId,
    } = action.payload;

    const body = JSON.stringify({
      bankTransfer,
      creditCard,
      creditCardWithFees,
      enableBikfyPay,
      userBankData: newFormValues ?? newFormValues,
    });

    const response = yield apiRequest(
      `api/v1/transfers/${transferId}/user-payment-method`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      yield put({
        type: enablePaymentTypes.SET_USER_PAYMENT_METHOD_SUCCESS,
        payload: {},
      });
    } else {
      yield put({
        type: enablePaymentTypes.SET_USER_PAYMENT_METHOD_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

function* getTransaction() {
  yield takeLatest(enablePaymentTypes.GET_TRANSFER, function* (action) {
    const { transferId } = action.payload;
    const response = yield apiRequest(
      `api/v1/transfers/get-one-transfer/${transferId}`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      const {
        accountTypes,
        bankData,
        banks,
        objects: { transfers },
      } = response;
      yield put({
        type: enablePaymentTypes.GET_TRANSFER_SUCCESS,
        payload: {
          accountTypes,
          bankData,
          banks,
          transfers,
        },
      });
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put({
        type: enablePaymentTypes.GET_TRANSFER_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

export default function* enablePaymentSaga() {
  yield spawn(initState);
  yield spawn(setUserPaymentMethod);
  yield spawn(getTransaction);
}
