import React from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import imageNotBike from 'commons/assets/not-bike.svg';
import { PATHS } from 'utils/paths';

const AlertModal = ({ alert, clearAlert }) => {
  const history = useHistory();

  return (
    <Dialog open={alert} onClose={clearAlert}>
      <DialogContent style={{ textAlign: 'center' }}>
        <Grid container justifyContent="center" alignItems="center">
          <img style={{ width: '200px' }} src={imageNotBike} alt="" />
        </Grid>
        <DialogContentText>
          Para optar a los beneficios del Club BIKFY, debes tener al menos una Bike correctamente
          registrada en tu cuenta.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => history.replace(PATHS.BIKE_REGISTER)}
          color="primary"
          variant="contained">
          Registra tu bike
        </Button>
        <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
