import React from 'react';
import { MenuItem, Select, InputLabel } from '@material-ui/core';

const SelectBike = (props) => {
  const { onChange, value, style } = props;
  return (
    <>
      <InputLabel variant="standard" shrink style={{ marginTop: '10px' }}>
        Estado
      </InputLabel>
      <Select
        value={value}
        name="bikeFilter"
        label="Estado"
        style={{ ...style, width: '100%', marginRight: 0 }}
        onChange={onChange}>
        <MenuItem value={1}>Bikes Vigentes</MenuItem>
        <MenuItem value={2}>Bikes Transferidas</MenuItem>
        <MenuItem value={3}>Bikes Pendientes</MenuItem>
      </Select>
    </>
  );
};

export default SelectBike;
