import React, { useEffect } from 'react';
import { composedComponent } from 'utils/functions';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { appActions } from 'commons/reducer';
import xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import { downloadBikeDataStoreActions } from './reducer';
import styles from './styles';
import saga from './saga';

const StoreDownloadBikeData = (props) => {
  const { downloadBikeDataStore, actions, classes } = props;

  const {
    loading, error, csvData, fileName,
  } = downloadBikeDataStore;

  useEffect(() => {
    if (csvData?.length > 0) {
      const worksheet = xlsx.utils.json_to_sheet(csvData);
      const csv = `\uFEFF${xlsx.utils.sheet_to_csv(worksheet)}`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(blob, `${fileName}.csv`);
      actions.clearCsvData();
    }
  }, [csvData]);

  const handleDownloadRegisterBikesFile = () => {
    actions.downloadRegisteredBikesFile();
  };

  const handleDownloadBikesTransfersFile = () => {
    actions.downloadBikeTransfersFile();
  };
  return (
    <>
      <Card>
        <CardContent>
          <Grid container justifyContent="center">
            <Typography variant="h5">DATOS</Typography>
          </Grid>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button onClick={() => handleDownloadRegisterBikesFile()} disabled={loading}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress size={18} className={classes.marginLeft} />
                ) : (
                  <GetAppIcon />
                )}
              </ListItemIcon>
              <ListItemText primary="Datos Registros de Bicicletas agrupados" />
            </ListItem>
            <ListItem button onClick={() => handleDownloadBikesTransfersFile()} disabled={loading}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress size={18} className={classes.marginLeft} />
                ) : (
                  <GetAppIcon />
                )}
              </ListItemIcon>
              <ListItemText primary="Datos Transferencias de Bicicletas agrupados" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default composedComponent(StoreDownloadBikeData, saga, {
  saga: 'sagaStoreDownloadBikeData',
  middlewares: [withStyles(styles)],
  states: ['downloadBikeDataStore', 'app.objects', 'app.user'],
  actions: [downloadBikeDataStoreActions, appActions],
});
