import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

const SendEmailDialog = (props) => {
  const { open, toggleSetSellerEmailDialog, handleSetSellerEmail, formSellerEmailValues, user } =
    props;

  const [formValues, setFormValues] = useState({
    ...formSellerEmailValues,
    buyerName: {
      ...formSellerEmailValues.buyerName,
      value: user.name ?? '',
    },
    buyerLastName: {
      ...formSellerEmailValues.buyerLastName,
      value: user.firstLastName ?? '',
    },
    buyerEmail: {
      ...formSellerEmailValues.buyerEmail,
      value: user.email ?? '',
    },
    buyerPhone: {
      ...formSellerEmailValues.buyerPhone,
      value: user.phone ?? '',
    },
  });

  const { buyerName, buyerLastName, buyerEmail, buyerPhone, emailMessage, acceptBikfyConditions } =
    formValues;

  // this effect is to set errors on form fields
  useEffect(() => {
    if (formSellerEmailValues.errors) {
      setFormValues(formSellerEmailValues);
    }
  }, [formSellerEmailValues.errors]);

  const handleChange = (event) => {
    const { value, name, checked } = event.target;

    let newValue = value;

    if (name === 'acceptBikfyConditions') {
      newValue = checked;
    }

    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: newValue,
      },
    }));
  };

  const handleClickSendButton = (event) => {
    handleSetSellerEmail({ formSellerEmailValues: formValues });
  };

  const handleClose = (event) =>
    toggleSetSellerEmailDialog({
      open: false,
      bikeId: 0,
      transferId: 0,
    });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle style={{ textAlign: 'center' }} className="color-primary">
        ¡Envía un correo al vendedor para contactarlo!
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Nombre"
              onChange={handleChange}
              name="buyerName"
              value={buyerName.value}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Apellido"
              onChange={handleChange}
              name="buyerLastName"
              value={buyerLastName.value}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Correo"
              onChange={handleChange}
              name="buyerEmail"
              value={buyerEmail.value}
              helperText={buyerEmail.helperText}
              error={!buyerEmail.isValid}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Teléfono"
              onChange={handleChange}
              name="buyerPhone"
              value={buyerPhone.value}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              rows={6}
              onChange={handleChange}
              name="emailMessage"
              value={emailMessage.value}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptBikfyConditions.value}
                    onChange={handleChange}
                    name="acceptBikfyConditions"
                    color="primary"
                  />
                }
                label="Al enviar este mensaje estoy aceptando las Políticas de Privacidad y los Términos y Condiciones de BIKFY."
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item />
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={handleClickSendButton}
              disabled={emailMessage.value === "" || !acceptBikfyConditions.value}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

SendEmailDialog.propTypes = {
  open: PropTypes.bool,
  handleSetSellerEmail: PropTypes.func.isRequired,
  toggleSetSellerEmailDialog: PropTypes.func.isRequired,
  formSellerEmailValues: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}),
};

SendEmailDialog.defaultProps = {
  open: false,
  user: {},
};

export default SendEmailDialog;
