import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'transferPreview/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const TRANSFERPREVIEW = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_TRANSFER_PREVIEW: `${PATH}FETCH_TRANSFER_PREVIEW`,
  FETCH_TRANSFER_PREVIEW_SUCCESS: `${PATH}FETCH_TRANSFER_PREVIEW_SUCCESS`,
  FETCH_TRANSFER_PREVIEW_ERROR: `${PATH}FETCH_TRANSFER_PREVIEW_ERROR`,
  ACCEPT_TRANSFER_SELLER: `${PATH}ACCEPT_TRANSFER_SELLER`,
  ACCEPT_TRANSFER_SELLER_SUCCESS: `${PATH}ACCEPT_TRANSFER_SELLER_SUCCESS`,
  ACCEPT_TRANSFER_SELLER_ERROR: `${PATH}ACCEPT_TRANSFER_SELLER_ERROR`,
  ACCEPT_TRANSFER_BUYER: `${PATH}ACCEPT_TRANSFER_BUYER`,
  ACCEPT_TRANSFER_BUYER_SUCCESS: `${PATH}ACCEPT_TRANSFER_BUYER_SUCCESS`,
  ACCEPT_TRANSFER_BUYER_ERROR: `${PATH}ACCEPT_TRANSFER_BUYER_ERROR`,
  GENERATE_PAYMENT: `${PATH}GENERATE_PAYMENT`,
  GENERATE_PAYMENT_SUCCESS: `${PATH}GENERATE_PAYMENT_SUCCESS`,
  GENERATE_PAYMENT_ERROR: `${PATH}GENERATE_PAYMENT_ERROR`,
  DELETE_TRANSFER: `${PATH}DELETE_TRANSFER`,
  DELETE_TRANSFER_SUCCESS: `${PATH}DELETE_TRANSFER_SUCCESS`,
  DELETE_TRANSFER_ERROR: `${PATH}DELETE_TRANSFER_ERROR`,
  CLEAR_ALERT_DENY: `${PATH}CLEAR_ALERT_DENY`,
  OPEN_ALERT_DENY: `${PATH}OPEN_ALERT_DENY`,
  OPEN_MODAL: `${PATH}OPEN_MODAL`,
  CLEAR_MODAL: `${PATH}CLEAR_MODAL`,
  CREATE_TRANSFER_PDF: `${PATH}CREATE_TRANSFER_PDF`,
  CREATE_TRANSFER_PDF_SUCCESS: `${PATH}CREATE_TRANSFER_PDF_SUCCESS`,
  CREATE_TRANSFER_PDF_FAILURE: `${PATH}CREATE_TRANSFER_PDF_FAILURE`,

  GET_TRANSFER: `${PATH}GET_TRANSFER`,
  GET_TRANSFER_SUCCESS: `${PATH}GET_TRANSFER_SUCCESS`,
  GET_TRANSFER_FAILURE: `${PATH}GET_TRANSFER_FAILURE`,

  CREATE_PAYMENT: `${PATH}CREATE_PAYMENT`,
  CREATE_PAYMENT_SUCCESS: `${PATH}CREATE_PAYMENT_SUCCESS`,
  CREATE_PAYMENT_FAILURE: `${PATH}CREATE_PAYMENT_FAILURE`,

  CONFIRM_STORE_TRANSFER: `${PATH}CONFIRM_STORE_TRANSFER`,
  CONFIRM_STORE_TRANSFER_SUCCESS: `${PATH}CONFIRM_STORE_TRANSFER_SUCCESS`,
  CONFIRM_STORE_TRANSFER_FAILURE: `${PATH}CONFIRM_STORE_TRANSFER_FAILURE`,

  SET_PAYMENT_TRANSFER_INFO: `${PATH}SET_PAYMENT_TRANSFER_INFO`,
  CREATE_MERCADO_PAGO_PAYMENT: `${PATH}CREATE_MERCADO_PAGO_PAYMENT`,
  VALIDATE_MERCADO_PAGO_PAYMENT: `${PATH}VALIDATE_MERCADO_PAGO_PAYMENT`,
  VALIDATE_MERCADO_PAGO_PAYMENT_SUCCESS: `${PATH}VALIDATE_MERCADO_PAGO_PAYMENT_SUCCESS`,
  SET_PAYMENT_METHOD: `${PATH}SET_PAYMENT_METHOD`,
  VALIDATE_VERIFICATION_CODE: `${PATH}VALIDATE_VERIFICATION_CODE`,
  GENERATE_NEW_CONFIRMATION_CODE: `${PATH}GENERATE_NEW_CONFIRMATION_CODE`,
  GENERATE_NEW_CONFIRMATION_CODE_SUCCESS: `${PATH}GENERATE_NEW_CONFIRMATION_CODE_SUCCESS`,
  OPERATION_FAILURE: `${PATH}OPERATION_FAILURE`,
  CLEAR_ERROR: `${PATH}CLEAR_ERROR`,
};

export const transferPreviewActions = {
  fetchTransferPreview: createAction(TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW, 'payload'),
  acceptTransferSeller: createAction(TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER, 'payload'),
  acceptTransferBuyer: createAction(TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER, 'payload'),
  generatePayment: createAction(TRANSFERPREVIEW.GENERATE_PAYMENT, 'payload'),
  deleteTransfer: createAction(TRANSFERPREVIEW.DELETE_TRANSFER, 'payload'),
  resetControls: createAction(TRANSFERPREVIEW.RESET_CONTROLS),
  clearAlert: createAction(TRANSFERPREVIEW.CLEAR_ALERT_DENY),
  openAlert: createAction(TRANSFERPREVIEW.OPEN_ALERT_DENY),
  clearModal: createAction(TRANSFERPREVIEW.CLEAR_MODAL),
  openModal: createAction(TRANSFERPREVIEW.OPEN_MODAL),
  createTransferPDF: createAction(TRANSFERPREVIEW.CREATE_TRANSFER_PDF, 'payload'),
  getTransfer: createAction(TRANSFERPREVIEW.GET_TRANSFER, 'payload'),
  createPayment: createAction(TRANSFERPREVIEW.CREATE_PAYMENT, 'payload'),
  setPaymentTransferInfo: createAction(TRANSFERPREVIEW.SET_PAYMENT_TRANSFER_INFO, 'payload'),
  createMercadoPagoPayment: createAction(TRANSFERPREVIEW.CREATE_MERCADO_PAGO_PAYMENT, 'payload'),
  setPaymentMethod: createAction(TRANSFERPREVIEW.SET_PAYMENT_METHOD, 'payload'),
  validateVerificationCode: createAction(TRANSFERPREVIEW.VALIDATE_VERIFICATION_CODE, 'payload'),
  generateNewVerificationCode: createAction(
    TRANSFERPREVIEW.GENERATE_NEW_CONFIRMATION_CODE,
    'payload',
  ),
  clearError: createAction(TRANSFERPREVIEW.CLEAR_ERROR),
  confirmExternalPaymentToStore: createAction(TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER, 'payload'),
};

const initialState = {
  formControls: {
    transferPreview: '',
  },
  flow: {
    url: '',
    token: '',
  },
  loading: false,
  error: false,
  errorMessage: '',
  message: '',
  successSeller: false,
  successBuyer: false,
  successDeleteTransfer: false,
  successNewConfirmationCode: false,
  alertDeny: false,
  documentModal: false,
  fileId: 0,
  transbank: {
    url: '',
    token: '',
  },
  paymentTransferInfo: {
    bikeInfo: {
      brand: '',
      model: '',
      serial: '',
    },
    bikfyPay: false,
  },
  paymentMethod: '',
  code: '',
};

const transferPreview = createReducer(initialState, {
  [TRANSFERPREVIEW.CLEAR_ERROR](state, action) {
    return {
      ...state,
      error: false,
      errorMessage: '',
      message: '',
      successNewConfirmationCode: false,
    };
  },
  [TRANSFERPREVIEW.RESET_CONTROLS](state) {
    return {
      ...state,
      formControls: {
        ...state.formControls,
        ...initialState.formControls,
      },
      flow: {
        ...state.flow,
        ...initialState.flow,
      },
      error: false,
      successSeller: false,
      successBuyer: false,
      alertDeny: false,
      successDeleteTransfer: false,
      documentModal: false,
      fileId: 0,
      paymentMethod: '',
    };
  },
  [TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.formControls.transferPreview = action.payload.transfer;
  },
  [TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.formControls.transferPreview = action.payload.transfer;
  },
  [TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER](state) {
    state.loading = true;
    state.error = false;
    state.successSeller = false;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.successSeller = true;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.successSeller = false;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER](state) {
    state.loading = true;
    state.error = false;
    state.successBuyer = false;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.successBuyer = true;
  },
  [TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.successBuyer = false;
  },
  [TRANSFERPREVIEW.GENERATE_PAYMENT](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFERPREVIEW.GENERATE_PAYMENT_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.flow = { ...action.payload };
  },
  [TRANSFERPREVIEW.GENERATE_PAYMENT_ERROR](state, action) {
    state.loading = false;
    state.error = true;
  },
  [TRANSFERPREVIEW.DELETE_TRANSFER](state) {
    state.loading = true;
    state.error = false;
  },
  [TRANSFERPREVIEW.DELETE_TRANSFER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.successDeleteTransfer = true;
  },
  [TRANSFERPREVIEW.DELETE_TRANSFER_ERROR](state) {
    state.loading = false;
    state.error = false;
  },
  [TRANSFERPREVIEW.OPEN_ALERT_DENY](state) {
    state.alertDeny = true;
  },
  [TRANSFERPREVIEW.CLEAR_ALERT_DENY](state) {
    state.alertDeny = false;
  },
  [TRANSFERPREVIEW.OPEN_MODAL](state) {
    state.documentModal = true;
  },
  [TRANSFERPREVIEW.CLEAR_MODAL](state) {
    state.documentModal = false;
  },
  [TRANSFERPREVIEW.CREATE_TRANSFER_PDF](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [TRANSFERPREVIEW.CREATE_TRANSFER_PDF_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      fileId: action.payload.fileId,
    };
  },
  [TRANSFERPREVIEW.CREATE_TRANSFER_PDF_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      errorMessage: action.payload.errorMessage,
    };
  },
  [TRANSFERPREVIEW.GET_TRANSFER](state) {
    return {
      ...state,
      errorMessage: '',
      loading: true,
      error: false,
    };
  },
  [TRANSFERPREVIEW.GET_TRANSFER_SUCCESS](state) {
    return {
      ...state,
      errorMessage: '',
      loading: false,
      error: false,
    };
  },
  [TRANSFERPREVIEW.GET_TRANSFER_FAILURE](state, action) {
    return {
      ...state,
      errorMessage: action.payload.errorMessage,
      loading: false,
      error: true,
    };
  },
  [TRANSFERPREVIEW.CREATE_PAYMENT](state) {
    return {
      ...state,
      errorMessage: '',
      loading: true,
      error: false,
    };
  },
  [TRANSFERPREVIEW.CREATE_PAYMENT_SUCCESS](state, action) {
    return {
      ...state,
      transbank: {
        ...state.transbank,
        url: action.payload.url,
        token: action.payload.token,
      },
      loading: false,
    };
  },
  [TRANSFERPREVIEW.CREATE_PAYMENT_FAILURE](state, action) {
    return {
      ...state,
      errorMessage: action.payload.errorMessage,
      loading: false,
      error: true,
    };
  },
  [TRANSFERPREVIEW.SET_PAYMENT_TRANSFER_INFO](state, action) {
    return {
      ...state,
      paymentTransferInfo: {
        ...state.paymentTransferInfo,
        bikfyPay: action.payload.bikfyPay,
        bikeInfo: {
          ...state.paymentTransferInfo.bikeInfo,
          ...action.payload.bikeInfo,
        },
      },
    };
  },
  [TRANSFERPREVIEW.SET_PAYMENT_METHOD](state, action) {
    return {
      ...state,
      paymentMethod: action.payload,
    };
  },
  [TRANSFERPREVIEW.VALIDATE_VERIFICATION_CODE](state, action) {
    return {
      ...state,
      loading: true,
      error: false,
      errorMessage: '',
      message: '',
    };
  },
  [TRANSFERPREVIEW.GENERATE_NEW_CONFIRMATION_CODE_SUCCESS](state, action) {
    return {
      ...state,
      message: action.payload.message,
      successNewConfirmationCode: true,
    };
  },
  [TRANSFERPREVIEW.OPERATION_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      message: action.payload.message,
      error: true,
    };
  },
});

export default transferPreview;
