import React from 'react';
import {
  Button, CircularProgress, Grid, TextField,
} from '@material-ui/core';
import CustomSelect from 'commons/components/CustomSelect';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import { formatTaxNumber, serialInput } from 'utils/functions';
import CustomInputFile from 'commons/components/CustomInputFile';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import { Alert } from '@material-ui/lab';

const priceTable = [
  { DESDE: 0, HASTA: 500000, BCI_ACTUAL: [1.00, 1.48] },
  { DESDE: 500001, HASTA: 1000000, BCI_ACTUAL: [1.65, 2.21] },
  { DESDE: 1000001, HASTA: 1500000, BCI_ACTUAL: [2.35, 2.84] },
  { DESDE: 1500001, HASTA: 2000000, BCI_ACTUAL: [3.30, 3.83] },
  { DESDE: 2000001, HASTA: 2500000, BCI_ACTUAL: [4.30, 4.65] },
  { DESDE: 2500001, HASTA: 3000000, BCI_ACTUAL: [5.16, 5.72] },
  { DESDE: 3000001, HASTA: 3500000, BCI_ACTUAL: [6.02, 6.57] },
  { DESDE: 3500001, HASTA: 4000000, BCI_ACTUAL: [6.88, 7.43] },
  { DESDE: 4000001, HASTA: 4500000, BCI_ACTUAL: [7.74, 8.29] },
  { DESDE: 4500001, HASTA: 500000000, BCI_ACTUAL: [8.25, 9.15] },
];

// function getBCIActual(amount, type, uf, format = true) {
//   for (let i = 0; i < priceTable.length; i++) {
//     if (amount >= priceTable[i].DESDE && amount <= priceTable[i].HASTA) {
//       const value = priceTable[i].BCI_ACTUAL[type] * uf;
//       // If `format` is true, format the result; otherwise, return the raw number
//       return format ? new Intl.NumberFormat('es-CL').format(value) : value;
//     }
//   }
//   return null; // If no match is found
// }

// no decimals ->
function getBCIActual(amount, type, uf, format = true) {
  for (let i = 0; i < priceTable.length; i++) {
    if (amount >= priceTable[i].DESDE && amount <= priceTable[i].HASTA) {
      const value = Math.round(priceTable[i].BCI_ACTUAL[type] * uf); // Crop decimals for all cases
      // If `format` is true, format the result; otherwise, return the raw rounded number
      return format ? new Intl.NumberFormat('es-CL').format(value) : value;
    }
  }
  return null; // If no match is found
}

const BikeForm = ({
  controls = {},
  errors = {},
  onControlChange,
  onSubmit = (e) => e.preventDefault(),
  brands = [],
  motorbikeBrands = [],
  vehicleTypes = [],
  loading,
  isBicycleSelected = false,
  uf,
}) => {
  const inputs = [
    {
      name: 'vehicleTypeId',
      label: 'Tipo de vehículo',
      type: 'select',
      options: vehicleTypes,
      visible: true,
      sm: 10,
    },
    {
      name: 'email',
      label: 'E-Mail cliente',
      type: 'text',
      visible: true,
    },
    {
      name: 'taxNumber',
      label: 'RUT Cliente',
      type: 'text',
      visible: true,
      getValue: formatTaxNumber,
    },
    {
      name: 'amount',
      label: 'Valor en factura o boleta',
      type: 'number',
      visible: true,
    },
    {
      name: 'invoiceNumber',
      label: 'N° de factura o boleta',
      type: 'text',
      visible: true,
    },
    {
      name: 'serial',
      label: 'Código de serie',
      visible: isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'chassisSerial',
      label: 'Código de serie chasis',
      visible: !isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'engineSerial',
      label: 'Código de serie motor',
      visible: !isBicycleSelected,
      type: 'text',
      getValue: (s) => serialInput(s),
    },
    {
      name: 'brandId',
      label: 'Marca',
      visible: true,
      type: 'select',
      options: isBicycleSelected ? brands : motorbikeBrands,
    },
    {
      name: 'insurance',
      label: 'Seguro seleccionado',
      visible: isBicycleSelected,
      type: 'select',
      options: [
        { value: 0, label: 'Ninguno' },
        { value: 1, label: `Robo + Pérdida Total (anual) ➡️ CLP ${getBCIActual(controls.amount, 0, uf)}` },
        { value: 2, label: `Robo + Pérdida Total + Accidentes Per. (anual) ➡️ CLP ${getBCIActual(controls.amount, 1, uf)}` },
        { value: 3, label: `Robo + Pérdida Total (por/mes) ➡️ CLP ${new Intl.NumberFormat('es-CL').format(Math.round(getBCIActual(controls.amount, 0, uf, false) / 12))}` },
        { value: 4, label: `Robo + Pérdida Total + Accidentes Per. (por/mes) ➡️ CLP ${new Intl.NumberFormat('es-CL').format(Math.round(getBCIActual(controls.amount, 1, uf, false) / 12))}` },
      ],

    },
  ];
  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {controls.amount > 5000000 && (
        <Grid item xs={10}>
          <Alert severity="warning">El monto máximo a asegurar una bici es de 5000000.</Alert>
        </Grid>
        )}
        {inputs.map((i) => (i.type === 'text' && i?.visible ? (
          <Grid key={i.name} item xs={12} sm={5} align="center">
            <TextField
              name={i.name}
              label={i.label}
              variant="outlined"
              fullWidth
              value={i.getValue ? i.getValue(controls[i.name]) : controls[i.name]}
              onChange={(e) => onControlChange(e, i.getValue)}
              error={!!errors[i.name]}
              helperText={errors[i.name] && errors[i.name][0]}
            />
          </Grid>
        ) : i.type === 'number' ? (
          <Grid key={i.name} item xs={12} sm={5} align="center">
            <CustomInputNumber
              label="Valor en factura o boleta"
              name="amount"
              onChange={onControlChange}
              value={controls[i.name]}
              error={!!errors[i.name]}
              helperText={errors[i.name] && errors[i.name][0]}
            />
          </Grid>
        ) : i.type === 'select' && i?.visible ? (
          <Grid key={i.name} item xs={12} sm={i.sm ? i.sm : 5} align="center">
            <CustomAutoComplete
              name={i.name}
              label={i.label}
              value={controls[i.name]}
              onChange={onControlChange}
              options={i.options}
              error={!!errors[i.name]}
              errors={errors[i.name]}
            />
          </Grid>
        ) : i.type === 'file' ? (
          <Grid key={i.name} item xs={12} sm={12} align="center">
            <CustomSelect
              name={i.name}
              label={i.label}
              value={controls[i.name]}
              onChange={onControlChange}
              options={i.options}
              hasError={!!errors[i.name]}
              errors={errors[i.name]}
            />
          </Grid>
        ) : null))}
        <Grid item xs={12} sm={10} align="center">
          <CustomInputFile
            title="Subir foto de la factura o boleta"
            id="invoiceFile"
            name="invoiceFile"
            documentType="documentInvoice"
            onChange={onControlChange}
            value={controls.invoiceFile}
          />
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Button type="submit" variant="contained" color="primary">
            {loading ? <CircularProgress size={24} /> : 'Registrar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default BikeForm;
