import { createReducer } from '../../utils/reducer';

import userInsuranceTypes from './userInsurance.types';

const initState = {
  isErrors: false,
  errorMessage: '',
  loaders: {
    isLoading: false,
  },
};

const userInsuranceScreenReducer = createReducer(initState, {
  [userInsuranceTypes.INIT_STATE](state) {
    return {
      ...state,
      ...initState,
    };
  },
});

export default userInsuranceScreenReducer;
