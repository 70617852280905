import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'report/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const REPORT = {
  REPORT_BIKE: `${PATH}REPORT_BIKE`,
  REPORT_BIKE_SUCCESS: `${PATH}REPORT_BIKE_SUCCESS`,
  REPORT_BIKE_ERROR: `${PATH}REPORT_BIKE_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
};

// actions
export const reportActions = {
  reportBike: createAction(REPORT.REPORT_BIKE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetControls: createAction(REPORT.RESET_CONTROLS),
  clearAlert: createAction(REPORT.CLEAR_ALERT),
  openAlert: createAction(REPORT.OPEN_ALERT),

};

const initialState = {
  formControls: {
    description: 'Soy propietario y me han robado la Bike.',
    radioOption: 'defaultOption',
  },
  alert: false,
  loading: false,
  error: false,
  successReport: false,
};

const report = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [REPORT.RESET_CONTROLS](state, action) {
    state.formControls = { ...initialState.formControls };
    state.error = false;
    state.alert = false;
    state.successReport = false;
  },
  [REPORT.REPORT_BIKE](state) {
    state.loading = true;
    state.error = false;
    state.successReport = false;
  },
  [REPORT.REPORT_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.alert = false;
    state.successReport = true;
  },
  [REPORT.REPORT_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.successReport = false;
  },
  [REPORT.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [REPORT.OPEN_ALERT](state) {
    state.alert = true;
  },
});

export default report;
