import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FileViewer from 'commons/components/FileViewer';
import { Typography } from '@material-ui/core';

const DocumentModal = ({ open, clearModal, documents }) => {
  const getExtension = (value) => {
    let extension = value.split('.');
    extension = extension[extension.length - 1];
    return extension;
  };

  const filteredDocuments = documents?.find((doc) => doc.type === 'own_invoice') ?? documents?.find((doc) => doc.type === 'serial');

  return (
    <Dialog
      open={open}
      onClose={clearModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent dividers>
        {filteredDocuments && (
          <>
            <Typography component="h6" align="center">
              {filteredDocuments?.type === 'serial'
                ? 'Código de serie'
                : 'Factura o Boleta Original'}
            </Typography>
            <FileViewer
              name={filteredDocuments?.file?.name}
              extension={getExtension(filteredDocuments?.file?.name)}
              url={filteredDocuments?.file?.url}
            />
            <br />
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={clearModal} variant="contained" color="primary">
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentModal;
