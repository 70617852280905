import { makeStyles } from '@material-ui/core';
import colors from 'utils/colors';

const { secondary, primary } = colors;

const useCustomAccordionStyles = makeStyles((theme) => ({
  bikfyFontColor: {
    color: primary,
  },
  bikfyGreyColor: {
    color: secondary,
  },
  responsiveMr: {
    marginRight: '7%',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  accordion: {
    '& .accordion-summary': {
      padding: '0px 1px',
      minHeight: '34px',
      '& .icon-container': {
        marginRight: '10px',
      },
      '& .title': {
        fontWeight: '500',
        color: secondary,
      },
      '& .MuiAccordionSummary-content': {
        margin: '0px',
        '& .MuiBox-root': {
          flex: 'unset',
        },
      },
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    responsiveMr: {
      marginRight: '3%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    responsiveMr: {
      marginRight: '7%',
    },
  },
}));

export default useCustomAccordionStyles;
