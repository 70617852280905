import colors from '../../utils/colors';

const { primary, danger } = colors;

const setBikfyColor = {
  color: primary,
};

const styles = (theme) => ({
  container: {
    maxWidth: '1000px',
    margin: '10px auto',
    boxShadow: '1px 1px 7px 2px rgba(0,0,0,0.5)',
  },
  titleContainer: {
    padding: '30px',
    '& .title': {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      '& .bikfyColor': {
        ...setBikfyColor,
      },
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  payMethodCard: {
    width: '317px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: '20px',
    marginTop: '20px',
    '&.bikfy-shadow': {
      boxShadow: '1px 1px 7px 2px rgba(69,198,217,0.8)',
    },
  },
  imgContainer: {
    height: '86px',
    width: '238px',
    margin: '30px 0px',
    '& .img': {
      height: '100%',
      width: '100%',
    },
  },
  payMethodTitleContainer: {
    margin: '20px 0px 10px 0px',
    '& .payMethodTitle': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      textTransform: 'uppercase',
      '&.bikfyColor': {
        ...setBikfyColor,
      },
    },
  },
  divider: {
    width: '230px',
    backgroundColor: '#CECECE',
  },
  listContainer: {
    padding: '20px',
    '& .listItem': {
      '& .listItemIcon': {
        minWidth: '30px',
        '& .icon': {
          ...setBikfyColor,
        },
      },
      '& .listItemText': {
        height: '50px',
        '& .MuiListItemText-primary': {
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '19px',
          letterSpacing: '0.05em',
        },
      },
    },
  },
  totalPayTable: {
    margin: '20px 0px 60px 0px',
    minHeight: '117px',
    '& .tableBody': {
      '& .tableRow': {
        borderBottom: 'none',
        '& .tableCell': {
          borderBottom: 'inherit',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '19px',
          padding: '10px',
          color: 'rgba(0, 0, 0, 0.5)',
          '&:nth-child(1)': {
            color: '#000000',
          },
        },
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .button': {
      width: '274px',
      margin: '0px auto',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      color: 'white',
      '&.bikfy-button': {
        backgroundColor: primary,
      },
      '&.disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  bikfyPayIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: primary,
  },
  cancelButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px',
  },
  warningModalContainer: {
    '& .generalContentContainer': {
      padding: '20px',
      '& .title': {
        textTransform: 'uppercase',
        color: danger,
        textAlign: 'center',
        '& h2': {
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '42px',
        },
      },
      '& .list-title': {
        marginTop: '15px',
        '& .bikfy-color': {
          ...setBikfyColor,
        },
      },
      '& .list': {
        '& .bold': {
          fontWeight: 700,
        },
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    warningModalContainer: {
      '& .buttonsContainer': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .enable-bikfy-pay': {
          marginBottom: '10px',
          width: 'calc(100% - 20px)',
        },
        '& .continue-button': {
          width: 'calc(100% - 20px)',
          marginLeft: '0px',
        },
      },
    },
  },
});

export default styles;
