import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FileViewer from 'commons/components/FileViewer';
import CustomTable from 'commons/components/CustomTable';

const FileModal = ({
  open, clear, fileData, maintenance,
}) => {
  const { url, extension, name } = fileData;

  return (
    <div>
      <Dialog open={open} onClose={clear} fullWidth>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <CustomTable
                maintenance={maintenance}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FileViewer
                url={url}
                extension={extension}
                name={name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={clear} variant="contained" color="primary">Volver</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileModal;
