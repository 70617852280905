import {
  ButtonBase, Grid, Paper, Typography,
} from '@material-ui/core';
import React from 'react';
import { PATHS } from 'utils/paths';

const OfferBikePilotRedirect = (props) => {
  const { handleRedirect, classes } = props;

  return (
    <ButtonBase
      className={classes.searchBikfyPublicationsButtonBase}
      onClick={() => handleRedirect(PATHS.OFFER_FOR_BIKE)}
    >
      <Grid
        container
        item
        xs={12}
        style={{ marginBottom: '10px', marginRight: '0px', marginTop: '5px' }}
        component={Paper}
        alignItems="center"
        justifyContent="center"
        className={classes.gridButton}
        direction="column"
      >
        {' '}
        <Typography align="center" className={classes.offerBikePilotTitle}>
          ¡Encuentra tu próxima Bici Seminueva!
        </Typography>
        <Typography className={classes.offerBikePilotSubtitle}>
          {' '}
        </Typography>
      </Grid>
    </ButtonBase>
  );
};

export default OfferBikePilotRedirect;
