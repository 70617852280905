// @ts-nocheck
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// icons
import MenuIcon from '@material-ui/icons/Menu';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AddIcon from '@material-ui/icons/Add';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
// styles
import CustomMenu from 'commons/components/CustomMenu';
import {
  Box, Grid, Link, useMediaQuery, useTheme,
} from '@material-ui/core';
import { PATHS } from 'utils/paths';
import styles from './styles';
import logo from '../../assets/Logo.svg';
import isotipo from '../../assets/isotipo.svg';

const useStyles = makeStyles(styles);
const infoUrl = process.env.REACT_APP_INFO_URL;
// const webUrl = process.env.REACT_APP_WEB_URL;

const NavBar = ({
  user, logOut, toggleDrawer, handleClickBikfyLogo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const isUserPilotOrNotLogged = user?.user?.type === 'pilot' || !user?.user?.type;

  const showPublishYourBikeButton = [
    PATHS.MARKETPLACE_DETAIL.replace(':transferId', params.transferId),
    PATHS.HOME,
  ].includes(location.pathname);

  const handleRedirectToRegisterBike = () => {
    if (user?.user?.type === 'ambassador') {
      history.push(PATHS.AMBASSADOR);
      return;
    }
    if (user?.user?.type === 'store') {
      history.push(PATHS.STORE_BIKE_REGISTER);
      return;
    }
    history.push(PATHS.BIKE_REGISTER);
  };
  const { origin } = window.location;

  // TODO: refactor, change to component ?
  function sessionOption() {
    if (user) {
      return <CustomMenu logOut={logOut} user={user.user} history={history} />;
    }
    return (
      <Button
        onClick={() => history.push(`${PATHS.LOGIN}?href=${encodeURI(location.pathname)}`)}
        color="primary"
      >
        Iniciar sesión
      </Button>
    );
  }

  const menuOptions = [
    <Button
      href={`${origin}/publish-bike`}
      color="default"
      key="MARKETPLACE"
      className={classes.link}
    >
      <Typography variant="body2">MARKETPLACE</Typography>
    </Button>,
    <Button
      href={`${infoUrl}/bikfy-servicios/`}
      color="default"
      key="SERVICIOS"
      className={classes.link}
    >
      <Typography variant="body2">SERVICIOS</Typography>
    </Button>,
    <Button
      href={`${infoUrl}/club-bikfy/`}
      color="default"
      key="CLUB BIKFY"
      className={classes.link}
    >
      <Typography variant="body2">CLUB BIKFY</Typography>
    </Button>,
    <Button
      href={`${infoUrl}/faq/`}
      color="default"
      key="¿PREGUNTAS FRECUENTES?"
      className={classes.link}
    >
      <Typography variant="body2">¿PREGUNTAS?</Typography>
    </Button>,
  ];

  return (
    <>
      <AppBar position="sticky" color="inherit" style={{ flexWrap: 'nowrap' }}>
        <Toolbar>
          <IconButton
            onClick={() => toggleDrawer()}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={() => handleClickBikfyLogo()}>
            <img style={{ height: '60px' }} src={isMdScreen ? isotipo : logo} alt="logo" />
          </IconButton>

          <Typography variant="h6" className={classes.title} />

          {!isMdScreen && menuOptions}

          <Button
            id="id_button_register_your_bike"
            variant="contained"
            color="primary"
            className={classes.link}
            style={{ minWidth: '30px' }}
            onClick={() => handleRedirectToRegisterBike()}
          >
            {isXsScreen ? <AddIcon /> : (
              <>
                REGISTRA GRATIS
                {!isMdScreen && ' TU BIKE'}
              </>
            )}
          </Button>

          {showPublishYourBikeButton && (
            <Button
              variant="contained"
              color="primary"
              className={classes.link}
              style={{ minWidth: '30px' }}
              onClick={() => history.push(PATHS.PILOT)}
            >
              {isXsScreen ? <StorefrontIcon /> : (
                <>
                  PUBLICA
                  {!isMdScreen && ' TU BIKE'}
                </>
              )}
            </Button>
          )}

          {isUserPilotOrNotLogged && (
            <Button
              id="id_button_register_your_bike"
              variant="contained"
              color="primary"
              // disabled for production
              // disabled
              className={classes.insuranceButton}
              style={{ minWidth: '30px' }}
              onClick={() => history.push(PATHS.INSURABLE)}
            >
              {isXsScreen ? (
                <VerifiedUserOutlinedIcon />
              ) : (
                <>
                  ASEGURA
                  {!isMdScreen && ' TU BIKE'}
                </>
              )}
            </Button>
          )}

          {sessionOption()}
        </Toolbar>
      </AppBar>

      {isMdScreen && (
        <Grid container justifyContent="space-between" style={{ paddingTop: '.5rem' }}>
          {menuOptions.slice(1).map((option, idx) => (
            <Grid item xs={12 / menuOptions.length + 1} key={`option-${idx}`}>
              <Box display="flex" justifyContent="center">
                {option}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default NavBar;
