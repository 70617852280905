import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { PATHS } from 'utils/paths';

const BuyYourBikeForm = ({ profileComplete, className }) => {
  // State to handle modal open/close
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} container justifyContent="center">
        <Typography className={className?.primaryTitle} align="center" component="h1">
          Compramos tu
          <Typography color="primary" variant="span" className={className?.secondaryTitle} align="center">
            {' '}
            Bicicleta
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} container justifyContent="center">
        <Typography className={className?.informativeText} align="center">
          Véndenos tu bicicleta en modalidad parte de pago y renuévala por una Semi-nueva o una nueva de nuestras marcas adheridas. Solicita una simulación asegurándote de tener fotos actualizadas de tu bicicleta.
        </Typography>
      </Grid>
      {!profileComplete && (
        <Grid item xs={10} sm={10}>
          <Box mt={2}>
            <Alert severity="info">
              <Typography align="center" className="message">
                Debes
                {' '}
                <Link to={`/login?href=${PATHS.OFFER_FOR_BIKE}`}>iniciar sesión</Link>
                {' '}
                para
                poder ofertar por una Bike
              </Typography>
            </Alert>
          </Box>
        </Grid>
      )}

      {/* Button to open modal */}
      <Grid item xs={12} sm={12} container justifyContent="center" style={{ marginBottom: '10px' }}>
        <Box mt={3}>
          <Button variant="outlined" color="primary" onClick={handleOpenModal}>
            Ver Política de Retoma de Bicicletas Usadas
          </Button>
        </Box>
      </Grid>

      {/* Modal for displaying the policy */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Política de Retoma de Bicicletas Usadas</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            Con el objetivo de que nuestras bicicletas usadas sean las más convenientes del mercado, hemos establecido las siguientes condiciones para la prestación del servicio:
          </Typography>
          <Typography variant="body2" component="div">
            <ul>
              <li>La bicicleta debe estar registrada correctamente.</li>
              <li>Antigüedad de la bicicleta: No debe superar los 24 meses desde la fecha de compra.</li>
              <li>Valor de la bicicleta: El precio de venta público debe ser de al menos $4.000.000.</li>
              <li>Marca de la bicicleta: Solo se aceptan bicicletas de las marcas Giant, Specialized, Norco, Propain, BH y Polygon.</li>
            </ul>
          </Typography>
          <Typography variant="body1" paragraph>
            Paso a paso para solicitar el servicio de retoma:
          </Typography>
          <Typography variant="body2" component="div">
            <ol>
              <li>
                Incorpora al menos 3 fotos recientes de tu bicicleta (lavada) en el registro de tu bicicleta
                {' '}
                <Link to="/home" target="_blank" rel="noopener noreferrer">aquí</Link>
                .
              </li>
              <li>
                Envía una solicitud de retoma
                {' '}
                <Link to="/buy-your-bike" target="_blank" rel="noopener noreferrer">aquí</Link>
                , incluyendo el valor que deseas recibir por la bicicleta y el estado de la misma.
              </li>

              <li>Si las condiciones son adecuadas, nos pondremos en contacto para ofrecer un rango de precios por tu bicicleta.</li>
              <li>Si aceptas el rango de precios, deberás traer la bicicleta a nuestro showroom en Av. Los Trapenses 3200, Local 17, Lo Barnechea, Santiago.</li>
              <li>Revisaremos la bicicleta y ofreceremos un valor final. Si estás de acuerdo, se emitirá un cupón por el monto de la retoma.</li>
              <li>El cupón puede usarse como método de pago en nuestras tiendas asociadas: Specialized Chile, Giant Chile, Trail Store, Cross Mountain, Propain y BH.</li>
            </ol>
          </Typography>
          <Typography variant="body1" paragraph>
            Para canjear el cupón, presenta tu CI en la caja de pago. Una vez activado, recibirás un código de seguridad en tu correo para finalizar el canje.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BuyYourBikeForm;
