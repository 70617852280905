import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import logoBci from 'commons/assets/logo-bci.png';
// import logoFullwheels from 'commons/assets/providers/logoFullwheels.png';
// import logoBikfy from 'commons/assets/logo.png';
import logoBikfy from 'commons/assets/bikfylab_logo.png';

import { useHistory } from 'react-router-dom';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function InformativeModal(props) {
  const history = useHistory();
  const classes = useStyles();
  const { open, closeInformativeModal } = props;
  return (
    <Dialog open={!open} onClose={() => closeInformativeModal()} className={classes.dialog} maxWidth="sm" fullWidth>
      <DialogContent
        style={{
          textAlign: 'center',
          backgroundColor: '#3BA3B2',
          color: 'white',
        }}
      >
        <img src={logoBikfy} alt="Logo Fullwheels" style={{ width: '50%', marginBottom: '16px' }} />
        {/* Added marginBottom to create space between the logo and the text */}

        <DialogContentText>
          {/* Set the title text to match the button background color */}
          <Typography style={{ color: '#323E48', fontWeight: 'bold' }}>
            ¡CONOCE LAS BICICLETAS SEMI-NUEVAS BIKFY!
          </Typography>

          {/* Custom bullet points with centered text */}
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white',
          }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ fontSize: '20px', lineHeight: 0 }}>•</span>
              <span>Garantías de fabricante</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ fontSize: '20px', lineHeight: 0 }}>•</span>
              <span>60 días de taller gratis</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ fontSize: '20px', lineHeight: 0 }}>•</span>
              <span>Reacondicionada</span>
            </div>
          </div>

          <Typography style={{ color: 'white', marginTop: '8px' }}>
            Conoce las Semi-Nuevas y mucho más...
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center', backgroundColor: '#3BA3B2', color: 'white' }}>
        <Grid container justifyContent="center">
          <Button
            style={{ backgroundColor: '#323E48' }}
            onClick={() => {
              closeInformativeModal();
              history.push('/publish-bike');
            }}
            color="primary"
            variant="contained"
          >
            VER SEMI-NUEVAS
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
