import React from 'react';
import CustomTitleDefault from 'commons/components/CustomTitleDefault';
import { CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import { serialInput } from 'utils/functions';

const SearchForm = ({
  brands,
  motorbikeBrands,
  vehicleTypes,
  controls,
  onControlChange,
  onSubmit,
  errors,
  loading,
}) => {
  const vehicleTypeSelected = () => {
    if (controls.vehicleTypeId && vehicleTypes) {
      const vehicleTypeSelected = vehicleTypes?.filter(
        (vt) => vt.value === controls.vehicleTypeId,
      )[0]?.label;
      return vehicleTypeSelected;
    }
  };
  const filteredBrands = () => {
    if (vehicleTypeSelected() === 'Bicicleta') {
      return brands;
    }
    if (vehicleTypeSelected() === 'Motocicleta') {
      return motorbikeBrands;
    }
    return [];
  };
  return (
    <>
      <CustomTitleDefault
        title="Informe BIKFY"
        subtitle="¡Compra bien y seguro revisando la información de la Bike que te gusta!"
      />
      <form onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color="textSecondary">
              Búsqueda de Bike
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomAutoComplete
              name="vehicleTypeId"
              label="Tipo de vehículo"
              value={controls.vehicleTypeId}
              onChange={onControlChange}
              options={vehicleTypes}
              error={!!errors.vehicleTypeId}
              errors={errors.vehicleTypeId}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomAutoComplete
              name="brandId"
              label="Marca"
              value={controls.brandId}
              onChange={onControlChange}
              options={filteredBrands()}
              error={!!errors.brandId}
              errors={errors.brandId}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="serial"
              label={`Código de serie ${vehicleTypeSelected() === 'Motocicleta' ? 'chasis' : ''}`}
              variant="outlined"
              fullWidth
              onChange={(e) => onControlChange(e, (s) => serialInput(s))}
              value={controls.serial}
              error={!!errors.serial}
              helperText={errors.serial}
            />
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Button type="submit" variant="contained" color="primary">
              {loading ? <CircularProgress size={24} /> : 'Buscar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SearchForm;
