import colors from '../../utils/colors';

const { primary, danger } = colors;

const generalStylesModal = {
  '& .close-button-container': {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '40px',
    '& .close-button': {
      borderRadius: '50px',
      padding: '0px',
      minWidth: '54px',
      color: 'gray',
      '& .MuiButton-label': {
        fontSize: '20px',
      },
    },
  },
  '& .title': {
    textAlign: 'center',
    '& h2': {
      fontWeight: 'bold',
      fontSize: '24px',
    },
    '& .bikfyColor': {
      textTransform: 'uppercase',
      color: primary,
    },
  },
  '& .content': {
    padding: '20px',
    '& .message': {
      '& .bikfyColor': {
        color: primary,
      },
      '& .bold': {
        fontWeight: 'bold',
      },
      '& .customRedOne': {
        color: danger,
      },
    },
  },
};

const styles = (theme) => ({
  generalContainer: {
    margin: '0 auto',
    boxShadow: '1px 1px 7px 2px rgba(0,0,0,0.5)',
    maxWidth: '1000px',
  },
  cardContainer: {
    paddingBottom: '20px',
  },
  titleContainer: {
    padding: '20px',
    '& .title': {
      textAlign: 'center',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '28px',
      '& .variant-text': {
        color: primary,
      },
    },
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '30px',
    justifyContent: 'space-between',
  },
  checkboxListContainer: {
    width: '546px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  checkboxContainer: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    '& .bikfyColor': {
      color: primary,
    },
    '& .checkboxIconContainer': {
      '& .icon': {
        marginTop: '7px',
      },
      '& :hover': {
        cursor: 'pointer',
        color: primary,
      },
    },
  },
  checkboxLabel: {
    fontWeight: 'bold',
    '& .bikfy-color': {
      color: primary,
    },
    '& .errorOutlineIcon:hover': {
      color: primary,
    },
  },
  variantListOne: {
    listStyle: 'none',
  },
  amountsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    width: '339px',
    '&.not-bikfy-pay': {
      boxShadow:
        '0px 3px 3px -2px rgb(230 70 120 / 20%), 0px 3px 4px 0px rgb(230 70 120 / 14%), 0px 1px 8px 0px rgb(230 70 120 / 12%);',
    },
  },
  imgContainer: {
    height: '70px',
  },
  amountListContainer: {
    margin: '15px 0px',
    '& .amountList': {
      width: '300px',
      padding: '0px',
      '& .amountListItem': {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        '& .typography': {
          display: 'inline',
        },
      },
      '&.variantListOne': {
        listStyle: 'none',
      },
    },
  },
  amountBoxContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .amountTitleContainer': {
      position: 'absolute',
      padding: '0px 4px',
      top: '-10px',
      backgroundColor: '#f9fafb',
      '& .amountTitle': {
        color: primary,
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
      },
    },
    '& .amountBox': {
      width: '280px',
      padding: '20px',
      textAlign: 'center',
      border: `2px solid ${primary}`,
      borderRadius: '10px',
      color: primary,
      '& .amount': {
        fontWeight: 'bold',
        fontSize: '24px',
      },
    },
  },
  checkButtonsContainer: {
    padding: '10px 10px 0px 180px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 20px 60px 20px',
    '& .goBackButton': {
      marginRight: '40px',
    },
  },
  confirmModalStyle: {
    ...generalStylesModal,
    '& .buttonsContainer': {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0px',
    },
  },
  informativeModal: {
    ...generalStylesModal,
  },
  formContainer: {
    padding: '30px',
    width: '100%',
  },
  formPaper: {
    padding: '20px',
  },
  formTitleContainer: {
    '& .formTitle': {
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '28px',
      '& .bikfyColor': {
        color: primary,
      },
    },
  },
  containerMargin: {
    marginBottom: '20px',
  },
  alertFormContainer: {
    margin: '20px 0px',
  },
  bankDataForm: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px 0px',
    justifyContent: 'space-between',
    padding: '30px',
    margin: '0px auto',
    width: '809px',
    '& .formInput': {
      width: '374px',
    },
    '& .accountNumberField': {
      width: '100%',
    },
  },
  listTitleContainer: {
    display: 'flex',
    '& .icon': {
      color: primary,
    },
    '& .title': {
      marginLeft: '8px',
      fontWeight: '700',
    },
  },
  iconContainer: {
    '& .icon': {
      height: '70px',
      width: '110px',
      display: 'block',
      color: '#E64678',
    },
  },
  notBikfyPayMessageContainer: {
    maxWidth: '208px',
    '& .not-bikfy-pay-message': {
      textAlign: 'center',
      color: '#E64678',
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: '21px',
      textShadow: '3px 5px 11px rgba(230,70,120,0.48)',
    },
  },
  [theme.breakpoints.down('md')]: {
    titleContainer: {
      width: '100%',
    },
    cardsContainer: {
      gap: '20px 0px',
    },
    checkboxListContainer: {
      width: '100%',
    },
    amountsContainer: {
      width: '100%',
    },
    bankDataForm: {
      width: 'auto',
      '& .formInput': {
        width: '350px',
      },
    },
    amountBoxContainer: {
      '& .amountTitleContainer': {
        '& .amountTitle': {
          fontSize: '14px',
        },
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    titleContainer: {
      padding: '20px 0px',
    },
    cardsContainer: {
      padding: '0px',
    },
    amountsContainer: {
      padding: '20px 0px',
    },
    formContainer: {
      marginTop: '20px',
      padding: '0px',
      width: '100%',
    },
    bankDataForm: {
      padding: '0px',
      '& .formInput': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    amountListContainer: {
      '& .amountList': {
        width: '260px',
      },
    },
    amountBoxContainer: {
      '& .amountTitleContainer': {
        top: '-10px',
        right: '40px',
      },
      '& .amountBox': {
        width: '200px',
      },
    },
    buttonsContainer: {
      '& .goBackButton': {
        marginRight: '20px',
      },
    },
    confirmModalStyle: {
      '& .content': {
        flex: 'none',
      },
      '& .buttonsContainer': {
        '&.mobile-container': {
          flexDirection: 'column',
          '& .continue-button': {
            marginTop: '10px',
            width: '100%',
            marginLeft: '0px',
          },
          '& .enable-button': {
            width: '100%',
          },
        },
      },
    },
  },
});

export default styles;
