import React, { useState } from 'react';
import { Button, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PATHS } from 'utils/paths';

const styles = (theme) => ({
  formInput: {
    width: '100%',
    '& input': {
      textAlign: 'center',
    },
  },
  [theme.breakpoints.down('xs')]: {
    title: {
      fontSize: '18px',
    },
  },
});

const useStyles = makeStyles(styles);

const VerificationCode = ({
  code,
  generateNewVerificationCode,
  loading,
  transferId,
  sellerName,
  validateVerificationCode,
  history,
}) => {
  const [verificationCode, setVerificationCode] = useState('');

  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setVerificationCode(value.toUpperCase());
  };

  const handleAcceptClick = () => validateVerificationCode({ transferId, verificationCode });

  const handleGenerateNewConfirmationCode = () => generateNewVerificationCode({ transferId });

  const redirectToHome = () => history.push(PATHS.PILOT);

  return (
    <Grid
      container
      component={Paper}
      spacing={3}
      direction="column"
      alignItems="center"
      style={{ padding: '30px 20px' }}>
      <Grid container item xs={12} justifyContent="center" alignContent="center">
        <Grid container item xs={12} justifyContent="center">
          <Typography className={classes.title} variant="h5" paragraph align="center">
            <b>CONFIRMA EL RECIBO DE LA BIKE Y FINALIZA LA COMPRAVENTA</b>
          </Typography>
        </Grid>
        <Grid container item xs={12} md={6}>
          <ul>
            <li>
              <Grid item xs={12}>
                <Typography variant="body2" paragraph style={{ marginBottom: 0 }}>
                  <b>VENTA PRESENCIAL:</b>
                </Typography>
              </Grid>
            </li>
          </ul>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Ingresa el código de verificación enviado al correo electrónico de tu vendedor{' '}
              {sellerName}
            </Typography>
          </Grid>
          <ul>
            <li>
              <Grid item xs={12}>
                <Typography variant="body2" paragraph style={{ marginBottom: 0 }}>
                  <b>VENTA CON DESPACHO:</b>
                </Typography>
              </Grid>
            </li>
          </ul>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Al momento de recibir éxitosamente la Bike, vuelve acceder a esta vista a través de
              “Bikes pendientes” para enviar el código de verificación a tu vendedor e ingresarlo a
              continuación.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={10} sm={6} md={4} lg={2} justifyContent="center">
        <TextField
          className={classes.formInput}
          label="Código de verificación"
          variant="outlined"
          name="verificationCode"
          value={verificationCode}
          onChange={handleChange}
          error={code === 'invalid'}
          helperText={code === 'invalid' && 'Código de  verificación no válido.'}
        />
      </Grid>
      <Grid container item xs={12} md={4} spacing={3} justifyContent="center">
        <Grid container item xs={12} justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            className="button"
            onClick={handleGenerateNewConfirmationCode}>
            Enviar nuevamente el código
          </Button>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Button
            id="id_button_confirm_receipt_of_the_bike"
            variant="contained"
            color="primary"
            className="button"
            onClick={handleAcceptClick}
            disabled={loading}>
            Aceptar y Continuar
            {loading && (
              <CircularProgress
                style={{
                  width: '15px',
                  height: '15px',
                  marginLeft: '10px',
                  color: 'white',
                }}
              />
            )}
          </Button>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Button
            variant="contained"
            className="button"
            onClick={redirectToHome}
            disabled={loading}>
            VOLVERÉ CUANDO RECIBA LA BIKE
            {loading && (
              <CircularProgress
                style={{
                  width: '15px',
                  height: '15px',
                  marginLeft: '10px',
                  color: 'white',
                }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerificationCode;
