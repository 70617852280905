import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const TransferModal = ({
  alert,
  buyerStatus,
  clearAlert,
  generateTransfer,
  formControl,
  id,
  loading,
}) => {
  let text = '';

  if (buyerStatus.status === 'no_buyer') {
    text =
      'Tu comprador no posee una cuenta BIKFY. De todas formas será notificado, y mientras tanto, podrás continuar con la transferencia.';
  } else if (buyerStatus.status === 'incomplete_buyer') {
    text =
      'El usuario comprador no ha completado la información de su perfil en BIKFY. De todas formas será notificado, y mientras tanto, podrás continuar con la transferencia.';
  } else {
    text = 'Tu transferencia está en condiciones de realizarse.';
  }

  const handleGenerateTransfer = (e) => {
    e.preventDefault();
    generateTransfer({ id, ...formControl });
  };

  return (
    <Dialog open={alert} onClose={clearAlert}>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText>{text}</DialogContentText>
        <DialogContentText>¿Estás seguro que deseas continuar?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearAlert} color="secondary">
          Cancelar
        </Button>
        <Button
          id="id_button_accept_start_of_transfer"
          onClick={handleGenerateTransfer}
          color="primary"
          autoFocus
          disabled={loading}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferModal;
