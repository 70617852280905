import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Document, Page } from 'react-pdf';
import CardContent from '@material-ui/core/CardContent';
import { Card } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const FileViewer = ({ url, extension, name }) => {
  const extensionTypes = ['png', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'];
  const [pdf, setPdf] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [pageSelect, setPageSelect] = useState(1);

  useEffect(() => {
    fetch(url)
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.blob();
      })
      .then((myBlob) => {
        const reader = new FileReader();
        reader.onload = function () {
          setPdf(this.result);
        };
        reader.readAsDataURL(myBlob);
      })
      .catch((error) => console.error('Error fetching document:', error));
  }, [url]);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf?.numPages);
  };

  const nextPages = () => {
    const newPage = pageSelect + 1;
    if (numPages >= newPage) setPageSelect(newPage);
  };

  const previousPage = () => {
    const newPage = pageSelect - 1;
    if (newPage >= 1) setPageSelect(newPage);
  };

  const image = (url) => (
    <img
      src={url}
      style={{
        maxWidth: '100%',
        maxHeight: '250px',
      }}
      alt=""
    />
  );

  const pdfFile = (pdf) => (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12}>
        <Card variant="outlined">
          <CardContent>
            <Document
              file={pdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageSelect}
              />
            </Document>
          </CardContent>
        </Card>
      </Grid>
      {numPages > 1
      && (
      <Grid item xs={12} sm={12}>
        <IconButton
          onClick={previousPage}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          onClick={nextPages}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Grid>
      ) }
    </Grid>

  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} sm={12} align="center">
        {name}
      </Grid>
      <Grid item xs={12} sm={12} align="center">
        {
          extension.toLowerCase() === 'pdf' ? pdfFile(pdf)
            : extensionTypes.includes(extension.toLowerCase()) ? image(url)
              : <p>El archivo posee una extensión incorrecta</p>
        }
      </Grid>
    </Grid>

  );
};

export default FileViewer;
