import { makeStyles } from '@material-ui/core';

import colors from '../../../../../../utils/colors';

const { primary } = colors;

const useCustomTabsStyles = makeStyles((theme) => ({
  generalTabsContainer: {
    borderRadius: '10px',
    '& .app-bar': {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: 'black',
      '& .tabs': {
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        '& .tab': {
          marginRight: '5px',
          backgroundColor: 'rgba(196, 196, 196, 0.35)',
          borderRadius: '10px 10px 0px 0px',
          padding: '0px 12px',
          height: '10px',
          '&.Mui-selected': {
            color: primary,
          },
        },
      },
    },
    '& .custom-tab-container': {
      '& .custom-tab': {
        padding: '24px',
        '& .contact-info-tab-container': {
          maxWidth: '800px',
          '& .contact-info': {
            display: 'flex',
            alignItems: 'center',
            '& .icon': {
              marginRight: '20px',
            },
          },
        },
        '& .bikfy-report-tab-container, .workshop-book-tab-container': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          '& .message-container': {
            textAlign: 'center',
            '& .message-title': {
              fontWeight: '700',
              fontSize: '20px',
            },
            '& .message-paragraph': {
              maxWidth: '600px',
              marginTop: '20px',
              fontSize: '20px',
              lineHeight: '19px',
            },
          },
          '& .buttons-container': {
            marginTop: '60px',
          },
        },
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    generalTabsContainer: {
      '& .app-bar': {
        '& .tabs': {
          '& .MuiTabs-fixed .MuiTabs-flexContainer': {
            flexDirection: 'column',
            alignItems: 'center',
          },
          '& .tab': {
            marginRight: '0px',
            width: '100%',
            maxWidth: '600px',
          },
        },
      },
    },
  },
}));

export default useCustomTabsStyles;
