import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  title: {
    color: '#fff',
    backgroundColor: '#45C6D9',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Row = ({ name, value }) => (
  <StyledTableRow key={name}>
    <TableCell component="th" scope="row">
      {name}
    </TableCell>
    <TableCell align="left">
      {name === 'Mantenciones'
        ? value?.map((v, i) => (
          <span key={i}>
            -
            {' '}
            {v.name}
            <br />
          </span>
        ))

        : value}
    </TableCell>
  </StyledTableRow>
);

function CustomizedTable(props) {
  const { classes, maintenance } = props;

  return (
    <Paper className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell
              colSpan={2}
              align="center"
              className={classes.title}
            >
              Detalle de la mantención
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="Mantenciones" value={maintenance?.type} />
          <Row name="Detalle" value={maintenance?.description} />
          <Row name="Fecha" value={maintenance?.date.split('T')[0]} />
        </TableBody>
      </Table>
    </Paper>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
