import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Alert } from '@material-ui/lab';

const InactiveUser = () => (
  <Grid container justifyContent="center">
    <Grid item xs={10} sm={10}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Alert severity="error">Debes validar tu correo electrónico.</Alert>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default InactiveUser;
