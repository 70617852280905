import React from 'react';
// MATERIAL
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';

import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import StarIcon from '@material-ui/icons/Star';
import { VerifiedUser } from '@material-ui/icons';

const OptionsAvailable = ({
  iconClass,
  classes,
  iconsServiceClass,
  withBikfyPay,
  WithFeesCreditCard,
  withDelivery,
  withInvoice,
  isInsured,
}) => (
  <Grid container className={iconsServiceClass}>
    {WithFeesCreditCard ? (
      <Grid item container>
        <Box display="flex">
          <Tooltip title=" Hasta 9 cuotas sin interés con tarjeta de crédito" arrow>
            <StarIcon style={{ color: '#FFC700', margin: '0 5px 0 0' }} />
          </Tooltip>
          <Typography>
            Hasta <b>9 cuotas sin interés</b>{' '}
          </Typography>
        </Box>
      </Grid>
    ) : (
      <Grid item style={{ width: '100%', height: '30px' }} />
    )}
    <Grid container spacing={1}>
      {isInsured && (
        <Grid item>
          <Tooltip title="Bike asegurada" arrow>
            <VerifiedUser className={`${iconClass} ${classes.bikfyGreyColor}`} />
          </Tooltip>
        </Grid>
      )}
      {withInvoice && (
        <Grid item>
          <Tooltip title="Con Documentación" arrow>
            <ReceiptOutlinedIcon className={`${iconClass} ${classes.bikfyGreyColor}`} />
          </Tooltip>
        </Grid>
      )}
      {withBikfyPay && (
        <Grid item>
          <Tooltip title="BIKFYPAY" arrow>
            <CreditCardOutlinedIcon className={`${iconClass} ${classes.bikfyGreyColor}`} />
          </Tooltip>
        </Grid>
      )}
      {withDelivery && (
        <Grid item>
          <Tooltip title="El vendedor realiza envíos" arrow>
            <LocalShippingOutlinedIcon className={`${iconClass} ${classes.bikfyGreyColor}`} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  </Grid>
);

export default OptionsAvailable;
