import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'maintenance/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_DATA = `${PATH}CHANGE_DATA`;

// Global constants for saga
export const MAINTENANCE = {
  FETCH_MAINTENANCE: `${PATH}FETCH_MAINTENANCE`,
  FETCH_MAINTENANCE_SUCCESS: `${PATH}FETCH_MAINTENANCE_SUCCESS`,
  FETCH_MAINTENANCE_ERROR: `${PATH}FETCH_MAINTENANCE_ERROR`,
  FETCH_MAINTENANCE_TYPES: `${PATH}FETCH_MAINTENANCE_TYPES`,
  FETCH_MAINTENANCE_TYPES_SUCCESS: `${PATH}FETCH_MAINTENANCE_TYPES_SUCCESS`,
  FETCH_MAINTENANCE_TYPES_ERROR: `${PATH}FETCH_MAINTENANCE_TYPES_ERROR`,
  FETCH_BIKE_INFORMATION: `${PATH}FETCH_BIKE_INFORMATION`,
  FETCH_BIKE_INFORMATION_SUCCESS: `${PATH}FETCH_BIKE_INFORMATION_SUCCESS`,
  FETCH_BIKE_INFORMATION_ERROR: `${PATH}FETCH_BIKE_INFORMATION_ERROR`,
  CREATE: `${PATH}CREATE`,
  CREATE_SUCCESS: `${PATH}CREATE_SUCCESS`,
  CREATE_ERROR: `${PATH}CREATE_ERROR`,
  UPDATE: `${PATH}UPDATE`,
  UPDATE_SUCCESS: `${PATH}UPDATE_SUCCESS`,
  UPDATE_ERROR: `${PATH}UPDATE_ERROR`,
  DELETE: `${PATH}DELETE`,
  DELETE_SUCCESS: `${PATH}DELETE_SUCCESS`,
  DELETE_ERROR: `${PATH}DELETE_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
  OPEN_MODAL_CREATE: `${PATH}OPEN_MODAL_CREATE`,
  OPEN_MODAL_UPDATE: `${PATH}OPEN_MODAL_UPDATE`,
  CLEAR_MODAL: `${PATH}CLEAR_MODAL`,
  OPEN_FILE_MODAL: `${PATH}OPEN_FILE_MODAL`,
  CLEAR_FILE_MODAL: `${PATH}CLEAR_FILE_MODAL`,
  CHANGE_MAINTENANCE: `${PATH}CHANGE_MAINTENANCE`,
  OPEN_DELETE_MODAL: `${PATH}OPEN_DELETE_MODAL`,
  CLEAR_DELETE_MODAL: `${PATH}CLEAR_DELETE_MODAL`,
};

// actions
export const maintenanceActions = {
  fetchMaintenance: createAction(MAINTENANCE.FETCH_MAINTENANCE, 'payload'),
  fetchMaintenanceTypes: createAction(MAINTENANCE.FETCH_MAINTENANCE_TYPES, 'payload'),
  create: createAction(MAINTENANCE.CREATE, 'payload'),
  update: createAction(MAINTENANCE.UPDATE, 'payload'),
  deleteMaintenance: createAction(MAINTENANCE.DELETE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  changeData: createAction(CHANGE_DATA, 'payload'),
  resetControls: createAction(MAINTENANCE.RESET_CONTROLS),
  clearAlert: createAction(MAINTENANCE.CLEAR_ALERT),
  openAlert: createAction(MAINTENANCE.OPEN_ALERT),
  openModalCreate: createAction(MAINTENANCE.OPEN_MODAL_CREATE),
  openModalUpdate: createAction(MAINTENANCE.OPEN_MODAL_UPDATE),
  clearModal: createAction(MAINTENANCE.CLEAR_MODAL),
  openFileModal: createAction(MAINTENANCE.OPEN_FILE_MODAL),
  closeFileModal: createAction(MAINTENANCE.CLEAR_FILE_MODAL),
  changeMaintenance: createAction(MAINTENANCE.CHANGE_MAINTENANCE, 'payload'),
  openDeleteModal: createAction(MAINTENANCE.OPEN_DELETE_MODAL),
  closeDeleteModal: createAction(MAINTENANCE.CLEAR_DELETE_MODAL),
  fetchBikeInformation: createAction(MAINTENANCE.FETCH_BIKE_INFORMATION, 'payload'),
};

const initialState = {
  formControls: {
    types: [],
    description: '',
    date: '',
    voucherFile: '',
    updateFile: 'no',
  },
  fileData: {
    url: '',
    extension: '',
    name: '',
    fileModal: false,
  },
  loading: false,
  error: false,
  success: false,
  alert: false,
  edit: false,
  modalOpen: false,
  maintenanceId: 0,
  modalDelete: false,
};

const maintenance = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [CHANGE_DATA](state, action) {
    state.fileData = {
      ...state.fileData,
      ...action.payload,
    };
  },
  [MAINTENANCE.RESET_CONTROLS](state, action) {
    state.formControls = { ...initialState.formControls };
    state.alert = false;
    state.success = false;
    state.modalDelete = false;
  },
  [MAINTENANCE.FETCH_MAINTENANCE](state) {
    state.loading = true;
    state.error = false;
  },
  [MAINTENANCE.FETCH_MAINTENANCE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [MAINTENANCE.FETCH_MAINTENANCE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [MAINTENANCE.FETCH_MAINTENANCE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [MAINTENANCE.FETCH_MAINTENANCE_TYPES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [MAINTENANCE.FETCH_MAINTENANCE_TYPES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [MAINTENANCE.FETCH_BIKE_INFORMATION](state) {
    state.loading = true;
    state.error = false;
  },
  [MAINTENANCE.FETCH_BIKE_INFORMATION_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [MAINTENANCE.FETCH_BIKE_INFORMATION_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [MAINTENANCE.CREATE](state) {
    state.loading = true;
    state.error = false;
    state.success = false;
  },
  [MAINTENANCE.CREATE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.success = true;
  },
  [MAINTENANCE.CREATE_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.success = false;
  },
  [MAINTENANCE.UPDATE](state) {
    state.loading = true;
    state.error = false;
  },
  [MAINTENANCE.UPDATE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.success = true;
  },
  [MAINTENANCE.UPDATE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [MAINTENANCE.DELETE](state) {
    state.loading = true;
    state.error = false;
  },
  [MAINTENANCE.DELETE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.success = true;
  },
  [MAINTENANCE.DELETE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [MAINTENANCE.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [MAINTENANCE.OPEN_ALERT](state) {
    state.alert = true;
  },
  [MAINTENANCE.OPEN_MODAL_CREATE](state) {
    state.modalOpen = true;
    state.edit = false;
  },
  [MAINTENANCE.OPEN_MODAL_UPDATE](state) {
    state.modalOpen = true;
    state.edit = true;
  },
  [MAINTENANCE.CLEAR_MODAL](state) {
    state.modalOpen = false;
  },
  [MAINTENANCE.OPEN_FILE_MODAL](state) {
    state.fileData.fileModal = true;
  },
  [MAINTENANCE.CLEAR_FILE_MODAL](state) {
    state.fileData.fileModal = false;
  },
  [MAINTENANCE.CHANGE_MAINTENANCE](state, action) {
    state.maintenanceId = action.payload;
  },
  [MAINTENANCE.CLEAR_DELETE_MODAL](state) {
    state.modalDelete = false;
  },
  [MAINTENANCE.OPEN_DELETE_MODAL](state) {
    state.modalDelete = true;
  },
});

export default maintenance;
