/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import { Button, Dialog, DialogTitle, DialogContent, Typography, Box } from '@material-ui/core';

const InformativeModal = (props) => {
  const { isOpen, className, fullScreen, toggleInformativeModal } = props;

  const handleCloseModal = () => {
    toggleInformativeModal();
  };

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} className={className} onClose={handleCloseModal}>
      {fullScreen && (
        <Box className="close-button-container">
          <Button onClick={handleCloseModal} className="close-button">
            X
          </Button>
        </Box>
      )}
      <DialogTitle className="title">
        <span className="bikfyColor">BIKFY</span>PAY
      </DialogTitle>
      <DialogContent className="content">
        <Typography className="message">
          Vende tu Bike de manera <span className="bold"> rápida y segura </span>.
        </Typography>
        <ul>
          <li>
            <Typography className="message">
              Evita <span className="customRedOne"> fraudes y riesgos </span> en la transacción
              monetaria.
            </Typography>
          </li>
          <li>
            <Typography className="message">
              Habilita que el comprador pueda pagar tu Bike con tarjetas de
              <span className="bold"> Crédito o Débito </span>.
            </Typography>
          </li>
        </ul>
        <Typography className="message">
          El Pago <span className="bikfyColor"> BIKFY </span> no es excluyente al Pago Externo, es
          solo un servicio adicional y es el comprador quien finalmente decide qué método de pago
          utilizar. En caso de que el comprador seleccione Pago Externo, no habrá una comisión por
          servicio.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

InformativeModal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  toggleInformativeModal: PropTypes.func.isRequired,
};

InformativeModal.defaultProps = {
  isOpen: false,
  className: '',
  fullScreen: false,
};

export default InformativeModal;
