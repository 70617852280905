import React from 'react';
import PermIdentitySharpIcon from '@material-ui/icons/PermIdentitySharp';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import MailOutlineSharpIcon from '@material-ui/icons/MailOutlineSharp';
import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import LocationCitySharpIcon from '@material-ui/icons/LocationCitySharp';
import LocationOnSharpIcon from '@material-ui/icons/LocationOnSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import StoreMallDirectorySharpIcon from '@material-ui/icons/StoreMallDirectorySharp';
import StorageSharpIcon from '@material-ui/icons/StorageSharp';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import StraightenSharpIcon from '@material-ui/icons/StraightenSharp';
import FormatColorFillSharpIcon from '@material-ui/icons/FormatColorFillSharp';
import InputSharpIcon from '@material-ui/icons/InputSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import ReportSharpIcon from '@material-ui/icons/ReportSharp';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';

export const userInfo = [
  {
    label: 'Nombre',
    key: 'name',
    icon: <PermIdentitySharpIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'R.U.T.',
    key: 'rut',
    icon: <FingerprintSharpIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Correo electrónico',
    key: 'email',
    icon: <MailOutlineSharpIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Fecha de nacimiento',
    key: 'birthdate',
    icon: <CalendarTodaySharpIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Género',
    key: 'gender',
    icon: <GroupOutlinedIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Teléfono',
    key: 'phone',
    icon: <CallOutlinedIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Comuna',
    key: 'comune',
    icon: <LocationCitySharpIcon />,
    shows: ['store', 'admin'],
  },
  {
    label: 'Calle',
    key: 'addressStreet',
    icon: <LocationOnSharpIcon />,
    shows: ['admin'],
  },
  {
    label: 'Número de calle',
    key: 'addressNumber',
    icon: <LocationOnSharpIcon />,
    shows: ['admin'],
  },
  {
    label: 'Dpto, casa u oficina',
    key: 'addressDetail',
    icon: <HomeSharpIcon />,
    shows: ['admin'],
  },
];

export const bikeInfo = [
  {
    label: 'Dueño actual',
    key: 'currentOwner',
    icon: <PermIdentitySharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Cantidad de dueños',
    key: 'numberOfOwners',
    icon: <PeopleOutlineIcon />,
    shows: ['store', 'admin'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Categoría',
    key: 'category',
    icon: <TwoWheelerIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Motorbike'],
  },
  {
    label: 'Categoría',
    key: 'category',
    icon: <DirectionsBikeIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle'],
  },
  {
    label: 'Marca',
    key: 'brand',
    icon: <StoreMallDirectorySharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Modelo',
    key: 'model',
    icon: <StorageSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Versión',
    key: 'version',
    icon: <StorageSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Año',
    key: 'year',
    icon: <CalendarTodaySharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Aro',
    key: 'rim',
    icon: <RadioButtonUncheckedIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle'],
  },
  {
    label: 'Talla',
    key: 'size',
    icon: <StraightenSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle'],
  },
  {
    label: 'Color',
    key: 'color',
    icon: <FormatColorFillSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Bike registrado por',
    key: 'userType',
    icon: <InputSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Código de serie',
    key: 'serial',
    icon: <FingerprintSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle'],
  },
  {
    label: 'Factura/Boleta emitida en',
    key: 'invoiceIssuedIn',
    icon: <DescriptionSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Factura/Boleta a nombre de',
    key: 'bikeType',
    icon: <DescriptionSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Valor Factura/Boleta o última transferencia',
    key: 'transferAmount',
    icon: <AttachMoneyIcon />,
    shows: ['store', 'admin'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'RUT emisor Factura/Boleta',
    key: 'transferInvoiceTaxNumber',
    icon: <LocationCityIcon />,
    shows: ['store', 'admin'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Inhabilitada',
    key: 'disabled',
    icon: <ReportSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Denunciada',
    key: 'reported',
    icon: <ReportSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Bicycle', 'Motorbike'],
  },
  {
    label: 'Número de motor',
    key: 'engineSerial',
    icon: <FingerprintSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Motorbike'],
  },
  {
    label: 'Número de chasis',
    key: 'chassisSerial',
    icon: <FingerprintSharpIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Motorbike'],
  },
  {
    label: 'Cilindraje motor',
    key: 'capacity',
    icon: <TwoWheelerIcon />,
    shows: ['store', 'admin', 'pilot'],
    vehicleType: ['Motorbike'],
  },
];
