import { createAction } from 'utils/reducer';

import enablePaymentScreenTypes from './enablePaymentScreen.types';

export const enablePaymentScreenActions = {
  initState: createAction(enablePaymentScreenTypes.INIT_STATE),
  setIsOpenConfirmModal: createAction(
    enablePaymentScreenTypes.SET_IS_OPEN_CONFIRM_MODAL,
    'payload',
  ),
  setUserPaymentMethod: createAction(enablePaymentScreenTypes.SET_USER_PAYMENT_METHOD, 'payload'),
  getTransaction: createAction(enablePaymentScreenTypes.GET_TRANSFER, 'payload'),
  toggleError: createAction(enablePaymentScreenTypes.TOGGLE_ERROR, 'payload'),
  setIsOpenInformativeModal: createAction(
    enablePaymentScreenTypes.SET_IS_OPEN_INFORMATIVE_MODAL,
    'payload',
  ),
};

export default enablePaymentScreenActions;
