import React from 'react';
import { Grid, makeStyles, Paper, RadioGroup, Radio, Typography } from '@material-ui/core';

import colors from '../../../../../utils/colors';

const { primary, grey } = colors;

const styles = (theme) => ({
  radioGroup: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  gridChildContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .selected': {
      boxShadow: '0px 0px 6px 5px rgba(69,198,216,0.50)',
      WebkitBoxShadow: ' 0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      MozBoxShadow: '0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      order: '1px solid rgba(0,0,0,0.1)',
    },
    '& .paperContainer': {
      width: '100%',
      padding: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      '& .radio-button': {
        '&.Mui-checked': {
          color: primary,
        },
      },
      '& .checked': {
        '& .title': {
          color: primary,
        },
        '& .icon': {
          color: primary,
        },
      },

      '& .topSubcontainer': {
        display: 'flex',
        alignItems: 'center',
        '& .radioContainer': {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '0px',
          '& span': {
            padding: '0px',
          },
        },
        '& .checked': {
          '& .title': {
            color: primary,
          },
        },
      },
      '& .lowerSubcontainer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: '.5rem',
        '& img': {
          width: '120px',
        },
        '& .disabled': {
          filter: 'grayscale(1)',
        },
      },
    },
    '& .disabled': {
      cursor: 'not-allowed',
      '& .title': {
        color: grey,
      },
      '& .icon': {
        color: grey,
      },
    },
  },
});

const useStyles = makeStyles(styles);

const PaymentMethodBox = ({ handleChange, handleClick, paymentMethods, selectedPaymentMethod }) => {
  const classes = useStyles();

  const { gridChildContainer, radioGroup } = classes;

  return (
    <RadioGroup value={selectedPaymentMethod} onChange={handleChange} className={radioGroup}>
      <Grid container justifyContent="center" spacing={2}>
        {paymentMethods?.map((paymentMethod) => (
          <Grid key={paymentMethod.id} item xs={12} sm={6} md={4} className={gridChildContainer}>
            <Paper
              className={paymentMethod.disabled ? 'paperContainer disabled' : 'paperContainer'}
              className={
                selectedPaymentMethod === paymentMethod.key
                  ? 'paperContainer selected'
                  : 'paperContainer'
              }
              // onClick={paymentMethod.disabled ? null : () => handleClick(paymentMethod.key)}
            >
              <Grid className="topSubcontainer" container item xs={12}>
                <Grid className="radioContainer" item xs={2}>
                  <Radio
                    value={paymentMethod.key}
                    className="radio-button"
                    disabled={paymentMethod.disabled}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  className={selectedPaymentMethod === paymentMethod.key && 'checked'}>
                  <Typography className="title">{paymentMethod.title}</Typography>
                </Grid>
                <Grid item xs={2} style={{ height: '1rem' }} />
                <Grid
                  item
                  xs={10}
                  className={selectedPaymentMethod === paymentMethod.key && 'checked'}>
                  {paymentMethod.subtitle ? (
                    <Typography className="title">{paymentMethod.subtitle}</Typography>
                  ) : (
                    <Typography className="title">{'\u00A0'}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid className="lowerSubcontainer" item xs={12}>
                <img
                  className={paymentMethod.disabled ? 'disabled' : ''}
                  src={paymentMethod.logo}
                  alt="bikfy-logo"
                />
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
};

export default PaymentMethodBox;
