import colors from '../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    width: '100%',
    margin: '0 auto',
    boxShadow: '1px 1px 7px 2px rgba(0,0,0,0.5)',
    padding: '20px',
  },
  titleAlertContainer: {
    // width: '100%',
    '& .title': {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    '& .alert': {
      display: 'flex',
      justifyContent: 'center',
      // width: '50%',
      margin: '30px 0px',
    },
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px 0px',
    justifyContent: 'space-between',
    padding: '30px',
    margin: '0px auto',
    width: '1000px',
    '& .formInput': {
      width: '450px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    '& .goBackButton': {
      marginRight: '40px',
    },
  },
  // UTILITY CLASSES
  bikfyColor: {
    color: primary,
  },
  // RESPONSIVE STYLES
  [theme.breakpoints.down('md')]: {
    formContainer: {
      width: 'auto',
      '& .formInput': {
        width: '350px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    titleAlertContainer: {
      '& .title': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
    },
    formContainer: {
      '& .formInput': {
        width: '100%',
      },
    },
  },
});

export default styles;
