/**
 * returns the user type
 * @param {*} user
 * @returns 'public' | 'pilot' | 'admin' | 'store' | 'ambassador'
 */
// eslint-disable-next-line import/prefer-default-export
export const getUserType = (user) => {
  if (!user) return 'public';
  return user.type;
};
