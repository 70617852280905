import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { cancelBikeRegistratorActions } from 'screens/CancelBikeRegistration/reducer';
import { composedComponent } from 'utils/functions';
import cancelBikeRegistrationSaga from 'screens/CancelBikeRegistration/saga';

const CancelBikeRegistration = (props) => {
  const { loading, message, error } = props;

  const queryParams = useLocation().search;

  const token = new URLSearchParams(queryParams).get('token');
  const { transferId } = jwt.decode(token)?.data ?? '';

  useEffect(() => {
    if (transferId) {
      props.actions.cancelRegistration({ transferId });
    } else {
      props.actions.setError();
    }
  }, []);

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="center">
        {loading ? (
          <CircularProgress />
        ) : (
          <Alert severity={error ? 'error' : 'success'}>{message}</Alert>
        )}
      </Grid>
    </Grid>
  );
};

// export default CancelBikeRegistration;

export default composedComponent(CancelBikeRegistration, cancelBikeRegistrationSaga, {
  states: [
    'cancelBikeRegistration.loading',
    'cancelBikeRegistration.message',
    'cancelBikeRegistration.error',
  ],
  actions: [cancelBikeRegistratorActions],
  saga: 'cancelBikeRegistrationSaga',
});
