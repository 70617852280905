/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import {
  Paper,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import bikfyLogo from 'commons/assets/logo-bikfy-main.svg';
import blackBikfyLogo from 'commons/assets/blackBikfyLogo.svg';

import { formatNumberToCLP } from '../../../utils/functions';

const Card = (props) => {
  const [enterMouseOnCard, setEnterMouseOnCard] = useState(false);

  const {
    classes,
    isBikfyPay,
    handleClickPayButton,
    purchaseContract,
    amountBike,
    buyerCommission,
    isDisablePayButton,
  } = props;

  const {
    payMethodCard,
    imgContainer,
    payMethodTitleContainer,
    divider,
    listContainer,
    totalPayTable,
    buttonContainer,
    bikfyPayIcon,
  } = classes;

  const logo = enterMouseOnCard ? bikfyLogo : blackBikfyLogo;
  const cardTitle = isBikfyPay ? 'Pago Bikfy' : 'Pago Externo';
  const classNameCardTitle = isBikfyPay ? 'payMethodTitle bikfyColor' : 'payMethodTitle';

  const totalAmountToPay = isBikfyPay
    ? amountBike + buyerCommission + purchaseContract
    : purchaseContract;

  const showBuyerCommission = buyerCommission > 0;

  const firstItemText = isBikfyPay ? 'Tarjeta de Crédito o Débito' : 'Pago externo a BIKFY';

  const handleMouseEnter = () => {
    if (isDisablePayButton) return;
    setEnterMouseOnCard(true);
  };

  const handleMouseLeave = () => {
    setEnterMouseOnCard(false);
  };

  return (
    <Paper
      className={`${payMethodCard} ${enterMouseOnCard ? 'bikfy-shadow' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {isBikfyPay && <VerifiedUserIcon className={`${bikfyPayIcon}`} />}
      <Box className={`${imgContainer}`}>
        <img src={logo} className="img" alt="bikfy-logo" />
      </Box>
      <Box className={`${payMethodTitleContainer}`}>
        <Typography component="h2" className={classNameCardTitle}>
          {cardTitle}
        </Typography>
      </Box>
      <Divider className={`${divider}`} />
      <Box className={`${listContainer}`}>
        <List className="list">
          <ListItem className="listItem">
            <ListItemIcon className="listItemIcon">
              <CheckIcon className="icon" />
            </ListItemIcon>
            <ListItemText className="listItemText">{firstItemText}</ListItemText>
          </ListItem>
          <ListItem className="listItem">
            <ListItemIcon className="listItemIcon">
              <CheckIcon className="icon" />
            </ListItemIcon>
            <ListItemText className="listItemText">
              Contrato de compraventa: <br />
              <span style={{ color: 'gray' }}>Transfiere legalmente la propiedad de tu bike</span>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Divider className={`${divider}`} />
      <Table className={`${totalPayTable}`}>
        <TableBody className="tableBody">
          <TableRow className="tableRow">
            <TableCell className="tableCell">Valor de Bike</TableCell>
            <TableCell className="tableCell" align="right">
              {formatNumberToCLP(amountBike)}
            </TableCell>
          </TableRow>
          {showBuyerCommission && (
            <TableRow className="tableRow">
              <TableCell className="tableCell">Comisión por Servicio</TableCell>
              <TableCell className="tableCell" align="right">
                {formatNumberToCLP(buyerCommission)}
              </TableCell>
            </TableRow>
          )}
          <TableRow className="tableRow">
            <TableCell className="tableCell">Contrato compraventa</TableCell>
            <TableCell className="tableCell" align="right">
              {formatNumberToCLP(purchaseContract)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box className={`${buttonContainer} ${!isBikfyPay ? 'absoluteContainer' : ''}`}>
        <Button
          variant="contained"
          disabled={isDisablePayButton}
          className={`button ${enterMouseOnCard && 'bikfy-button'} ${
            isDisablePayButton && 'disabled'
          }`}
          onClick={() => handleClickPayButton(isBikfyPay)}>
          Pagar {formatNumberToCLP(totalAmountToPay)}
        </Button>
      </Box>
    </Paper>
  );
};

Card.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isBikfyPay: PropTypes.bool,
  handleClickPayButton: PropTypes.func.isRequired,
  purchaseContract: PropTypes.number,
  amountBike: PropTypes.number,
  buyerCommission: PropTypes.number,
  isDisablePayButton: PropTypes.bool,
};

Card.defaultProps = {
  isBikfyPay: false,
  purchaseContract: 0,
  amountBike: 0,
  buyerCommission: 0,
  isDisablePayButton: false,
};

export default Card;
