import React from 'react';
import Grid from '@material-ui/core/Grid';
import { getItemInStorage } from 'utils/functions';
import { Card, Button, useTheme, useMediaQuery } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TransferContract from 'commons/components/TransferContract';
import { useParams } from 'react-router-dom';

const Contract = () => {
  const params = useParams();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleDownloadPdf = () => {
    const session = getItemInStorage('user');
    const { id } = params;

    fetch(`/api/v1/documents/get/${id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${session.token}`,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Contrato.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <Grid container justifyContent="center">
      {isMobileScreen && (
        <Grid container alignContent="center" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: '0.8rem', marginBottom: '15px' }}
            onClick={handleDownloadPdf}>
            Descargar Contrato
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} align="center">
                <TransferContract
                  id={params.id}
                  isMobileScreen={isMobileScreen}
                  handleDownloadPdf={handleDownloadPdf}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Contract;
