import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Input,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';
import BikeImage from 'screens/Home/components/BikeImage';
import { formatNumberToCLPSeparator } from 'utils/functions';
import Categories from '../../../utils/categories';

const useStyles = makeStyles((theme) => ({
  margin: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonStyle: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  cardContent: {
    paddingRight: theme.spacing(4),
  },
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 6),
    maxWidth: 500,
    width: '100%',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  fileInfo: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  fileLink: {
    marginLeft: theme.spacing(1),
  },
}));

// Restored validate function
const validate = (value) => value || 'No especificado';

const BikeCard = ({
  bike,
  brandId,
  brand,
  model,
  category,
  history,
  openModal,
  transferId = '#',
  bikeFilter = 1,
  handleToggleShowDisableDialog,
  motorbike,
  inEvaluation,
  onCreateCouponClick,
  onRejectClick,
  userId,
}) => {
  const {
    id: bikeId, year, color, serial, reported, dataComplete, transfer,
  } = bike;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleFileChange = (event, setFile) => {
    setFile(event.target.files[0]);
  };

  const handleCreateCoupon = async () => {
    if (!amount || !file1 || !file2) {
      alert('Please fill in the amount and upload both files.');
      return;
    }

    try {
      const numericAmount = amount.replace(/\./g, ''); // Remove thousand separators

      // Trigger Redux action to create a coupon with files
      await onCreateCouponClick({
        userId,
        amount: Number(numericAmount),
        transferId,
        files: [file1, file2],
      });

      setOpen(false);
    } catch (error) {
      console.error('Failed to create coupon:', error);
    }
  };

  const handleOnClickCard = () => {
    if (bike.isBicycle) {
      history.push(`/search/${brandId}/${serial}`);
    } else {
      history.push(`/search/${brandId}/${motorbike[0].chassisSerial}`);
    }
  };

  return (
    <Card style={{ cursor: 'pointer' }} onClick={handleOnClickCard}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3} align="center">
            {category ? (
              bikeFilter === 1 ? (
                <BikeImage category={category} reported={reported} openModal={openModal} />
              ) : (
                <img src={Categories[category]} alt="" style={{ width: '100%' }} />
              )
            ) : (
              <Link className={classes.link} to={`/bike/${transferId}`}>
                <BlockIcon style={{ width: '70%', height: '70%' }} />
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <Typography component="h5" variant="h4">
              {`${brand} ${model || ''}`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Código de serie:
              {' '}
              {validate(serial)}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {transfer?.buyer?.name}
              {' '}
              {transfer?.buyer?.lastName}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              RUT:
              {' '}
              {validate(transfer?.buyer?.taxNumber)}
            </Typography>
          </Grid>
          {bikeFilter === 1 && (
            <Grid item xs={12} sm={3} md={2} className={classes.buttonStyle}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!(!reported && dataComplete) || (!bike.isBicycle && !motorbike[0]?.chassisSerial)}
              >
                Ver
              </Button>
              {inEvaluation && (
                <>
                  <Button
                    variant="contained"
                    color="inherit"
                    fullWidth
                    style={{ marginTop: '10px' }}
                    onClick={handleOpen}
                    size="small"
                  >
                    Crear Cupón
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size="small"
                    style={{ marginTop: '10px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onRejectClick({ bikeId, userId: transfer?.buyer?.id });
                    }}
                  >
                    Reject
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ marginTop: '10px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleToggleShowDisableDialog({ bikeId });
                }}
              >
                {bike.disable ? 'Habilitar' : 'Inhabilitar'}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper} onClick={(event) => event.stopPropagation()}>
            <Typography variant="h6" gutterBottom>
              Creación de Cupón
            </Typography>
            <TextField
              label="Ingrese el valor de canje"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={amount}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/\./g, '');
                const formattedValue = formatNumberToCLPSeparator(rawValue);
                setAmount(formattedValue);
              }}
            />
            <div className={classes.fileInfo}>
              <Input
                type="file"
                onChange={(e) => handleFileChange(e, setFile1)}
                style={{ display: 'none' }}
                id="file-upload-1"
              />
              <label htmlFor="file-upload-1">
                <Button variant="contained" component="span" color="default">
                  Adjuntar mandato
                </Button>
              </label>
              {file1 && (
                <Tooltip title={file1.name}>
                  <IconButton component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
              {file1 && (
                <a
                  href={URL.createObjectURL(file1)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.fileLink}
                >
                  Ver mandato
                </a>
              )}
            </div>

            <div className={classes.fileInfo}>
              <Input
                type="file"
                onChange={(e) => handleFileChange(e, setFile2)}
                style={{ display: 'none' }}
                id="file-upload-2"
              />
              <label htmlFor="file-upload-2">
                <Button variant="contained" component="span" color="default">
                  Adjuntar informe técnico
                </Button>
              </label>
              {file2 && (
                <Tooltip title={file2.name}>
                  <IconButton component="span">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
              {file2 && (
                <a
                  href={URL.createObjectURL(file2)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.fileLink}
                >
                  Ver informe técnico
                </a>
              )}
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateCoupon}
              disabled={!amount || !file1 || !file2}
              style={{ marginTop: '10px' }}
            >
              Crear Cupón
            </Button>
          </div>
        </Fade>
      </Modal>
    </Card>
  );
};

export default BikeCard;
