import React from 'react';

import CustomDataGrid from 'commons/components/CustomDataGrid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Tooltip, useMediaQuery } from '@material-ui/core';

const MaintenancesTable = ({
  data = {},
  handleGetFile = {},
  handleOpenUpdate = {},
  handleOpenDelete = {},
  viewActions = true,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const columns = [
    {
      field: 'type',
      headerName: 'Mantenciones',
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const text = params.row.type.map((i) => i.name).join(', ');
        return (
          <Tooltip title={text}>
            <span onClick={() => { handleGetFile(params.row.id); }}>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      hide: isSmallScreen,
      field: 'description',
      headerName: 'Detalle',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      hide: isSmallScreen,
      field: 'date',
      headerName: 'Fecha',
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const text = params.row.date.split('T')[0];
        return (
          <span>{text}</span>
        );
      },
    },
    {
      field: 'file',
      headerName: 'Comprobante',
      flex: 1,
      filterable: false,
      sortable: false,
      align: 'center',
      maxWidth: 1,
      renderCell: (params) => (
        <IconButton component="span" onClick={() => { handleGetFile(params.row.id); }}>
          <FileCopyIcon />
        </IconButton>
      ),
    },

  ];

  if (viewActions) {
    columns.push({
      field: 'Actions',
      headerName: 'Acciones',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component="span" onClick={() => { handleOpenUpdate(params.row.id); }}>
            <CreateIcon />
          </IconButton>
          <IconButton component="span" onClick={() => { handleOpenDelete(params.row.id); }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),

      flex: 1,
    });
  }

  return (
    <div>
      <CustomDataGrid rows={data} columns={columns} />
    </div>
  );
};

export default MaintenancesTable;
