/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'redeemCoupon/';

// Global constants for saga
export const REDEEM_COUPON = {
  FETCH_COUPONS: `${PATH}FETCH_COUPONS`,
  FETCH_COUPONS_SUCCESS: `${PATH}FETCH_COUPONS_SUCCESS`,
  FETCH_COUPONS_ERROR: `${PATH}FETCH_COUPONS_ERROR`,
  REDEEM_COUPON: `${PATH}REDEEM_COUPON`,
  REDEEM_COUPON_SUCCESS: `${PATH}REDEEM_COUPON_SUCCESS`,
  REDEEM_COUPON_ERROR: `${PATH}REDEEM_COUPON_ERROR`,
  CONSULT_COUPON_CODE: `${PATH}CONSULT_COUPON_CODE`,
  CONSULT_COUPON_CODE_SUCCESS: `${PATH}CONSULT_COUPON_CODE_SUCCESS`,
  CONSULT_COUPON_CODE_ERROR: `${PATH}CONSULT_COUPON_CODE_ERROR`,
};

// Actions
export const redeemCouponActions = {
  fetchCoupons: createAction(REDEEM_COUPON.FETCH_COUPONS, 'payload'),
  redeemCoupon: createAction(REDEEM_COUPON.REDEEM_COUPON, 'payload'),
  consultCouponCode: createAction(REDEEM_COUPON.CONSULT_COUPON_CODE, 'payload'), // New action
};

// Initial state
const initialState = {
  loading: false,
  error: false,
  coupons: {},
  redeemedCoupon: null,
  loadingRedeem: false,
  errorRedeem: false,
  loadingConsult: false,
  errorConsult: false,
  message: '',
};

// Reducer
const redeemCoupon = createReducer(initialState, {
  [REDEEM_COUPON.FETCH_COUPONS](state) {
    state.loading = true;
    state.coupons = [];
    state.error = false;
    state.message = '';
  },
  [REDEEM_COUPON.FETCH_COUPONS_SUCCESS](state, action) {
    state.loading = false;
    state.coupons = action.payload; // Array of coupons associated with the taxNumber
    state.error = false;
  },
  [REDEEM_COUPON.FETCH_COUPONS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [REDEEM_COUPON.REDEEM_COUPON](state) {
    state.loadingRedeem = true;
    state.errorRedeem = false;
    state.message = '';
  },
  [REDEEM_COUPON.REDEEM_COUPON_SUCCESS](state, action) {
    state.loadingRedeem = false;
    state.errorRedeem = false;
    state.redeemedCoupon = action.payload; // Store redeemed coupon details
    state.coupons[action.payload.coupon.id].usedAt = true;
  },
  [REDEEM_COUPON.REDEEM_COUPON_ERROR](state, action) {
    state.loadingRedeem = false;
    state.errorRedeem = true;
    state.message = action.payload.errorMsg;
  },
  [REDEEM_COUPON.CONSULT_COUPON_CODE](state) {
    state.loadingConsult = true;
    state.errorConsult = false;
    state.message = '';
  },
  [REDEEM_COUPON.CONSULT_COUPON_CODE_SUCCESS](state, action) {
    state.loadingConsult = false;
    state.errorConsult = false;
    state.message = 'Coupon code sent successfully!';
  },
  [REDEEM_COUPON.CONSULT_COUPON_CODE_ERROR](state, action) {
    state.loadingConsult = false;
    state.errorConsult = true;
    state.message = action.payload.errorMsg;
  },
});

export default redeemCoupon;
