import { put, spawn, takeLatest } from 'redux-saga/effects';
import { TRANSFERPREVIEW } from 'screens/TransferPreview/reducer';
import apiRequest, { apiSuccess, get, post, destroy } from 'utils/api';
import * as APP from 'commons/reducer';

function* fetchTransferPreview() {
  yield takeLatest(TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW));
    const response = yield get(`api/v1/transfers/${id}/transfer-summary`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW));
    if (!response.error) {
      yield put(apiSuccess(TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: 'Hubo un error al validar la transferencia' }),
      );
      yield put(apiSuccess(TRANSFERPREVIEW.FETCH_TRANSFER_PREVIEW_ERROR));
    }
  });
}

function* acceptTransferSeller() {
  yield takeLatest(TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER));
    const response = yield get(`api/v1/transfers/${id}/accept-transfer-seller`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER));
    if (!response.error) {
      yield put(apiSuccess(TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia en espera del comprador'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al obtener los datos' }));
      yield put(apiSuccess(TRANSFERPREVIEW.ACCEPT_TRANSFER_SELLER_ERROR));
    }
  });
}

function* acceptTransferBuyer() {
  yield takeLatest(TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER));
    const response = yield post(`api/v1/transfers/${id}/accept-transfer-buyer`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER));

    if (!response.error) {
      yield put(apiSuccess(TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia exitosa'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al obtener los datos' }));
      yield put(apiSuccess(TRANSFERPREVIEW.ACCEPT_TRANSFER_BUYER_ERROR));
    }
  });
}

function* generatePayment() {
  yield takeLatest(TRANSFERPREVIEW.GENERATE_PAYMENT, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFERPREVIEW.GENERATE_PAYMENT));
    const response = yield post(`api/v1/payments/create?id=${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFERPREVIEW.GENERATE_PAYMENT));

    if (!response.error) {
      yield put(apiSuccess(TRANSFERPREVIEW.GENERATE_PAYMENT_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al generar el pago' }));
      yield put(apiSuccess(TRANSFERPREVIEW.GENERATE_PAYMENT_ERROR));
    }
  });
}

function* deleteTransfer() {
  yield takeLatest(TRANSFERPREVIEW.DELETE_TRANSFER, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, TRANSFERPREVIEW.DELETE_TRANSFER));
    const response = yield destroy(`api/v1/transfers/${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, TRANSFERPREVIEW.DELETE_TRANSFER));

    if (!response.error) {
      yield put(apiSuccess(TRANSFERPREVIEW.DELETE_TRANSFER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia rechazada'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al rechazar la transferencia' }));
      yield put(apiSuccess(TRANSFERPREVIEW.DELETE_TRANSFER_ERROR));
    }
  });
}

function* createTransferPDF() {
  yield takeLatest(
    TRANSFERPREVIEW.CREATE_TRANSFER_PDF,
    function* createTransferPDFGenerator(action) {
      const { transferId } = action.payload;

      const response = yield get(`api/v1/transfers/create-pdf/${transferId}`);

      if (!response.error) {
        const { fileId, objects } = response;
        yield put({ type: TRANSFERPREVIEW.CREATE_TRANSFER_PDF_SUCCESS, payload: { fileId } });
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, { objects }));
      } else {
        const error = JSON.parse(response.errorMsg);
        const { errorMessage } = error;
        yield put({
          type: TRANSFERPREVIEW.CREATE_TRANSFER_PDF_FAILURE,
          payload: { errorMessage },
        });
        yield put(apiSuccess(APP.SET_ERROR, { errorMsg: errorMessage }));
      }
    },
  );
}

function* getTransfer() {
  yield takeLatest(TRANSFERPREVIEW.GET_TRANSFER, function* getTransferGenerator(action) {
    const { transferId } = action.payload;

    const response = yield apiRequest(
      `api/v1/transfers/get-one-transfer/${transferId}`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      yield put({
        type: TRANSFERPREVIEW.GET_TRANSFER_SUCCESS,
      });
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put({
        type: TRANSFERPREVIEW.GET_TRANSFER_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

function* createPayment() {
  yield takeLatest(TRANSFERPREVIEW.CREATE_PAYMENT, function* creatPaymentGenerator(action) {
    const { transferId, isBikfyPayment, paymentMethod } = action.payload;

    const body = JSON.stringify({ isBikfyPayment, paymentMethod });

    const response = yield apiRequest(
      `api/v1/payments/create-transbank/${transferId}`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      const { url, token } = response;
      yield put({
        type: TRANSFERPREVIEW.CREATE_PAYMENT_SUCCESS,
        payload: {
          url,
          token,
        },
      });
    } else {
      const { errorMessage } = JSON.parse(response.errorMsg);
      yield put({
        type: TRANSFERPREVIEW.CREATE_PAYMENT_FAILURE,
        payload: { errorMessage },
      });
    }
  });
}

function* confirmStoreTransfer() {
  yield takeLatest(
    TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER,
    function* confirmStoreTransferGenerator(action) {
      const { transferId, isBikfyPayment, paymentMethod } = action.payload;

      const body = JSON.stringify({ isBikfyPayment, paymentMethod });

      const response = yield apiRequest(
        `api/v1/payments/confirm-store-external-payment/${transferId}`,
        { method: 'POST', body },
        true,
      );

      if (!response.error) {
        const {
          objects: { transfers },
        } = response;
        yield put(apiSuccess(TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER_SUCCESS, response));
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      } else {
        const { errorMessage } = JSON.parse(response.errorMsg);
        yield put({
          type: TRANSFERPREVIEW.CONFIRM_STORE_TRANSFER_FAILURE,
          payload: { errorMessage },
        });
      }
    },
  );
}

function* createMercadoPagoPayment() {
  yield takeLatest(
    TRANSFERPREVIEW.CREATE_MERCADO_PAGO_PAYMENT,
    function* creatMercadoPagoPaymentGenerator(action) {
      const { transferId, paymentMethod } = action.payload;

      const body = JSON.stringify({ paymentMethod });

      const response = yield apiRequest(
        `api/v1/payments/create-mercado-pago/${transferId}`,
        { method: 'POST', body },
        true,
      );

      if (!response.error) {
        yield put({
          type: TRANSFERPREVIEW.CREATE_PAYMENT_SUCCESS,
          payload: {
            url: response.url,
            token: '0610',
          },
        });
      } else {
        console.log('Error Front');
      }
    },
  );
}

function* generateNewVerificationCode() {
  yield takeLatest(TRANSFERPREVIEW.GENERATE_NEW_CONFIRMATION_CODE, function* (action) {
    const { transferId } = action.payload;

    const response = yield apiRequest(
      `api/v1/transfers/${transferId}/verification-code`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      const { message } = response;

      yield put({
        type: TRANSFERPREVIEW.GENERATE_NEW_CONFIRMATION_CODE_SUCCESS,
        payload: {
          message,
        },
      });
    } else {
      const { message } = JSON.parse(response.errorMsg);

      yield put({
        type: TRANSFERPREVIEW.OPERATION_FAILURE,
        payload: { message },
      });
    }
  });
}

function* validateVerificationCode() {
  yield takeLatest(TRANSFERPREVIEW.VALIDATE_VERIFICATION_CODE, function* (action) {
    const { transferId, verificationCode } = action.payload;

    const body = JSON.stringify({ verificationCode });

    const response = yield apiRequest(
      `api/v1/transfers/${transferId}/verification-code`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      const { objects } = response;
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, { objects }));
    } else {
      const { message } = JSON.parse(response.errorMsg);

      yield put({
        type: TRANSFERPREVIEW.OPERATION_FAILURE,
        payload: { message },
      });
    }
  });
}

export default function* root() {
  yield spawn(fetchTransferPreview);
  yield spawn(acceptTransferSeller);
  yield spawn(acceptTransferBuyer);
  yield spawn(generatePayment);
  yield spawn(deleteTransfer);
  yield spawn(createTransferPDF);
  yield spawn(getTransfer);
  yield spawn(createPayment);
  yield spawn(createMercadoPagoPayment);
  yield spawn(generateNewVerificationCode);
  yield spawn(validateVerificationCode);
  yield spawn(confirmStoreTransfer);
}
