import React, { useEffect, useLayoutEffect } from 'react';
import { withRouter, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Project
import { appActions } from 'commons/reducer';
import { actionProps, selectState } from 'utils/reduxActions';
import { ReactReduxContext } from 'config/configureStore';
import connectSaga from 'utils/saga';

// containers
// styles
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import * as locales from '@material-ui/core/locale';
import saga from '../commons/saga';
import { Routes } from './components';
import colors from '../utils/colors';

import './styles/general.styles.scss';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.primary,
        contrastText: '#fff',
      },
      secondary: {
        main: colors.danger,
      },
    },
    typography: {
      fontFamily: ["'Mark Pro'", 'sans-serif'].join(','),
    },
  },
  locales.esES,
);

const App = (props) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    props.actions.getUser();
  }, [props.actions]);

  if (props.loadingUser) return <div />;

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Routes user={props.user?.user} />
      </Switch>
    </ThemeProvider>
  );
};

// ------------------ CONFIG CONTAINER ------------------

const withSaga = connectSaga({ key: 'sagaApp', saga });

const withConnect = connect(
  selectState(
    'app.user',
    'app.loadingUser',
  ),
  actionProps(appActions),
  null,
  { context: ReactReduxContext },
);

export default compose(withRouter, withConnect)(withSaga(App));
