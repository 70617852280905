import { put, spawn, takeLatest } from 'redux-saga/effects';

import { salesMethodActionTypes } from '../reducer';
import apiRequest from '../../../utils/api';

function* updateSalesMethod() {
  yield takeLatest(salesMethodActionTypes.UPDATE_SALES_METHOD, function* (action) {
    const { enableBikfyPay, transferId } = action.payload;

    const body = JSON.stringify({
      enableBikfyPay,
    });

    const response = yield apiRequest(
      `api/v1/transfers/${transferId}/user-payment-method`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      yield put({ type: salesMethodActionTypes.UPDATE_SALES_METHOD_SUCCESS });
    } else {
      yield put({
        type: salesMethodActionTypes.UPDATE_SALES_METHOD_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

function* checkIfBikeAlreadySold() {
  yield takeLatest(salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD, function* (action) {
    const { transferId } = action.payload;
    const response = yield apiRequest(
      `api/v1/bikes/check-if-bike-was-sold-by-last-user/${transferId}`,
      { method: 'get' },
    );
    if (!response?.error) {
      yield put({
        type: salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: salesMethodActionTypes.CHECK_IF_BIKE_WAS_ALREADY_SOLD_ERROR,
      });
    }
  });
}

export default function* PaymentMethodSaga() {
  yield spawn(updateSalesMethod);
  yield spawn(checkIfBikeAlreadySold);
}
