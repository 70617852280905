import * as React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { FormHelperText, TextField } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomMultipleSelect = ({
  name,
  label,
  value,
  options,
  onChange,
  error,
  helperText,
  firstOptionText,
}) => {
  const handleChange = (e) => {
    onChange({ target: { name, value: e.target.value } });
  };

  const getSelectValues = (selected) =>
    options
      .filter((item) => selected.includes(item.value))
      .map((record) => record.label)
      .join(', ');

  return (
    <div>
      <FormControl fullWidth>
        <TextField
          color="primary"
          select
          SelectProps={{
            multiple: true,
            value: value ?? [],
            renderValue: options ? getSelectValues : '',
          }}
          variant="standard"
          label={label}
          onChange={handleChange}
          error={!!error}
          helperText={error}>
          <MenuItem disabled value="">
            {firstOptionText}
          </MenuItem>
          {options?.map((item, index) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox color="primary" checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default CustomMultipleSelect;
