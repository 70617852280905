/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from '@material-ui/core';

const ConfirmModal = (props) => {
  const {
    isOpen,
    setEnableBikfyPay,
    toggleConfirmModal,
    enableBikfyPay = true,
    className,
    handleClickAcceptModalButton,
    isShowButtons,
    fullScreen,
    is393pxWidthScreen,
  } = props;

  const handleClickEnableBikfyPayButton = () => {
    setEnableBikfyPay(true);
  };

  const handleOnClose = () => {
    toggleConfirmModal();
  };

  const noSelectedBikfyPayModalContent = (
    <>
      <Typography className="message">
        Vende tu Bike de manera <span className="bold"> rápida y segura </span>.
      </Typography>
      <ul>
        <li>
          <Typography className="message">
            Evita <span className="customRedOne"> fraudes y riesgos </span> en la transacción
            monetaria.
          </Typography>
        </li>
        <li>
          <Typography className="message">
            Habilita que el comprador pueda pagar tu Bike con tarjetas de
            <span className="bold"> Crédito o Débito </span>.
          </Typography>
        </li>
      </ul>
      <Typography className="message">
        El <span className="bikfyColor"> BIKFY</span>PAY no es excluyente al Pago Externo, es solo
        un servicio adicional y es el comprador quien finalmente decide qué método de pago a
        utilizar. En caso de que el comprador seleccione Pago Externo, no habrá una comisión por
        servicio.
      </Typography>
    </>
  );

  const selectedBikfyPayModalContent = (
    <Typography className="message">
      Recibirás tu dinero, descontada la comisión por servicios, en la cuenta bancaria ingresada
      dentro de un máximo de
      <span className="bold"> 5 días </span> hábiles desde la confirmación de recepción de la
      bicicleta por parte del comprador.
    </Typography>
  );

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} className={className} onClose={handleOnClose}>
      {fullScreen && (
        <Box className="close-button-container">
          <Button onClick={handleOnClose} className="close-button">
            X
          </Button>
        </Box>
      )}
      <DialogTitle className="title">
        <span className="bikfyColor">BIKFY</span>PAY
      </DialogTitle>
      <DialogContent className="content">
        {enableBikfyPay ? selectedBikfyPayModalContent : noSelectedBikfyPayModalContent}
      </DialogContent>
      {isShowButtons && (
        <DialogActions
          className={`buttonsContainer ${is393pxWidthScreen ? 'mobile-container' : ''}`}>
          {!enableBikfyPay && (
            <Button
              className="enable-button"
              variant="contained"
              color="primary"
              onClick={handleClickEnableBikfyPayButton}>
              Habilitar
            </Button>
          )}

          <Button
            id="id_button_accept_transfer_process"
            variant="contained"
            className="continue-button"
            color={!enableBikfyPay ? 'secondary' : 'primary'}
            onClick={handleClickAcceptModalButton}>
            {!enableBikfyPay ? 'Continuar de todos modos' : 'Aceptar'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setEnableBikfyPay: PropTypes.func.isRequired,
  toggleConfirmModal: PropTypes.func.isRequired,
  handleClickAcceptModalButton: PropTypes.func.isRequired,
  enableBikfyPay: PropTypes.bool,
  className: PropTypes.string,
  isShowButtons: PropTypes.bool,
  fullScreen: PropTypes.bool,
  is393pxWidthScreen: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  className: '',
  isShowButtons: true,
  fullScreen: false,
  is393pxWidthScreen: false,
};

export default ConfirmModal;
