import React, { useEffect, useRef, useState } from 'react';
import { getItemInStorage } from 'utils/functions';
import { Document, Page, pdfjs } from 'react-pdf';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Grid, Button } from '@material-ui/core';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const TransferContract = ({ id, isMobileScreen, handleDownloadPdf }) => {
  const [pdf, setPdf] = useState('');
  const [width, setWidth] = useState('');
  const ref = useRef();

  useEffect(() => {
    const session = getItemInStorage('user');
    const authorizationHeader = { Authorization: `Bearer ${session && session.token}` };

    fetch(`/api/v1/documents/get/${id}`, { headers: authorizationHeader })
      .then((response) => response.blob())
      .then((myBlob) => {
        const reader = new FileReader();
        reader.onload = function () {
          setPdf(this.result);
        };
        reader.readAsDataURL(myBlob);
      });
  }, [id]);

  useEffect(() => {
    setWidth(isMobileScreen ? window.innerWidth * 0.915 : ref.current.offsetWidth);
  }, [pdf]);

  return (
    <div
      ref={ref}
      style={{
        maxWidth: '95vw',
        overflow: 'auto',
      }}>
      <Document file={pdf}>
        <Page pageNumber={1} width={width} />
      </Document>
      {!isMobileScreen && (
        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <IconButton color="inherit" onClick={handleDownloadPdf}>
            <GetAppIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TransferContract;
