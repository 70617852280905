import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import banner from 'commons/assets/banner/banner_kuhl.png';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
    height: '200px',
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '150px', // Adjust height for mobile
    },
  },
  bannerText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', // Adjust font size for mobile
    },
  },
}));

const MarketingBanner = ({ isMobileScreen }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.bannerContainer} style={isMobileScreen ? { height: '120px' } : {}}>
      <Grid item xs={12}>
        <Typography className={classes.bannerText}>
          <br />
          <br />
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MarketingBanner;
