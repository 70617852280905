import React from 'react';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const CustomInputAdornment = ({
  label, name, value, onChange, error, helperText, openAlert, disabled,
}) => (

  <TextField
    disabled={disabled}
    variant="outlined"
    label={label}
    fullWidth
    name={name}
    type="text"
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    InputProps={
          {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => openAlert()}
                  edge="end"
                >
                  <InfoIcon />
                </IconButton>
              </InputAdornment>
            ),
          }
      }
  />

);

export default CustomInputAdornment;
