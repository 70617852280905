import camelize from 'camelcase-keys-deep';

export default class Bike {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    let response = null;
    if (this.entityId) {
      response = this.env().Entity.findBy('id', this.entityId);
    }
    return response;
  }

  get brand() {
    let response = null;
    if (this.brandId) {
      response = this.env().Brand.findBy('id', this.brandId);
    }
    return response;
  }

  get category() {
    let response = null;
    if (this.categoryId) {
      response = this.env().Category.findBy('id', this.categoryId);
    }
    return response;
  }

  get model() {
    let response = null;
    if (this.modelId) {
      response = this.env().Model.findBy('id', this.modelId);
    }
    return response;
  }

  get size() {
    let response = null;
    if (this.sizeId) {
      response = this.env().Size.findBy('id', this.sizeId);
    }
    return response;
  }

  get rim() {
    let response = null;
    if (this.rimId) {
      response = this.env().Rim.findBy('id', this.rimId);
    }
    return response;
  }

  get document() {
    let response = null;
    if (this.id) {
      response = this.env().Document.findAllBy('bikeId', this.id);
    }
    return response;
  }

  get maintenances() {
    let response = null;
    if (this.id) {
      response = this.env().Maintenance.findAllBy('bikeId', this.id);
    }
    return response;
  }
}
