const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paperAnchorLeft: {
    width: '300px',
  },
  titles: {
    marginTop: '15px',
    textAlign: 'center',
  },
  icon: {
    width: '240px',
    height: '162px',
    display: 'inline-block',
    marginRight: '5px',
  },
  nested: {
    paddingLeft: theme.spacing(7),
  },
});

export default styles;
