import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@material-ui/core';
import { STORE_BIKE_STATUS } from 'utils/consts';

const BikeSelectorByState = (props) => {
  const { sortBikes, storeValue } = props;

  const [selectedOption, setSelectedOption] = useState('my_registers');

  useEffect(() => {
    setSelectedOption(storeValue.field);
  }, [storeValue]);

  const handleChange = (event) => {
    const { value } = event.target;
    sortBikes(value);
  };

  return (
    <TextField
      value={selectedOption}
      select
      variant="outlined"
      onChange={handleChange}
      size="small"
      style={{ width: '100%' }}>
      {/* <MenuItem value="all">Ver todas</MenuItem> */}
      <MenuItem value={STORE_BIKE_STATUS['1']}>Mis registros</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['2']}>Mis registros pendientes</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['3']}>Bikes con acceso otorgado</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['4']}>Bikes con acceso pendiente</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['5']}>Bikes publicadas por mí</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['6']}>Bikes publicadas por pilot</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['9']}>Mis transferencias pendientes</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['7']}>Bikes transferidas</MenuItem>
      <MenuItem value={STORE_BIKE_STATUS['8']}>Bikes denunciadas</MenuItem>
    </TextField>
  );
};

BikeSelectorByState.propTypes = {
  sortBikes: PropTypes.func,
};

export default BikeSelectorByState;
