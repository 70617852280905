import colors from '../../utils/colors';

const { primary, secondary } = colors;

const tuneIconStyles = {
  borderRadius: '5px',
  color: '#FFFFFF',
  padding: '8px 10px',
  marginRight: '30px',
  backgroundColor: '#5EC6D5',
};

const styles = (theme) => ({
  padding0: {
    padding: '0',
  },
  margin0: {
    margin: '0',
  },
  bikfyGreyColor: {
    color: secondary,
  },
  upperCaseText: {
    textTransform: 'uppercase',
  },
  generalContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '25px',
    width: '100%',
    maxWidth: '1440px',
    margin: '0 auto',
  },
  positionSticky: {
    position: 'sticky',
  },
  top0: {
    top: '0px',
  },
  bottom20: {
    bottom: '20px',
  },
  zIndex1: {
    zIndex: 1,
  },
  padding08px: {
    padding: '0 8px !important',
  },
  padding8px: {
    padding: '8px',
  },
  whatsappFab: {
    position: 'fixed',
    bottom: '2%',
    right: '2%',
    maxHeight: '60px',
    color: 'white',
    cursor: 'pointer',
  },
  generalFilterContainer: {
    gridColumn: '1 / 2',
    '& .paper-filter-container': {
      marginTop: '70px',
      maxWidth: '428px',
      width: '100%',
      padding: '30px 20px',
      '& .title-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .title': {
          fontSize: '24px',
        },
        '& .title-tune-icon-button': {
          display: 'none',
          marginLeft: '10px',
          width: '100%',
          maxWidth: '120px',
          ...tuneIconStyles,
        },
      },
      '& .filter-buttons-container': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        '& .search-button': {
          width: '100%',
          maxWidth: '207px',
        },
        '& .clear-icon-button': {
          border: '2px solid #5EC6D5',
          padding: '4px',
          color: '#5EC6D5',
          borderRadius: '5px',
          marginLeft: '30px',
          fontSize: '35px',
        },
      },
      '& .filter-form-container': {
        marginTop: '20px',
        paddingBottom: '6px',
      },
    },
  },
  gridButton: {
    background: 'cyan1',
    border: '1px solid',
    borderColor: '#45C6D9',
    marginRight: '10px',
    transition: '0.3s',
    '&:hover': {
      background: '#eeeeee',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '195px',
      maxWidth: '300px',
    },
  },
  buttonBaseStyles: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  bikfyPublicationsGrid: {
    border: '2px solid',
    borderColor: '#323E48',
    transition: '0.3s',
    minWidth: '195px',
    padding: '5px', // Reduced padding
    '&:hover': {
      background: '#1a1f24',
    },
    [theme.breakpoints.between(600, 960)]: {
      minWidth: '245px',
      maxWidth: '300px',
      marginBottom: '10px',
      marginLeft: '10px',
      marginTop: '5px',
    },
  },
  marketplacePublicationsGrid: {
    border: '2px solid',
    borderColor: '#45C6D9',
    transition: '0.3s',
    minWidth: '195px',
    '&:hover': {
      background: '#3a929e',
    },
    [theme.breakpoints.between(600, 960)]: {
      minWidth: '245px',
      maxWidth: '300px',
      marginBottom: '10px',
      marginLeft: '10px',
      marginTop: '5px',
    },
  },
  searchBikfyPublicationsButtonBase: {
    width: '100%',
    marginRight: '12px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  offerBikePilotTitle: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    marginTop: '15px',
  },
  offerBikePilotSubtitle: {
    fontSize: '0.85rem',
    paddingBottom: '10px',
  },
  showBikfyPublicationsButtonText: {
    display: 'flex',
    alignItems: 'center',
    color: '#45C6D9',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    paddingBottom: '5px',
    marginTop: '5px',
    [theme.breakpoints.between('lg')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '65px',
      marginTop: '0px',
      paddingBottom: '0px',
    },
  },
  bikesGeneralContainer: {
    gridColumn: '2 / 4',
    '& .buttons-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      '& .two-elements-container': {
        display: 'flex',
        '& .tune-icon-button': {
          ...tuneIconStyles,
        },
        '& .sorted-by-container': {
          display: 'flex',
          alignItems: 'center',
          '& .sorted-by-label': {
            fontSize: '14px',
            fontWeight: '700',
            textTransform: 'uppercase',
            marginRight: '10px',
            color: secondary,
          },
          '& .sorted-by-buttons-container': {
            '& .sorted-by-button': {
              backgroundColor: 'transparent',
              border: '2px solid #000000',
              padding: '5px 10px',
              fontWeight: '700',
              '& .icon': {
                marginLeft: '5px',
                fontSize: '20px',
              },
              '&:last-of-type': {
                marginLeft: '25px',
              },
            },
          },
        },
      },
      '& .change-type-list-button': {
        padding: '0px',
        '& .icon': {
          fontSize: '40px',
        },
      },
    },
    '& .custom-pagination-container': {
      display: 'inline-block',
      '& .custom-pagination': {
        '& .MuiTablePagination-toolbar': {
          paddingLeft: '5px',
        },
      },
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(16),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(6),
      right: theme.spacing(12),
    },
  },
  statusPaymentDialog: {
    '& .title .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      textAlign: 'center',
      '& .bikfyColor': {
        color: primary,
      },
    },
    '& .dialogContent': {
      margin: '15px 0px',
      padding: '0px 140px',
      '& .stepper': {
        '& .stepTitle': {
          fontSize: '24px',
          lineHeight: '28px',
        },
        '& *.completed': {
          color: primary,
        },
        '& *.bold': {
          fontWeight: '700',
        },
      },
    },
    '& .buttonContainer': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    '& .MuiPaper-root': {
      maxWidth: '890px',
    },
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },
  vehicleTypeInput: {
    marginTop: '10px',
    marginRight: '10px',
  },
  [theme.breakpoints.down('md')]: {
    generalFilterContainer: {
      '& .paper-filter-container': {
        marginTop: '0px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    generalContainer: {
      gridTemplateColumns: '1fr',
    },
    generalFilterContainer: {
      gridColumn: 'unset',
      margin: '0px 5px',
      '& .paper-filter-container': {
        maxWidth: 'unset',
        padding: '10px 5px',
        '& .title-container': {
          '& .title-tune-icon-button': {
            display: 'block',
          },
        },
      },
    },
    bikesGeneralContainer: {
      gridColumn: 'unset',
      '& .buttons-container': {
        '& .two-elements-container': {
          '& .tune-icon-button': {
            display: 'none',
          },
          '& .sorted-by-container': {
            width: '100%',
            marginRight: '10px',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .sorted-by-button': {
              '&:last-of-type': {
                marginLeft: '10px',
              },
            },
          },
        },
      },
    },
    statusPaymentDialog: {
      '& .dialogContent': {
        padding: '0px',
      },
    },
  },
});

export default styles;
