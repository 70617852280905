import React from 'react';
import {
  Typography, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    // backgroundColor: '#f0f8ff',
    backgroundColor: '#303E48',
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MarketplaceTitle = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="h5"
        component="h3"
        style={{
          fontWeight: '500',
          fontSize: '28px',
          paddingBottom: '20px',
          color: '#ffffff',
        }}
      >
        {' '}

        {/* EL MARKETPLACE PARA COMPRAR Y VENDER SEGURO TU BIKE */}
        EL ÚNICO MARKETPLACE DONDE OBTIENES LA PROPIEDAD LEGAL DE TU BIKE
        {/* Venta de motos y bicicletas usadas. */}
        {/* VENTA DE MOTOS Y BICICLETAS USADAS */}
        {/* COMPRA BIKES DEBIDAMENTE REGISTRADAS Y OBTÉN LEGALMENTE SU PROPIEDAD */}
      </Typography>
      <Typography
        variant="h6"
        style={{
          fontWeight: '400',
          fontSize: '24px',
          color: '#ffffff',
          lineHeight: '1.2', // Adjust the value to reduce or increase line height
        }}
        gutterBottom
        component="h1"
      >
        Venta de bicicletas y motos usadas debidamente registradas
      </Typography>

      <Typography
        variant="h6"
        style={{
          fontWeight: '400',
          fontSize: '16px',
          // paddingBottom: '12px',
          color: '#ffffff',
        }}
        gutterBottom
      />

    </div>
  );
};

export default MarketplaceTitle;
