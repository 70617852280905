import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'download-bike-data-store/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

const BIKE_REGISTERS_FILE_NAME = 'Datos_Registro_Bike';
const BIKE_TRANSFERS_FILE_NAME = 'Datos_Transferencia_Bike';

// Global constants for saga
export const DOWNLOAD_BIKE_DATA_STORE = {
  DOWNLOAD_REGISTERED_BIKES_FILE: `${PATH}DOWNLOAD_REGISTERED_BIKES_FILE`,
  DOWNLOAD_REGISTERED_BIKES_FILE_SUCCESS: `${PATH}DOWNLOAD_REGISTERED_BIKES_FILE_SUCCESS`,
  DOWNLOAD_REGISTERED_BIKES_FILE_ERROR: `${PATH}DOWNLOAD_REGISTERED_BIKES_FILE_ERROR`,
  DOWNLOAD_BIKES_TRANSFERS_FILE: `${PATH}DOWNLOAD_BIKES_TRANSFERS_FILE`,
  DOWNLOAD_BIKES_TRANSFERS_FILE_SUCCESS: `${PATH}DOWNLOAD_BIKES_TRANSFERS_FILE_SUCCESS`,
  DOWNLOAD_BIKES_TRANSFERS_FILE_ERROR: `${PATH}DOWNLOAD_BIKES_TRANSFERS_FILE_ERROR`,
  CLEAR_CSV_DATA: `${PATH}CLEAR_CSV_DATA`,
};

// actions
export const downloadBikeDataStoreActions = {
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  downloadRegisteredBikesFile: createAction(
    DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE,
    'payload',
  ),
  downloadBikeTransfersFile: createAction(
    DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE,
    'payload',
  ),
  clearCsvData: createAction(DOWNLOAD_BIKE_DATA_STORE.CLEAR_CSV_DATA),
};

const initialState = {
  loading: false,
  error: false,
  csvData: [],
  fileName: '',
};

const downloadBikeDataStore = createReducer(initialState, {
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE](state) {
    state.loading = true;
    state.error = false;
  },
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE_SUCCESS](state, action) {
    state.fileName = BIKE_REGISTERS_FILE_NAME;
    state.csvData = action.payload.csvData;
    state.loading = false;
    state.error = false;
  },
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE](state) {
    state.loading = true;
    state.error = false;
  },
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE_SUCCESS](state, action) {
    state.fileName = BIKE_TRANSFERS_FILE_NAME;
    state.csvData = action.payload.csvData;
    state.loading = false;
    state.error = false;
  },
  [DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [DOWNLOAD_BIKE_DATA_STORE.CLEAR_CSV_DATA](state) {
    state.csvData = [];
  },
});

export default downloadBikeDataStore;
