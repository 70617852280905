// React imports
import React, { useState } from 'react';
// Material imports
import {
  Avatar, Chip, CircularProgress, Fab, Grid,
} from '@material-ui/core';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { IMG_CROP_RATIO } from 'utils/functions';
import styles from './styles';

const fileType = ['Foto de portada', 'Foto frontal', 'Foto izquierdo'];

const SortablePhoto = React.memo(
  SortableElement(({
    value, myIndex, onEditCrop, onRemove,
  }) => {
    const [isHovered, setHover] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const imageStyles = {
      objectFit: 'cover',
      width: IMG_CROP_RATIO >= 1 ? 200 : IMG_CROP_RATIO * 200,
      height: IMG_CROP_RATIO >= 1 ? 200 / IMG_CROP_RATIO : 200,
    };

    return (
      <div>
        {!loaded && (
          <div style={styles.imageContainer}>
            <CircularProgress />
          </div>
        )}
        <div
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{ ...styles.imageContainer, display: loaded ? 'flex' : 'none' }}
        >
          {loaded &&
            <Chip
              style={styles.index}
              label={fileType[myIndex] || (myIndex + 1) - fileType.length}
              color="primary"
            />
          }
          <img alt="lot" onLoad={() => setLoaded(true)} src={value.src} style={imageStyles} />
          {isHovered && (
            <>
              <Fab size="small" style={styles.extraFab} onClick={() => onEditCrop(myIndex)}>
                <EditIcon />
              </Fab>
              <Fab size="small" style={styles.fab} onClick={() => onRemove(myIndex)}>
                <DeleteIcon />
              </Fab>
            </>
          )}
        </div>
      </div>
    );
  }),
);

const SortableGallery = SortableContainer(({ items, onEditCrop, onRemove }) => (
  <Grid container spacing={3} style={{ margin: 0 }}>
    {items.map((value, index) => (
      <SortablePhoto
        key={index}
        index={index}
        myIndex={index}
        value={value}
        onEditCrop={onEditCrop}
        onRemove={onRemove}
      />
    ))}
  </Grid>
));

const SortedImages = ({
  images, onSortEnd, onEditCrop, onRemove,
}) => (
  <SortableGallery
    distance={1}
    items={images}
    onSortEnd={onSortEnd}
    axis="xy"
    onEditCrop={onEditCrop}
    onRemove={onRemove}
  />
);

export default SortedImages;
