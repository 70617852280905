import colors from '../../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  amountBoxContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .amountTitleContainer': {
      position: 'absolute',
      padding: '0px 4px',
      top: '-10px',
      backgroundColor: '#f9fafb',
      '& .amountTitle': {
        color: primary,
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
      },
    },
    '& .amountBox': {
      width: '280px',
      padding: '20px',
      textAlign: 'center',
      border: `2px solid ${primary}`,
      borderRadius: '10px',
      color: primary,
      '& .amount': {
        fontWeight: 'bold',
        fontSize: '24px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    titleContainer: {
      padding: '20px 0px',
    },
    cardsContainer: {
      padding: '0px',
    },
    amountsContainer: {
      padding: '20px 0px',
    },
    formContainer: {
      marginTop: '20px',
      padding: '0px',
      width: '100%',
    },
    bankDataForm: {
      padding: '0px',
      '& .formInput': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    amountListContainer: {
      '& .amountList': {
        width: '260px',
      },
    },
    amountBoxContainer: {
      '& .amountTitleContainer': {
        top: '-10px',
        right: '40px',
      },
      '& .amountBox': {
        width: '200px',
      },
    },
    buttonsContainer: {
      '& .goBackButton': {
        marginRight: '20px',
      },
    },
    confirmModalStyle: {
      '& .content': {
        flex: 'none',
      },
      '& .buttonsContainer': {
        '&.mobile-container': {
          flexDirection: 'column',
          '& .continue-button': {
            marginTop: '10px',
            width: '100%',
            marginLeft: '0px',
          },
          '& .enable-button': {
            width: '100%',
          },
        },
      },
    },
  },
});

export default styles;
