import colors from '../../../../utils/colors';

const { primary, white, secondary } = colors;

export const styles = (theme) => ({
  root: {
    
  },
  minHeight430px: {
    minHeight: '430px',
  },
  bikfyGreyColor: {
    color: secondary,
  },
  boldFont: {
    fontWeight: '700',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
  },
  content: {
    flex: '1 0 auto',
    paddingTop: '10px',
    paddingBottom: '5px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  mozaicIcon: {
    fontSize: '1.6rem',
  },
  mozaicCardPrice: {
    marginTop: '5px',
  },
  normalListPrice: {
    fontSize: '28px',
  },
  normalListTitleCard: {
    fontSize: '28px',
    fontWeight: '700',
  },
  sizeYearTypography: {
    fontSize: '18px',
    margin: '5px',
  },
  iconsService: {
    minHeight: '60px',
  },
  imgContainer: {
    maxWidth: '252px',
  },
  card: {
    display: 'flex',
    width: '100%',
    maxWidth: '846px',
    margin: '10px 0px',
    height: '251px',
    '& .image-container': {
      maxWidth: '275px',
      '& .custom.link-icon': {
        textDecoration: 'none',
        color: '#000',
        display: 'block',
        width: '200px',
        height: '200px',
        margin: '20px auto',
        '& .icon': {
          width: '100%',
          height: '100%',
        },
      },
      '& .bike-img': {
        width: '100%',
        height: '300px',
        objectFit: 'cover',
      },
    },
    '& .general-info-container': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '25px 0px 0px 15px',
      '& .title-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .title': {
          display: 'inline',
          fontWeight: '700',
          fontSize: '40px',
          color: '#323E48',
          '& .mozaic-card-title-variant': {
            display: 'none',
          },
        },
        '& .edit-icon-button': {
          backgroundColor: '#E9EDF0',
          marginLeft: '20px',
          padding: '8px',
          '& .icon': {
            fontSize: '20px',
            color: '#000',
          },
        },
      },
      '& .info-container': {
        '& .info-typography': {
          color: '#323E48',
          fontSize: '18px',
          fontWeight: '700',
        },
      },
      '& .price-container': {
        '& .price-typography': {
          fontSize: '28px',
          fontWeight: '700',
          color: '#323E48',
        },
      },
      '& .icons-option-button-container': {
        '& .services-bikfy-icons-container': {
          '& .services-bikfy-icons': {
            height: '20px',
            marginRight: '5px',
            '&:first-of-type': {
              marginRight: '0px',
            },
          },
        },
      },
      '& .action-buttons-container': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        '& .option-button-container': {
          '& .option-button': {
            margin: '5px 0px',
            borderRadius: '5px',
            width: '60px',
            padding: '5px',
          },
        },
        '& .button': {
          marginRight: '5px',
          padding: '5px 10px',
          maxWidth: '200px',
          width: '100%',
          fontSize: '12px',
        },
        '& .email-button': {
          border: `3px solid ${primary}`,
        },
        '& .variant-color-one': {
          backgroundColor: '#303e48',
        },
        '& .state-button': {
          width: '100%',
        },
        '& .mr-5': {
          marginRight: '5px',
        },
        '& .b-none': {
          border: 'none',
        },
      },
    },
  },
  mozaicCard: {
    width: '100%',
    maxWidth: '245px',
    maxHeight: '290px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .image-container': {
      height: '199px',
      '& .custom.link-icon': {
        textDecoration: 'none',
        color: '#000',
        display: 'block',
        width: '150px',
        height: '150px',
        margin: '20px auto',
        '& .icon': {
          width: '100%',
          height: '100%',
        },
      },
    },
    '& .general-info-container': {
      padding: '5px',
      '& .title-container': {
        '& .title': {
          fontWeight: '700',
          fontSize: '15px',
        },
        '& .edit-icon-button': {
          display: 'none',
        },
      },
      '& .price-container': {
        '& .price-typography': {
          fontSize: '14px',
        },
      },
      '& .info-container': {
        '& .info-typography': {
          fontSize: '10px',
          fontWeight: '700',
        },
      },
      '& .icons-option-button-container': {
        display: 'flex',
        justifyContent: 'space-between',
        '& .services-bikfy-icons-container': {
          '& .services-bikfy-icons': {
            height: '15px',
            '&:first-of-type': {
              marginRight: '-5px',
            },
          },
        },
      },
    },
    '& .action-buttons-container': {
      display: 'none',
    },
    '& .option-button-container': {
      '&.d-none': {
        display: 'none',
      },
      '& .option-button': {
        height: '20px',
        padding: '0px',
        marginLeft: '6px',
      },
    },
  },
  flex1: {
    flex: 1,
  },
  darkButton: {
    backgroundColor: secondary,
    color: white,
  },
  responsiveIcon: {
    width: '90%',
    height: '80%',
  },
  marginRigth5: {
    marginRight: '10px',
  },
  marginRight20: {
    marginRight: '20px',
  },
  bikfyColorFont: {
    color: primary,
  },
  [theme.breakpoints.down('md')]: {
    card: {
      '& .image-container': {
        width: '200px',
      },
      '& .general-info-container': {
        '& .action-buttons-container': {
          '& .button': {
            padding: '5px 5px',
          },
        },
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    card: {
      '& .general-info-container': {
        margin: '25px 2px',
        '& .title-container': {
          '& .title': {
            display: 'inline',
            fontWeight: '700',
            fontSize: '30px',
          },
        },
        '& .price-container': {
          '& .price-typography': {
            fontSize: '25px',
          },
        },
        '& .info-container': {
          '& .info-typography': {
            color: '#323E48',
            fontSize: '20px',
          },
        },
      },
    },
    responsiveIcon: {
      width: '50%',
      height: '50%',
    },
    imgContainer: {
      margin: '0 auto',
    },
  },
  [theme.breakpoints.down('xs')]: {
    controls: {
      '& .button-container': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .cut-text-button': {
        width: '130px',
      },
    },
    content: {
      '& .bike-info-container': {
        display: 'flex',
      },
    },
  },
});

export default styles;
