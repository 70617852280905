/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// import material
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';

import CustomInputNumber from 'commons/components/CustomInputNumber';
import TooltipButton from 'commons/components/TooltipButton';

const TransferForm = ({
  controls = {},
  errors = {},
  onControlChange,
  onSubmit,
  profileComplete,
}) => (
  <form onSubmit={onSubmit} autoComplete="off">
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={10} sm={7} md={11}>
        <CustomInputNumber
          disabled={!profileComplete}
          label="Precio de venta (CLP)"
          name="amount"
          onChange={onControlChange}
          value={controls.amount}
          error={!!errors.amount}
          helperText={errors.amount}
        />
      </Grid>
      <Grid item xs={2} sm={1}>
        <TooltipButton
          text1="El Precio de venta es importante porque estará incluido en el Contrato Compraventa
                        de tu Bike."
          arrow
          placement="right">
          <InfoIcon />
        </TooltipButton>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={10} sm={7} md={11}>
        <TextField
          disabled={!profileComplete}
          fullWidth
          variant="outlined"
          label="Email del comprador"
          id="email"
          name="email"
          value={controls.email}
          onChange={onControlChange}
          error={!!errors.email}
          helperText={errors.email && errors.email[0]}
        />
      </Grid>
    </Grid>
  </form>
);

export default TransferForm;
