import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { TablePagination } from '@material-ui/core';

const CustomDataGrid = ({
  rows, columns,
}) => (
  <div style={{ height: '400px', width: '100%' }}>
    <DataGrid
      disableColumnMenu
      disableSelectionOnClick
      disableColumnSelector
      disableMultipleSelection
      pageSize={5}
      columns={columns}
      rows={rows}
      components={{
        NoRowsOverlay: () => (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            La BIKE no cuenta con mantenciones
          </div>
        ),
      }}
    />
  </div>
);

export default CustomDataGrid;
