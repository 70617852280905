import {
  put, spawn, takeLatest, select,
} from 'redux-saga/effects';
import apiRequest, {
  apiSuccess, destroy, get, post,
  postForm,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { ADMIN } from 'screens/Admin/reducer';

function serialize(obj = {}) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== '') {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
}

function* fetchBikes() {
  yield takeLatest(ADMIN.FETCH_BIKES, function* (action) {
    const { payload } = action;

    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_BIKES));
    const response = yield get(`api/v1/bikes/all?${serialize(payload)}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_BIKES));

    if (!response.error) {
      yield put(apiSuccess(APP.RESET_OBJECT, 'disabledReasons'));
      yield put(apiSuccess(ADMIN.FETCH_BIKES_SUCCESS));
      yield put(apiSuccess(ADMIN.SET_PAGINATION, response.pagination));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_BIKES_ERROR, {
          errorMsg: 'Error al cargar las Bikes',
        }),
      );
    }
  });
}

function* fetchBrands() {
  yield takeLatest(ADMIN.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_BRANDS_ERROR, {
          errorMsg: 'Error al cargar las marcas',
        }),
      );
    }
  });
}

function* fetchMotorbikeCategories() {
  yield takeLatest(ADMIN.FETCH_MOTORBIKE_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_MOTORBIKE_CATEGORIES));
    const response = yield get('api/v1/motorbike-categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_MOTORBIKE_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_MOTORBIKE_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_MOTORBIKE_CATEGORIES_ERROR, {
          errorMsg: 'Error al cargar las marcas',
        }),
      );
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(ADMIN.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_MOTORBIKE_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_MOTORBIKE_BRANDS_ERROR, {
          errorMsg: 'Error al cargar las marcas',
        }),
      );
    }
  });
}

function* fetchMotorbikeModels() {
  yield takeLatest(ADMIN.FETCH_MOTORBIKE_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_MOTORBIKE_MODELS));
    const response = yield get('api/v1/motorbike-models');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_MOTORBIKE_MODELS));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_MOTORBIKE_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_MOTORBIKE_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(ADMIN.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_VEHICLE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_VEHICLE_TYPES_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículos',
        }),
      );
    }
  });
}

function* fetchEngineCapacities() {
  yield takeLatest(ADMIN.FETCH_ENGINE_CAPACITIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_ENGINE_CAPACITIES));
    const response = yield get('api/v1/engine-capacities');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_ENGINE_CAPACITIES));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_ENGINE_CAPACITIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_ENGINE_CAPACITIES_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículos',
        }),
      );
    }
  });
}

function* fetchModels() {
  yield takeLatest(ADMIN.FETCH_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_MODELS));
    const response = yield get('api/v1/models');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_MODELS));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchCategories() {
  yield takeLatest(ADMIN.FETCH_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.FETCH_CATEGORIES));
    const response = yield get('api/v1/categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.FETCH_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(ADMIN.FETCH_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(ADMIN.FETCH_CATEGORIES_ERROR, {
          errorMsg: 'Error al cargar las categorias',
        }),
      );
    }
  });
}

function* disableBike() {
  yield takeLatest(ADMIN.TOGGLE_DISABLE_BIKE, function* (action) {
    const { formValues, isDisable } = action.payload;

    const { bikeId } = yield select((state) => state.admin.disableDialog);

    let url = `api/v1/bikes/disable-bike/${bikeId}`;

    if (isDisable) {
      url = `api/v1/bikes/remove-disable-bike/${bikeId}`;
    }

    const response = yield apiRequest(
      url,
      { method: 'put', body: JSON.stringify(formValues) },
      true,
    );

    if (!response.error) {
      const { message } = response;

      yield put(apiSuccess(ADMIN.TOGGLE_DISABLE_BIKE_SUCCESS));
      yield put(
        apiSuccess(ADMIN.SET_PAGINATION, {
          ...action.payload.pagination,
          total: action.payload.pagination.total - 1,
        }),
      );
      yield put(apiSuccess(APP.SET_SUCCESS, message));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response.errorMsg,
        }),
      );
      yield put(
        apiSuccess(ADMIN.FETCH_BIKES_ERROR, {
          errorMsg: response.message,
        }),
      );
    }
  });
}

function* setDisableFormDialog() {
  yield takeLatest(ADMIN.SET_DISABLE_FORM_DIALOG, function* (action) {
    yield put(apiSuccess(ADMIN.SET_DISABLE_FORM_DIALOG_SUCCESS, action.payload));
  });
}

// New function for creating a coupon
function* createCoupon() {
  yield takeLatest(ADMIN.CREATE_COUPON, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.CREATE_COUPON));

    // Create FormData object
    const formData = new FormData();

    // Append amount, userId, and transferId as part of the JSON body
    const jsonBody = {
      amount: action.payload.amount,
      userId: action.payload.userId,
      transferId: action.payload.transferId,
    };

    // Convert jsonBody to string and append to FormData
    formData.append('jsonBody', JSON.stringify(jsonBody));

    // Append files to FormData with consistent naming
    formData.append('file1', action.payload.files[0], action.payload.files[0].name);
    formData.append('file2', action.payload.files[1], action.payload.files[1].name);

    console.log('Files being sent:', action.payload.files[0], action.payload.files[1]);

    // Send request to create coupon with files
    const response = yield postForm('api/v1/coupons/create', formData);

    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.CREATE_COUPON));

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, 'Coupon created successfully'));
      yield put(apiSuccess(ADMIN.CREATE_COUPON_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error creating coupon' }));
      yield put(apiSuccess(ADMIN.CREATE_COUPON_ERROR, { errorMsg: 'Error creating coupon' }));
    }
  });
}

function* rejectCoupon() {
  yield takeLatest(ADMIN.REJECT_COUPON, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, ADMIN.REJECT_COUPON));

    const response = yield post('api/v1/coupons/reject', { bikeId: action.payload.bikeId, userId: action.payload.userId });

    yield put(apiSuccess(APP.REMOVE_LOADING, ADMIN.REJECT_COUPON));

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, 'Bike evaluation rejected successfully'));
      yield put(apiSuccess(ADMIN.REJECT_COUPON_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error rejecting bike evaluation' }));
      yield put(apiSuccess(ADMIN.REJECT_COUPON_ERROR, { errorMsg: 'Error rejecting bike evaluation' }));
    }
  });
}

export default function* saga() {
  yield spawn(fetchBikes);
  yield spawn(fetchBrands);
  yield spawn(fetchModels);
  yield spawn(fetchCategories);
  yield spawn(disableBike);
  yield spawn(setDisableFormDialog);
  yield spawn(fetchVehicleTypes);
  yield spawn(fetchEngineCapacities);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchMotorbikeModels);
  yield spawn(fetchMotorbikeCategories);
  yield spawn(createCoupon); // Spawn new createCoupon saga
  yield spawn(rejectCoupon); // Spawn new rejectCoupon saga
}
