import { createReducer } from '../../utils/reducer';
import adminPayTypes from './adminPay.types';

const initState = {
  loaders: {
    isLoading: false,
  },
  errors: {
    isErrors: false,
    message: '',
  },
  csvData: [],
  payedTransfers: [],
};

const adminPayReducer = createReducer(initState, {
  [adminPayTypes.CLEAR_CSV_PAYED_TRANSFER](state) {
    return {
      ...state,
      ...initState,
    };
  },
  [adminPayTypes.GET_PENDING_TRANSFER](state) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
    };
  },
  [adminPayTypes.GET_PENDING_TRANSFER_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      csvData: action.payload.csvData,
    };
  },
  [adminPayTypes.GET_PENDING_TRANSFER_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
      errors: {
        isErrors: true,
        message: action.payload.errorMessage,
      },
    };
  },
  [adminPayTypes.UPLOAD_EXCEL](state) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
    };
  },
  [adminPayTypes.UPLOAD_EXCEL_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      payedTransfers: action.payload.payedTransfers,
    };
  },
  [adminPayTypes.UPLOAD_EXCEL_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        isErrors: true,
        message: action.payload.errorMessage,
      },
    };
  },
});

export default adminPayReducer;
