/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Button, Card, CardContent, CardMedia, CircularProgress, DialogTitle, Grid, IconButton, Link, Tooltip,
  Typography,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { userInfo } from 'utils/reportBikfy';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import fullwheelsLogo from '../../assets/providers/logoFullwheels.png';
import fullBikeLogo from '../../assets/banner_insurance/banner0_d.png';

const ProvidersList = ({
  data = [],
  loadingProviders,
  loadingSubscriptions,
  loadingConfirmation,
  errorProviders,
  errorSubscriptions,
  createSubscription,
  userId,
  subscriptions,
  oneclickSubscriptionUrl,
  isMobileScreen,
  hasValidBikes,
}) => {
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);

  const handleClickView = (subscriptionId) => {
    // Assuming you have an action creator to set the selected provider ID in Redux state
    // dispatch(setSelectedProvider(subscriptionId));

    // Redirect to the provider details page
    history.push(`/subscription/${subscriptionId}`);
  };

  const handleClickCreateSubscription = (providerId) => {
    // Assuming you have an action creator to set the selected provider ID in Redux state
    // dispatch(setSelectedProvider(providerId));

    // Redirect to the provider details page
    // history.push(`/provider/${providerId}`);
    setShouldRedirect(true);
    createSubscription({ userId, providerId });
  };
  const provider = data[0];

  // const hasSubscription = useMemo(() => Object.values(subscriptions).some((subscription) => subscription.providerId === provider?.id && subscription.userId === userId && subscription.status === 'active'), [subscriptions, userId, provider?.id]);
  const { hasSubscription, activeSubscription } = useMemo(() => {
    const activeSubscriptionFound = Object.values(subscriptions).find(
      (subscription) => subscription.providerId === provider?.id && subscription.userId === userId && subscription.status === 'active',
    );
    return {
      hasSubscription: !!activeSubscriptionFound,
      activeSubscription: activeSubscriptionFound,
    };
  }, [subscriptions, userId, provider?.id]);

  useEffect(() => {
    if (oneclickSubscriptionUrl && shouldRedirect) {
      window.location.href = oneclickSubscriptionUrl; // Redirects the user
      setShouldRedirect(false);
    }
  }, [oneclickSubscriptionUrl]);

  if (loadingProviders || loadingConfirmation) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const renderSubscriptionButton = () => {
    if (loadingSubscriptions) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (hasSubscription) {
      return (
        <Button
          disabled={loadingSubscriptions || !hasValidBikes}
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          onClick={() => handleClickView(activeSubscription?.id)}
        >
          PASE VIRTUAL
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loadingSubscriptions || !hasValidBikes}
        // onClick={() => handleClickCreateSubscription(provider?.id)}
        onClick={() => setShowSubscriptionAlert(true)}
      >
        CONTRATAR
      </Button>
    );
  };

  return (
    <div>
      <Typography variant="h6" color="textSecondary">
        Membresías
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={0} alignItems="center">
            {/* Logo */}
            <Grid item xs={false} lg={3}>
              <CardMedia
                image={fullwheelsLogo}
                title="Live from space album cover"
                style={{ height: 100 }}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={6} lg={6}>
              <Typography component="h5" variant="h6" color="primary" style={{ width: '90%', alignSelf: 'left' }}>
                {provider?.name}
              </Typography>
              <Typography component="h5" variant="subtitle1" color="primary" style={{ width: '90%', alignSelf: 'left' }}>
                {`$${provider?.cost.toLocaleString('de-DE')} al mes`}
              </Typography>
              {!isMobileScreen && (
              <Typography variant="body1">
                Obtén tu membresía mensual al parque Fullwheels
              </Typography>
              )}

            </Grid>

            {/* Button */}
            <Grid item xs={6} lg={3}>
              {/* {loadingSubscriptions ? (
                <CircularProgress />
              ) : (
                hasSubscription ? (
                  <Button
                    disabled={loadingSubscriptions}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleButtonClick(provider?.id)}
                  >
                    VER PASE VIRTUAL
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleClickCreateSubscription(provider?.id)}
                  >
                    CONTRATAR
                  </Button>
                )
              )} */}
              {renderSubscriptionButton()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog onClose={() => setShowSubscriptionAlert(false)} aria-labelledby="customized-dialog-title" open={showSubscriptionAlert}>
        <DialogTitle id="customized-dialog-title">
          Contratar suscripción
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Para contratar deberás registrar tu tarjeta de crédito en
            Transbank a través de un cargo de $50 que será devuelto.
          </Typography>
          <Typography gutterBottom>
            Inmediatamente después de haber registrado tu tarjeta, se te cargará la membresía
            mensual por $22.000 al mes, lo que se mantendrá de mes en mes hasta que decidas
            dar de baja la membresía.
          </Typography>
        </DialogContent>
        <DialogActions>
          {loadingSubscriptions ? (
            <CircularProgress />
          ) : (
            <Button
              autoFocus
              onClick={() => handleClickCreateSubscription(provider?.id)}
              color="primary"
              disabled={!hasValidBikes}
            >
              Continuar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default ProvidersList;
