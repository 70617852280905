import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, post } from 'utils/api';

import { ACTIVATE } from 'screens/Activate/reducer';

function* activateUser() {
  yield takeLatest(ACTIVATE.ACTIVATE_USER, function* (action) {
    const body = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, ACTIVATE.ACTIVATE_USER));
    const response = yield post('api/v1/users/activate', body);
    yield put(apiSuccess(APP.REMOVE_LOADING, ACTIVATE.ACTIVATE_USER));

    if (!response.error) {
      yield put(apiSuccess(ACTIVATE.ACTIVATE_USER_SUCCESS, response));
    } else {
      yield put(apiSuccess(ACTIVATE.ACTIVATE_USER_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(activateUser);
}
