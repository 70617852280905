import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, destroy, get, postForm, putForm } from 'utils/api';
import { MAINTENANCE } from 'screens/Maintenance/reducer';

function* fetchMaintenance() {
  yield takeLatest(MAINTENANCE.FETCH_MAINTENANCE, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.FETCH_MAINTENANCE));
    const id = action.payload;
    const response = yield get(`api/v1/bike/${id}/maintenances`);
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.FETCH_MAINTENANCE));
    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.FETCH_MAINTENANCE_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las mantenciones' }));
      yield put(apiSuccess(MAINTENANCE.FETCH_MAINTENANCE_ERROR));
    }
  });
}

function* fetchBikeInformation() {
  yield takeLatest(MAINTENANCE.FETCH_BIKE_INFORMATION, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.FETCH_BIKE_INFORMATION));
    const id = action.payload;
    const response = yield get(`api/v1/bikes/bike-information/${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.FETCH_BIKE_INFORMATION));
    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.FETCH_BIKE_INFORMATION_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar la Bike' }));
      yield put(apiSuccess(MAINTENANCE.FETCH_BIKE_INFORMATION_ERROR));
    }
  });
}

function* fetchMaintenanceTypes() {
  yield takeLatest(MAINTENANCE.FETCH_MAINTENANCE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.FETCH_MAINTENANCE_TYPES));
    const response = yield get('api/v1/bike/maintenances-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.FETCH_MAINTENANCE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.FETCH_MAINTENANCE_TYPES_SUCCESS, response));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar las mantenciones' }));
      yield put(apiSuccess(MAINTENANCE.FETCH_MAINTENANCE_TYPES_ERROR));
    }
  });
}

function* create() {
  yield takeLatest(MAINTENANCE.CREATE, function* (action) {
    const { bikeId } = action.payload;
    const body = new FormData();

    for (const key of Object.keys(action.payload.body)) {
      if (typeof action.payload.body[key] === 'string') {
        body.append(`${key}`, action.payload.body[key].trim());
      } else {
        body.append(`${key}`, action.payload.body[key] ?? '');
      }
    }

    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.CREATE));
    const response = yield postForm(`api/v1/bike/${bikeId}/maintenances`, body);
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.CREATE));

    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.CREATE_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Mantención agregada'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al guardar la mantención' }));
      yield put(apiSuccess(MAINTENANCE.CREATE_ERROR));
    }
  });
}

function* update() {
  yield takeLatest(MAINTENANCE.UPDATE, function* (action) {
    const { id } = action.payload;
    const body = new FormData();

    for (const key of Object.keys(action.payload.body)) {
      if (typeof action.payload.body[key] === 'string') {
        body.append(`${key}`, action.payload.body[key].trim());
      } else {
        body.append(`${key}`, action.payload.body[key] ?? '');
      }
    }

    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.UPDATE));
    const response = yield putForm(`api/v1/bike/maintenances/${id}`, body);
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.UPDATE));

    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.UPDATE_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Mantención editada'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al editar la mantención' }));
      yield put(apiSuccess(MAINTENANCE.UPDATE_ERROR));
    }
  });
}

function* deleteMaintenance() {
  yield takeLatest(MAINTENANCE.DELETE, function* (action) {
    const id = action.payload;

    yield put(apiSuccess(APP.ADD_LOADING, MAINTENANCE.DELETE));
    const response = yield destroy(`api/v1/bike/maintenances/${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, MAINTENANCE.DELETE));

    if (!response.error) {
      yield put(apiSuccess(MAINTENANCE.DELETE_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Mantención eliminada'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al editar la mantención' }));
      yield put(apiSuccess(MAINTENANCE.DELETE_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(fetchMaintenance);
  yield spawn(fetchMaintenanceTypes);
  yield spawn(create);
  yield spawn(update);
  yield spawn(deleteMaintenance);
  yield spawn(fetchBikeInformation);
}
