import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import apiRequest, { apiSuccess, get } from 'utils/api';
import { formatDateToString } from 'utils/functions';
import { DOWNLOAD_BIKE_DATA_STORE } from '../reducer';

function serialize(obj = {}) {
  const str = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== '') {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  return str.join('&');
}

function* downloadRegisteredBikesFile() {
  yield takeLatest(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE, function* (action) {
    const queryParams = {
      bikeState: 'store_download_bikes_registered',
    };
    const response = yield apiRequest(
      `api/v1/bikes/store/generate-excel?${serialize(queryParams)}`,
      { method: 'GET' },
      true,
    );
    if (!response.error) {
      const { message, results } = response;
      if (results.length === 0) {
        yield put({
          type: APP.SET_ERROR,
          payload: { errorMsg: 'No se encuentran registros' },
        });
        return;
      }
      yield put({ type: APP.SET_SUCCESS, payload: message });
      const filteredTest = results
        .filter((value, index, self) => {
          const valuesToInspect = { ...value };
          delete valuesToInspect.bikeYear;
          if (Object.values(valuesToInspect).includes(null)) {
            return false;
          }
          return !self.some(
            (item) =>
              item?.bikeId === value?.bikeId && item?.transferCreatedAt > value?.transferCreatedAt,
          );
        })
        .map(({ bikeId, ...item }) => ({
          ...item,
          transferCreatedAt: formatDateToString(item.transferCreatedAt),
          userBirthday: formatDateToString(item.userBirthday),
        }));
      yield put(
        apiSuccess(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE_SUCCESS, {
          csvData: filteredTest,
        }),
      );
      yield put(apiSuccess(APP.SET_SUCCESS, 'Excel descargado con éxito'));
    } else {
      yield put(
        apiSuccess(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_REGISTERED_BIKES_FILE_ERROR, {
          errorMsg: 'Error al cargar los registros',
        }),
      );
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

function* downloadBikesTransfersFile() {
  yield takeLatest(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE, function* (action) {
    const queryParams = {
      bikeState: 'store_download_bikes_transferred',
    };
    const response = yield apiRequest(
      `api/v1/bikes/store/generate-excel?${serialize(queryParams)}`,
      { method: 'GET' },
      true,
    );
    if (!response.error) {
      const { message, csvData } = response;
      yield put(
        apiSuccess(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE_SUCCESS, { csvData }),
      );
      if (csvData.length === 0) {
        yield put({
          type: APP.SET_ERROR,
          payload: { errorMsg: 'La tienda no cuenta con transferencias por el momento' },
        });
        return;
      }
      yield put({ type: APP.SET_SUCCESS, payload: message });
    } else {
      yield put(
        apiSuccess(DOWNLOAD_BIKE_DATA_STORE.DOWNLOAD_BIKES_TRANSFERS_FILE_ERROR, {
          errorMsg: 'Error al cargar las transferencias de la tienda',
        }),
      );
      yield put({
        type: APP.SET_ERROR,
        payload: { errorMsg: 'Ha ocurrido un error al momento de hacer la solicitud' },
      });
    }
  });
}

export default function* root() {
  yield spawn(downloadRegisteredBikesFile);
  yield spawn(downloadBikesTransfersFile);
}
