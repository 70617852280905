import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { composedComponent } from 'utils/functions';
import saga from 'screens/Home/saga';
import { styles } from './styles';
import { homeActions } from '../../reducer';

const AdCard = (props) => {
  const {
    classes,
    listType,
    handleClickCard,
    fullImage,
    actions,
  } = props;
  const [adData, setAdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMozaicList = listType === 'mozaic-list';

  const type = isMozaicList ? 'only_image' : 'list_only_image';

  useEffect(() => {
    setLoading(true);
    actions.fetchAds({
      type,
      callback: (err, data) => {
        if (err) {
          setError(err);
        } else {
          setAdData(data[0]);
        }
        setLoading(false);
      },
    });
  }, [actions, listType]);

  const handleClickImage = (event) => {
    event.stopPropagation();
    actions.clickAd({ adId: adData?.id, link: adData?.link });
    window.open(adData?.link, '_blank');
  };

  const placeholderSize = !isMozaicList ? '920x248' : '312x493';

  return (
    <Card
      className={`${classes.root} ${isMozaicList ? '' : ''}`}
      style={{
        cursor: 'pointer',
        width: '100%',
        flexDirection: isMozaicList ? 'column' : 'row',
        display: 'flex',
      }}
      onClick={handleClickCard}
    >
      <Grid container style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: isMozaicList ? '157%' : '27%',
            background: '#f0f0f0', // Placeholder background color
            overflow: 'hidden',
          }}
        >
          {loading ? (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <img
              src={adData?.imageUrl || `https://via.placeholder.com/${placeholderSize}`}
              alt="Ad"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              onClick={handleClickImage}
            />
          )}
        </Grid>
        {!fullImage && (
          <Grid item xs={12} className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Box className={classes.bikfyGreyColor} fontWeight="fontWeightBold">
                    Patrocinado
                  </Box>
                </Grid>
              </Grid>
              <Typography
                itemProp="name"
                variant="h2"
                style={{
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  fontFamily: 'Mark Pro',
                  lineHeight: 1.6,
                  marginTop: '8px',
                }}
              >
                Promueve tu producto o servicio.
              </Typography>
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button variant="contained" color="primary" onClick={handleClickImage}>
                  Saber más
                </Button>
              </Box>
            </CardContent>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default composedComponent(AdCard, saga, {
  saga: 'sagaHome',
  middlewares: [withStyles(styles)],
  states: [
    'home.ads',
  ],
  actions: [homeActions],
});
