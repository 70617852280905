import { takeLatest, spawn, put } from 'redux-saga/effects';
import { apiSuccess, get, postForm } from 'utils/api';
import * as APP from 'commons/reducer';

import { AMBASSADOR } from 'screens/Ambassador/reducer';

function* fetchBrands() {
  yield takeLatest(AMBASSADOR.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, AMBASSADOR.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, AMBASSADOR.FETCH_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(AMBASSADOR.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas' }));
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(AMBASSADOR.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, AMBASSADOR.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, AMBASSADOR.FETCH_MOTORBIKE_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(AMBASSADOR.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas de motocicletas' }));
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(AMBASSADOR.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, AMBASSADOR.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, AMBASSADOR.FETCH_VEHICLE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(AMBASSADOR.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar marcas de motocicletas' }));
    }
  });
}

function* registerBike() {
  yield takeLatest(AMBASSADOR.REGISTER_BIKE, function* (action) {
    const body = new FormData();

    for (const key of Object.keys(action.payload)) {
      let value = action.payload[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      body.append(`${key}`, value);
    }
    const response = yield postForm('api/v1/bikes/register-bike-ambassador', body);

    if (!response.error) {
      yield put(apiSuccess(APP.SET_SUCCESS, 'Registro exitoso'));
      yield put(apiSuccess(AMBASSADOR.REGISTER_BIKE_SUCCESS));
      yield put(apiSuccess(AMBASSADOR.RESET_CONTROLS));
    } else {
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: 'El código de serie ya se encuentra registrado' }),
      );
      yield put(apiSuccess(AMBASSADOR.REGISTER_BIKE_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(fetchBrands);
  yield spawn(registerBike);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchVehicleTypes);
}
