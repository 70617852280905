import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import serialLocation from '../../../../commons/assets/serial-location.png';
import serialLocationMobil from '../../../../commons/assets/serial-location-mobile.png';
import motoSerialLocation from '../../../../commons/assets/serial-motos-location.png';
import motoSerialLocationMobile from '../../../../commons/assets/serial-motos-location-mobile.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  modalMobil: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const ModalSerial = ({ open, clearAlert, isBicycleSelected }) => {
  const classes = useStyles();

  const Img = ({ image, className }) => (
    <img className={className} src={image} alt="" style={{ width: '100%' }} />
  );

  return (
    <Dialog open={open} onClose={clearAlert}>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText>Tú código de serie no debe tener espacios en blanco.</DialogContentText>
        <DialogContentText>
          Si tu código de serie tiene espacios en blanco, por favor elimínelos ó remplazalos con
          guiones(-).
        </DialogContentText>
        <DialogContentText>
          <Img
            className={classes.modal}
            image={isBicycleSelected ? serialLocation : motoSerialLocation}
          />
          <Img
            className={classes.modalMobil}
            image={isBicycleSelected ? serialLocationMobil : motoSerialLocationMobile}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => clearAlert()} color="primary" variant="outlined" autoFocus>
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSerial;
