import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props) {
  const { inputRef, onChange, onBlur, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      onBlur={onBlur}
      thousandSeparator="."
      decimalSeparator=","
      prefix="$"
      allowEmptyFormatting
    />
  );
}

const CustomInputNumber = (props) => (
  <TextField
    fullWidth
    variant="outlined"
    itemProp="price"
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      inputComponent: NumberFormatCustom,
    }}
    {...props}
  />
);

export default CustomInputNumber;
