import React from 'react';
import Layout from 'commons/containers/Layout';
import { Route } from 'react-router-dom';

const Wrapper = ({ component: Component, withLayout, ...rest }) => (
  <>
    {withLayout
      ? (
        <Layout>
          <Component {...rest} />
        </Layout>
      )
      : <Component {...rest} />}
  </>
);

const PublicRoute = ({ component: Component, withLayout, ...rest }) => (
  <Route
    {...rest}
    component={(props) => <Wrapper {...props} component={Component} withLayout={withLayout} />}
  />
);

export default PublicRoute;
