const PATH = 'adminPay';

const adminPayTypes = {
  CLEAR_CSV_PAYED_TRANSFER: `${PATH}/CLEAR_CSV_PAYED_TRANSFER`,

  GET_PENDING_TRANSFER: `${PATH}/GET_PENDING_TRANSFER`,
  GET_PENDING_TRANSFER_SUCCESS: `${PATH}/GET_PENDING_TRANSFER_SUCCESS`,
  GET_PENDING_TRANSFER_FAILURE: `${PATH}/GET_PENDING_TRANSFER_FAILURE`,

  UPLOAD_EXCEL: `${PATH}/UPLOAD_EXCEL`,
  UPLOAD_EXCEL_SUCCESS: `${PATH}/UPLOAD_EXCEL_SUCCESS`,
  UPLOAD_EXCEL_FAILURE: `${PATH}/UPLOAD_EXCEL_FAILURE`,

  SEND_PAYMENT_EMAILS_TO_PERFORM: `${PATH}/SEND_PAYMENT_EMAILS_TO_PERFORM`,
  PENDING_PAYMENT_EMAIL: `${PATH}/PENDING_PAYMENT_EMAIL`,
};

export default adminPayTypes;
