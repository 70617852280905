import React from 'react';
// MATERIAL
import { Box, Button, Paper, Grid } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from 'commons/components/ClearIcon';

import { view } from 'utils/pathTypes';
import FilterForm from '../FilterForm/FilterForm';

const FilterCard = (props) => {
  const { objects, order, actions, state, handleSetShowFilters, isMobileScreen, pathType } = props;

  const applyFilters = () => {
    if (pathType === view.STORE_HOME) {
      actions.resetStoreHomePagination();
      actions.getBikesForStore();
    } else {
      actions.resetMarketplacePagination();
      actions.getPublishedBikes();
    }

    if (isMobileScreen) handleSetShowFilters();
  };

  const resetInputs = () => {
    if (pathType === view.STORE_HOME) {
      actions.resetStoreHomeFilterForm();
      actions.getBikesForStore();
    } else {
      actions.resetFilterForm();
      actions.getPublishedBikes();
    }

    if (isMobileScreen) handleSetShowFilters();
  };

  return (
    <Paper
      style={{
        position: 'sticky',
        top: '0px',
      }}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Box m={2} display="flex" padding="0px" margin="0px 0px 8px 0px">
              <Box flexGrow={1} pr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={applyFilters}
                  id="btn-find-published-bikes"
                  style={{ width: '100%' }}>
                  Buscar
                </Button>
              </Box>
              <Box>
                <Button variant="outlined" color="primary" onClick={resetInputs}>
                  <ClearIcon />
                </Button>
              </Box>
              {(pathType === view.MARKETPLACE || pathType === view.STORE_HOME) &&
                isMobileScreen && (
                  <Box marginLeft={1}>
                    <Button variant="outlined" color="primary" onClick={handleSetShowFilters}>
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FilterForm
              formValues={state}
              setFormValues={actions.setFilterForm}
              changeVehicleTypeControl={actions.changeVehicleTypeControls}
              objects={objects}
              order={order}
              pathType={pathType}
              changeControls={actions.changeControls}
              setStoreHomeFormValues={actions.setStoreHomeFilterForm}
              changeVehicleTypeStoreFilterForm={actions.changevehicleTypeStoreFilterForm}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FilterCard;
