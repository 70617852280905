import React, { useEffect, useState } from 'react';
import {
  Grid, Typography, CircularProgress, makeStyles,
} from '@material-ui/core';

import saga from 'screens/Home/saga';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
// import banner from 'commons/assets/banner/banner_kuhl.png'; // Fallback banner image
import { homeActions } from '../../reducer';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    // [theme.breakpoints.down('sm')]: {
    //   height: '120px', // Adjust height for mobile
    // },
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  bannerTextContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: darken the banner for text readability
  },
  bannerText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', // Adjust font size for mobile
    },
  },
  loadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const MarketingBanner = (props) => {
  const {
    listType,
    actions,
    isMobileScreen,
  } = props;

  const [adData, setAdData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    actions.fetchAds({
      type: !isMobileScreen ? 'banner' : 'banner_mobile',
      callback: (err, data) => {
        if (err) {
          setError(err);
          setLoading(false);
        } else {
          setAdData(data[0]);
          setLoading(false);
        }
      },
    });
  }, [actions, listType, isMobileScreen]);

  const handleClickImage = (event) => {
    event.stopPropagation();
    actions.clickAd({ adId: adData?.id, link: adData?.link });
    window.open(adData?.link, '_blank');
  };

  return (
    <Grid container className={classes.bannerContainer} onClick={handleClickImage} style={{ cursor: 'pointer' }}>
      {loading ? (
        <CircularProgress className={classes.loadingIndicator} />
      ) : (
        <>
          <img
            src={adData?.imageUrl}
            alt="Banner"
            className={classes.bannerImage}
          />

        </>
      )}
    </Grid>
  );
};

export default composedComponent(MarketingBanner, saga, {
  saga: 'sagaHome',
  states: [
    'app.objects',
    'app.objects.transfers',
    'app.order',
    'app.user',
    'home',
    'home.pathType',
    'home.sortBy',
    'home.storeHomeSortBy',
    'home.ads',
  ],
  actions: [homeActions, appActions],
});
