import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import emojiScreamingImage from '../../assets/screaming-emoji.png';
import useNotLoggedUserMessage from './notLoggedUserMessageStyles';
import { useHistory } from 'react-router-dom';

const NotLoggedUserMessage = (props) => {
  const { title, info } = props;

  const history = useHistory();

  const classes = useNotLoggedUserMessage();
  const handleClickLoginButton = () => {
    history.push('/login');
  };

  return (
    <Grid container>
      <Grid xs={12} item>
        <img
          alt="emoji-screaming"
          className={`global img ${classes.margin0Auto}`}
          src={emojiScreamingImage}
        />
      </Grid>
      <Grid xs={12} item className={`${classes.margin0Auto} ${classes.mWidth600}`}>
        <Typography className={`${classes.tAlingCenter} ${classes.fWeight700} ${classes.fSize20}`}>
          Para revisar el {title} deber iniciar sesión
        </Typography>
        <Typography
          className={`${classes.tAlingCenter} ${classes.mTop20} ${classes.fSize20} ${classes.lineHeight19}`}>
          {info}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Box display="flex" justifyContent="center" width="100%" marginTop="60px">
          <Button variant="contained" color="primary" onClick={handleClickLoginButton}>
            Iniciar sesión
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

NotLoggedUserMessage.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
};

NotLoggedUserMessage.defaultProps = {
  title: '',
  info: '',
};

export default NotLoggedUserMessage;
