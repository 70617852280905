const styles = (theme) => ({
  [theme.breakpoints.down('xs')]: {
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .button-container': {
        marginTop: '20px',
      },
    },
  },
});

export default styles;
