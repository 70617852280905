const styles = {
  imageContainer: {
    position: 'relative',
    display: 'flex',
    minWidth: 200,
    minHeight: 200,
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  index: {
    backgroundColor: '#0d47a1',
    position: 'absolute',
    left: 4,
    top: 4,
  },
  fab: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  extraFab: {
    position: 'absolute',
    bottom: 10,
    right: 60,
  },
};

export default styles;
