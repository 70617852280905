import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'ambassador/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const AMBASSADOR = {
  REGISTER_BIKE: `${PATH}REGISTER_BIKE`,
  REGISTER_BIKE_SUCCESS: `${PATH}REGISTER_BIKE_SUCCESS`,
  REGISTER_BIKE_ERROR: `${PATH}REGISTER_BIKE_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_SUCCESS`,

  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,
  FETCH_VEHICLE_TYPES_ERROR: `${PATH}FETCH_VEHICLE_TYPES_ERROR`,

  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS_ERROR: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
};

// actions
export const ambassadorActions = {
  registerBike: createAction(AMBASSADOR.REGISTER_BIKE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  resetControls: createAction(AMBASSADOR.RESET_CONTROLS),
  fetchBrands: createAction(AMBASSADOR.FETCH_BRANDS),
  fetchMotorbikeBrands: createAction(AMBASSADOR.FETCH_MOTORBIKE_BRANDS),
  fetchVehicleTypes: createAction(AMBASSADOR.FETCH_VEHICLE_TYPES),
};

const initialState = {
  formControls: {
    email: '',
    taxNumber: '',
    phoneNumber: '',
    serial: '',
    chassisSerial: '',
    engineSerial: '',
    brandId: '',
    serialFile: '',
  },
  loading: false,
  error: false,
  success: false,
};

const ambassador = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [AMBASSADOR.RESET_CONTROLS](state) {
    state.formControls = { ...initialState.formControls };
  },
  [AMBASSADOR.REGISTER_BIKE](state) {
    state.loading = true;
    state.error = false;
  },
  [AMBASSADOR.FETCH_BRANDS](state) {
    state.loading = true;
  },
  [AMBASSADOR.REGISTER_BIKE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [AMBASSADOR.FETCH_BRANDS_SUCCESS](state) {
    state.loading = false;
  },
  [AMBASSADOR.REGISTER_BIKE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [AMBASSADOR.FETCH_MOTORBIKE_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [AMBASSADOR.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [AMBASSADOR.FETCH_MOTORBIKE_BRANDS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [AMBASSADOR.FETCH_VEHICLE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [AMBASSADOR.FETCH_VEHICLE_TYPES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [AMBASSADOR.FETCH_VEHICLE_TYPES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
});

export default ambassador;
