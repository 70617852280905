/* eslint-disable no-param-reassign */
import { Subscriptions } from '@material-ui/icons';
import { createAction, createReducer } from 'utils/reducer';
// Local constants
const PATH = 'benefit/';

// Global constants for saga
export const BENEFIT = {
  FETCH_BENEFITS: `${PATH}FETCH_BENEFITS`,
  FETCH_BENEFITS_SUCCESS: `${PATH}FETCH_BENEFITS_SUCCESS`,
  FETCH_BENEFITS_ERROR: `${PATH}FETCH_BENEFITS_ERROR`,
  FETCH_BIKES: `${PATH}FETCH_BIKES`,
  FETCH_BIKES_SUCCESS: `${PATH}FETCH_BIKES_SUCCESS`,
  FETCH_BIKES_ERROR: `${PATH}FETCH_BIKES_ERROR`,
  FETCH_PROVIDERS: `${PATH}FETCH_PROVIDERS`,
  FETCH_PROVIDERS_SUCCESS: `${PATH}FETCH_PROVIDERS_SUCCESS`,
  FETCH_PROVIDERS_ERROR: `${PATH}FETCH_PROVIDERS_ERROR`,
  FETCH_SUBSCRIPTIONS: `${PATH}FETCH_SUBSCRIPTIONS`,
  FETCH_SUBSCRIPTIONS_SUCCESS: `${PATH}FETCH_SUBSCRIPTIONS_SUCCESS`,
  FETCH_SUBSCRIPTIONS_ERROR: `${PATH}FETCH_SUBSCRIPTIONS_ERROR`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  CREATE_SUBSCRIPTION: `${PATH}CREATE_SUBSCRIPTION`,
  CREATE_SUBSCRIPTION_SUCCESS: `${PATH}CREATE_SUBSCRIPTION_SUCCESS`,
  CREATE_SUBSCRIPTION_ERROR: `${PATH}CREATE_SUBSCRIPTION_ERROR`,
  CONFIRM_SUBSCRIPTION: `${PATH}CONFIRM_SUBSCRIPTION`,
  CONFIRM_SUBSCRIPTION_SUCCESS: `${PATH}CONFIRM_SUBSCRIPTION_SUCCESS`,
  CONFIRM_SUBSCRIPTION_ERROR: `${PATH}CONFIRM_SUBSCRIPTION_ERROR`,
};

// actions
export const benefitActions = {
  fetchBenefits: createAction(BENEFIT.FETCH_BENEFITS),
  fetchBikes: createAction(BENEFIT.FETCH_BIKES),
  fetchProviders: createAction(BENEFIT.FETCH_PROVIDERS),
  openAlert: createAction(BENEFIT.OPEN_ALERT),
  clearAlert: createAction(BENEFIT.CLEAR_ALERT),
  createSubscription: createAction(BENEFIT.CREATE_SUBSCRIPTION, 'payload'),
  confirmSubscription: createAction(BENEFIT.CONFIRM_SUBSCRIPTION, 'payload'),
  fetchSubscriptions: createAction(BENEFIT.FETCH_SUBSCRIPTIONS, 'payload'),
};

const initialState = {
  alert: false,
  loading: false,
  completedFetchBikes: false,
  hasValidBikes: false,
  error: false,
  message: '',
  completedFetchProviders: false,
  loadingProviders: false,
  errorProviders: false,
  oneclickSubscriptionUrl: '',
  loadingSubscriptions: false,
  errorSubscriptions: false,
  completedFetchSubscriptions: false,

};

const benefit = createReducer(initialState, {
  [BENEFIT.FETCH_BENEFITS](state) {
    state.error = false;
  },
  [BENEFIT.FETCH_BENEFITS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BENEFIT.FETCH_BENEFITS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.FETCH_BIKES](state) {
    state.completedFetchBikes = false;
    state.loading = true;
    state.error = false;
    state.alert = false;
  },
  [BENEFIT.FETCH_BIKES_SUCCESS](state, action) {
    state.completedFetchBikes = true;
    state.hasValidBikes = action.payload;
    state.error = false;
  },
  [BENEFIT.FETCH_BIKES_ERROR](state, action) {
    state.completedFetchBikes = true;
    state.hasValidBikes = false;
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.FETCH_PROVIDERS](state) {
    state.completedFetchProviders = false;
    state.loadingProviders = true;
    state.errorProviders = false;
  },
  [BENEFIT.FETCH_PROVIDERS_SUCCESS](state, action) {
    state.completedFetchProviders = true;
    state.loadingProviders = false;

    state.errorProviders = false;
  },
  [BENEFIT.FETCH_PROVIDERS_ERROR](state, action) {
    state.completedFetchProviders = true;
    state.loadingProviders = false;
    state.errorProviders = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.FETCH_SUBSCRIPTIONS](state) {
    state.completedFetchSubscriptions = false;
    state.loadingSubscriptions = true;
    state.errorSubscriptions = false;
  },
  [BENEFIT.FETCH_SUBSCRIPTIONS_SUCCESS](state, action) {
    state.subscriptions = action.subscriptions;
    state.completedFetchSubscriptions = true;
    state.loadingSubscriptions = false;
    state.errorSubscriptions = false;
  },
  [BENEFIT.FETCH_SUBSCRIPTIONS_ERROR](state, action) {
    state.loadingSubscriptions = false;
    state.errorSubscriptions = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.CREATE_SUBSCRIPTION](state) {
    state.loadingSubscriptions = true;
    state.error = false;
    state.alert = false;
  },
  [BENEFIT.CREATE_SUBSCRIPTION_SUCCESS](state, action) {
    state.oneclickSubscriptionUrl = action.payload.subUrl;
    // state.subscriptions = [...state.subscriptions, action.payload];
    state.loadingSubscriptions = false;
    state.error = false;
  },
  [BENEFIT.CREATE_SUBSCRIPTION_ERROR](state, action) {
    state.loadingSubscriptions = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.CONFIRM_SUBSCRIPTION](state) {
    state.error = false;
    state.alert = false;
    state.loadingSubscriptions = true;
    state.loadingConfirmation = true;
  },
  [BENEFIT.CONFIRM_SUBSCRIPTION_SUCCESS](state, action) {
    // state.subscriptions = [...state.subscriptions, action.payload];
    state.error = false;
    state.loadingSubscriptions = false;
    state.loadingConfirmation = false;
  },
  [BENEFIT.CONFIRM_SUBSCRIPTION_ERROR](state, action) {
    state.loadingSubscriptions = false;
    state.loadingConfirmation = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BENEFIT.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [BENEFIT.OPEN_ALERT](state) {
    state.alert = true;
    state.loading = false;
  },
});

export default benefit;
