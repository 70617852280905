import React, { useCallback, useEffect, useState } from 'react';
import { composedComponent, validateRut } from 'utils/functions';
import {
  Card, CardContent, Typography, withStyles, makeStyles,
} from '@material-ui/core';
/* import styles from 'screens/Login/styles'; */
import { appActions } from 'commons/reducer';
import { profileActions } from 'screens/Profile/reducer';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'utils/hooks/useForm';
import { useHistory, useLocation } from 'react-router-dom';
import { specialCharFormat } from 'utils/consts';
import From from './components/Form';
import saga from './saga';
import UserVehicleInterestsModal from './components/userVehicleInterestsModal';
import styles from './styles';

const useStyles = makeStyles(styles);

const Profile = (props) => {
  const classes = useStyles();
  const date = new Date();
  const history = useHistory();
  const location = useLocation();

  const [openUserInterestsModal, setOpenUserInterestsModal] = useState(false);

  const vehicleTypes = props?.order?.vehicleTypes
    ?.map((vt) => props?.vehicleTypes[vt])
    .map((a) => ({ value: a?.id, label: a?.name }));

  useEffect(() => {
    if (props.user) {
      props.actions.changeControls({
        name: props.user.name,
        email: props.user.email,
        firstLastName: props.user.firstLastName,
        secondLastName: props.user.secondLastName,
        taxNumber: props.user.taxNumber,
        documentNumber: props.user.documentNumber,
        birthday: props.user.birthday,
        gender: props.user.gender,
        phone: props.user.phone ? props.user.phone : '+56',
        addressStreet: props.user.addressStreet,
        addressNumber: props.user.addressNumber,
        addressDetail: props.user.addressDetail,
        comuneId: props.user.comuneId,
      });
    }
  }, [props.user]);

  useEffect(() => {
    props.actions.getVehicleTypes();
    props.actions.getStateFromApiComunes();
    props.actions.getProfile();
  }, [props.actions.getProfile]);

  useEffect(() => {
    if (props.successProfile) {
      if (location?.state?.prevPath?.split('/').pop() === 'insurance') {
        props.actions.getUser();
        history.go(-1);
        return;
      }
      if (props.user.firstTimeUser) {
        setOpenUserInterestsModal(true);
        return;
      }
      props.actions.getUser();
      history.push('/home');
    }
  }, [props.successProfile]);

  useEffect(() => {
    if (props.successUserInterests) {
      props.actions.resetControls();
      props.actions.getUser();
      history.push('/home');
    }
  }, [props.successUserInterests]);

  useEffect(
    () => () => {
      props.actions.resetControls();
    },
    [props.actions.resetControls],
  );

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
  }, []);

  const onClickUpdate = (e) => {
    e.preventDefault();
    onSubmit(() => props.actions.updateProfile(props.formControls));
  };

  const onVehicleTypeChange = (vt) => {
    const vTypesSelected = [...props.vehicleTypesSelected];
    if (vTypesSelected?.includes(vt.value)) {
      props.actions.updateVehicleTypesSelected({
        vehicleTypesSelected: vTypesSelected.filter(
          (vehicleTypeSelected) => vehicleTypeSelected !== vt.value,
        ),
      });
    } else {
      vTypesSelected.push(vt.value);
      props.actions.updateVehicleTypesSelected({
        vehicleTypesSelected: vTypesSelected,
      });
    }
  };

  const handleUpdateUserVehicleInterests = () => {
    props.actions.updateUserVehicleInterests({
      vehicleTypesSelected: [...props.vehicleTypesSelected],
    });
  };

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      name: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Nombre es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      firstLastName: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Apellido Paterno es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      secondLastName: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Apellido Materno es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      taxNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El R.U.T es requerido',
        },
        {
          check: (value) => !!value && validateRut(value),
          message: 'El R.U.T es inválido',
        },
      ],
      birthday: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La Fecha de nacimiento es requerida',
        },
        {
          check: (value) => new Date(value) < date,
          message: 'La Fecha es inválida',
        },
      ],
      gender: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Género es requerido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono celular es requerido',
        },
        {
          check: (value) => (value.substr(0, 3) === '+56' ? value.trim().length >= 12 : value.trim().length >= 8),
          message: 'El formato es incorrecto',
        },
      ],
      comuneId: [
        {
          check: (value) => !!value,
          message: 'La Comuna es requerida',
        },
      ],
      addressStreet: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La Calle es requerida',
        },
      ],
      addressNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Número de calle es requerido',
        },
      ],
    },
  });

  const comunes = props.order?.comunes
    ?.map((c) => props.comunes[c])
    .map((a) => ({ value: a?.id, label: a?.name }));

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" component="h5" align="center">
                  Datos personales
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1" color="textSecondary" component="p" align="center">
                  Debes completar tu perfil con los siguientes datos para poder
                  {' '}
                  <b>registrar, publicar, transferir, comprar o vender Bikes</b>
                  {' '}
                  dentro de BIKFY.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <From
                  user={props.user}
                  controls={props.formControls}
                  onChange={onChange}
                  onSubmit={onClickUpdate}
                  comunes={comunes}
                  errors={errors}
                  classes={props.classes}
                  loading={props.loading}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <UserVehicleInterestsModal
        vehicleTypes={vehicleTypes}
        classes={classes}
        onVehicleTypeChange={onVehicleTypeChange}
        vehicleTypesSelected={props?.vehicleTypesSelected}
        handleUpdateUserVehicleInterests={handleUpdateUserVehicleInterests}
        openModal={openUserInterestsModal}
        loading={props.loading}
      />
    </Grid>
  );
};

export default composedComponent(Profile, saga, {
  saga: 'sagaProfile',
  middlewares: [withStyles(styles)],
  states: [
    'profile.formControls',
    'profile.user',
    'profile.loading',
    'profile.vehicleTypesSelected',
    'profile.successProfile',
    'profile.successUserInterests',
    'app.objects.comunes',
    'app.objects.vehicleTypes',
    'app.order',
  ],
  actions: [profileActions, appActions],
});
