import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'; // Added Tooltip
import { composedComponent, formatTaxNumber } from 'utils/functions';
import saga from './saga';
import { redeemCouponActions } from './reducer';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(2),
  },
  redeemButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const RedeemCoupon = (props) => {
  const classes = useStyles();
  const {
    actions, coupons, loading, loadingRedeem, user,
  } = props;
  const loggedUser = user?.user;

  const [taxNumber, setTaxNumber] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [selectedBuyer, setSelectedBuyer] = useState(null);

  const [verificationCode, setVerificationCode] = useState('');
  const usedIn = (loggedUser.type === 'store') ? { usedIn: loggedUser.id } : undefined;

  useEffect(() => {
    if (loggedUser.type === 'pilot') {
      actions.fetchCoupons({ taxNumber: loggedUser.taxNumber });
    } else if (loggedUser.type === 'admin') {
      actions.fetchCoupons({ taxNumber, ...usedIn });
    }
    // No action for 'store' type
  }, []);
  // Empty dependency array ensures this only runs on mount

  const handleSearch = () => {
    actions.fetchCoupons({ taxNumber, ...usedIn });
  };

  const handleRedeemClick = (couponId, email, buyer) => {
    actions.consultCouponCode({ couponId, email });
    setSelectedCouponId(couponId);
    setSelectedBuyer(buyer);

    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setVerificationCode('');
  };

  const handleConfirmRedeem = () => {
    actions.redeemCoupon({
      code: verificationCode, usedIn: loggedUser.id, store: loggedUser, buyer: selectedBuyer,
    });
    handleModalClose();
  };

  const handleTaxNumberChange = (e) => {
    const formattedTaxNumber = formatTaxNumber(e.target.value);
    setTaxNumber(formattedTaxNumber);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        {loggedUser.type === 'pilot' ? 'Mis Cupones' : 'Buscar cupón por RUT del comprador'}
      </Typography>

      {loggedUser.type !== 'pilot' && (
        <Box className={classes.searchContainer}>
          <TextField
            label="Ingrese el RUT"
            variant="outlined"
            size="medium"
            className={classes.searchInput}
            value={taxNumber}
            onChange={handleTaxNumberChange}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} disabled={taxNumber === '' && loggedUser.type === 'store'}>
            Buscar
          </Button>
        </Box>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>N°</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>VALOR CUPÓN</TableCell>
                <TableCell>ESTADO</TableCell>
                {loggedUser.type !== 'pilot' && <TableCell>CANJE</TableCell>}
                <TableCell>CÓDIGO</TableCell>
                {' '}
                {/* New column for coupon code */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(coupons).map((coupon, index) => (
                <TableRow key={coupon.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{coupon.owner.name}</TableCell>
                  <TableCell>{coupon.owner.email}</TableCell>
                  <TableCell>{`$ ${coupon.amount?.toLocaleString()}`}</TableCell>
                  <TableCell>{!coupon.usedAt ? 'Activo' : 'Usado'}</TableCell>

                  {/* Only render the redeem button if the user is not a pilot */}
                  {loggedUser.type !== 'pilot' && (
                    <TableCell>
                      {!coupon.usedAt ? (
                        <Button
                          className={classes.redeemButton}
                          disabled={loadingRedeem}
                          color="primary"
                          onClick={() => handleRedeemClick(coupon.id, coupon.owner.email, coupon.owner)}
                        >
                          Canjear Cupón
                        </Button>
                      ) : (
                        <Tooltip title={`Redimido el ${new Date(coupon.usedAt).toLocaleString()}`}>
                          <span>
                            {' '}
                            {/* Wrap in a span for Tooltip to work on disabled buttons */}
                            <Button
                              className={classes.redeemButton}
                              disabled
                              style={{ backgroundColor: 'red', color: 'white' }}
                            >
                              Canjeado
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}

                  {/* Show coupon code only after redemption */}
                  <TableCell>
                    {coupon.usedAt ? coupon.code : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle>CONFIRMAR CUPÓN DE CANJE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor ingresa el código de verificación que ha recibido el cliente del cupón canjeado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Código de verificación"
            type="text"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary" disabled={loadingRedeem}>
            Cancelar
          </Button>
          {loadingRedeem ? (
            <CircularProgress size={24} />
          ) : (
            <Button onClick={handleConfirmRedeem} color="primary">
              Aceptar y Continuar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default composedComponent(RedeemCoupon, saga, {
  saga: 'sagaRedeemCoupon',
  states: [
    'redeemCoupon.coupons',
    'redeemCoupon.loading',
    'redeemCoupon.loadingRedeem',
    'app.user',
  ],
  actions: [redeemCouponActions],
});
