import React from 'react';
import Carousel from 'react-material-ui-carousel';
import BannerImage from 'commons/assets/banner/banner-fondo-marketplace-landing.png';
import MobileBannerImage from 'commons/assets/banner/banner-fondo-marketplace-landing-mobile.png';

import ButtonBike from 'commons/assets/banner/istotipo_enduro_blanco.png';
import ButtonMotorbike from 'commons/assets/banner/MX_blanco_cropped.png';
import {
  Button,
  Grid,
  Icon,
  SvgIcon,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PATHS } from 'utils/paths';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatNumber } from 'utils/functions';
import styles from './styles';

const useStyles = makeStyles(styles);
const resources = [
  {
    key: 1,
    desktop: { img: BannerImage },
    mobile: { img: MobileBannerImage },
  },
  /* {
    key: 2,
    desktop: { img: Banner2, txt: Text2 },
    mobile: { img: Banner2M, txt: Text2 },
  },
  {
    key: 3,
    desktop: { img: Banner3, txt: Text3 },
    mobile: { img: Banner3M, txt: Text3 },
  }, */
];

const Banner = (props) => {
  const { usersCount, userType, bikesCount } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleRedirectToRegisterBike = () => {
    if (userType === 'ambassador') {
      history.push(PATHS.AMBASSADOR);
      return;
    }
    if (userType === 'store') {
      history.push(PATHS.STORE_BIKE_REGISTER);
      return;
    }
    history.push(PATHS.BIKE_REGISTER);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Carousel
          animation="slide"
          interval={5000}
          timeout={1000}
          cycleNavigation={false}
          indicators={false}
        >
          {resources.map((r) => (
            <Grid
              container
              key={r.key}
              justifyContent="center"
              alignContent="center"
              alignItems="start"
            >
              <Grid
                item
                xs={12}
                className={classes.bannerImageBackground}
                style={{
                  backgroundImage: `url(${isXsScreen ? r.mobile.img : r.desktop.img})`,
                }}
              >
                {/* <img
                  src={isXsScreen ? r.mobile.img : r.desktop.img}
                  alt={`foto ${r.key}`}
                  className={classes.img}
                /> */}
                <Grid container justifyContent="center" alignContent="center" alignItems="start">
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.bannerTitleText} component="p">
                      ¡REGISTRA TU BIKE Y DESCUBRE UN MUNDO DE SERVICIOS Y BENEFICIOS!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      className={classes.bannerSubtitleText}
                      component="h1"
                    >
                      Somos el primer Registro de bicicletas y motos deportivas en Chile

                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      className={classes.bannerThirdTitleText}
                      component="p"
                    >
                      Nos preocupamos de formalizar el mercado de usados combatiendo el robo
                    </Typography>
                  </Grid>
                  <Grid container alignContent="center" alignItems="center" justifyContent="center">
                    {userType && (
                      <Button
                        id="register-bike-button"
                        color="primary"
                        variant="contained"
                        onClick={() => handleRedirectToRegisterBike()}
                        style={{
                          height: '60px',
                          maxWidth: '350px',
                          borderRadius: '30px',
                        }}
                        startIcon={(
                          <img
                            src={ButtonBike}
                            alt="Custom Image"
                            className={classes.bannerRegisterButton}
                          />
                        )}
                        endIcon={(
                          <img
                            src={ButtonMotorbike}
                            alt="Custom Image"
                            className={classes.bannerRegisterButtonMotorbike}
                          />
                        )}
                      >
                        <Typography className={classes.bannerRegisterButtonText} align="center">
                          Registra tu bike
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="start"
        className={classes.usersCountContainer}
      >
        <Typography align="center" className={classes.usersCountText}>
          {usersCount ? `¡Únete a los ${formatNumber(usersCount)} usuarios y ${formatNumber(bikesCount)} Bikes ya registrados!` : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Banner;
