import React from 'react';
import {
  Grid, makeStyles, useTheme,
} from '@material-ui/core';

import { insuranceActions } from 'screens/Insurance/reducer';
import saga from 'screens/Insurance/saga';
import { composedComponent } from 'utils/functions';
import Banner0 from 'commons/assets/banner_insurance/banner0_d.png';
import styles from './styles';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles(styles);
const resources = [
  {
    key: 0,
    desktop: { img: Banner0 },
  },
];

const BannerCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Carousel
          animation="slide"
          interval={5000}
          timeout={1000}
          cycleNavigation
          indicators={false}
        >
          {resources.map((r) => (
            <Grid container key={r.key}>
              <Grid item xs={12} className={classes.item}>
                <img src={r.desktop.img} alt={`foto ${r.key}`} className={classes.img} />
              </Grid>
            </Grid>
          ))}

        </Carousel>
      </Grid>
    </Grid>
  );
};

export default composedComponent(BannerCard, saga, {
  saga: 'sagaInsurance',
  states: ['insurance'],
  actions: [insuranceActions],
});
