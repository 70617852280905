/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';

// Material
import { Grid, withStyles, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useParams } from 'react-router-dom';
import { composedComponent } from '../../utils/functions';

import buyerPayMethodScreenActions from './buyerPayMethodScreen.actions';
import buyerPayMethodScreenSaga from './buyerPayMethodScreen.saga';

import styles from './styles';
import Title from './components/Title';
import Card from './components/Card';
import WarningModal from './components/warningModal';

const BuyerPayMethodScreen = (props) => {
  const { buyerPayMethod: buyerPayMethodState, actions, classes, history, objects } = props;

  const { transbank, modals } = buyerPayMethodState;
  const { warningModal } = modals;

  const {
    container,
    titleContainer,
    cardsContainer,
    payMethodCard,
    totalPayTable,
    imgContainer,
    payMethodTitleContainer,
    divider,
    listContainer,
    buttonContainer,
    cancelButtonContainer,
    bikfyPayIcon,
    absoluteButtonContainer,
    warningModalContainer,
  } = classes;

  const cardClasses = {
    payMethodCard,
    imgContainer,
    payMethodTitleContainer,
    divider,
    listContainer,
    totalPayTable,
    buttonContainer,
    bikfyPayIcon,
    absoluteButtonContainer,
  };

  const { createPayment, getTransfer, setOpenWarningModal } = actions;

  const transbankFormRef = useRef(null);
  const { transferId } = useParams();
  const theme = useTheme();
  const isDownSxScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const transfer = objects?.transfers?.[transferId];
  const isBikfyPaymentEnable = transfer?.transferDetail?.bikfyPayment;
  const commission = objects?.commissions?.[1];

  useEffect(() => {
    if (!transfer || !commission) {
      getTransfer({ transferId });
    }
  }, []);

  useEffect(() => {
    if (transbank.url !== '' && transbank.token !== '') {
      transbankFormRef.current.submit();
    }
  }, [transbank]);

  const toggleSetOpenModal = () => {
    setOpenWarningModal({ isOpen: !warningModal.isOpen });
  };

  const handleClickPayButton = (isBikfyPay) => {
    if (!isBikfyPay && isBikfyPaymentEnable) {
      return toggleSetOpenModal();
    }

    return createPayment({ isBikfyPay, transferId });
  };

  const setBikfyPayWarningModalButton = (isBikfyPay) => createPayment({ isBikfyPay, transferId });

  const handleClickCancelButton = () => history.goBack();

  const buyerCommission = (transfer?.amount * commission?.buyer) / 100;

  return (
    <Grid container className={`${container}`}>
      <Grid item xs={12} className={`${titleContainer}`}>
        <Title />
      </Grid>
      <Grid item xs={12} className={`${cardsContainer}`}>
        <Card
          classes={cardClasses}
          isBikfyPay
          handleClickPayButton={handleClickPayButton}
          purchaseContract={commission?.amountPurchaseContract}
          buyerCommission={buyerCommission}
          amountBike={transfer?.amount}
          isDisablePayButton={!isBikfyPaymentEnable}
        />
        <Card
          classes={cardClasses}
          handleClickPayButton={handleClickPayButton}
          amountBike={transfer?.amount}
          purchaseContract={commission?.amountPurchaseContract}
        />
      </Grid>
      <Grid item xs={12} className={`${cancelButtonContainer}`}>
        <Button variant="contained" color="secondary" onClick={handleClickCancelButton}>
          Cancelar
        </Button>
      </Grid>
      <form ref={transbankFormRef} action={transbank.url}>
        <input type="hidden" name="token_ws" value={transbank.token} />
      </form>
      <WarningModal
        isOpen={warningModal.isOpen}
        className={`${warningModalContainer}`}
        toggleSetOpenModal={toggleSetOpenModal}
        setBikfyPayWarningModalButton={setBikfyPayWarningModalButton}
        fullScreen={isDownSxScreen}
      />
    </Grid>
  );
};

export default composedComponent(BuyerPayMethodScreen, buyerPayMethodScreenSaga, {
  saga: 'buyerPayMethodScreenSaga',
  middlewares: [withStyles(styles)],
  states: ['buyerPayMethod', 'app.objects'],
  actions: [buyerPayMethodScreenActions],
});
