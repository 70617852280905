import React, { useState } from 'react';

import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const OptionButton = (props) => {
  const { handleSetOpenReportModal, user } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOptionButton = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionMenu = (event) => {
    setAnchorEl(null);
  };

  const handleClickReportButton = (event) => {
    handleSetOpenReportModal();
    handleCloseOptionMenu();
  };

  return (
    <Box>
      <Tooltip title={!user ? 'Para desplegar las opciones debes iniciar sesión' : 'Opciones'}>
        <Box style={{ cursor: 'pointer' }}>
          <Button onClick={handleClickOptionButton} disabled={!user}>
            <MoreVertIcon />
          </Button>
        </Box>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseOptionMenu}>
        <MenuItem onClick={handleClickReportButton}>Reportar</MenuItem>
      </Menu>
    </Box>
  );
};

export default OptionButton;
