const styles = (theme) => ({
  dialog: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '52px',
    },
  },
});

export default styles;
