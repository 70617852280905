import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useState } from 'react';

const ReportPublishDialog = (props) => {
  const { open, handleToggleReportPublishModal, reportReasons, handlePublishReport } = props;

  const handleClose = (event) => {
    handleToggleReportPublishModal({ bikeMarketId: 0 });
  };

  const [formValue, setFormValue] = useState({ reportReasonId: '' });

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormValue((prevState) => ({
      ...prevState,
      [name]: Number(value),
    }));
  };

  const handleClickReportButton = () => {
    const { reportReasonId } = formValue;
    if (reportReasonId) handlePublishReport({ reportReasonId });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        ¿Por qué deseas <span className="color-danger">reportar</span> esta publicación?
      </DialogTitle>
      <DialogContent>
        <RadioGroup onChange={handleChange} name="reportReasonId" value={formValue.reportReasonId}>
          <Grid container spacing={2}>
            {reportReasons?.map((reportReason) => (
              <Grid item xs={12} key={reportReason.id}>
                <Paper>
                  <Box mx={2}>
                    <FormControlLabel
                      value={reportReason.id}
                      control={<Radio />}
                      label={reportReason.name}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClickReportButton}>
          Reportar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReportPublishDialog.propTypes = {
  open: PropTypes.bool,
  handleToggleReportPublishModal: PropTypes.func,
  reportReasons: PropTypes.arrayOf(PropTypes.shape({})),
  handlePublishReport: PropTypes.func,
};

ReportPublishDialog.defaultProps = {
  open: false,
  handleToggleReportPublishModal: () => {},
  reportReasons: [],
  handlePublishReport: () => {},
};

export default ReportPublishDialog;
