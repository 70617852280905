import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { cleanWhiteSpaces, composedComponent, validateEmail } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { transferActions } from 'screens/Transfer/reducer';
import { useForm } from 'utils/hooks/useForm';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { TOTAL_SELLER_FLOW_STEPS } from 'utils/consts';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import saga from 'screens/Transfer/saga';
import SimpleStepper from 'commons/components/SimpleStepper';
import TransferModal from 'screens/Transfer/components/TransferModal';
import TransferBike from './components/transferCard';

import styles from './styles';

const useStyles = makeStyles(styles);

const SpecialText = () => (
  <Typography
    className="specialTitle"
    variant="h6"
    component="span"
    color="primary"
    style={{ fontWeight: '700' }}>
    vendiendo y transfiriendo
  </Typography>
);

const Transfer = (props) => {
  const {
    alert,
    actions,
    buyerStatus,
    error,
    formControls,
    loading,
    match,
    newTransferId,
    objects,
    user,
  } = props;

  const history = useHistory();
  const { id } = match.params;

  const transfer = objects?.transfers[id];
  const motorbikeInfo = objects?.motorbikes ? Object.values(objects?.motorbikes)[0] : {};
  const brand = transfer?.bike?.brand?.name;
  const model = transfer?.bike?.model?.name;
  const size = transfer?.bike?.size?.name;
  const category = transfer?.bike?.category?.name;
  const rim = transfer?.bike?.rim?.name;
  const year = transfer?.bike?.year;
  const serial = transfer?.bike?.serial;
  const infoUrl = process.env.REACT_APP_INFO_URL;
  const isBicycle = transfer?.bike?.isBicycle;
  const engineSerial = motorbikeInfo?.engineSerial;
  const chassisSerial = motorbikeInfo?.chassisSerial;
  const engineCapacity = motorbikeInfo?.engineCapacity?.capacity;

  const classes = useStyles();

  const { cardContainer } = classes;

  useEffect(() => {
    if (error) {
      actions.resetControls();
      history.push('/');
    }
  }, [error]);

  useEffect(() => {
    actions.resetControls();
  }, [actions.resetControls]);

  useEffect(() => {
    actions.fetchTransfer(id);
  }, [id]);

  useEffect(() => {
    if (newTransferId) {
      history.push(`/transfer/${newTransferId}/sales-method`);
      actions.resetControls();
    }
    // }, [newTransferId, actions.resetControls]);
  }, [newTransferId]);

  const changeControls = useCallback((name, value) => {
    if (name === 'email') {
      value = cleanWhiteSpaces(value);
    }
    actions.changeControls({ [name]: value });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(() => actions.transferValidation({ id, ...formControls }));
  };

  const { onChange, onSubmit, errors } = useForm(formControls, null, {
    onChange: changeControls,
    validations: {
      email: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El Email es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'El Email es inválido',
        },
      ],
      amount: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'El Precio de venta es requerido',
        },
      ],
    },
  });

  const validate = (value) => value || 'No especificado';

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card className={cardContainer}>
          <CardContent>
            <Grid container>
              <SimpleStepper activeStep={1} stepTotal={TOTAL_SELLER_FLOW_STEPS} />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography
                  className="title"
                  color="textSecondary"
                  style={{ fontWeight: '700' }}
                  variant="h6"
                  align="center">
                  Estás <SpecialText /> la Titularidad de la Bike por medio de una compraventa.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {transfer?.wasDeletedWithSoldReasonLastBikeMarket && (
                  <Alert severity="warning">
                    <Typography>
                      Has vendido tu Bike. De acuerdo con nuestros{' '}
                      <a href={`${infoUrl}/terminos-y-condiciones2/`} color="inherit">
                        T&C
                      </a>
                      , debes transferir al nuevo dueño para evitar que pierda su trazabilidad.
                    </Typography>
                  </Alert>
                )}
              </Grid>
              {!user?.user.dataComplete && (
                <Grid item xs={12} sm={12}>
                  <Alert severity="info">
                    Completa tu <Link to="/profile">perfil</Link> para poder realizar una
                    transferencia.
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <TransferBike
                  title={`${brand} ${model || ''}`}
                  size={validate(size)}
                  category={validate(category)}
                  rim={validate(rim)}
                  year={validate(year)}
                  serial={validate(serial)}
                  engineSerial={validate(engineSerial)}
                  chassisSerial={validate(chassisSerial)}
                  engineCapacity={engineCapacity}
                  isBicycle={isBicycle}
                  errors={errors}
                  onControlChange={onChange}
                  controls={formControls}
                  onSubmit={handleSubmit}
                  loading={loading}
                  profileComplete={user?.user.dataComplete}
                />
              </Grid>
              <Grid container spacing={3} style={{ padding: '0px 15px' }}>
                <Grid item xs={12} sm={12}>
                  <CustomCheckBox
                    disabled={!user?.user.dataComplete}
                    label="Declaro que esta Bike me pertenece y está debidamente inscrita en el Registro de Titularidad de BIKFY."
                    onChange={onChange}
                    name="possessionCheckbox"
                    value={formControls.possessionCheckbox}
                  />
                </Grid>
                <Grid item xs={12} sm={12} align="end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formControls.possessionCheckbox}>
                    {loading ? <CircularProgress size={24} /> : 'Continuar'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <TransferModal
        alert={alert}
        buyerStatus={buyerStatus}
        clearAlert={actions.clearAlert}
        formControl={formControls}
        generateTransfer={actions.fetchTransferPreview}
        id={id}
        loading={loading}
      />
    </Grid>
  );
};

export default composedComponent(Transfer, saga, {
  states: [
    'transfer.loading',
    'transfer.error',
    'transfer.formControls',
    'transfer.alert',
    'transfer.buyerStatus',
    'transfer.newTransferId',
    'app.objects',
    'app.user',
  ],
  actions: [transferActions, appActions],
  saga: 'transferSaga',
});
