import React from 'react';
import Typography from '@material-ui/core/Typography';

const CustomTitle = ({ firstTitle = '', secondTitle = '', variant }) => (
  <Typography
    color="textSecondary"
    style={{ fontWeight: '700', fontSize: '1.25rem'}}
    component="h5"
    align="center"
  >
    <Typography
      variant="h6"
      component="span"
      color={variant}
      style={{ fontWeight: '700' }}
    >
      {firstTitle}
    </Typography>
    {' '}
    {secondTitle}
  </Typography>
);

export default CustomTitle;
