import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, TablePagination } from '@material-ui/core';

const ROWS_PER_PAGE = [9, 15, 30, 45];

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  },
}));

const CustomPagination = (props) => {
  const { pagination, setPagination, rowsPerPage } = props;
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPagination({
      offset: newPage * pagination.limit,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setPagination({
      limit: rowsPerPage,
      offset: 0,
      page: 0,
    });
  };

  return (
    <TablePagination
      component="div"
      count={pagination.total}
      page={pagination.page}
      onPageChange={handleChangePage}
      rowsPerPage={pagination.limit}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Bikes por página"
      rowsPerPageOptions={rowsPerPage ?? ROWS_PER_PAGE}
      className={classes.root}
    />
  );
};

CustomPagination.propTypes = {
  pagination: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default CustomPagination;
