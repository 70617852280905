import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'profile/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const PROFILE = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  POST_UPDATE_PROFILE: `${PATH}POST_UPDATE_PROFILE`,
  POST_UPDATE_PROFILE_SUCCESS: `${PATH}POST_UPDATE_PROFILE_SUCCESS`,
  POST_UPDATE_PROFILE_ERROR: `${PATH}POST_UPDATE_PROFILE_ERROR`,
  GET_STATE_FROM_API_COMUNES: `${PATH}GET_STATE_FROM_API_COMUNES`,
  GET_STATE_FROM_API_COMUNES_SUCCESS: `${PATH}GET_STATE_FROM_API_COMUNES_SUCCESS`,
  GET_PROFILE: `${PATH}GET_PROFILE`,
  GET_PROFILE_SUCCESS: `${PATH}GET_PROFILE_SUCCESS`,
  GET_PROFILE_ERROR: `${PATH}GET_PROFILE_ERROR`,
  GET_VEHICLE_TYPES: `${PATH}GET_VEHICLE_TYPES`,
  GET_VEHICLE_TYPES_SUCCESS: `${PATH}GET_VEHICLE_TYPES_SUCCESS`,
  GET_VEHICLE_TYPES_ERROR: `${PATH}GET_VEHICLE_TYPES_ERROR`,
  UPDATE_VEHICLE_TYPES_SELECTED: `${PATH}UPDATE_VEHICLE_TYPES_SELECTED`,
  UPDATE_USER_VEHICLE_INTERESTS: `${PATH}UPDATE_USER_VEHICLE_INTERESTS`,
  UPDATE_USER_VEHICLE_INTERESTS_SUCCESS: `${PATH}UPDATE_USER_VEHICLE_INTERESTS_SUCCESS`,
  UPDATE_USER_VEHICLE_INTERESTS_ERROR: `${PATH}UPDATE_USER_VEHICLE_INTERESTS_ERROR`,
};

// actions
export const profileActions = {
  resetControls: createAction(PROFILE.RESET_CONTROLS),
  updateProfile: createAction(PROFILE.POST_UPDATE_PROFILE, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls'),
  updateVehicleTypesSelected: createAction(PROFILE.UPDATE_VEHICLE_TYPES_SELECTED, 'payload'),
  getProfile: createAction(PROFILE.GET_PROFILE, 'payload'),
  getStateFromApiComunes: createAction(PROFILE.GET_STATE_FROM_API_COMUNES),
  getVehicleTypes: createAction(PROFILE.GET_VEHICLE_TYPES),
  updateUserVehicleInterests: createAction(PROFILE.UPDATE_USER_VEHICLE_INTERESTS, 'payload'),
};

const initialState = {
  formControls: {
    name: '',
    firstLastName: '',
    secondLastName: '',
    taxNumber: '',
    documentNumber: '',
    birthday: '',
    phone: '',
    gender: '',
    addressStreet: '',
    addressNumber: '',
    addressDetail: '',
    comuneId: '',
    email: '',
  },
  vehicleTypesSelected: [],
  user: null,
  loading: false,
  error: false,
  successProfile: false,
  successUserInterests: false,
  message: '',
  comunes: '',
};

const profile = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
  },
  [PROFILE.RESET_CONTROLS](state) {
    state.successProfile = false;
    state.successUserInterests = false;
  },
  [PROFILE.UPDATE_VEHICLE_TYPES_SELECTED](state, action) {
    state.vehicleTypesSelected = action.payload.vehicleTypesSelected;
  },
  [PROFILE.POST_UPDATE_PROFILE](state) {
    state.loading = true;
    state.error = false;
    state.successProfile = false;
  },
  [PROFILE.POST_UPDATE_PROFILE_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.successProfile = true;
  },
  [PROFILE.POST_UPDATE_PROFILE_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.successProfile = false;
  },
  [PROFILE.UPDATE_USER_VEHICLE_INTERESTS](state) {
    state.loading = true;
    state.error = false;
  },
  [PROFILE.UPDATE_USER_VEHICLE_INTERESTS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.user.firstTimeUser = false;
    state.user.completedDataWithoutVehicleInterests = false;
    state.successUserInterests = true;
  },
  [PROFILE.UPDATE_USER_VEHICLE_INTERESTS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [PROFILE.GET_PROFILE](state) {
    state.loading = true;
    state.error = false;
  },
  [PROFILE.GET_PROFILE_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.user = action.payload;
  },
  [PROFILE.GET_PROFILE_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [PROFILE.GET_VEHICLE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [PROFILE.GET_VEHICLE_TYPES_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
  },
  [PROFILE.GET_VEHICLE_TYPES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
});

export default profile;
