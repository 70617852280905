import React, { useCallback, useEffect } from 'react';
// Import material-ui
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
//---
import CustomTitle from 'commons/components/CustomTitle';
import ReportForm from 'screens/Report/components/ReportForm';
import { composedComponent } from 'utils/functions';
import saga from 'screens/Report/saga';
import { reportActions } from 'screens/Report/reducer';
import { appActions } from 'commons/reducer';
import { useForm } from 'utils/hooks/useForm';
import ReportModal from 'screens/Report/components/ReportModal';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { PATHS } from 'utils/paths';

const Report = (props) => {
  const history = useHistory();
  const { id } = props.match.params;
  const { alert } = props;

  useEffect(() => {
    if (props.successReport) {
      props.actions.resetControls();
      history.push(PATHS.PILOT);
    }
  }, [props.successReport]);

  useEffect(() => {
    props.actions.resetControls();
  }, [props.resetControls]);

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.actions.reportBike({ id, ...props.formControls });
  };

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      description: [
        {
          check: (value) => value && value.trim().length > 0,
          message: 'Campo requerido',
        },
      ],
    },
  });

  const handleValidateReport = (e) => {
    e.preventDefault();
    onSubmit(() => props.actions.openAlert());
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <CustomTitle
                  firstTitle="Denuncia"
                  secondTitle="tu Bike"
                  variant="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" color="textSecondary" component="p" align="center">
                  Al denunciar tu Bike nadie más que tú podrá reclamarla
                  legalmente y te avisaremos en caso que alguien desee hacerlo.
                </Typography>
              </Grid>
              {!props.user?.user.dataComplete
                && (
                  <Grid item xs={12} sm={12}>
                    <Alert severity="info">
                      Completa tu
                      {' '}
                      <Link to="/profile">perfil</Link>
                      {' '}
                      para poder realizar una denuncia.
                    </Alert>
                  </Grid>
                )}
              <Grid item xs={12} sm={12}>
                <ReportForm
                  changeControls={onChange}
                  controls={props.formControls}
                  onSubmit={handleValidateReport}
                  errors={errors}
                  profileComplete={props.user?.user.dataComplete}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <ReportModal
        alert={alert}
        clearAlert={props.actions.clearAlert}
        handleSubmit={handleSubmit}
        loading={props.loading}
      />
    </Grid>
  );
};

export default composedComponent(Report, saga,
  {
    states: [
      'report.formControls',
      'report.alert',
      'report.successReport',
      'report.loading',
      'app.user',
    ],
    actions: [reportActions, appActions],
    saga: 'reportSaga',
  });
