import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, {
  apiSuccess, destroy, get, post,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { PURCHASEPREVIEW } from '../reducer';

function* getTransfer() {
  yield takeLatest(PURCHASEPREVIEW.GET_TRANSFER, function* getTransferGenerator(action) {
    const { transferId } = action.payload;

    const response = yield apiRequest(
      `api/v1/transfers/get-one-transfer/${transferId}`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      yield put({
        type: PURCHASEPREVIEW.GET_TRANSFER_SUCCESS,
      });
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put({
        type: PURCHASEPREVIEW.GET_TRANSFER_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

function* fetchCoupons() {
  yield takeLatest(PURCHASEPREVIEW.FETCH_COUPONS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.FETCH_COUPONS));

    const response = yield get(`api/v1/coupons${action.payload.taxNumber ? `?taxNumber=${action.payload.taxNumber}` : ''}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.FETCH_COUPONS));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(PURCHASEPREVIEW.FETCH_COUPONS_SUCCESS, response.coupons));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: response.errorMsg || 'Error fetching coupons' }));
      yield put(apiSuccess(PURCHASEPREVIEW.FETCH_COUPONS_ERROR, { errorMsg: 'Error fetching coupons' }));
    }
  });
}

// Redeem a specific coupon by its code and the store ID (usedIn)
function* redeemCoupon() {
  yield takeLatest(PURCHASEPREVIEW.REDEEM_COUPON, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.REDEEM_COUPON));

    const response = yield post('api/v1/coupons/redeem', {
      code: action.payload.code, usedIn: action.payload.usedIn, store: action.payload.store, buyer: action.payload.buyer,
    });
    yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.REDEEM_COUPON));

    if (!response.error) {
      // yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Cupón cargado correctamente'));
      yield put(apiSuccess(PURCHASEPREVIEW.REDEEM_COUPON_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error redeeming coupon' }));
      yield put(apiSuccess(PURCHASEPREVIEW.REDEEM_COUPON_ERROR, { errorMsg: 'Error redeeming coupon' }));
    }
  });
}

function* deleteTransfer() {
  yield takeLatest(PURCHASEPREVIEW.DELETE_TRANSFER, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.DELETE_TRANSFER));
    const response = yield destroy(`api/v1/transfers/${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.DELETE_TRANSFER));

    if (!response.error) {
      yield put(apiSuccess(PURCHASEPREVIEW.DELETE_TRANSFER_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia rechazada'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al rechazar la transferencia' }));
      yield put(apiSuccess(PURCHASEPREVIEW.DELETE_TRANSFER_ERROR));
    }
  });
}

function* getStateFromApiComunes() {
  yield takeLatest(PURCHASEPREVIEW.GET_STATE_FROM_API_COMUNES, function* (action) {
    // yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.POST_UPDATE_PROFILE));
    const response = yield get('api/v1/comunes/');
    // yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.POST_UPDATE_PROFILE));
    yield put(apiSuccess(PURCHASEPREVIEW.GET_STATE_FROM_API_COMUNES_SUCCESS, response));
  });
}

function* getStateFromApiRegions() {
  yield takeLatest(PURCHASEPREVIEW.GET_STATE_FROM_API_COMUNES, function* (action) {
    // yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.POST_UPDATE_PROFILE));
    const response = yield get('api/v1/regions/');
    // yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.POST_UPDATE_PROFILE));
    yield put(apiSuccess(PURCHASEPREVIEW.GET_STATE_FROM_API_REGIONS_SUCCESS, response));
  });
}

function* createTransferPDF() {
  yield takeLatest(
    PURCHASEPREVIEW.CREATE_TRANSFER_PDF,
    function* createTransferPDFGenerator(action) {
      const { transferId } = action.payload;

      const response = yield get(`api/v1/transfers/create-pdf/${transferId}`);

      if (!response.error) {
        const { fileId, objects } = response;
        yield put({ type: PURCHASEPREVIEW.CREATE_TRANSFER_PDF_SUCCESS, payload: { fileId } });
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, { objects }));
      } else {
        const error = JSON.parse(response.errorMsg);
        const { errorMessage } = error;
        yield put({
          type: PURCHASEPREVIEW.CREATE_TRANSFER_PDF_FAILURE,
          payload: { errorMessage },
        });
        yield put(apiSuccess(APP.SET_ERROR, { errorMsg: errorMessage }));
      }
    },
  );
}

function* nextStep() {
  yield takeLatest(
    PURCHASEPREVIEW.NEXT_STEP,
    function* continueNextStep(action) {
      const { transferId, formControls } = action.payload;
      const {
        commune,
        addressStreet,
        addressNumber,
        apartment,
      } = formControls;

      const body = JSON.stringify({
        commune,
        addressStreet,
        addressNumber,
        apartment,
      });

      const response = yield apiRequest(
        `api/v1/transfers/nextStep/${transferId}`,
        { method: 'POST', body },
        // true,
      );

      if (!response.error) {
        const { fileId, objects } = response;
        yield put({ type: PURCHASEPREVIEW.NEXT_STEP_SUCCESS, payload: { fileId } });
        yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, { objects }));
      } else {
        const error = JSON.parse(response.errorMsg);
        const { errorMessage } = error;
        yield put({
          type: PURCHASEPREVIEW.NEXT_STEP_FAILURE,
          payload: { errorMessage },
        });
        yield put(apiSuccess(APP.SET_ERROR, { errorMsg: errorMessage }));
      }
    },
  );
}

function* createPayment() {
  yield takeLatest(PURCHASEPREVIEW.CREATE_PAYMENT, function* creatPaymentGenerator(action) {
    const {
      transferId, isBikfyPayment, paymentMethod, coupon,
    } = action.payload;

    console.log('transbank');

    const body = JSON.stringify({ isBikfyPayment, paymentMethod, coupon });

    const response = yield apiRequest(
      `api/v1/payments/create-transbank/${transferId}`,
      { method: 'POST', body },
      true,
    );

    if (!response.error) {
      const { url, token } = response;
      yield put({
        type: PURCHASEPREVIEW.CREATE_PAYMENT_SUCCESS,
        payload: {
          url,
          token,
        },
      });
    } else {
      const { errorMessage } = JSON.parse(response.errorMsg);
      yield put({
        type: PURCHASEPREVIEW.CREATE_PAYMENT_FAILURE,
        payload: { errorMessage },
      });
    }
  });
}

function* createMercadoPagoPayment() {
  yield takeLatest(
    PURCHASEPREVIEW.CREATE_MERCADO_PAGO_PAYMENT,
    function* creatMercadoPagoPaymentGenerator(action) {
      const { transferId, paymentMethod } = action.payload;

      const body = JSON.stringify({ paymentMethod });

      const response = yield apiRequest(
        `api/v1/payments/create-mercado-pago/${transferId}`,
        { method: 'POST', body },
        true,
      );

      if (!response.error) {
        yield put({
          type: PURCHASEPREVIEW.CREATE_PAYMENT_SUCCESS,
          payload: {
            url: response.url,
            token: '0610',
          },
        });
      } else {
        console.log('Error Front');
      }
    },
  );
}

function* acceptTransferBuyer() {
  yield takeLatest(PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER));
    const response = yield post(`api/v1/transfers/${id}/accept-transfer-buyer`);
    yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER));

    if (!response.error) {
      yield put(apiSuccess(PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER_SUCCESS, response));
      // yield put(apiSuccess(APP.SET_SUCCESS, 'Transferencia exitosa'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al obtener los datos' }));
      yield put(apiSuccess(PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER_ERROR));
    }
  });
}

function* generatePayment() {
  yield takeLatest(PURCHASEPREVIEW.GENERATE_PAYMENT, function* (action) {
    const id = action.payload;
    yield put(apiSuccess(APP.ADD_LOADING, PURCHASEPREVIEW.GENERATE_PAYMENT));
    const response = yield post(`api/v1/payments/create?id=${id}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, PURCHASEPREVIEW.GENERATE_PAYMENT));

    if (!response.error) {
      yield put(apiSuccess(PURCHASEPREVIEW.GENERATE_PAYMENT_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al generar el pago' }));
      yield put(apiSuccess(PURCHASEPREVIEW.GENERATE_PAYMENT_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(getTransfer);
  yield spawn(deleteTransfer);
  yield spawn(getStateFromApiComunes);
  yield spawn(getStateFromApiRegions);
  yield spawn(createTransferPDF);
  yield spawn(nextStep);
  yield spawn(createPayment);
  yield spawn(createMercadoPagoPayment);
  yield spawn(acceptTransferBuyer);
  yield spawn(generatePayment);
  yield spawn(fetchCoupons);
  yield spawn(redeemCoupon);
}
