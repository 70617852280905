// @ts-nocheck
import React, { useMemo } from 'react';
// Project
import { appActions } from 'commons/reducer';
import { composedComponent } from 'utils/functions';
import { homeActions } from 'screens/Home/reducer';
import saga from 'commons/saga';
// material
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
// components
import NavBar from 'commons/components/NavBar';
import SideBar from 'commons/components/SideBar';
import { LinearProgress } from '@material-ui/core';
import { PATHS } from 'utils/paths';
import Footer from 'commons/components/Footer';
import Banner from 'commons/components/Banner';

import pathTypes, { view } from 'utils/pathTypes';
import { useLocation } from 'react-router-dom';
import { adminActions } from 'screens/Admin/reducer';
import { getUserType } from '../../../screens/Home/utils';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Layout = (props) => {
  const userType = getUserType(props.user?.user);
  const location = useLocation();
  const { pathname } = location;
  const { pathType } = useMemo(() => pathTypes({ pathname, userType }), [pathname, userType]);

  const logOut = () => {
    localStorage.clear();
    props.actions.setUser(null);
    props.actions.resetFilterForm();
    props.actions.resetStoreHomeFilterForm();
    props.actions.clearPilotAlert();
    props.actions.clearTransferAlert();
    props.actions.resetUserAlertedInLanding();
    props.history.push(PATHS.HOME);
  };

  const toggleDrawer = (redirectTo = null) => {
    props.actions.toggleDrawer({ path: redirectTo });
    if (redirectTo === PATHS.HOME && pathname !== PATHS.HOME) {
      props.actions.resetFilterForm();
      props.actions.clearLastMarketplaceOrder();
      props.actions.clearSortBy();
      props.actions.resetMarketplacePagination();
    }
    if (redirectTo === PATHS.STORE && pathname !== PATHS.STORE && userType === 'store') {
      props.actions.clearStoreHomeSortBy();
      props.actions.resetStoreHomeFilterForm();
      props.actions.resetStoreHomePagination();
    }
    if (redirectTo === PATHS.ADMIN && pathname !== PATHS.ADMIN && userType === 'admin') {
      props.actions.resetControls();
      props.actions.resetPagination();
    }
  };

  const toggleError = () => {
    props.actions.toggleError();
  };

  const toggleSuccess = () => {
    props.actions.toggleSuccess();
  };

  const handleClickBikfyLogo = () => {
    if (pathname !== PATHS.HOME) {
      props.actions.resetFilterForm();
      props.actions.clearLastMarketplaceOrder();
      props.actions.clearSortBy();
      props.actions.resetMarketplacePagination();
      props.history.push(PATHS.HOME);
    }
  };

  return (
    <>
      <NavBar
        logOut={logOut}
        toggleDrawer={toggleDrawer}
        user={props.user}
        handleClickBikfyLogo={handleClickBikfyLogo}
      />

      {props.loading.length ? <LinearProgress style={{ marginTop: '-4px' }} /> : null}
      <SideBar isOpen={props.drawerIsOpen} toggleDrawer={toggleDrawer} user={props.user} />
      <div className="content-container">
        {pathType === view.LANDING && (
          <Banner
            usersCount={props?.usersCount}
            userType={userType}
            bikesCount={props?.bikesCount}
          />
        )}
        <Grid
          container
          justifyContent="center"
          style={{ paddingTop: '32px', paddingBottom: '32px' }}
        >
          <Grid item md={11} sm={12}>
            {props.children}
          </Grid>
        </Grid>
      </div>

      {/* ALERT: error */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {props.errorMsg}
        </Alert>
      </Snackbar>
      {/* ALERT: success */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.success}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {props.successMsg}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default composedComponent(Layout, saga, {
  saga: 'sagaApp',
  states: [
    'app.searchPrinterLoading',
    'app.drawerIsOpen',
    'app.deviceError',
    'app.device',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.user',
    'app.loading',
    'home.pilotAlert',
    'home.usersCount',
    'home.bikesCount',
  ],
  actions: [appActions, homeActions, adminActions],
});
