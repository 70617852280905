import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import saga from 'screens/Insurance/saga';
import { insuranceActions } from 'screens/Insurance/reducer';
import { composedComponent } from 'utils/functions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { PlanCard } from '../PlanCard/PlanCard';

const featuresBasic = [
  { title: 'Robo de bicicleta', description: 'Cobertura a valor comercial como consecuencia del robo con violencia o fuerza en las cosas (vulneración de algún sistema de seguridad).' },
  {
    title: 'Pérdida total',
    description:
      'Cobertura a valor comercial en caso de daños iguales o superiores al 75% del valor comercial.',
  },
];
const featuresFull = [
  ...featuresBasic,
  {
    title: 'Accidentes personales',
    description: 'Muerte accidental o incapacidad total cobertura anual hasta 150UF.',
  },
  {
    title: 'Reembolso gastos médicos',
    description: 'Cobertura anual de hasta 15UF. Un evento por vigencia.',
  },
];

// eslint-disable-next-line import/prefer-default-export
function PlanSelectorCard(props) {
  const { insurance, actions } = props;
  const {
    quotes,
    loading,
    loadingPdfBasic,
    loadingPdfFull,
    formControls,
    loadingPurchaseBasic,
    loadingPurchaseFull,
  } = insurance;
  const { beneficiary } = formControls;
  const history = useHistory();

  const INFORMATION_FOOTER = 'La información presentada es sólo un resumen de las principales características del seguro. Las coberturas y exclusiones se rigen según lo dispuesto en las Condiciones Generales de la póliza, las que se encuentran debidamente depositadas en la Comisión para el Mercado Financiero bajo el código POL POL120200100, Artículo. 3, numeral 3.1-B y C, 3.3.1, 3.3.2 y 3.3.3';

  const user = {
    comune: beneficiary.comune,
    addressNumber: beneficiary.addressNumber,
    email: beneficiary.email,
    addressStreet: beneficiary.addressStreet,
  };
  const acceptInfoBasicQuote = {
    bciQuoteId: quotes.basic.bciQuoteId,
    user,
    transferId: formControls.bike.transferId,
    fees: JSON.stringify({
      feeGross: quotes.basic.feeGross,
      feeNet: quotes.basic.feeNet,
      feeTax: quotes.basic.feeTax,
    }),
  };
  const acceptInfoFullQuote = {
    bciQuoteId: quotes.full.bciQuoteId,
    user,
    transferId: formControls.bike.transferId,
    fees: JSON.stringify({
      feeGross: quotes.full.feeGross,
      feeNet: quotes.full.feeNet,
      feeTax: quotes.full.feeTax,
    }),
  };

  const infoBasicCard = {
    bciQouteId: quotes.basic.bciQuoteId,
    title: 'Básico',
    price: quotes.basic.feeGross,
    features: featuresBasic,
    onClick: () => actions.acceptBasicInsuranceQuote(acceptInfoBasicQuote),
    downloadPdf: () => actions.generatePdfInsuranceBasicQuote(quotes.basic.bciQuoteId),
    loadingPdf: loadingPdfBasic,
    loadingPurchase: loadingPurchaseBasic,
    disabled: loadingPurchaseFull,
  };
  const infoFullCard = {
    bciQouteId: quotes.full.bciQuoteId,
    title: 'Full',
    price: quotes.full.feeGross,
    features: featuresFull,
    onClick: () => actions.acceptFullInsuranceQuote(acceptInfoFullQuote),
    downloadPdf: () => actions.generatePdfInsuranceFullQuote(quotes.full.bciQuoteId),
    loadingPdf: loadingPdfFull,
    loadingPurchase: loadingPurchaseFull,
    disabled: loadingPurchaseBasic,
  };
  const infoCards = [infoBasicCard, infoFullCard];
  return (
    <>
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="stretch"
            justifyContent="center"
            style={{ display: 'flex' }}
          >
            {loading && (
              <Grid item xs={12} sm={3}>
                <LinearProgress />
              </Grid>
            )}
            {!loading
              && infoCards.map((info) => (
                <Grid item style={{ display: 'flex', marginBottom: '2rem' }} xs={12} sm={3}>
                  <PlanCard info={info} />
                </Grid>
              ))}
          </Grid>
          <Divider style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }} />
          {!loading && <Typography align="center">{INFORMATION_FOOTER}</Typography>}
          <IconButton edge="start" onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
        </CardContent>
      </Card>
    </>
  );
}
export default composedComponent(PlanSelectorCard, saga, {
  saga: 'sagaInsurance',
  states: ['insurance'],
  actions: [insuranceActions],
});
