import React, { useCallback } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { DirectionsBike } from '@material-ui/icons';
import CustomSelectField from 'commons/components/CustomAutoComplete';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import { insuranceActions } from 'screens/Insurance/reducer';
import saga from 'screens/Insurance/saga';
import styles from 'screens/Insurance/styles';
import { composedComponent } from 'utils/functions';
import { useForm } from 'utils/hooks/useForm';

const BikeForm = (props) => {
  const { formControls, bikeFormValues: formValues, actions } = props;
  const { bike } = formControls;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onChangeControls = useCallback((name, value) => {
    actions.changeBikeControls({ [name]: value });
  }, []);

  const { onChange, onSubmit, errors } = useForm(bike, null, {
    onChange: onChangeControls,
    validations: {
      amountInsurable: [
        {
          check: (value) => !!value,
          message: 'El monto asegurable es requerido. ',
        },
        {
          check: (value) => parseInt(value ?? '0', 10) <= 5000000,
          message:
            'Si tu bicicleta tiene un valor mayor a $5.000.000 puedes asegurarla hasta por un tope de $5.000.000. ',
        },
        {
          check: (value) => parseInt(value ?? '0', 10) > 0,
          message: 'El monto asegurable debe ser válido. ',
        },
      ],
      useTypeId: [
        {
          check: (value) => !!value,
          message: 'El Tipo de uso es requerido',
        },
      ],
    },
  });
  const handleSubmit = () => {
    onSubmit(() => {
      actions.changeStep('2');
      actions.registerInsuranceQuotes({
        transferId: bike.transferId,
        data: {
          beneficiary: formControls.beneficiary,
          insurance: {
            useTypeId: bike.useTypeId,
            amountInsurable: bike.amountInsurable,
          },
        },
      });
    });
  };
  const inputProperty = (item) => {
    switch (item.type) {
      case 'text':
        return (
          <Grid item md={1} xs={12} style={{ marginTop: '18px', marginBottom: '18px' }}>
            <TextField
              id={item.name}
              key={item.name}
              disabled={item.disabled}
              label={item.label}
              variant="standard"
              size="small"
              fullWidth
              value={bike[item.name]}
              style={{ whiteSpace: 'nowrap' }}
            />
          </Grid>
        );
      case 'number':
        return (
          <Grid item md={2} xs={12} style={{ marginTop: '18px', marginBottom: '18px' }}>
            <CustomInputNumber
              label={item.label}
              name={item.name}
              onChange={onChange}
              value={bike[item.name]}
              error={!!errors[item.name]}
              helperText={errors[item.name]}
              disabled={false}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} md={2} style={{ marginTop: '18px', marginBottom: '18px' }}>
            <CustomSelectField
              label={item.label}
              name={item.name}
              onChange={onChange}
              options={item.options}
              value={bike[item.name]}
              error={!!errors[item.name]}
              errors={errors[item.name]}
              disabled={false}
              info={false}
              size="medium"
            />
          </Grid>
        );
      default:
        return 'value';
    }
  };

  return (
    <>
      {isMobileScreen ? (
        <Card style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: '0.5em', marginLeft: '0.4em', marginRight: '0.1em' }}
          >
            <Grid item xs={1}>
              <DirectionsBike fontSize="large" />
            </Grid>
          </Grid>
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {formValues.map((item) => inputProperty(item))}
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            style={{ paddingBottom: '0.5em', marginBottom: '10px' }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Calcular
            </Button>
          </Grid>
        </Card>
      ) : (
        <Card>
          <Grid
            container
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
          >
            <Grid item style={{ marginLeft: '2em' }}>
              <DirectionsBike fontSize="large" />
            </Grid>
            {formValues.map((item) => inputProperty(item))}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '2em' }}
                onClick={handleSubmit}
              >
                Calcular
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default composedComponent(BikeForm, saga, {
  saga: 'sagaInsurance',
  states: ['insurance.formControls'],
  middlewares: [withStyles(styles)],
  actions: [insuranceActions],
});
