import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ClearIcon from '@material-ui/icons/Clear';
import TuneIcon from '@material-ui/icons/Tune';
import InfoIcon from '@material-ui/icons/Info';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Container,
  Typography,
  withStyles,
  Card,
  TextField,
  Switch,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import BikeCard from 'screens/Admin/components/bikeCard';
import { composedComponent, serialInput } from 'utils/functions';
import saga from 'screens/Admin/saga';
import { appActions } from 'commons/reducer';
import { adminActions } from 'screens/Admin/reducer';
import NotBike from 'commons/components/NotBike';
import ClearIcon from 'commons/components/ClearIcon';
import { useForm } from 'utils/hooks/useForm';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomPagination from 'commons/components/CustomPagination';
import CustomMultiSelectComplete from 'commons/components/CustomMultiSelectComplete';
import styles from './styles';
import ModalPhotos from './components/ModalPhotos';
import DisableDialog from './components/DisableDialog/DisableDialog';

const states = [
  {
    value: 'transferred',
    label: 'Transferidas',
  },
  {
    value: 'pending',
    label: 'Pendientes',
  },
  {
    value: 'incompleted',
    label: 'Pre-registradas',
  },
  {
    value: 'completed',
    label: 'Completadas',
  },
];

const getValues = ({ order, objects, name }) => order[name]?.map((t) => objects[name][t]) || Object.values(objects[name]);

const Admin = (props) => {
  const {
    classes,
    objects,
    formControls: controls,
    pagination,
    showFilter,
    actions,
    user,
    order = {},
    admin: adminState,
  } = props;

  const history = useHistory();
  const [openModalPhotos, setOpenModalPhotos] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);
  const vehicleTypes = getValues({ order, objects, name: 'vehicleTypes' });
  const motorbikes = getValues({ order, objects, name: 'motorbikes' });

  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchModels();
    props.actions.fetchCategories();
    props.actions.fetchVehicleTypes();
    props.actions.fetchEngineCapacities();
    props.actions.fetchMotorbikeBrands();
    props.actions.fetchMotorbikeModels();
    props.actions.fetchMotorbikeCategories();
  }, []);

  // esto actualiza el listado ****
  useEffect(() => {
    props.actions.fetchBikes({ ...pagination, ...controls });
  }, [pagination.page, pagination.limit, pagination.total]);

  const bikes = props.order?.bikes?.map((id) => objects?.bikes[id]).filter((bike) => bike) || [];

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({ [name]: value });
    if (name === 'vehicleTypeId') {
      props.actions.changeVehicleTypeControls();
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {},
  });

  const handleOpenPhotos = (documents) => {
    setOpenModalPhotos(true);

    const docs = documents.filter((doc) => ['left_side', 'front_side', 'right_side'].includes(doc.type));
    if (user?.user?.type === 'admin') {
      const invoice = documents.find((doc) => doc.type === 'own_invoice');
      invoice && docs.push(invoice);
    }
    setPhotos(docs);
  };
  const handleclosePhotos = () => {
    setOpenModalPhotos(false);
  };

  const vehicleTypesInput = useMemo(
    () => props.order?.vehicleTypes
      ?.map((vt) => props.vehicleTypes[vt])
      .map((a) => ({ value: a.id, label: a.name })),
    [props.vehicleTypes, props.order?.vehicleTypes],
  );

  const brands = useMemo(
    () => props.order?.brands?.map((b) => props.brands[b]).map((a) => ({ value: a.id, label: a.name })),
    [props.brands, props.order?.brands],
  );

  const motorbikeBrands = useMemo(
    () => props.order?.motorbikeBrands
      ?.map((mb) => props.motorbikeBrands[mb])
      .map((a) => ({ value: a.id, label: a.name })),
    [props.motorbikeBrands, props.order?.motorbikeBrands],
  );

  const motorbikeModels = useMemo(
    () => props.order?.motorbikeModels
      ?.map((mm) => props.motorbikeModels[mm])
      .map((a) => ({ value: a.id, label: a.name, brandId: a.brandId })),
    [props.motorbikeModels, props.order?.motorbikeModels],
  );

  const models = useMemo(
    () => props.order?.models
      ?.map((b) => props.models[b])
      .map((a) => ({ value: a.id, label: a.name, brandId: a.brandId })),
    [props.models, props.order?.models],
  );

  const categories = useMemo(
    () => props.order?.categories
      ?.map((b) => props.categories[b])
      .map((a) => ({ value: a.id, label: a.name })),
    [props.categories, props.order?.categories],
  );

  const motorbikeCategories = useMemo(
    () => props.order?.motorbikeCategories
      ?.map((mc) => props.motorbikeCategories[mc])
      .map((a) => ({ value: a.id, label: a.name })),
    [props.motorbikeCategories, props.order?.motorbikeCategories],
  );

  const engineCapacities = useMemo(
    () => props.order?.engineCapacities
      ?.map((ec) => props.engineCapacities[ec])
      .map((a) => ({ value: a.id, label: a.capacity })),
    [props.engineCapacities, props.order?.engineCapacities],
  );

  const handleToggleShowDisableDialog = ({ bikeId = 0 }) => {
    const isDisabled = objects.bikes[bikeId]?.disable;
    if (isDisabled) {
      actions.setDisableFormDialog({
        disabledReasonIds: objects?.bikes[bikeId]?.disabledBikes?.map(
          (disabledBike) => disabledBike.disabledReasonId,
        ),
      });
    }
    if (adminState.disableDialog.show) {
      actions.setDisableFormDialog({
        reason: '',
        disabledReasonIds: [],
      });
    }
    actions.showDisableDialog({ show: !adminState.disableDialog.show, bikeId });
  };

  const handleToggleDisableBike = ({ formValues, isDisable }) => {
    actions.toggleDisableBike({ formValues, pagination: { ...pagination }, isDisable });
  };

  const onDisableBike = (bikeId) => {
    actions.toggleDisableBike({
      bikeId,
      pagination: { ...pagination },
    });
  };

  const onClickSearchBikeButton = () => {
    props.actions.resetPagination();
    props.actions.fetchBikes(controls);
  };

  const isVehicleTypeBicycle = vehicleTypes.filter((vehicleType) => vehicleType.id === controls.vehicleTypeId)[0]?.name
    === 'Bicicleta';

  const isVehicleTypeMotorbike = vehicleTypes.filter((vehicleType) => vehicleType.id === controls.vehicleTypeId)[0]?.name
    === 'Motocicleta';

  const filteredModels = models?.filter((m) => controls.brandId?.includes(m.brandId));

  const filteredMotorbikeModels = motorbikeModels?.filter((m) => controls.brandId?.includes(m.brandId));

  return (
    <Container>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={3} className={showFilter ? '' : classes.hide}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" align="center">
                Filtros
              </Typography>

              <Card className={classes.filterActions}>
                <Grid container justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.btnDark}
                      onClick={() => props.actions.resetControls()}
                    >
                      <ClearIcon />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onClickSearchBikeButton()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <form onSubmit={onSubmit} autoComplete="off">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Personas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Correo"
                      variant="outlined"
                      className={classes.field}
                      value={controls.email}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="rut"
                      label="RUT."
                      variant="outlined"
                      className={classes.field}
                      value={controls.rut}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Ingrese el RUT en este formato 12.345.678-9">
                              <InfoIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Bikes</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={vehicleTypes}
                      size="medium"
                      disableClearable
                      getOptionLabel={(option) => option.name}
                      value={
                        vehicleTypes?.find((item) => item.id === controls.vehicleTypeId) ?? null
                      }
                      fullWidth
                      onChange={(event, newValue) => changeControls('vehicleTypeId', newValue?.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className={classes.field}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {isVehicleTypeBicycle && (
                    <Grid item xs={12}>
                      <TextField
                        name="serial"
                        label="Código de serie"
                        variant="outlined"
                        className={classes.field}
                        value={controls.serial}
                        onChange={(e) => onChange(e, (s) => serialInput(s))}
                      />
                    </Grid>
                  )}
                  {isVehicleTypeMotorbike && (
                    <Grid item xs={12}>
                      <TextField
                        name="engineSerial"
                        label="Código de serie motor"
                        variant="outlined"
                        className={classes.field}
                        value={controls.engineSerial}
                        onChange={(e) => onChange(e, (s) => serialInput(s))}
                      />
                    </Grid>
                  )}
                  {isVehicleTypeMotorbike && (
                    <Grid item xs={12}>
                      <TextField
                        name="chassisSerial"
                        label="Código de serie chasis"
                        variant="outlined"
                        className={classes.field}
                        value={controls.chassisSerial}
                        onChange={(e) => onChange(e, (s) => serialInput(s))}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <CustomAutoComplete
                      name="categoryId"
                      label="Categoría"
                      value={controls.categoryId}
                      onChange={onChange}
                      options={isVehicleTypeBicycle ? categories : motorbikeCategories}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomMultiSelectComplete
                      name="brandId"
                      label="Marca"
                      value={controls.brandId}
                      onChange={onChange}
                      options={isVehicleTypeBicycle ? brands : motorbikeBrands}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <CustomAutoComplete
                      name="brandId"
                      label="Marca"
                      value={controls.brandId}
                      onChange={onChange}
                      options={isVehicleTypeBicycle ? brands : motorbikeBrands}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <CustomMultiSelectComplete
                      name="modelId"
                      label="Modelo"
                      value={controls.modelId}
                      onChange={onChange}
                      options={isVehicleTypeBicycle ? filteredModels : filteredMotorbikeModels}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <CustomAutoComplete
                      name="modelId"
                      label="Modelo"
                      value={controls.modelId}
                      onChange={onChange}
                      options={isVehicleTypeBicycle ? filteredModels : filteredMotorbikeModels}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <CustomAutoComplete
                      name="state"
                      label="Estado"
                      value={controls.state}
                      onChange={onChange}
                      options={states}
                    />
                  </Grid>
                  {isVehicleTypeMotorbike && (
                    <Grid item xs={12}>
                      <CustomAutoComplete
                        name="engineCapacityId"
                        label="Capacidad Motor"
                        value={controls.engineCapacityId}
                        onChange={onChange}
                        options={engineCapacities}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography>Habilitados</Typography>
                      </Box>
                      <Box>
                        <Switch
                          checked={controls.disable}
                          onChange={(e) => onChange(e)}
                          color="primary"
                          name="disable"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography>En Evaluación</Typography>
                      </Box>
                      <Box>
                        <Switch
                          checked={controls.inEvaluation}
                          onChange={(e) => onChange(e)}
                          color="primary"
                          name="inEvaluation"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography>Denunciadas</Typography>
                      </Box>
                      <Box>
                        <Switch
                          checked={controls.reported}
                          onChange={(e) => onChange(e)}
                          color="primary"
                          name="reported"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomAutoComplete
                      name="document"
                      label="Documentos"
                      value={controls.document}
                      onChange={onChange}
                      options={[
                        {
                          value: 'withDocument',
                          label: 'Con Documentos',
                        },
                        {
                          value: 'withoutDocument',
                          label: 'Sin Documentos',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </form>
        </Grid>

        <Grid item xs={12} sm={showFilter ? 9 : 10}>
          <Grid container justifyContent="space-between" spacing={3} style={{ marginTop: '45px' }}>
            <Button
              className={`${classes.btnDark} ${classes.btnFilter}`}
              variant="contained"
              onClick={() => props.actions.toggleFilter()}
            >
              <TuneIcon />
            </Button>
            <CustomPagination pagination={pagination} setPagination={props.actions.setPagination} />
          </Grid>

          {bikes.length === 0 ? (
            <NotBike loading={props.loading} filter={1} />
          ) : (
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              {bikes.map((b) => (
                <Grid item xs={12} key={b.id}>
                  <BikeCard
                    bike={b}
                    brandId={b.brand?.id}
                    brand={b.brand?.name}
                    inEvaluation={b.inEvaluation}
                    motorbike={motorbikes.filter((mb) => mb?.bikeId === b?.id)}
                    model={b.model?.name}
                    category={b.category?.name}
                    size={b.size?.name}
                    transferId={b.transfer?.id}
                    history={history}
                    openModal={() => handleOpenPhotos(b.documents)}
                    onDisableBike={onDisableBike}
                    handleToggleShowDisableDialog={handleToggleShowDisableDialog}
                    onCreateCouponClick={actions.createCoupon}
                    onRejectClick={actions.rejectCoupon}
                    userId={b.transfer.buyer.id}

                  />
                </Grid>
              ))}
            </Grid>
          )}

          <CustomPagination pagination={pagination} setPagination={props.actions.setPagination} />
        </Grid>
      </Grid>

      <ModalPhotos open={openModalPhotos} handleClose={handleclosePhotos} photos={photos} />
      <DisableDialog
        open={adminState.disableDialog.show}
        handleToggleShowDisableDialog={handleToggleShowDisableDialog}
        disabledReasons={objects.disabledReasons}
        disableFormDialog={adminState.disableFormDialog}
        handleToggleDisableBike={handleToggleDisableBike}
        isDisable={!!objects?.bikes[adminState.disableDialog.bikeId]?.disable}
        actions={actions}
        disabledReasonIds={objects?.bikes[adminState.disableDialog.bikeId]?.disabledBikes?.map(
          (disabledBike) => disabledBike.disabledReasonId,
        )}
      />
    </Container>
  );
};
export default composedComponent(Admin, saga, {
  saga: 'sagaadmin',
  middlewares: [withStyles(styles)],
  states: [
    'admin.loading',
    'admin.formControls',
    'admin.showFilter',
    'admin.pagination',
    'admin',
    'app.objects',
    'app.objects.brands',
    'app.objects.motorbikeBrands',
    'app.objects.models',
    'app.objects.motorbikeModels',
    'app.objects.categories',
    'app.objects.transfers',
    'app.objects.vehicleTypes',
    'app.objects.engineCapacities',
    'app.objects.motorbikeCategories',
    'app.order',
    'app.user',
  ],
  actions: [adminActions, appActions],
});
