import React from 'react';
// MATERIAL
import { Typography, Grid, Tooltip } from '@material-ui/core';

import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { dateToString } from 'utils/functions';

const DatePublished = ({ isMozaicList, classes, createdAt }) => (
  <Grid item style={{ display: 'flex', alignItems: 'flex-start' }}>
    <Grid container alignItems="center">
      <Grid item>
        <DateRangeOutlinedIcon
          className={classes.bikfyGreyColor}
          style={{ fontSize: isMozaicList ? '0.7rem' : '1.25rem', marginRight: '4px' }}
        />
      </Grid>
      <Grid item>
        <Tooltip title="Fecha de publicación" arrow>
          <Typography
            variant="caption"
            className={classes.bikfyGreyColor}
            style={{
              fontSize: isMozaicList ? '0.7rem' : '',
            }}>
            {dateToString(createdAt)}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  </Grid>
);

export default DatePublished;
