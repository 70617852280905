import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'pilot/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const PILOT = {
  POST_ADD_PILOT: `${PATH}POST_ADD_PILOT`,
  POST_ADD_PILOT_SUCCESS: `${PATH}POST_ADD_PILOT_SUCCESS`,
  POST_ADD_PILOT_ERROR: `${PATH}POST_ADD_PILOT_ERROR`,
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  TOGGLE_ERROR: `${PATH}TOGGLE_ERROR`,
  SET_EMAIL_CONTROLS: `${PATH}SET_EMAIL_CONTROLS`,
};

// actions
export const registerActions = {
  addPilot: createAction(PILOT.POST_ADD_PILOT, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'controls', 'invalidControls'),
  setEmailControls: createAction(PILOT.SET_EMAIL_CONTROLS, 'email'),
  resetControls: createAction(PILOT.RESET_CONTROLS),
  toggleError: createAction(PILOT.TOGGLE_ERROR),
};

const initialState = {
  formControls: {
    name: '',
    firstName: '',
    secondName: '',
    taxNumber: '',
    documentNumber: '',
    birthday: '',
    email: '',
    verifyEmail: '',
    password: '',
    verifyPassword: '',
    acceptTerms: false,
  },
  loading: false,
  error: false,
  successRegister: false,
  message: '',
};

const register = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.controls,
    };
  },
  [PILOT.SET_EMAIL_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      email: action.email,
      verifyEmail: action.email,
    };
  },
  [PILOT.POST_ADD_PILOT](state) {
    state.loading = true;
    state.error = false;
    state.message = '';
  },
  [PILOT.POST_ADD_PILOT_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.successRegister = true;
    state.message = 'El Registro se ha realizado con éxito. Recibirás un email para activar tu cuenta o inicia sesión con Google utilizando el mismo correo electrónico.';
  },
  [PILOT.POST_ADD_PILOT_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.successRegister = false;
    state.message = action.payload.errorMsg;
  },
  [PILOT.RESET_CONTROLS](state) {
    state.formControls = { ...initialState.formControls };
    state.message = '';
    state.successRegister = false;
  },
  [PILOT.TOGGLE_ERROR](state) {
    state.error = false;
    state.message = '';
  },
});

export default register;
