import React, { useState } from 'react';
// material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
// react-crop
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IMG_CROP_RATIO } from 'utils/functions';

const CroppedImageModal = ({
  initialCrop, onCommit, image, show, onClose,
}) => {
  const [crop, setCrop] = useState({ ...initialCrop, aspect: IMG_CROP_RATIO });
  const [ratios, setRatios] = useState({ x: 1, y: 1 });

  function onChange(newCrop) {
    setCrop({ ...newCrop, aspect: IMG_CROP_RATIO });
  }

  const onConfirm = () => {
    const transCrop = {
      x: crop.x / ratios.x,
      y: crop.y / ratios.y,
      width: crop.width / ratios.x,
      height: crop.height / ratios.y,
    };
    onCommit(transCrop);
  };

  function onLoad(displayImage) {
    const xRatio = displayImage.width / image.width;
    const yRatio = displayImage.height / image.height;
    setRatios({ x: xRatio, y: yRatio });
    setCrop({
      x: xRatio * crop.x,
      y: yRatio * crop.y,
      width: xRatio * crop.width,
      height: yRatio * crop.height,
      aspect: IMG_CROP_RATIO,
    });
    return false;
  }

  return (
    <Dialog fullWidth open={show} onClose={onClose} maxWidth="lg">
      <DialogTitle>Editar recorte</DialogTitle>
      <DialogContent>
        <Grid container item xs={12} spacing={2} justifyContent="center">
          <ReactCrop src={image?.src} crop={crop} onChange={onChange} onImageLoaded={onLoad} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CroppedImageModal;
