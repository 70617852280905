import { makeStyles } from '@material-ui/core';
import colors from '../../../../utils/colors';

const { primary } = colors;

const useBikeDetailStyles = makeStyles((theme) => ({
  generalContainer: {
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  commonsPaper: {
    maxWidth: '1351px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '20px',
  },
  fWeight700: {
    fontWeight: 700,
  },
  bikeInfoPaper: {
    '& .container': {
      minHeight: '538px',
      '& .bike-img-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .img': {
          height: '100%',
          objectFit: 'cover',
        },
      },
      '& .bike-info-container': {
        '& .price-container': {
          '& .price': {
            color: primary,
            fontWeight: '700',
            fontSize: '36px',
          },
        },
        '& .props-bike-container': {
          '& .props-bike': {
            fontWeight: '700',
            fontSize: '20px',
          },
        },
        '& .bike-location-container': {
          display: 'flex',
          marginTop: '15px',
          '& .bike-location': {
            fontWeight: '700',
            fontSize: '20px',
          },
        },
        '& .bike-description-container': {
          margin: '20px 0px 0px 10px',
          minHeight: '231px',
          '& .title': {
            fontWeight: '700',
            fontSize: '14px',
          },
          '& .description': {
            fontSize: '14px',
          },
        },
        '& .buttons-container': {
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
          '& .btn': {
            color: primary,
            borderColor: primary,
            padding: '0px 15px',
            '& .icon': {
              marginRight: '10px',
            },
            '&:last-of-type': {
              marginLeft: '10px',
            },
          },
        },
      },
    },
  },
  tabsPaper: {
    borderRadius: '10px',
    backgroundColor: 'transparent',
  },
  amountBoxContainer: {
    '& .amountBox': {
      padding: '10px',
    },
  },
  mTop10: {
    marginTop: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    bikeInfoPaper: {
      '& .container': {
        '& .bike-img-container': {
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
  [theme.breakpoints.down('xs')]: {},
}));

export default useBikeDetailStyles;
