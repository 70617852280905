import React from 'react';
import {
  Box, Button, makeStyles, Typography,
} from '@material-ui/core';
import logoReport from 'commons/assets/bike-logo-report.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import styles from './styles';

const useStyles = makeStyles(styles);
const types = {
  left_side: 'lateral izquierdo',
  front_side: 'frontal',
  right_side: 'lateral derecho',
  extra: 'extra',
};
const BikeDetailImage = (props) => {
  const {
    openModal, url, bike, doc,
  } = props;
  const classes = useStyles();

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  if (url) {
    return (
      <Box display="flex" className={classes.root} onClick={handleModal}>
        <img src={url} alt="Foto" className={classes.blur} />
        <img
          src={url}
          className={classes.img}
          itemProp="image"
          alt={`Imagen ${types[doc?.type]} - ${bike?.brand?.name} ${bike?.model?.name} ${bike?.rim?.name}`}
        />

        <Box className={classes.eye}>
          <VisibilityIcon className={classes.icon} color="primary" style={{ fontSize: 60 }} />
          <Button variant="outlined" size="small" color="primary" className={classes.btn}>
            ver fotos
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" className={classes.root}>
      <BlockIcon className={classes.icon} style={{ fontSize: 200 }} />
    </Box>
  );
};

export default BikeDetailImage;
