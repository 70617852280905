import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const CustomTitleDefault = ({ title, subtitle = '' }) => (
  <Grid container spacing={3} justifyContent="center">
    <Grid item xs={12} sm={12}>
      <Typography variant="h6" component="h6" align="center">
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={12}>
      <Typography variant="body2" color="textSecondary" component="p" align="center">
        {subtitle}
      </Typography>
    </Grid>
  </Grid>
);

export default CustomTitleDefault;
