import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';

const WarningModal = ({ handleAccept, handleCancel, loading, openModal, title, text }) => {
  return (
    <Dialog open={openModal} onClose={handleCancel}>
      <DialogContent style={{ textAlign: 'center' }}>
        <DialogContentText>
          <Typography variant="h4" color="secondary">
            {title}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography variant="body1">{text}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancelar
        </Button>
        <Button
          id="id_button_continue_transaction_payment"
          onClick={handleAccept}
          color="primary"
          autoFocus>
          Continuar
          {loading && (
            <CircularProgress
              style={{ width: '15px', height: '15px', marginLeft: '5px', color: 'white' }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
