/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'bikeDetail/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;
const CHANGE_DATA_FILE = `${PATH}CHANGE_DATA_FILE`;

// Global constants for saga
export const BIKE_DETAIL = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,

  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_ERROR`,

  VALIDATE_BIKE: `${PATH}VALIDATE_BIKE`,
  VALIDATE_BIKE_SUCCESS: `${PATH}VALIDATE_BIKE_SUCCESS`,
  VALIDATE_BIKE_ERROR: `${PATH}VALIDATE_BIKE_ERROR`,

  SEARCH_BIKE: `${PATH}SEARCH_BIKE`,
  SEARCH_BIKE_SUCCESS: `${PATH}SEARCH_BIKE_SUCCESS`,
  SEARCH_BIKE_ERROR: `${PATH}SEARCH_BIKE_ERROR`,

  FETCH_MAINTENANCE: `${PATH}FETCH_MAINTENANCE`,
  FETCH_MAINTENANCE_SUCCESS: `${PATH}FETCH_MAINTENANCE_SUCCESS`,
  FETCH_MAINTENANCE_ERROR: `${PATH}FETCH_MAINTENANCE_ERROR`,

  VIEW_MAINTENANCE: `${PATH}VIEW_MAINTENANCE`,
  CLOSE_MAINTENANCE: `${PATH}CLOSE_MAINTENANCE`,

  OPEN_FILE_MODAL: `${PATH}OPEN_FILE_MODAL`,
  CLEAR_FILE_MODAL: `${PATH}CLEAR_FILE_MODAL`,

  // SELLER_EMAIL_DIALOG
  TOGGLE_SET_SELLER_EMAIL_DIALOG: `${PATH}TOGGLE_SET_SELLER_EMAIL_DIALOG`,
  SET_SELLER_EMAIL: `${PATH}SET_SELLER_EMAIL`,
  SET_SELLER_EMAIL_SUCCESS: `${PATH}SET_SELLER_EMAIL_SUCCESS`,
  SET_SELLER_EMAIL_FAIL: `${PATH}SET_SELLER_EMAIL_FAIL`,

  // BIKE DETAIL MARKETPLACE
  GET_PUBLISHED_BIKE: `${PATH}GET_PUBLISHED_BIKE`,
  GET_PUBLISHED_BIKE_SUCCESS: `${PATH}GET_PUBLISHED_BIKE_SUCCESS`,
  GET_PUBLISHED_BIKE_FAIL: `${PATH}GET_PUBLISHED_BIKE_FAIL`,

  // PUBLISH BIKE
  SET_PUBLISH_BIKE_FORM_VALUES: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES`,
  SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS`,
  SET_PUBLISH_BIKE_FORM_VALUES_FAIL: `${PATH}SET_PUBLISH_BIKE_FORM_VALUES_FAIL`,

  // GET COMISSIONS
  GET_COMMISSIONS: `${PATH}GET_COMMISSIONS`,
  GET_COMMISSIONS_SUCCESS: `${PATH}GET_COMMISSIONS_SUCCESS`,
  GET_COMMISSIONS_FAIL: `${PATH}GET_COMMISSIONS_FAIL`,

  // SAVE_FORM_VALUES
  SAVE_PUBLISH_FORM_VALUES: `${PATH}SAVE_PUBLISH_FORM_VALUES`,

  // DELETE PUBLICATION
  DELETE_PUBLICATION: `${PATH}DELETE_PUBLICATION`,
  DELETE_PUBLICATION_SUCCESS: `${PATH}DELETE_PUBLICATION_SUCCESS`,
  DELETE_PUBLICATION_FAIL: `${PATH}DELETE_PUBLICATION_FAIL`,

  SET_SCREEN_MODE: `${PATH}SET_SCREEN_MODE`,

  SET_OPEN_REPORT_MODAL: `${PATH}SET_OPEN_REPORT_MODAL`,

  REPORT_PUBLISH: `${PATH}REPORT_PUBLISH`,
  REPORT_PUBLISH_SUCCESS: `${PATH}REPORT_PUBLISH_SUCCESS`,
  REPORT_PUBLISH_FAIL: `${PATH}REPORT_PUBLISH_FAIL`,

  CLEAR_PUBLISH_FORM: `${PATH}CLEAR_PUBLISH_FORM`,

  PERSIST_EDIT_MODE_SCREEN: `${PATH}PERSIST_EDIT_MODE_SCREEN`,

  SET_REDIRECT: `${PATH}SET_REDIRECT`,

  SET_LAST_TRANSFER_ID: `${PATH}SET_LAST_TRANSFER_ID`,

  SET_REMEMBER_TRANSFER_DIALOG: `${PATH}SET_REMEMBER_TRANSFER_DIALOG`,

  SET_DELETE_PUBLICATION_REASON: `${PATH}SET_DELETE_PUBLICATION_REASON`,
  CLEAR_DELETE_PUBLICATION_REASON: `${PATH}CLEAR_DELETE_PUBLICATION_REASON`,

  FETCH_TRANSFER_PREVIEW: `${PATH}FETCH_TRANSFER_PREVIEW`,
  FETCH_TRANSFER_PREVIEW_SUCCESS: `${PATH}FETCH_TRANSFER_PREVIEW_SUCCESS`,
  FETCH_TRANSFER_PREVIEW_FAILURE: `${PATH}FETCH_TRANSFER_PREVIEW_FAILURE`,
};

// actions
export const bikeDetailActions = {
  // initState: createAction(),
  fetchBrands: createAction(BIKE_DETAIL.FETCH_BRANDS),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  changeDataFile: createAction(CHANGE_DATA_FILE, 'payload'),
  validateBike: createAction(BIKE_DETAIL.VALIDATE_BIKE, 'payload'),
  searchBike: createAction(BIKE_DETAIL.SEARCH_BIKE, 'payload'),
  resetControls: createAction(BIKE_DETAIL.RESET_CONTROLS),
  viewMaintenance: createAction(BIKE_DETAIL.VIEW_MAINTENANCE),
  closeMaintenance: createAction(BIKE_DETAIL.CLOSE_MAINTENANCE),
  fetchMaintenance: createAction(BIKE_DETAIL.FETCH_MAINTENANCE, 'payload'),
  openFileModal: createAction(BIKE_DETAIL.OPEN_FILE_MODAL),
  closeFileModal: createAction(BIKE_DETAIL.CLEAR_FILE_MODAL),

  // SELLER_EMAIL_DIALOG
  toggleSetSellerEmailDialog: createAction(BIKE_DETAIL.TOGGLE_SET_SELLER_EMAIL_DIALOG, 'payload'),
  setSellerEmail: createAction(BIKE_DETAIL.SET_SELLER_EMAIL, 'payload'),
  setErrorsSellerEmail: createAction(BIKE_DETAIL.SET_SELLER_EMAIL_FAIL, 'payload'),

  // BIKE DETAIL MARKETPLACE
  getPublishedBike: createAction(BIKE_DETAIL.GET_PUBLISHED_BIKE, 'payload'),

  // PUBLISH BIKE
  setPublishBikeFormValues: createAction(BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES, 'payload'),

  // GET COMISSIONS
  getCommissions: createAction(BIKE_DETAIL.GET_COMMISSIONS, 'payload'),

  savePublishFormValues: createAction(BIKE_DETAIL.SAVE_PUBLISH_FORM_VALUES, 'payload'),

  deletePublication: createAction(BIKE_DETAIL.DELETE_PUBLICATION, 'payload'),

  setScreenMode: createAction(BIKE_DETAIL.SET_SCREEN_MODE, 'payload'),

  setOpenReportModal: createAction(BIKE_DETAIL.SET_OPEN_REPORT_MODAL, 'payload'),

  reportPublish: createAction(BIKE_DETAIL.REPORT_PUBLISH, 'payload'),

  clearPublishForm: createAction(BIKE_DETAIL.CLEAR_PUBLISH_FORM, 'payload'),

  setPersistEditModeScreen: createAction(BIKE_DETAIL.PERSIST_EDIT_MODE_SCREEN, 'payload'),

  setRedirect: createAction(BIKE_DETAIL.SET_REDIRECT, 'payload'),

  setLastTransferId: createAction(BIKE_DETAIL.SET_LAST_TRANSFER_ID, 'payload'),

  setRememberTransferDialog: createAction(BIKE_DETAIL.SET_REMEMBER_TRANSFER_DIALOG, 'payload'),

  setDeletePublicationReason: createAction(BIKE_DETAIL.SET_DELETE_PUBLICATION_REASON, 'payload'),
  clearDeletePublicationReason: createAction(BIKE_DETAIL.CLEAR_DELETE_PUBLICATION_REASON),

  fetchTransferPreview: createAction(BIKE_DETAIL.FETCH_TRANSFER_PREVIEW, 'payload'),
};

const initState = {
  openReportModal: false,
  openSendMailModal: false,
  bikeMarketId: null,
  lastTransferId: 0,
  deletePublicationReasonId: 0,
  sellerEmailForm: {
    buyerName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerLastName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerEmail: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerPhone: {
      value: '',
      isValid: true,
      helperText: '',
    },
    emailMessage: {
      value: 'Hola, vi tu Bike publicada en BIKFY, ¿sigue disponible?',
      isValid: true,
      helperText: '',
    },
    acceptBikfyConditions: {
      value: false,
      isValid: true,
      helperText: '',
    },
    bikeId: {
      value: 0,
      isValid: true,
      helperText: '',
    },
    transferId: {
      value: 0,
      isValid: true,
      helperText: '',
    },
  },
  publishBikeForm: {
    authorizedPublication: {
      value: true,
      helpText: '',
      isValid: true,
    },
    description: {
      value: '',
      isValid: true,
      helperText: '',
    },
    withBikfyPay: {
      value: false,
      isValid: true,
      helperText: '',
    },
    creditCard: {
      value: false,
      isValid: true,
      helperText: '',
    },
    creditCardWithFees: {
      value: false,
      isValid: true,
      helperText: '',
    },
    bankTransfer: {
      value: true,
      isValid: true,
      helperText: '',
    },
    showContact: {
      value: false,
      isValid: true,
      helperText: '',
    },
    withDelivery: {
      value: false,
      isValid: true,
      helperText: '',
    },
    price: {
      value: '',
      isValid: true,
      helperText: '',
    },
    comuneId: {
      value: 0,
      isValid: true,
      helperText: '',
    },
  },
  commissions: {
    buyer: 0,
    seller: 0,
    transbank: 0,
    amountPurchaseContract: 0,
  },
  errors: {
    isErrors: false,
    message: '',
  },
  screenMode: 'read-mode', // read-mode, edit-mode
  loaders: {
    isLoading: true,
  },
  bikeSuccess: false,
  viewMaintenance: false,
  openModal: false,
  modals: {
    setSellerEmail: {
      open: false,
    },
    report: {
      open: false,
      bikeMarketId: 0,
    },
    rememberTransfer: {
      open: false,
    },
  },
  formSellerEmailValues: {
    buyerName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerLastName: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerEmail: {
      value: '',
      isValid: true,
      helperText: '',
    },
    buyerPhone: {
      value: '',
      isValid: true,
      helperText: '',
    },
    emailMessage: {
      value: 'Hola, vi tu Bike publicada en BIKFY, ¿sigue disponible?',
      isValid: true,
      helperText: '',
    },
    acceptBikfyConditions: {
      value: false,
      isValid: true,
      helperText: '',
    },
  },
  chargedForm: false,
  persistEditModeScreen: false,
  redirectPublish: false,
  redirectDelete: false,
  reportPublishInfo: {
    transferId: 0,
  },
  bikeId: 0,
  transferId: 0,
  dataFile: {
    url: '',
    extension: '',
    name: '',
  },
  newTransferId: null,
};

const bikeDetailReducer = createReducer(initState, {
  [BIKE_DETAIL.RESET_CONTROLS](state) {
    state.loaders = {
      ...state.loaders,
      ...initState.loaders,
    };
    state.error = false;
    state.errors = {
      ...state.errors,
      ...initState.errors,
    };
    state.bikeSuccess = false;
    state.viewMaintenance = false;
    state.openModal = false;
    state.modals = {
      ...state.modals,
      ...initState.modals,
    };
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...initState.formSellerEmailValues,
    };
    state.commissions = {
      ...state.commissions,
      ...initState.commissions,
    };
    state.redirect = false;
  },
  [BIKE_DETAIL.GET_PUBLISHED_BIKE](state, action) {
    state.loading = true;
    state.error = false;
    state.loaders.isLoading = true;
    state.latestTransferId = action.payload.transferId;
  },
  [BIKE_DETAIL.GET_PUBLISHED_BIKE_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.loaders.isLoading = false;
  },
  [BIKE_DETAIL.GET_PUBLISHED_BIKE_FAIL](state) {
    state.loading = false;
    state.error = true;
  },
  [BIKE_DETAIL.SET_SCREEN_MODE](state, action) {
    state.screenMode = action.payload.screenMode;
  },
  [BIKE_DETAIL.GET_COMMISSIONS](state) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
  },
  [BIKE_DETAIL.GET_COMMISSIONS_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.commissions = {
      ...state.commissions,
      ...action.payload.commissions,
    };
  },
  [BIKE_DETAIL.GET_COMMISSIONS_ERROR](state, action) {
    state.errors.isErrors = true;
    state.errors.message = action.payload.errorMessage;
  },
  [BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES](state, action) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
    state.errors.message = '';
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...action.payload.newPublishBikeFormValues,
    };
  },
  [BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES_SUCCESS](state, action) {
    state.loaders.isLoading = false;
    state.redirectPublish = true;
    state.publishBikeForm = {
      ...state.publishBikeForm,
      ...initState.publishBikeForm,
    };
    state.latestTransferId = 0;
    state.persistEditModeScreen = false;
  },
  [BIKE_DETAIL.SET_PUBLISH_BIKE_FORM_VALUES_FAIL](state, action) {
    state.loaders.isLoading = false;
    state.errors.isErrors = true;
  },
  [BIKE_DETAIL.DELETE_PUBLICATION](state) {
    state.loaders.isLoading = true;
    state.errors.isErrors = false;
  },
  [BIKE_DETAIL.DELETE_PUBLICATION_SUCCESS](state) {
    state.loaders.isLoading = false;
    state.redirectDelete = true;
  },
  [BIKE_DETAIL.DELETE_PUBLICATION_FAIL](state) {
    state.loaders.isLoading = false;
    state.errors.isErrors = true;
  },
  [BIKE_DETAIL.SET_OPEN_REPORT_MODAL](state, action) {
    state.modals.report.open = action.payload.open;
    state.modals.report.bikeMarketId = action.payload.bikeMarketId;
  },
  [BIKE_DETAIL.REPORT_PUBLISH](state) {
    state.loaders.isLoading = true;
  },
  [BIKE_DETAIL.REPORT_PUBLISH_SUCCESS](state) {
    state.loaders.isLoading = false;
    state.modals.report.open = false;
    state.modals.report.bikeMarketId = 0;
  },
  [BIKE_DETAIL.REPORT_PUBLISH_FAIL](state) {
    state.loaders.isLoading = false;
  },
  [BIKE_DETAIL.TOGGLE_SET_SELLER_EMAIL_DIALOG](state, action) {
    state.modals.setSellerEmail.open = action.payload.open;
    state.transferId = action.payload.transferId;
    state.bikeId = action.payload.bikeId;
  },
  [BIKE_DETAIL.SET_SELLER_EMAIL](state, action) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
    state.loading = true;
    state.error = false;
  },
  [BIKE_DETAIL.SET_SELLER_EMAIL_SUCCESS](state) {
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...initState.formSellerEmailValues,
    };
    state.loading = false;
    state.modals.setSellerEmail.open = false;
  },
  [BIKE_DETAIL.SET_SELLER_EMAIL_FAIL](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMessage;
    state.formSellerEmailValues = {
      ...state.formSellerEmailValues,
      ...action.payload.formSellerEmailValues,
    };
  },
  [BIKE_DETAIL.SAVE_PUBLISH_FORM_VALUES](state, action) {
    state.publishBikeForm = action.payload.publishBikeForm;
    state.chargedForm = true;
  },
  [BIKE_DETAIL.OPEN_FILE_MODAL](state) {
    state.openModal = true;
  },
  [BIKE_DETAIL.CLEAR_FILE_MODAL](state) {
    state.openModal = false;
  },
  [CHANGE_DATA_FILE](state, action) {
    state.dataFile = {
      ...state.dataFile,
      ...action.payload,
    };
  },
  [BIKE_DETAIL.CLEAR_PUBLISH_FORM](state) {
    state.publishBikeForm = initState.publishBikeForm;
  },
  [BIKE_DETAIL.PERSIST_EDIT_MODE_SCREEN](state, action) {
    state.persistEditModeScreen = action.payload.persistEditModeScreen;
  },
  [BIKE_DETAIL.SET_REDIRECT](state, action) {
    state.redirectPublish = action.payload.redirect;
    state.redirectDelete = action.payload.redirect;
  },
  [BIKE_DETAIL.SET_LAST_TRANSFER_ID](state, action) {
    state.lastTransferId = action.payload.transferId;
  },
  [BIKE_DETAIL.SET_REMEMBER_TRANSFER_DIALOG](state, action) {
    state.modals.rememberTransfer.open = action.payload;
  },
  [BIKE_DETAIL.CLEAR_DELETE_PUBLICATION_REASON](state) {
    state.deletePublicationReasonId = initState.deletePublicationReasonId;
  },
  [BIKE_DETAIL.SET_DELETE_PUBLICATION_REASON](state, action) {
    state.deletePublicationReasonId = action.payload.deletePublicationReasonId;
  },
  [BIKE_DETAIL.FETCH_TRANSFER_PREVIEW](state) {
    state.loading = true;
    state.error = false;
  },
  [BIKE_DETAIL.FETCH_TRANSFER_PREVIEW_SUCCESS](state, action) {
    state.loading = false;
    state.newTransferId = action.payload.newTransferId;
  },
  [BIKE_DETAIL.FETCH_TRANSFER_PREVIEW_FAILURE](state) {
    state.loading = false;
    state.error = true;
  },
});

export default bikeDetailReducer;
