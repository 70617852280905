import colors from '../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    padding: '30px',
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
  },
  titleContainer: {
    '& .title': {
      textAlign: 'center',
      fontWeight: '700',
      fontSize: '24px',
      marginBottom: '20px',
      '& .bikfy-color': {
        color: primary,
      },
    },
  },
  buttonsContainer: {
    marginTop: '20px',
  },
  tableContainer: {
    marginTop: '30px',
  },
});

export default styles;
