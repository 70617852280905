import { createReducer } from 'utils/reducer';

import bankDataScreenTypes from './bankDataScreen.types';

const initState = {
  loaders: {
    isLoading: false,
    isFormLoading: false,
  },
  banks: [],
  accountTypes: [],
  formValues: {
    userName: '',
    rut: '',
    email: '',
    bankId: 0,
    accountTypeId: 0,
    accountNumber: '',
  },
  errors: {
    isErrors: false,
    errorMessage: '',
  },
  bankDataId: 0,
};

const enablePaymentScreenReducer = createReducer(initState, {
  [bankDataScreenTypes.GET_BANK_DATA](state) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
      errors: {
        ...initState.errors,
      },
      bankDataId: initState.bankDataId,
      formValues: {
        ...initState.formValues,
      },
    };
  },
  [bankDataScreenTypes.GET_BANK_DATA_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      banks: [...action.payload.banks],
      accountTypes: [...action.payload.accountTypes],
      errors: {
        ...initState.errors,
      },
      formValues: {
        ...state.formValues,
        userName: action.payload.formValues.userName,
        rut: action.payload.formValues.rut,
        email: action.payload.formValues.email,
        bankId: action.payload.formValues.bankId,
        accountTypeId: action.payload.formValues.accountTypeId,
        accountNumber: action.payload.formValues.account,
      },
    };
  },
  [bankDataScreenTypes.GET_BANK_DATA_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [bankDataScreenTypes.GET_STORE_BANK_DATA](state) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
      errors: {
        ...initState.errors,
      },
      bankDataId: initState.bankDataId,
      formValues: {
        ...initState.formValues,
      },
    };
  },
  [bankDataScreenTypes.GET_STORE_BANK_DATA_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      banks: [...action.payload.banks],
      accountTypes: [...action.payload.accountTypes],
      errors: {
        ...initState.errors,
      },
      formValues: {
        ...state.formValues,
        userName: action.payload.formValues.userName,
        rut: action.payload.formValues.rut,
        email: action.payload.formValues.email,
        bankId: action.payload.formValues.bankId,
        accountTypeId: action.payload.formValues.accountTypeId,
        accountNumber: action.payload.formValues.account,
      },
    };
  },
  [bankDataScreenTypes.GET_STORE_BANK_DATA_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [bankDataScreenTypes.SET_USER_DATA_BANK](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: true,
      },
      errors: {
        ...state.errors,
      },
      formValues: {
        ...state.formValues,
        ...action.payload.formValues,
      },
    };
  },
  [bankDataScreenTypes.SET_USER_DATA_BANK_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: false,
      },
      errors: {
        ...state.errors,
        ...initState.errors,
      },
      formValues: {
        ...state.formValues,
        ...initState.formValues,
      },
      bankDataId: action.payload.bankDataId,
    };
  },
  [bankDataScreenTypes.SET_USER_DATA_BANK_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [bankDataScreenTypes.SET_STORE_DATA_BANK](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: true,
      },
      errors: {
        ...state.errors,
      },
      formValues: {
        ...state.formValues,
        ...action.payload.formValues,
      },
    };
  },
  [bankDataScreenTypes.SET_STORE_DATA_BANK_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: false,
      },
      errors: {
        ...state.errors,
        ...initState.errors,
      },
      formValues: {
        ...state.formValues,
        ...initState.formValues,
      },
      bankDataId: action.payload.bankDataId,
    };
  },
  [bankDataScreenTypes.SET_STORE_DATA_BANK_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isFormLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
  [bankDataScreenTypes.RESET_BANK_DATA_ID](state) {
    return {
      ...state,
      bankDataId: initState.bankDataId,
    };
  },
  [bankDataScreenTypes.TOGGLE_ERROR](state, action) {
    return {
      ...state,
      errors: {
        ...state.errors,
        isErrors: action.payload.isErrors,
        errorMessage: action.payload.errorMessage,
      },
    };
  },
});

export default enablePaymentScreenReducer;
