import {
  takeLatest,
  spawn,
  put,
} from 'redux-saga/effects';
import { LOGIN } from 'screens/Login/reducer';

// import apiRequest from 'utils/api';
import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  LOG_OUT,
  SEARCH_PRINTER,
  SEARCH_PRINTER_FAIL,
  SEARCH_PRINTER_SUCCESS,
  PRINT,
  appActions,
} from 'commons/reducer';
import { truthty } from 'utils/functions';
import apiRequest, { apiSuccess, get, post } from 'utils/api';

const VENDOR_ID = 2655;

// function* logout() {
//   yield takeLatest(LOG_OUT, function* () {
//     yield apiRequest('/logout', { method: 'get' });
//   });
// }

function* getUser() {
  yield takeLatest(GET_USER, function* () {
    const response = yield apiRequest('api/v1/users/user', { method: 'get' }, true, true);
    if (!response.error) {
      yield put(apiSuccess(GET_USER_SUCCESS, response));
    } else {
      localStorage.removeItem('user');
      yield put(apiSuccess(GET_USER_FAIL));
    }
  });
}

export default function* root() {
  yield spawn(getUser);
}
