import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const GeneralCardSkeleton = ({ idMosaicMode = false }) => {
  return (
    <Grid item xs={12}>
      <Card>
        <Grid container>
          <Grid item sm={idMosaicMode ? 12 : 5} xs={12}>
            <Skeleton width="100%" height={240} variant="rect" />
          </Grid>
          <Grid container item sm={idMosaicMode ? 12 : 7} xs={12}>
            <CardContent
              style={{
                width: '100%',
                padding: '10px',
              }}>
              <Grid container direction="column" style={{ height: '100%' }}>
                <Grid container item justifyContent="space-between">
                  <Grid item xs={10}>
                    <Skeleton width="60%" height={20} />
                    <Skeleton width="40%" height={30} />
                    <Skeleton width="50%" height={40} />
                    <Skeleton width="35%" height={50} />
                  </Grid>
                  {!idMosaicMode && (
                    <Grid item xs={2}>
                      <Skeleton width="100%" height={20} />
                    </Grid>
                  )}
                </Grid>
                <Grid container item justifyContent="space-between" alignItems="center">
                  {!idMosaicMode ? (
                    <Grid container item xs={9} justifyContent="space-between">
                      <Skeleton width="48%" height={70} />
                      <Skeleton width="48%" height={70} />
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <Skeleton width="100%" height={30} />
                    </Grid>
                  )}
                  <Grid container item xs={1} justifyContent="flex-end" alignItems="flex-end">
                    <Skeleton width="20%" height={70} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default GeneralCardSkeleton;
