import payment from 'screens/Payment/reducer';
import { createAction, createReducer } from 'utils/reducer';

const PATH = 'purchasePreview/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

export const PURCHASEPREVIEW = {
  GET_TRANSFER: `${PATH}GET_TRANSFER`,
  GET_TRANSFER_SUCCESS: `${PATH}GET_TRANSFER_SUCCESS`,
  GET_TRANSFER_FAILURE: `${PATH}GET_TRANSFER_FAILURE`,

  DELETE_TRANSFER: `${PATH}DELETE_TRANSFER`,
  DELETE_TRANSFER_SUCCESS: `${PATH}DELETE_TRANSFER_SUCCESS`,
  DELETE_TRANSFER_ERROR: `${PATH}DELETE_TRANSFER_ERROR`,

  SET_PAYMENT_METHOD: `${PATH}SET_PAYMENT_METHOD`,

  POST_UPDATE_PROFILE: `${PATH}POST_UPDATE_PROFILE`,
  POST_UPDATE_PROFILE_ERROR: `${PATH}POST_UPDATE_PROFILE_ERROR`,
  POST_UPDATE_PROFILE_SUCCESS: `${PATH}POST_UPDATE_PROFILE_SUCCESS`,

  GET_STATE_FROM_API_COMUNES: `${PATH}GET_STATE_FROM_API_COMUNES`,
  GET_STATE_FROM_API_COMUNES_SUCCESS: `${PATH}GET_STATE_FROM_API_COMUNES_SUCCESS`,
  GET_STATE_FROM_API_REGIONS: `${PATH}GET_STATE_FROM_API_REGIONS`,
  GET_STATE_FROM_API_REGIONS_SUCCESS: `${PATH}GET_STATE_FROM_API_REGIONS_SUCCESS`,

  CREATE_PAYMENT: `${PATH}CREATE_PAYMENT`,
  CREATE_PAYMENT_SUCCESS: `${PATH}CREATE_PAYMENT_SUCCESS`,
  CREATE_PAYMENT_FAILURE: `${PATH}CREATE_PAYMENT_FAILURE`,

  CREATE_MERCADO_PAGO_PAYMENT: `${PATH}CREATE_MERCADO_PAGO_PAYMENT`,
  VALIDATE_MERCADO_PAGO_PAYMENT: `${PATH}VALIDATE_MERCADO_PAGO_PAYMENT`,
  VALIDATE_MERCADO_PAGO_PAYMENT_SUCCESS: `${PATH}VALIDATE_MERCADO_PAGO_PAYMENT_SUCCESS`,

  CONFIRM_STORE_TRANSFER: `${PATH}CONFIRM_STORE_TRANSFER`,
  CONFIRM_STORE_TRANSFER_SUCCESS: `${PATH}CONFIRM_STORE_TRANSFER_SUCCESS`,
  CONFIRM_STORE_TRANSFER_FAILURE: `${PATH}CONFIRM_STORE_TRANSFER_FAILURE`,

  CREATE_TRANSFER_PDF: `${PATH}CREATE_TRANSFER_PDF`,
  CREATE_TRANSFER_PDF_SUCCESS: `${PATH}CREATE_TRANSFER_PDF_SUCCESS`,
  CREATE_TRANSFER_PDF_FAILURE: `${PATH}CREATE_TRANSFER_PDF_FAILURE`,

  NEXT_STEP: `${PATH}NEXT_STEP`,
  NEXT_STEP_SUCCESS: `${PATH}NEXT_STEP_SUCCESS`,
  NEXT_STEP_FAILURE: `${PATH}NEXT_STEP_FAILURE`,

  ACCEPT_TRANSFER_BUYER: `${PATH}ACCEPT_TRANSFER_BUYER`,
  ACCEPT_TRANSFER_BUYER_SUCCESS: `${PATH}ACCEPT_TRANSFER_BUYER_SUCCESS`,
  ACCEPT_TRANSFER_BUYER_ERROR: `${PATH}ACCEPT_TRANSFER_BUYER_ERROR`,

  GENERATE_PAYMENT: `${PATH}GENERATE_PAYMENT`,
  GENERATE_PAYMENT_SUCCESS: `${PATH}GENERATE_PAYMENT_SUCCESS`,
  GENERATE_PAYMENT_ERROR: `${PATH}GENERATE_PAYMENT_ERROR`,

  FETCH_COUPONS: `${PATH}FETCH_COUPONS`,
  FETCH_COUPONS_SUCCESS: `${PATH}FETCH_COUPONS_SUCCESS`,
  FETCH_COUPONS_ERROR: `${PATH}FETCH_COUPONS_ERROR`,
  REDEEM_COUPON: `${PATH}REDEEM_COUPON`,
  REDEEM_COUPON_SUCCESS: `${PATH}REDEEM_COUPON_SUCCESS`,
  REDEEM_COUPON_ERROR: `${PATH}REDEEM_COUPON_ERROR`,
};

export const purchasePreviewActions = {
  getTransfer: createAction(PURCHASEPREVIEW.GET_TRANSFER, 'payload'),
  deleteTransfer: createAction(PURCHASEPREVIEW.DELETE_TRANSFER, 'payload'),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  setPaymentMethod: createAction(PURCHASEPREVIEW.SET_PAYMENT_METHOD, 'payload'),
  createPayment: createAction(PURCHASEPREVIEW.CREATE_PAYMENT, 'payload'),
  createMercadoPagoPayment: createAction(PURCHASEPREVIEW.CREATE_MERCADO_PAGO_PAYMENT, 'payload'),
  confirmExternalPaymentToStore: createAction(PURCHASEPREVIEW.CONFIRM_STORE_TRANSFER, 'payload'),
  updateProfile: createAction(PURCHASEPREVIEW.POST_UPDATE_PROFILE, 'payload'),
  getStateFromApiComunes: createAction(PURCHASEPREVIEW.GET_STATE_FROM_API_COMUNES),
  getStateFromApiRegions: createAction(PURCHASEPREVIEW.GET_STATE_FROM_API_REGIONS),
  createTransferPDF: createAction(PURCHASEPREVIEW.CREATE_TRANSFER_PDF, 'payload'),
  nextStep: createAction(PURCHASEPREVIEW.NEXT_STEP, 'payload'),
  acceptTransferBuyer: createAction(PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER, 'payload'),
  generatePayment: createAction(PURCHASEPREVIEW.GENERATE_PAYMENT, 'payload'),
  fetchCoupons: createAction(PURCHASEPREVIEW.FETCH_COUPONS, 'payload'),
  redeemCoupon: createAction(PURCHASEPREVIEW.REDEEM_COUPON, 'payload'),
};

export const initialState = {
  formControls: {
    commune: '',
    addressStreet: '',
    addressNumber: '',
    apartment: '',
    transferPreview: '',
    couponNumber: '',
    paymentMethod: '',
  },
  coupons: {},
  transbank: {
    url: '',
    token: '',
  },
  errorMessage: '',
  loading: false,
  error: false,
  successProfile: false,
  redeemedCoupon: null,
  loadingRedeem: false,
  errorRedeem: false,
  loadingConsult: false,
};

const purchasePreview = createReducer(initialState, {
  [PURCHASEPREVIEW.FETCH_COUPONS](state) {
    state.loading = true;
    state.coupons = [];
    state.error = false;
    state.message = '';
  },
  [PURCHASEPREVIEW.FETCH_COUPONS_SUCCESS](state, action) {
    state.loading = false;
    state.coupons = action.payload; // Array of coupons associated with the taxNumber
    state.error = false;
  },
  [PURCHASEPREVIEW.FETCH_COUPONS_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [PURCHASEPREVIEW.REDEEM_COUPON](state) {
    state.loadingRedeem = true;
    state.errorRedeem = false;
    state.message = '';
  },
  [PURCHASEPREVIEW.REDEEM_COUPON_SUCCESS](state, action) {
    state.loadingRedeem = false;
    state.errorRedeem = false;
    state.redeemedCoupon = action.payload; // Store redeemed coupon details
    state.coupons[action.payload.coupon.id].usedAt = true;
  },
  [PURCHASEPREVIEW.REDEEM_COUPON_ERROR](state, action) {
    state.loadingRedeem = false;
    state.errorRedeem = true;
    state.message = action.payload.errorMsg;
  },
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [PURCHASEPREVIEW.GET_TRANSFER](state) {
    return {
      ...state,
      errorMessage: '',
      loading: true,
      error: false,
    };
  },
  [PURCHASEPREVIEW.GET_TRANSFER_SUCCESS](state) {
    return {
      ...state,
      loading: false,
      error: false,
    };
  },
  [PURCHASEPREVIEW.GET_TRANSFER_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: action.payload.message,
    };
  },
  [PURCHASEPREVIEW.DELETE_TRANSFER](state) {
    return {
      ...state,
      errorMessage: '',
      loading: true,
      error: false,
    };
  },
  [PURCHASEPREVIEW.DELETE_TRANSFER_SUCCESS](state) {
    return {
      ...state,
      loading: false,
      error: false,
    };
  },
  [PURCHASEPREVIEW.DELETE_TRANSFER_ERROR](state) {
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: 'Error al rechazar la transferencia',
    };
  },
  [PURCHASEPREVIEW.SET_PAYMENT_METHOD](state, action) {
    return {
      ...state,
      paymentMethod: action.payload,
    };
  },
  [PURCHASEPREVIEW.CREATE_PAYMENT](state) {
    return {
      ...state,
      errorMessage: '',
      loading: true,
      error: false,
    };
  },
  [PURCHASEPREVIEW.CREATE_PAYMENT_SUCCESS](state, action) {
    return {
      ...state,
      transbank: {
        ...state.transbank,
        url: action.payload.url,
        token: action.payload.token,
      },
      loading: false,
    };
  },
  [PURCHASEPREVIEW.CREATE_PAYMENT_FAILURE](state, action) {
    return {
      ...state,
      errorMessage: action.payload.errorMessage,
      loading: false,
      error: true,
    };
  },
  [PURCHASEPREVIEW.CREATE_TRANSFER_PDF](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [PURCHASEPREVIEW.CREATE_TRANSFER_PDF_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      fileId: action.payload.fileId,
    };
  },
  [PURCHASEPREVIEW.CREATE_TRANSFER_PDF_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      errorMessage: action.payload.errorMessage,
    };
  },
  [PURCHASEPREVIEW.NEXT_STEP](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [PURCHASEPREVIEW.NEXT_STEP_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      fileId: action.payload.fileId,
    };
  },
  [PURCHASEPREVIEW.NEXT_STEP_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      errorMessage: action.payload.errorMessage,
    };
  },
  [PURCHASEPREVIEW.CONFIRM_STORE_TRANSFER](state) {
    state.loading = true;
    state.error = false;
  },
  [PURCHASEPREVIEW.CONFIRM_STORE_TRANSFER_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    // state.formControls.transferPreview = action.payload.transfer;
  },
  [PURCHASEPREVIEW.CONFIRM_STORE_TRANSFER_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER](state) {
    state.loading = true;
    state.error = false;
    state.successBuyer = false;
  },
  [PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
    state.successBuyer = true;
  },
  [PURCHASEPREVIEW.ACCEPT_TRANSFER_BUYER_ERROR](state) {
    state.loading = false;
    state.error = true;
    state.successBuyer = false;
  },
  [PURCHASEPREVIEW.GENERATE_PAYMENT](state) {
    state.loading = true;
    state.error = false;
  },
  [PURCHASEPREVIEW.GENERATE_PAYMENT_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
    state.flow = { ...action.payload };
  },
  [PURCHASEPREVIEW.GENERATE_PAYMENT_ERROR](state, action) {
    state.loading = false;
    state.error = true;
  },
});

export default purchasePreview;
