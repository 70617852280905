import React from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  RadioGroup,
  Radio,
  Typography,
  TextField,
} from '@material-ui/core';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import colors from 'utils/colors';

const { primary, grey } = colors;

const styles = (theme) => ({
  radioGroup: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  gridChildContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& .selected': {
      boxShadow: '0px 0px 6px 5px rgba(69,198,216,0.50)',
      WebkitBoxShadow: ' 0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      MozBoxShadow: '0px 0px 6px 0px 5px rgba(69,198,216,0.50)',
      order: '1px solid rgba(0,0,0,0.1)',
    },
    '& .paperContainer': {
      width: '100%',
      padding: '.5rem',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      '& .radio-button': {
        '&.Mui-checked': {
          color: primary,
        },
      },
      '& .checked': {
        '& .title': {
          color: primary,
        },
        '& .icon': {
          color: primary,
        },
      },
      '& .topSubcontainer': {
        display: 'flex',
        alignItems: 'center',
        '& .radioContainer': {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '0px',
          '& span': {
            padding: '0px',
          },
        },
        '& .checked': {
          '& .title': {
            color: primary,
          },
        },
      },
      '& .lowerSubcontainer': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: '.5rem',
        '& img': {
          width: '120px',
        },
        '& .disabled': {
          filter: 'grayscale(1)',
        },
      },
    },
    '& .disabled': {
      cursor: 'not-allowed',
      '& .title': {
        color: grey,
      },
      '& .icon': {
        color: grey,
      },
    },
  },
});

const useStyles = makeStyles(styles);

const DeliveryMethodsBox = ({
  handleClick,
  selectedDeliveryMethod,
  onControlChange,
  controls = {},
  communes,
  sellerInfo,
  storeSellerInfo,
  transfer,
}) => {
  console.log(communes, storeSellerInfo, transfer);
  const classes = useStyles();
  const deliveryMethods = [
    {
      id: 1,
      key: 'OnStore',
      title: 'Retiro en tienda',
      subtitle: 'Gratis',
      description: storeSellerInfo && storeSellerInfo.comuneId && communes[storeSellerInfo.comuneId - 1]
        ? `(${storeSellerInfo.addressStreet} ${storeSellerInfo.addressNumber} ${storeSellerInfo.addressDetail || ''}, ${communes[storeSellerInfo.comuneId - 1]?.label})`
        : 'Información de dirección no disponible', // Default message if data is unavailable
      disabled: false,
    },
    {
      id: 2,
      key: 'SendToAddress',
      title: 'Despacho a domicilio',
      subtitle: 'Valor estimado $9.900',
      description: '(3-5 días RM)',
      disabled: false,
    },
  ];

  const isOnStoreSelected = selectedDeliveryMethod === 'OnStore'; // Check if "Retiro en tienda" is selected

  return (
    <Grid container justifyContent="center" spacing={6} alignItems="center" alignContent="center" direction="row">
      <Grid item xs={12} sm={5}>
        <RadioGroup value={selectedDeliveryMethod} className={classes.radioGroup}>
          <Grid container justifyContent="center" spacing={2} direction="column">
            {deliveryMethods?.map((deliveryMethod) => (
              <Grid key={deliveryMethod.id} item xs={12} className={classes.gridChildContainer}>
                <Paper
                  className={selectedDeliveryMethod === deliveryMethod.key ? 'paperContainer selected' : 'paperContainer'}
                  onClick={deliveryMethod.disabled ? null : () => handleClick(deliveryMethod.key)}
                >
                  <Grid className="topSubcontainer" container item xs={12}>
                    <Grid className="radioContainer" item xs={2}>
                      <Radio
                        value={deliveryMethod.key}
                        className="radio-button"
                        disabled={deliveryMethod.disabled}
                      />
                    </Grid>
                    <Grid item xs={10} className={selectedDeliveryMethod === deliveryMethod.key && 'checked'}>
                      <Typography className="title">{deliveryMethod.title}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ height: '1rem' }} />
                    <Grid item xs={10} className={selectedDeliveryMethod === deliveryMethod.key && 'checked'}>
                      <Typography color="textSecondary">{deliveryMethod.subtitle}</Typography>
                      <Typography color="textSecondary">{deliveryMethod.description}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container justifyContent="space-between" spacing={1} direction="column">
          <Grid item className={classes.gridChildContainer}>
            <Typography variant="h6" component="span" color="textSecondary">
              Dirección de Despacho
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomAutoComplete
              id="commune"
              name="commune"
              label="Comuna"
              value={controls.commune}
              onChange={onControlChange}
              options={communes}
              disabled={isOnStoreSelected} // Disable field if "Retiro en tienda" is selected
            />
          </Grid>
          <Grid item className={classes.gridChildContainer}>
            <TextField
              fullWidth
              id="addressStreet"
              name="addressStreet"
              label="Nombre de calle"
              variant="outlined"
              value={controls.addressStreet}
              onChange={onControlChange}
              disabled={isOnStoreSelected} // Disable field if "Retiro en tienda" is selected
            />
          </Grid>
          <Grid item className={classes.gridChildContainer}>
            <TextField
              fullWidth
              id="addressNumber"
              name="addressNumber"
              label="Número de calle"
              variant="outlined"
              value={controls.addressNumber}
              onChange={onControlChange}
              disabled={isOnStoreSelected} // Disable field if "Retiro en tienda" is selected
            />
          </Grid>
          <Grid item className={classes.gridChildContainer}>
            <TextField
              fullWidth
              id="apartment"
              name="apartment"
              label="Casa, Depto u Oficina"
              variant="outlined"
              value={controls.apartment}
              onChange={onControlChange}
              disabled={isOnStoreSelected} // Disable field if "Retiro en tienda" is selected
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeliveryMethodsBox;
