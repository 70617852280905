import { PATHS } from './paths';

export const view = {
  MARKETPLACE: 'marketplace',
  LANDING: 'landing',
  PILOT_HOME: 'pilot_home',
  STORE_HOME: 'store_home',
  ADMIN_HOME: 'admin_home',
  AMBASSADOR_HOME: 'ambassador_home',
};

const pathTypes = ({ pathname, userType }) => {
  const ROLE = {
    public: {
      [PATHS.HOME]: view.LANDING,
      [PATHS.MARKETPLACE]: view.MARKETPLACE,
    },
    pilot: {
      [PATHS.HOME]: view.LANDING,
      [PATHS.PILOT]: view.PILOT_HOME,
      [PATHS.MARKETPLACE]: view.MARKETPLACE,
    },
    store: {
      [PATHS.HOME]: view.LANDING,
      [PATHS.STORE]: view.STORE_HOME,
      [PATHS.MARKETPLACE]: view.MARKETPLACE,

    },
    admin: {
      [PATHS.HOME]: view.LANDING,
      [PATHS.MARKETPLACE]: view.MARKETPLACE,

    },
    ambassador: {
      [PATHS.HOME]: view.LANDING,
      [PATHS.MARKETPLACE]: view.MARKETPLACE,

    },
  };

  return { pathType: userType ? ROLE[userType][pathname] : '' };
};

export default pathTypes;
