import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Edit, Person } from '@material-ui/icons';
import { insuranceActions } from 'screens/Insurance/reducer';
import saga from 'screens/Insurance/saga';
import { composedComponent } from 'utils/functions';

const formValues = [
  {
    label: 'Nombre',
    name: 'name',
  },
  {
    label: 'Apellido P.',
    name: 'firstLastName',
  },
  {
    label: 'Apellido M.',
    name: 'secondLastName',
  },
  {
    label: 'Teléfono',
    name: 'phone',
  },
  {
    label: 'Fecha nacimiento',
    name: 'birthdate',
  },
  {
    label: 'Comuna',
    name: 'comune',
  },
  {
    label: 'Dirección',
    name: 'addressStreet',
  },
  {
    label: 'Número',
    name: 'addressNumber',
  },
];

const BeneficiaryForm = (props) => {
  const { formControls, handleProfile } = props;
  const { beneficiary } = formControls;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const inputProperty = (item) => (
    <Grid item xs={6}>
      <TextField
        id={item.name}
        disabled
        label={item.label}
        value={beneficiary[item.name]}
        defaultValue={beneficiary[item.name]}
        onAbort={() => {}}
        style={{ whiteSpace: 'nowrap' }}
        size="medium"
      />
    </Grid>
  );
  return (
    <>
      {isMobileScreen ? (
        <Card style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginLeft: '0.1em', marginRight: '0.1em' }}>
            <Grid item>
              <Person fontSize="large" />
            </Grid>
            <Grid item>
              <IconButton>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              style={{ paddingBottom: '0.5em' }}
              wrap="nowrap">
              <Grid container direction="row" justifyContent="center" alignItems="center">
                {formValues.map((item) => inputProperty(item))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingTop: '1em', paddingBottom: '1em' }}
            wrap="nowrap">
            <Grid item xs={2} style={{ marginLeft: '2em' }}>
              <Person fontSize="large" />
            </Grid>
            {formValues.map((item) => inputProperty(item))}
            <Grid item style={{ marginRight: '1em' }}>
              <IconButton onClick={() => handleProfile()}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default composedComponent(BeneficiaryForm, saga, {
  saga: 'sagaInsurance',
  states: ['insurance.formControls'],
  actions: [insuranceActions],
});
