import { takeLatest, spawn, put } from 'redux-saga/effects';
import { apiSuccess, post, get } from 'utils/api';
import * as APP from 'commons/reducer';

import { PROFILE } from 'screens/Profile/reducer';

function* updateProfile() {
  yield takeLatest(PROFILE.POST_UPDATE_PROFILE, function* (action) {
    const body = {};
    for (const key in action.payload) {
      let value = action.payload[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      body[key] = value;
    }
    yield put(apiSuccess(APP.ADD_LOADING, PROFILE.POST_UPDATE_PROFILE));
    const response = yield post('api/v1/users/userUpdate', body);
    yield put(apiSuccess(APP.REMOVE_LOADING, PROFILE.POST_UPDATE_PROFILE));

    if (!response.error) {
      yield put(apiSuccess(PROFILE.POST_UPDATE_PROFILE_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Sus datos fueron actualizados'));
    } else {
      yield put(apiSuccess(PROFILE.POST_UPDATE_PROFILE_ERROR, response));
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response?.errorMsg ? response?.errorMsg : 'Error al actualizar los datos',
        }),
      );
    }
  });
}

function* getStateFromApiComunes() {
  yield takeLatest(PROFILE.GET_STATE_FROM_API_COMUNES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PROFILE.POST_UPDATE_PROFILE));
    const response = yield get('api/v1/comunes/');
    yield put(apiSuccess(APP.REMOVE_LOADING, PROFILE.POST_UPDATE_PROFILE));
    yield put(apiSuccess(PROFILE.GET_STATE_FROM_API_COMUNES_SUCCESS, response));
  });
}

function* getVehicleTypesFromApi() {
  yield takeLatest(PROFILE.GET_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PROFILE.GET_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types/');
    yield put(apiSuccess(APP.REMOVE_LOADING, PROFILE.GET_VEHICLE_TYPES));
    yield put(apiSuccess(PROFILE.GET_VEHICLE_TYPES_SUCCESS));
    yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
  });
}

function* getProfile() {
  yield takeLatest(PROFILE.GET_PROFILE, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PROFILE.GET_PROFILE));
    const response = yield get('api/v1/users/user');
    yield put(apiSuccess(APP.REMOVE_LOADING, PROFILE.GET_PROFILE));
    if (!response.error) {
      yield put(apiSuccess(PROFILE.GET_PROFILE_SUCCESS, response.user));
    } else {
      yield put(apiSuccess(PROFILE.GET_PROFILE_ERROR));
    }
  });
}

function* updateUserVehicleInterests() {
  yield takeLatest(PROFILE.UPDATE_USER_VEHICLE_INTERESTS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PROFILE.UPDATE_USER_VEHICLE_INTERESTS));
    const body = {
      vehicleTypes: action.payload.vehicleTypesSelected,
    };
    const response = yield post('api/v1/users/user-vehicle-interests', body);
    yield put(apiSuccess(APP.REMOVE_LOADING, PROFILE.UPDATE_USER_VEHICLE_INTERESTS));
    if (!response.error) {
      yield put(apiSuccess(PROFILE.UPDATE_USER_VEHICLE_INTERESTS_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Datos actualizados'));
    } else {
      yield put(apiSuccess(PROFILE.UPDATE_USER_VEHICLE_INTERESTS_ERROR, response));
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: response?.errorMsg ? response?.errorMsg : 'Error al actualizar los datos',
        }),
      );
    }
  });
}

export default function* root() {
  yield spawn(updateProfile);
  yield spawn(getStateFromApiComunes);
  yield spawn(getProfile);
  yield spawn(getVehicleTypesFromApi);
  yield spawn(updateUserVehicleInterests);
}
