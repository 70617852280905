import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MATERIAL
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from 'commons/components/ClearIcon';

import useCustomAccordionStyles from './styles';

const CustomAccordion = (props) => {
  const {
    children,
    title,
    formValues,
    handleChange,
    fieldName,
    disableClearButton,
    alwaysExpanded,
    handleResetMinMaxInput,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const classes = useCustomAccordionStyles();

  const fieldNameIsObject = typeof fieldName === 'object';

  const showClearIcon = fieldNameIsObject
    ? formValues[fieldName.min] > 0 || formValues[fieldName.max] > 0
    : formValues[fieldName]?.length > 0 || formValues[fieldName] > 0;

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleClickClearIcon = (event) => {
    event.stopPropagation();

    if (fieldNameIsObject) {
      handleResetMinMaxInput([
        { name: fieldName.min, value: '' },
        { name: fieldName.max, value: '' },
      ]);
      /* handleChange({ target: { name: fieldName.min, value: '' } });
      handleChange({ target: { name: fieldName.max, value: '' } }); */
    } else {
      handleChange({ target: { name: fieldName, value: '' } });
    }
  };

  const classNameColor =
    showClearIcon || expanded ? classes.bikfyFontColor : classes.bikfyGreyColor;

  return (
    <Accordion
      className={`${classes.accordion}`}
      elevation={0}
      expanded={alwaysExpanded ? true : expanded}
      onChange={handleAccordionChange}>
      <AccordionSummary className="accordion-summary">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex">
            <Box className="icon-container">
              {expanded || alwaysExpanded ? (
                <RemoveIcon className={`${classes.bikfyFontColor}`} />
              ) : (
                <AddIcon className={`${classNameColor}`} />
              )}
            </Box>
            <Typography className={`${classes.fontWeight500}  ${classNameColor}`}>
              {title}
            </Typography>
          </Box>
          {showClearIcon && !disableClearButton && (
            <ClearIcon
              className={`${classes.bikfyFontColor} ${classes.responsiveMr}`}
              onClick={handleClickClearIcon}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">{children}</AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  formValues: PropTypes.shape({}).isRequired,
};

CustomAccordion.defaultProps = {
  title: '',
};

export default CustomAccordion;
