import { put, spawn, takeLatest } from 'redux-saga/effects';
import { BIKE } from 'screens/Bike/reducer';

import apiRequest, {
  apiSuccess, get, post, postForm,
} from 'utils/api';
import * as APP from 'commons/reducer';
import { appActions } from 'commons/reducer';
import { v4 as uuidv4 } from 'uuid';
import { extension } from 'utils/functions';

function* fetchBike() {
  yield takeLatest(BIKE.FETCH_BIKE, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_BIKE));
    const response = yield get(`api/v1/bikes/${action.payload}`);
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_BIKE));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_BIKE_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(BIKE.SET_IMAGES_DATA, response.images));
    } else {
      yield put(apiSuccess(BIKE.FETCH_BIKE_ERROR, { errorMsg: 'Error al cargar la Bike' }));
    }
  });
}

function* fetchBrands() {
  yield takeLatest(BIKE.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(BIKE.FETCH_BRANDS_ERROR, { errorMsg: 'Error al cargar las marcas' }));
    }
  });
}

function* fetchModels() {
  yield takeLatest(BIKE.FETCH_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_MODELS));
    const response = yield get('api/v1/models');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_MODELS));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(BIKE.FETCH_MODELS_ERROR, { errorMsg: 'Error al cargar los modelos' }));
    }
  });
}

function* fetchCategories() {
  yield takeLatest(BIKE.FETCH_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_CATEGORIES));
    const response = yield get('api/v1/categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_CATEGORIES_ERROR, { errorMsg: 'Error al cargar las categorias' }),
      );
    }
  });
}

function* fetchCurrencies() {
  yield takeLatest(BIKE.FETCH_CURRENCIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_CURRENCIES));
    const response = yield get('api/v1/currencies');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_CURRENCIES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_CURRENCIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_CURRENCIES_ERROR, { errorMsg: 'Error al cargar las monedas' }),
      );
    }
  });
}

function* fetchSizes() {
  yield takeLatest(BIKE.FETCH_SIZES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_SIZES));
    const response = yield get('api/v1/sizes');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_SIZES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_SIZES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(BIKE.FETCH_SIZES_ERROR, { errorMsg: 'Error al cargar las tallas' }));
    }
  });
}

function* fetchRims() {
  yield takeLatest(BIKE.FETCH_RIMS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_RIMS));
    const response = yield get('api/v1/rims');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_RIMS));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_RIMS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(apiSuccess(BIKE.FETCH_RIMS_ERROR, { errorMsg: 'Error al cargar los aros' }));
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(BIKE.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_VEHICLE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_VEHICLE_TYPES_ERROR, {
          errorMsg: 'Error al cargar los tipos de bikes',
        }),
      );
    }
  });
}

function* fetchMotorbikeCategories() {
  yield takeLatest(BIKE.FETCH_MOTORBIKE_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_MOTORBIKE_CATEGORIES));
    const response = yield get('api/v1/motorbike-categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_MOTORBIKE_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_MOTORBIKE_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_MOTORBIKE_CATEGORIES_ERROR, {
          errorMsg: 'Error al cargar los tipos de bikes',
        }),
      );
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(BIKE.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_MOTORBIKE_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_MOTORBIKE_BRANDS_ERROR, {
          errorMsg: 'Error al cargar los tipos de bikes',
        }),
      );
    }
  });
}

function* fetchMotorbikeModels() {
  yield takeLatest(BIKE.FETCH_MOTORBIKE_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_MOTORBIKE_MODELS));
    const response = yield get('api/v1/motorbike-models');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_MOTORBIKE_MODELS));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_MOTORBIKE_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_MOTORBIKE_MODELS_ERROR, {
          errorMsg: 'Error al cargar los tipos de bikes',
        }),
      );
    }
  });
}

function* fetchEngineCapacities() {
  yield takeLatest(BIKE.FETCH_ENGINE_CAPACITIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BIKE.FETCH_ENGINE_CAPACITIES));
    const response = yield get('api/v1/engine-capacities');
    yield put(apiSuccess(APP.REMOVE_LOADING, BIKE.FETCH_ENGINE_CAPACITIES));
    if (!response.error) {
      yield put(apiSuccess(BIKE.FETCH_ENGINE_CAPACITIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BIKE.FETCH_ENGINE_CAPACITIES_ERROR, {
          errorMsg: 'Error al cargar los tipos de bikes',
        }),
      );
    }
  });
}

function* registerBike() {
  yield takeLatest(BIKE.REGISTER_BIKE, function* (action) {
    const { controls, imagesFiles } = action;
    yield put(appActions.setLoader('bike', true));
    const body = new FormData();
    const jsonBody = {
      vehicleTypeId: controls.vehicleTypeId,
      chassisSerial: controls.chassisSerial,
      engineSerial: controls.engineSerial,
      engineCapacityId: controls.engineCapacityId,
      modelId: controls.modelId,
      year: controls.year,
      version: controls.version,
      sizeId: controls.sizeId,
      rimId: controls.rimId,
      currencyId: controls.currencyId,
      color: controls.color,
      serial: controls.serial,
      amount: controls.amount,
      invoiceTaxNumber: controls.invoiceTaxNumber,
      invoiceNumber: controls.invoiceNumber,
      categoryId: controls.categoryId,
      brandId: controls.brandId,
      invoiceCheckbox: controls.invoiceCheckbox,
      foreignCheckbox: controls.foreignCheckbox,
      invoiceInMyName: controls.invoiceInMyName,
      companyName: controls.companyName,
      companyTaxNumber: controls.companyTaxNumber,
      companyAddress: controls.companyAddress,
      purchasedAt: controls.purchasedAt,
      dataCompleted: controls.dataComepleted,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(jsonBody)) {
      let value = controls[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      jsonBody[key] = value;
    }
    body.append('jsonBody', JSON.stringify(jsonBody));
    // eslint-disable-next-line no-restricted-syntax
    for (const file of imagesFiles) {
      const now = new Date().getTime().toString();
      const random = uuidv4().split('-')[0];
      body.append('imagesFiles[]', file, `${now}-${random}.${extension(file.name)}`);
    }
    if (controls.invoiceFile?.name) {
      body.append('invoiceFile', controls.invoiceFile, controls.invoiceFile.name);
    }
    if (controls.serialFile?.name) {
      body.append('serialFile', controls.serialFile, controls.serialFile.name);
    }

    const response = yield postForm('api/v1/bikes/register-bike-pilot', body);
    if (!response.error) {
      yield put(apiSuccess(BIKE.REGISTER_BIKE_SUCCESS));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Registro exitoso'));
    } else {
      yield put(apiSuccess(BIKE.REGISTER_BIKE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: response.errorMsg }));
    }

    yield put(appActions.setLoader('bike', false));
  });
}

function* editBike() {
  yield takeLatest(BIKE.EDIT_BIKE, function* (action) {
    const { controls, imagesFiles } = action;
    const body = new FormData();
    const jsonBody = {
      vehicleTypeId: controls.vehicleTypeId,
      chassisSerial: controls.chassisSerial,
      engineSerial: controls.engineSerial,
      engineCapacityId: controls.engineCapacityId,
      modelId: controls.modelId,
      year: controls.year,
      version: controls.version,
      sizeId: controls.sizeId,
      rimId: controls.rimId,
      currencyId: controls.currencyId,
      color: controls.color,
      serial: controls.serial,
      amount: controls.amount,
      invoiceTaxNumber: controls.invoiceTaxNumber,
      invoiceNumber: controls.invoiceNumber,
      categoryId: controls.categoryId,
      brandId: controls.brandId,
      documentInvoiceId: controls.documentInvoiceId,
      documentSerialId: controls.documentSerialId,
      actualInvoiceId: controls.actualInvoiceId,
      actualSerialId: controls.actualSerialId,
      invoiceCheckbox: controls.invoiceCheckbox,
      foreignCheckbox: controls.foreignCheckbox,
      invoiceInMyName: controls.invoiceInMyName,
      companyName: controls.companyName,
      companyTaxNumber: controls.companyTaxNumber,
      companyAddress: controls.companyAddress,
      purchasedAt: controls.purchasedAt,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(jsonBody)) {
      let value = controls[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      jsonBody[key] = value;
    }
    body.append('jsonBody', JSON.stringify(jsonBody));

    // eslint-disable-next-line no-restricted-syntax
    for (const file of imagesFiles) {
      const now = new Date().getTime().toString();
      const random = uuidv4().split('-')[0];
      body.append('imagesFiles[]', file, `${now}-${random}.${extension(file.name)}`);
    }

    if (!controls.documentInvoiceId && controls.invoiceFile?.name) {
      body.append('invoiceFile', controls.invoiceFile, controls.invoiceFile.name);
    }
    if (!controls.documentSerialId && controls.serialFile?.name) {
      body.append('serialFile', controls.serialFile, controls.serialFile.name);
    }

    const response = yield postForm(`api/v1/bikes/${controls.transferId}`, body);
    if (!response.error) {
      yield put(apiSuccess(BIKE.EDIT_BIKE_SUCCESS));
      yield put(apiSuccess(APP.DELETE_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'Bike Actualizada'));
      if (response.patInsurance) {
        yield put(apiSuccess(BIKE.REDIRECT_INSURANCE));
      }
    } else {
      yield put(apiSuccess(BIKE.EDIT_BIKE_ERROR));
      const errorMessage = JSON.parse(response?.errorMsg);
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg: errorMessage?.error
            ? errorMessage.error
            : 'El código de serie ya se encuentra registrado',
        }),
      );
    }
  });
}

function* updateReport() {
  yield takeLatest(BIKE.UPDATE_REPORT, function* (action) {
    const id = action.payload;

    const response = yield post(`api/v1/report/update/${id}`);

    if (!response.error) {
      yield put(apiSuccess(BIKE.UPDATE_REPORT_SUCCESS, response));
      yield put(apiSuccess(APP.SET_SUCCESS, 'La denuncia fue removida'));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Verifique los datos ingresados' }));
      yield put(apiSuccess(BIKE.UPDATE_REPORT_ERROR));
    }
  });
}

export default function* root() {
  yield spawn(fetchBrands);
  yield spawn(fetchModels);
  yield spawn(fetchCategories);
  yield spawn(fetchSizes);
  yield spawn(fetchRims);
  yield spawn(fetchCurrencies);
  yield spawn(fetchBike);
  yield spawn(registerBike);
  yield spawn(editBike);
  yield spawn(updateReport);
  yield spawn(fetchVehicleTypes);
  yield spawn(fetchMotorbikeCategories);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchMotorbikeModels);
  yield spawn(fetchEngineCapacities);
}
