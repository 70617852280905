import colors from '../../../../utils/colors';

const { primary } = colors;

const styles = (theme) => ({
  generalContainer: {
    width: '100%',
    maxWidth: '1139px',
    margin: '0 auto',
  },
  paperContainer: {
    width: '100%',
    padding: '50px 0px 100px 0px',
    '& .title-container': {
      '& .title': {
        fontWeight: '700',
        fontSize: '36px',
        textAlign: 'center',
      },
    },
    '& .message-container': {
      margin: '0 auto',
      maxWidth: '692px',
      marginTop: '100px',
      '& .paragraph': {
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '400',
        '& .bikfy-color': {
          color: primary,
        },
      },
      '& :nth-child(2)': {
        marginTop: '20px',
      },
    },
    '& .button-container': {
      marginTop: '50px',
      display: 'flex',
      justifyContent: 'center',
      '& .show-bike-button': {
        padding: '10px 40px',
        borderRadius: '20px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    paperContainer: {
      '& .title-container': {
        '& .title': {
          fontSize: '26px',
        },
      },
      '& .message-container': {
        '& .paragraph': {
          fontSize: '20px',
        },
      },
    },
  },
});

export default styles;
