const styles = (theme) => ({
  [theme.breakpoints.down('xs')]: {
    cardContainer: {
      '& .title': {
        fontSize: '1rem',
        '& .specialTitle': {
          fontSize: '1rem',
        },
      },
    },
  },
});

export default styles;
