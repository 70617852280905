import React, { useEffect, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { CardContent, Snackbar, withStyles, CardMedia } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import { getParameterByName, composedComponent, cleanWhiteSpaces } from 'utils/functions';
import { appActions } from 'commons/reducer';
import Alert from '@material-ui/lab/Alert';
import { PATHS } from 'utils/paths';
import styles from './styles';
import { recoveryPasswordActions } from './reducer';
import saga from './saga';

import RecoveryForm from './components/recoveryForm';
import UpdatePasswordForm from './components/updatePasswordForm';
import logo from '../../commons/assets/Logo.svg';

const RecoveryPassword = (props) => {
  const { classes, showSuccessSnackbar, actions } = props;
  const history = useHistory();

  useEffect(() => {
    const token = getParameterByName('token', props.location.search);
    const email = getParameterByName('email', props.location.search);
    props.actions.setControls({ token, email });

    return () => {
      actions.showSuccessSnackbar({ showSuccessSnackbar: false, message: '' });
    };
  }, [props.actions, props.location.seaprops, props.location.search]);

  const onChangeText = useCallback((name, value) => {
    if (name === 'email') {
      value = cleanWhiteSpaces(value);
    }
    props.actions.setControls({ [name]: value });

    return () => {
      actions.showSuccessSnackbar({ showSuccessSnackbar: false, message: '' });
    };
  }, []);

  const toggleError = () => {
    props.actions.toggleError();
  };

  const toggleSuccess = () => {
    actions.showSuccessSnackbar({ showSuccessSnackbar: false, message: '' });
    history.push(PATHS.LOGIN);
  };

  return (
    <div style={styles.containerContent}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '100vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card>
            <CardContent style={styles.cardContent}>
              <CardMedia className={classes.image} image={logo} title="Bikfy" />
              <Switch>
                <Route
                  exact
                  path="/recovery-password"
                  render={() => (
                    <RecoveryForm
                      onChangeText={onChangeText}
                      email={props.controls.email}
                      classes={props.classes}
                      loading={props.loading}
                      controls={props.controls}
                      actions={props.actions}
                      history={history}
                    />
                  )}
                />
                <Route
                  exact
                  path="/update-password"
                  render={() => (
                    <UpdatePasswordForm
                      onChangeText={onChangeText}
                      password={props.controls.password}
                      verifyPassword={props.controls.verifyPassword}
                      classes={props.classes}
                      loading={props.loading}
                      controls={props.controls}
                      actions={props.actions}
                    />
                  )}
                />
              </Switch>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}>
        <Alert onClose={toggleError} severity="error">
          {props.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          if (showSuccessSnackbar) {
            history.push(PATHS.LOGIN);
          }
        }}>
        <Alert onClose={toggleSuccess} severity="success">
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default composedComponent(RecoveryPassword, saga, {
  saga: 'sagaRecoveryPassword',
  middlewares: [withStyles(styles)],
  states: [
    'recoveryPassword.controls',
    'recoveryPassword.message',
    'recoveryPassword.error',
    'recoveryPassword.successChangePassword',
    'recoveryPassword.loading',
    'recoveryPassword.showSuccessSnackbar',
  ],
  actions: [recoveryPasswordActions],
});
