import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const PublishBikeForm = (props) => {
  const { handleChange, formValues } = props;

  const { description } = formValues;

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Typography className="title">Descripción</Typography>
        <TextField
          multiline
          minRows={10}
          maxRows={12}
          fullWidth
          name="description"
          value={description.value}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default PublishBikeForm;
