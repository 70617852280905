import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardActions, Grid, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
  compressImage,
  fileToImageAsync,
  getCroppedImage,
  getDefaultCrop,
  IMG_CROP_RATIO,
} from 'utils/functions';
import 'react-image-crop/dist/ReactCrop.css';
import CroppedImageModal from '../CroppedImageModal';
import SortedImages from '../SortedImages';
import { useCallback } from 'react';

const ImagesSection = ({
  controls,
  actions,
  disabled,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  async function onSelectFiles(event) {
    actions.setLoader('bike', true);
    let files = Array.from(event.target.files);
    const imgLength = controls.croppedImages.length;
    if ((imgLength + files.length) > 10) {
      actions.setError({ errorMsg: 'Está permitido cargar hasta 10 fotos' });
      const leftover = imgLength + files.length - 10;
      files = files.slice(0, files.length - leftover);
      if (files.length === 0) {
        actions.setLoader('bike', false);
        return;
      }
    }

    const compressedFiles = await Promise.all(files.map(compressImage));
    const filesNames = compressedFiles.map((file) => file.name);
    const result = await Promise.all(compressedFiles.map(fileToImageAsync));
    const defaultCrops = result.map((image) => getDefaultCrop(image, IMG_CROP_RATIO));
    const cropped = result.map((image, index) => getCroppedImage(image, defaultCrops[index]));
    const meta = result.map(() => ({ id: 0, state: 'save', index: controls.imagesMeta.length }));
    actions.setFilesNames([...controls.filesNames, ...filesNames]);
    actions.setImages([...controls.images, ...result]);
    actions.setCrops([...controls.crops, ...defaultCrops]);
    actions.setCroppedImages([...controls.croppedImages, ...cropped]);
    actions.setImagesMeta([...controls.imagesMeta, ...meta]);
    actions.setLoader('bike', false);
  }

  function onCommitCrop(crop) {
    actions.setLoader('lots-manager', true);
    const newCrops = [...controls.crops];
    newCrops[selectedIndex] = crop;
    actions.setCrops(newCrops);
    const croppedImage = getCroppedImage(controls.images[selectedIndex], crop);
    const newCroppedImages = [...controls.croppedImages];
    newCroppedImages[selectedIndex] = croppedImage;
    actions.setCroppedImages(newCroppedImages);
    if (controls.imagesMeta[selectedIndex].id > 0) {
      actions.updateMeta(selectedIndex, { state: 'update' });
    }

    setSelectedIndex(null);
    actions.setLoader('lots-manager', false);
  }

  const onEditCrop = useCallback((index) => {
    setSelectedIndex(index);
  }, []);

  const onRemove = useCallback(
    (index) => {
      const newFilesNames = [...controls.filesNames];
      newFilesNames.splice(index, 1);
      actions.setFilesNames(newFilesNames);
      const newImages = [...controls.images];
      newImages.splice(index, 1);
      actions.setImages(newImages);
      const newCrops = [...controls.crops];
      newCrops.splice(index, 1);
      actions.setCrops(newCrops);
      const newCroppedImages = [...controls.croppedImages];
      newCroppedImages.splice(index, 1);
      actions.setCroppedImages(newCroppedImages);
      if (controls.imagesMeta[index].id > 0) {
        actions.deleteImage(controls.imagesMeta[index].id);
      }
      const newImagesMeta = [...controls.imagesMeta];
      newImagesMeta.splice(index, 1);
      actions.setImagesMeta(newImagesMeta);
    },
    [
      actions,
      controls.croppedImages,
      controls.crops,
      controls.filesNames,
      controls.images,
      controls.imagesMeta,
    ],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      actions.setFilesNames(arrayMoveImmutable(controls.filesNames, oldIndex, newIndex));
      actions.setImages(arrayMoveImmutable(controls.images, oldIndex, newIndex));
      actions.setCrops(arrayMoveImmutable(controls.crops, oldIndex, newIndex));
      actions.setCroppedImages(arrayMoveImmutable(controls.croppedImages, oldIndex, newIndex));
      actions.setImagesMeta(
        arrayMoveImmutable(controls.imagesMeta, oldIndex, newIndex).map((item, index) => ({
          ...item,
          state: 'update',
          index,
        })),
      );
    },
    [
      actions,
      controls.croppedImages,
      controls.crops,
      controls.filesNames,
      controls.images,
      controls.imagesMeta,
    ],
  );

  return (
    <Grid container justifyContent="center">
      {/* START images input */}
      <Grid container item xs={12} justifyContent="center">
        <input
          id="images"
          type="file"
          multiple
          hidden
          accept="image/*"
          onChange={onSelectFiles}
          disabled={disabled}
        />

        <label htmlFor="images">
          <Button color="primary" component="span" variant="contained" disabled={disabled}>
            <Grid container>
              <Grid container item xs={12} justifyContent="center">
                <AddIcon />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                +10 Fotos Bike (opcional)
              </Grid>
            </Grid>
          </Button>
        </label>
      </Grid>
      {/* END images input */}

      <SortedImages
        images={controls.croppedImages}
        onEditCrop={onEditCrop}
        onRemove={onRemove}
        onSortEnd={onSortEnd}
      />

      {selectedIndex != null && (
        <CroppedImageModal
          image={controls.images[selectedIndex]}
          initialCrop={controls.crops[selectedIndex]}
          show={true}
          onCommit={onCommitCrop}
          onClose={() => setSelectedIndex(null)}
        />
      )}
    </Grid>
  );
};

export default ImagesSection;
