import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import TotalAmountBox from 'commons/components/TotalAmounBox/TotalAmounBox';

const PublishBikeForm = (props) => {
  const {
    handleSetFormValues,
    handleChange,
    formValues,
    setFormValues,
    classes,
    publishedBikeValue,
  } = props;

  const { description, withBikfyPay, showContact, withDelivery, price } = formValues;

  const handleClickPublishButton = () => handleSetFormValues({
    newPublishBikeFormValues: formValues,
  });

  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Typography className="title">Descripción</Typography>
        <TextField
          multiline
          rows={10}
          fullWidth
          name="description"
          value={description.value}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12} style={{ marginTop: '15px', position: 'relative' }}>
        <Box display="flex" flexDirection="column" maxWidth={250} margin="0 auto">
          <Box display="inherit" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="inherit" alignItems="inherit">
              <FormControlLabel
                control={<Checkbox checked={withDelivery.value} onChange={handleChange} name="withDelivery" />}
                label={<Typography className={`${classes.fWeight700}`}>¿Haces envíos?</Typography>}
              />
            </Box>
            <Tooltip
              title="Ofrezco el servicio de envío a mis posibles compradores."
              placement="right">
              <ErrorOutlineIcon />
            </Tooltip>
          </Box>

          <Box display="inherit" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="inherit" alignItems="inherit">
              <FormControlLabel
                control={<Checkbox checked={showContact.value} onChange={handleChange} name="showContact" />}
                label={<Typography className={`${classes.fWeight700}`}>Habilitar Whatsapp</Typography>}
              />
            </Box>
            <Tooltip
              title="Deseo que los potenciales compradores me contacten a mi Whatsapp."
              placement="right">
              <ErrorOutlineIcon />
            </Tooltip>
          </Box>

          <Box display="inherit" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="inherit" alignItems="inherit">
              <FormControlLabel
                control={<Checkbox checked={withBikfyPay.value} onChange={handleChange} name="withBikfyPay" />}
                label={(
                  <Typography className={`${classes.fWeight700}`}>
                    Habilitar
                    <span className="bikfy-color"> BIKFY</span>
                    PAY
                  </Typography>
                )}
              />
            </Box>
            <Tooltip
              title="Deseo incorporar la comisión de BIKFYPAY correspondiente a un 3,7% en el precio de mi publicación, a cambio de que el comprador pueda utilizar tarjetas bancarias para el pago de mi Bike."
              placement="right">
              <ErrorOutlineIcon />
            </Tooltip>
          </Box>

          <TotalAmountBox
            totalAmount={Number(publishedBikeValue)}
            className={`${classes.amountBoxContainer} ${classes.mTop10}`}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
            onClick={handleClickPublishButton}>
            Publicar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

PublishBikeForm.propTypes = {
  formValues: PropTypes.shape({}).isRequired,
  setFormValues: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
};

PublishBikeForm.defaultProps = {
  classes: {},
};

export default PublishBikeForm;
