import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';

import safariLogo from '../../../../commons/assets/safari-logo.png';

// eslint-disable-next-line import/prefer-default-export
export function SafariUserAgentModal(props) {
  const { isSafariUserModalOpen, setIsSafariUserModalOpen, classes } = props;
  return (
    <Dialog
      open={isSafariUserModalOpen}
      onClose={() => setIsSafariUserModalOpen(false)}
      className={classes.bikfyModal}>
      <DialogContent style={{ textAlign: 'center' }} className={classes.bikfyModal}>
        <DialogContentText>
          <Typography className={classes.modalTitle}>
            Hemos detectado que estás desde el navegador Safari
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <img src={safariLogo} style={{ maxWidth: '90px', marginBottom: '10px' }} />
        </DialogContentText>
        <br />
        <DialogContentText className={classes.subtitleText}>
          Para contratar un seguro de una Bike, recomendamos:
        </DialogContentText>
        <DialogContentText>
          <Grid container justifyContent="center">
            <List component="ul">
              <ListItem>
                {' '}
                <Typography align="center">
                  · Habilitar los Pop-ups en la configuración del Navegador
                </Typography>{' '}
              </ListItem>
              <ListItem>
                {' '}
                <Typography align="center">· Ocupar el navegador Google Chrome</Typography>{' '}
              </ListItem>
            </List>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.bikfyModalActions}>
        <Grid container justifyContent="center">
          <Button
            onClick={() => setIsSafariUserModalOpen(false)}
            color="primary"
            variant="contained"
            className={classes.marginBottom}>
            Entendido
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
