import Downhill from 'commons/assets/downhill.png';
import Kids from 'commons/assets/kids.png';
import Dirt from 'commons/assets/dirt.png';
import FatBike from 'commons/assets/fatbike.png';
import Trial from 'commons/assets/trial.png';
import EbikeMTB from 'commons/assets/ebikemtb.png';
import EbikeUrbano from 'commons/assets/ebikeurbano.png';
import CrossCountry from 'commons/assets/crosscountry.png';
import BMXBicicross from 'commons/assets/bmxbicicross.png';
import UrbanoMultiuso from 'commons/assets/urbanomultiuso.png';
import EnduroTrail from 'commons/assets/endurotrail.png';
import RutaGravelCiclocross from 'commons/assets/rutagravelciclocross.png';
import EDirtBikes from 'commons/assets/edirtbikes.jpg';
import EBikeGravel from 'commons/assets/ebike-gravel.png';
import RecumbentTrike from 'commons/assets/recumbent-trike.png';
import Enduro from 'commons/assets/enduro0.png';
import TrialMotorbike from 'commons/assets/trial0.png';
import SnowMobile from 'commons/assets/moto_nieve0.png';
import mxsx from 'commons/assets/MX0.png';
import gp from 'commons/assets/GP0.png';
import Supermotard from 'commons/assets/supermotard0.png';
import Rally from 'commons/assets/Rally0.png';
import atv from 'commons/assets/ATV0.png';

const categories = {
  Downhill,
  Kids,
  Dirt,
  'Fat Bike': FatBike,
  Trial,
  'Ebike MTB': EbikeMTB,
  'Ebike Urbano': EbikeUrbano,
  CrossCountry,
  'BMX/Bicicross': BMXBicicross,
  'Urbano/Multiuso': UrbanoMultiuso,
  'Enduro/Trail': EnduroTrail,
  'Ruta/Triatlón': RutaGravelCiclocross,
  'Gravel/Ciclocross': RutaGravelCiclocross,
  'E-Dirt Bikes': EDirtBikes,
  'Ebike Gravel': EBikeGravel,
  'Recumbent Trike': RecumbentTrike,
  Enduro,
  'Trial Motorbike': TrialMotorbike,
  'Moto de nieve': SnowMobile,
  'MX/SX': mxsx,
  GP: gp,
  Supermotard,
  Rally,
  ATV: atv,
};

export default categories;
