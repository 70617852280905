import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';

import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import StarIcon from '@material-ui/icons/Star';

import colors from 'utils/colors';

const { yellow } = colors;

const ServicesIcons = ({
  accessedBy,
  creditCardWithFees,
  showBikfyPayIcon,
  showDeliveryIcon,
  withInvoice,
}) => {
  const anyIconAvailable =
    accessedBy || creditCardWithFees || showBikfyPayIcon || showDeliveryIcon || withInvoice;
  return (
    <Grid
      container
      spacing={anyIconAvailable ? 2 : 0}
      style={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
      <Grid item xs={12}>
        {creditCardWithFees && (
          <Grid item style={{ display: 'flex' }}>
            <StarIcon style={{ color: yellow, marginRight: 5 }} />
            <Tooltip title="Hasta 9 cuotas sin interés con tarjeta de crédito" arrow>
              <Typography align="center">
                Hasta <b>9 cuotas sin interés</b>
              </Typography>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} spacing={anyIconAvailable ? 2 : 0}>
        {withInvoice && (
          <Grid item>
            <Tooltip title="Con Documentación" arrow>
              <ReceiptOutlinedIcon fontSize="large" />
            </Tooltip>
          </Grid>
        )}
        {showBikfyPayIcon && (
          <Grid item>
            <Tooltip title="BIKFYPAY" arrow>
              <CreditCardOutlinedIcon fontSize="large" />
            </Tooltip>
          </Grid>
        )}
        {showDeliveryIcon && (
          <Grid item>
            <Tooltip title="El vendedor realiza envíos" arrow>
              <LocalShippingOutlinedIcon fontSize="large" />
            </Tooltip>
          </Grid>
        )}
        {accessedBy && (
          <Grid item>
            <Tooltip title="Bike revisada por la tienda" arrow>
              <CheckCircleOutlineOutlinedIcon fontSize="large" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ServicesIcons;
