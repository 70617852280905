import {
  ButtonBase, Grid, Paper, Typography,
} from '@material-ui/core';
import React from 'react';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BeenhereIcon from '@material-ui/icons/Beenhere';

const FilterBikfyPublicationsButtons = (props) => {
  const {
    classes, actions, filterForm, hideIconBreakpoint,
  } = props;

  const handleMarketplaceClick = () => {
    actions.setFilterForm({
      filterForm: {
        ...filterForm,
        searchBikfyPublications: false,
      },
    });
    actions.resetMarketplacePagination();
    actions.getPublishedBikes();
  };

  const handleBikfyClick = () => {
    actions.setFilterForm({
      filterForm: {
        ...filterForm,
        searchBikfyPublications: true,
      },
    });
    actions.resetMarketplacePagination();
    actions.getPublishedBikes();
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      {/* Title */}
      <Grid item>
        <Typography variant="h6" align="center" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
          TIPOS DE BICICLETAS
        </Typography>
      </Grid>

      {/* Bikfy Button */}
      <Grid item xs={12} style={{ width: '100%' }}>
        <ButtonBase
          onClick={handleBikfyClick}
          className={classes.searchBikfyPublicationsButtonBase}
          style={{ width: '100%' }}
        >
          <Grid
            container
            item
            xs={12}
            component={Paper}
            alignItems="center"
            justifyContent="center"
            className={classes.bikfyPublicationsGrid}
            style={{
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #87CEEB',
              maxWidth: '300px',
              width: '100%',
            }}
          >
            {!hideIconBreakpoint && (
              <Grid
                item
                style={{
                  color: '#FFD700',
                  paddingTop: '5px',
                  marginRight: '8px',
                }}
              >
                <BeenhereIcon />
              </Grid>
            )}
            <Grid item>
              <Typography align="center" className={classes.showBikfyPublicationsButtonText}>
                AQUÍ PARA VER SEMI-NUEVAS BIKFY
              </Typography>
            </Grid>
          </Grid>
        </ButtonBase>
      </Grid>

      {/* Marketplace Button */}
      <Grid item xs={12} style={{ width: '100%' }}>
        <ButtonBase
          onClick={handleMarketplaceClick}
          className={classes.searchBikfyPublicationsButtonBase}
          style={{ width: '100%' }}
        >
          <Grid
            container
            item
            xs={12}
            component={Paper}
            alignItems="center"
            justifyContent="center"
            className={classes.marketplacePublicationsGrid}
            style={{
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #87CEEB',
              maxWidth: '300px',
              width: '100%',
            }}
          >
            {!hideIconBreakpoint && (
              <Grid
                item
                style={{
                  color: '#87CEEB',
                  paddingTop: '5px',
                  marginRight: '8px',
                }}
              >
                <StorefrontIcon />
              </Grid>
            )}
            <Grid item>
              <Typography align="center" className={classes.showBikfyPublicationsButtonText}>
                AQUÍ PARA VER TODAS LAS BIKES
              </Typography>
            </Grid>
          </Grid>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default FilterBikfyPublicationsButtons;
