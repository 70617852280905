import React from 'react';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const CustomButtonFloat = (props) => {
  const { className, handleSubmit } = props;

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className={className}
        onClick={handleSubmit}
        id="btn-add-bike"
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default CustomButtonFloat;
