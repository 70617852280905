import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import Layout from 'commons/containers/Layout';
import AuthUserContext from 'commons/context/user';
import InactiveUser from 'utils/inactiveUser';

const PrivateRoute = ({
  user, loading, withLayout, ...rest
}) => {
  const location = useLocation();

  if (!user.activated && location.pathname !== '/activation') {
    return (
      <Layout>
        <InactiveUser />
      </Layout>
    );
  }

  return (
    <Layout>
      <AuthUserContext.Provider value={user}>
        <Route {...rest} />
      </AuthUserContext.Provider>
    </Layout>
  );
};

export default PrivateRoute;
