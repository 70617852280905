import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'bike/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const BIKE = {
  RESET_CONTROLS: `${PATH}RESET_CONTROLS`,
  REGISTER_BIKE: `${PATH}REGISTER_BIKE`,
  REGISTER_BIKE_SUCCESS: `${PATH}REGISTER_BIKE_SUCCESS`,
  REGISTER_BIKE_ERROR: `${PATH}REGISTER_BIKE_ERROR`,
  EDIT_BIKE: `${PATH}EDIT_BIKE`,
  EDIT_BIKE_SUCCESS: `${PATH}EDIT_BIKE_SUCCESS`,
  EDIT_BIKE_ERROR: `${PATH}EDIT_BIKE_ERROR`,
  REDIRECT_INSURANCE: `${PATH}REDIRECT_INSURANCE`,
  FETCH_BIKE: `${PATH}FETCH_BIKE`,
  FETCH_BIKE_SUCCESS: `${PATH}FETCH_BIKE_SUCCESS`,
  FETCH_BIKE_ERROR: `${PATH}FETCH_BIKE_ERROR`,
  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_ERROR`,
  FETCH_MODELS: `${PATH}FETCH_MODELS`,
  FETCH_MODELS_SUCCESS: `${PATH}FETCH_MODELS_SUCCESS`,
  FETCH_MODELS_ERROR: `${PATH}FETCH_MODELS_ERROR`,
  FETCH_CATEGORIES: `${PATH}FETCH_CATEGORIES`,
  FETCH_CATEGORIES_SUCCESS: `${PATH}FETCH_CATEGORIES_SUCCESS`,
  FETCH_CATEGORIES_ERROR: `${PATH}FETCH_CATEGORIES_ERROR`,
  FETCH_SIZES: `${PATH}FETCH_SIZES`,
  FETCH_SIZES_SUCCESS: `${PATH}FETCH_SIZES_SUCCESS`,
  FETCH_SIZES_ERROR: `${PATH}FETCH_SIZES_ERROR`,
  FETCH_RIMS: `${PATH}FETCH_RIMS`,
  FETCH_RIMS_SUCCESS: `${PATH}FETCH_RIMS_SUCCESS`,
  FETCH_RIMS_ERROR: `${PATH}FETCH_RIMS_ERROR`,
  FETCH_CURRENCIES: `${PATH}FETCH_CURRENCIES`,
  FETCH_CURRENCIES_SUCCESS: `${PATH}FETCH_CURRENCIES_SUCCESS`,
  FETCH_CURRENCIES_ERROR: `${PATH}FETCH_CURRENCIES_ERROR`,
  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_ERROR: `${PATH}FETCH_VEHICLE_TYPES_ERROR`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,
  FETCH_MOTORBIKE_CATEGORIES: `${PATH}FETCH_MOTORBIKE_CATEGORIES`,
  FETCH_MOTORBIKE_CATEGORIES_SUCCESS: `${PATH}FETCH_MOTORBIKE_CATEGORIES_SUCCESS`,
  FETCH_MOTORBIKE_CATEGORIES_ERROR: `${PATH}FETCH_MOTORBIKE_CATEGORIES_ERROR`,
  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS_ERROR: `${PATH}FETCH_MOTORBIKE_BRANDS_ERROR`,
  FETCH_MOTORBIKE_MODELS: `${PATH}FETCH_MOTORBIKE_MODELS`,
  FETCH_MOTORBIKE_MODELS_SUCCESS: `${PATH}FETCH_MOTORBIKE_MODELS_SUCCESS`,
  FETCH_MOTORBIKE_MODELS_ERROR: `${PATH}FETCH_MOTORBIKE_MODELS_ERROR`,
  FETCH_ENGINE_CAPACITIES: `${PATH}FETCH_ENGINE_CAPACITIES`,
  FETCH_ENGINE_CAPACITIES_SUCCESS: `${PATH}FETCH_ENGINE_CAPACITIES_SUCCESS`,
  FETCH_ENGINE_CAPACITIES_ERROR: `${PATH}FETCH_ENGINE_CAPACITIES_ERROR`,
  UPDATE_REPORT: `${PATH}UPDATE_REPORT`,
  UPDATE_REPORT_SUCCESS: `${PATH}UPDATE_REPORT_SUCCESS`,
  UPDATE_REPORT_ERROR: `${PATH}UPDATE_REPORT_ERROR`,
  CLEAR_ALERT: `${PATH}CLEAR_ALERT`,
  OPEN_ALERT: `${PATH}OPEN_ALERT`,
  CLEAR_ALERT_SERIAL: `${PATH}CLEAR_ALERT_SERIAL`,
  OPEN_ALERT_SERIAL: `${PATH}OPEN_ALERT_SERIAL`,
  SORT_IMAGES: `${PATH}SORT_IMAGES`,

  SET_FILES_NAMES: `${PATH}SET_FILES_NAMES`,
  SET_IMAGES: `${PATH}SET_IMAGES`,
  SET_CROPS: `${PATH}SET_CROPS`,
  SET_CROPPED_IMAGES: `${PATH}SET_CROPPED_IMAGES`,
  SET_IMAGES_META: `${PATH}SET_IMAGES_META`,
  SET_IMAGES_DATA: `${PATH}SET_IMAGES_DATA`,
  UPDATE_META: `${PATH}UPDATE_META`,
  DELETE_IMAGE: `${PATH}DELETE_IMAGE`,
};

// actions
export const bikeActions = {
  registerBike: createAction(BIKE.REGISTER_BIKE, 'controls', 'imagesFiles'),
  editBike: createAction(BIKE.EDIT_BIKE, 'controls', 'imagesFiles'),
  resetControls: createAction(BIKE.RESET_CONTROLS),
  fetchBike: createAction(BIKE.FETCH_BIKE, 'payload'),
  fetchBrands: createAction(BIKE.FETCH_BRANDS),
  fetchModels: createAction(BIKE.FETCH_MODELS),
  fetchCategories: createAction(BIKE.FETCH_CATEGORIES),
  fetchSizes: createAction(BIKE.FETCH_SIZES),
  fetchRims: createAction(BIKE.FETCH_RIMS),
  fetchCurrencies: createAction(BIKE.FETCH_CURRENCIES),
  fetchVehicleTypes: createAction(BIKE.FETCH_VEHICLE_TYPES),
  fetchMotorbikeCategories: createAction(BIKE.FETCH_MOTORBIKE_CATEGORIES),
  fetchMotorbikeBrands: createAction(BIKE.FETCH_MOTORBIKE_BRANDS),
  fetchMotorbikeModels: createAction(BIKE.FETCH_MOTORBIKE_MODELS),
  fetchEngineCapacities: createAction(BIKE.FETCH_ENGINE_CAPACITIES),
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  updateReport: createAction(BIKE.UPDATE_REPORT, 'payload'),
  clearAlert: createAction(BIKE.CLEAR_ALERT),
  openAlert: createAction(BIKE.OPEN_ALERT),
  clearAlertSerial: createAction(BIKE.CLEAR_ALERT_SERIAL),
  openAlertSerial: createAction(BIKE.OPEN_ALERT_SERIAL),
  reorderImages: createAction(BIKE.SORT_IMAGES, 'payload'),

  setFilesNames: createAction(BIKE.SET_FILES_NAMES, 'filesNames'),
  setImages: createAction(BIKE.SET_IMAGES, 'images'),
  setCrops: createAction(BIKE.SET_CROPS, 'crops'),
  setCroppedImages: createAction(BIKE.SET_CROPPED_IMAGES, 'croppedImages'),
  setImagesMeta: createAction(BIKE.SET_IMAGES_META, 'meta'),
  setImagesData: createAction(BIKE.SET_IMAGES_DATA, 'images'),
  updateMeta: createAction(BIKE.UPDATE_META, 'index', 'meta'),
  deleteImage: createAction(BIKE.DELETE_IMAGE, 'id'),
};

const initialState = {
  formControls: {
    id: null,
    modelId: null,
    year: '',
    version: '',
    sizeId: null,
    rimId: null,
    currencyId: 24,
    engineCapacityId: null,
    color: '',
    serial: '',
    chassisSerial: '',
    engineSerial: '',
    amount: '',
    invoiceTaxNumber: '',
    invoiceNumber: '',
    categoryId: null,
    brandId: null,
    vehicleTypeId: null,
    invoiceFile: '',
    serialFile: '',
    companyName: '',
    companyTaxNumber: '',
    companyAddress: '',
    documentSerialId: null,
    documentInvoiceId: null,
    invoiceCheckbox: false,
    foreignCheckbox: false,
    invoiceInMyName: 1,
    possessionCheckbox: false,
    reported: false,
    purchasedAt: '',
    dataCompleted: true,
    filesNames: [],
    images: [],
    crops: [],
    croppedImages: [],
    imagesMeta: [],
    deletedImages: [],
  },
  bikeLoading: false,
  error: false,
  successRegister: false,
  successEdit: false,
  successUpdateReport: false,
  alert: false,
  alertSerial: false,
  message: '',
  vehicleTypeFetched: false,
  redirectInsurance: false,

  imagesData: [],
};

const photoTypes = {
  right: {
    name: 'rightSideFile',
    documentType: 'documentRightId',
    actual: 'actualRightId',
  },
  left: {
    name: 'leftSideFile',
    documentType: 'documentLeftId',
    actual: 'actualLeftId',
  },
  front: {
    name: 'frontSideFile',
    documentType: 'documentFrontId',
    actual: 'actualFrontId',
  },
  extra: {
    name: 'extra_',
    documentType: 'documentExtraId',
    actual: 'actualExtraId',
  },
};

const sortFile = (state, action) => {
  let counter = 1;
  let oldBackUp = {};
  let newBackUp = {};
  let index = null;
  let key = '';
  let photoTypesToWork1 = null;
  let photoTypesToWork2 = null;
  let result = {};

  const {
    totalFiles, image, oldIndex, newIndex,
  } = action.payload;

  for (let i = newIndex; i < totalFiles; i++) {
    if (counter === 1) {
      if (oldIndex > 2) {
        key = 'extra';
        photoTypesToWork1 = photoTypes[key];
        oldBackUp = {
          [`${photoTypesToWork1.name}${oldIndex - 2}`]:
            state.formControls[`${photoTypesToWork1.name}${oldIndex - 2}`],
          [`${photoTypesToWork1.documentType}${oldIndex - 2}`]:
            state.formControls[`${photoTypesToWork1.documentType}${oldIndex - 2}`],
          [`${photoTypesToWork1.actual}${oldIndex - 2}`]:
            state.formControls[`${photoTypesToWork1.actual}${oldIndex - 2}`],
        };
      } else {
        key = Object.keys(photoTypes)[oldIndex];
        photoTypesToWork1 = photoTypes[key];
        oldBackUp = {
          [photoTypesToWork1.name]: state.formControls[photoTypesToWork1.name],
          [photoTypesToWork1.documentType]: state.formControls[photoTypesToWork1.documentType],
          [photoTypesToWork1.actual]: state.formControls[photoTypesToWork1.actual],
        };
      }
    }

    index = i <= 2 ? i : 3;
    key = Object.keys(photoTypes)[index];
    photoTypesToWork2 = photoTypes[key];

    newBackUp = {
      [photoTypesToWork2.name]: state.formControls[photoTypesToWork2.name],
      [photoTypesToWork2.documentType]: state.formControls[photoTypesToWork2.documentType],
      [photoTypesToWork2.actual]: state.formControls[photoTypesToWork2.actual],
    };

    result = {
      [photoTypesToWork2.name]: oldBackUp[photoTypesToWork1.name],
      [photoTypesToWork2.documentType]: oldBackUp[photoTypesToWork1.documentType],
      [photoTypesToWork2.actual]: oldBackUp[photoTypesToWork1.actual],

      [photoTypesToWork1.name]: newBackUp[photoTypesToWork2.name],
      [photoTypesToWork1.documentType]: newBackUp[photoTypesToWork2.documentType],
      [photoTypesToWork1.actual]: newBackUp[photoTypesToWork2.actual],
    };

    counter++;
  }
  state = { ...state, ...result };
};

const bike = createReducer(initialState, {
  [BIKE.SORT_IMAGES]: sortFile,
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [BIKE.RESET_CONTROLS](state) {
    state.formControls = { ...initialState.formControls };
    state.successRegister = false;
    state.successEdit = false;
    state.successUpdateReport = false;
    state.alert = false;
    state.alertSerial = false;
    state.imagesData = initialState.imagesData;
  },
  [BIKE.REGISTER_BIKE](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.REGISTER_BIKE_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
    state.successRegister = true;
  },
  [BIKE.REGISTER_BIKE_ERROR](state) {
    state.bikeLoading = false;
    state.error = true;
    state.successRegister = false;
  },
  [BIKE.REDIRECT_INSURANCE](state) {
    state.redirectInsurance = true;
  },
  [BIKE.EDIT_BIKE](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.EDIT_BIKE_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
    state.successEdit = true;
  },
  [BIKE.EDIT_BIKE_ERROR](state) {
    state.bikeLoading = false;
    state.error = true;
    state.successEdit = false;
  },
  [BIKE.FETCH_BIKE](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_BIKE_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_BIKE_ERROR](state) {
    state.bikeLoading = false;
    state.error = true;
  },
  [BIKE.FETCH_BRANDS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_BRANDS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_BRANDS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_MODELS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_MODELS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_MODELS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_CATEGORIES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_CATEGORIES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_CATEGORIES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_SIZES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_SIZES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_SIZES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_RIMS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_RIMS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_RIMS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_CURRENCIES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_CURRENCIES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_CURRENCIES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_VEHICLE_TYPES](state) {
    state.vehicleTypeFetched = false;
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_VEHICLE_TYPES_SUCCESS](state) {
    state.vehicleTypeFetched = true;
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_VEHICLE_TYPES_ERROR](state, action) {
    state.vehicleTypeFetched = false;
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_MOTORBIKE_CATEGORIES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_CATEGORIES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_CATEGORIES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_MOTORBIKE_BRANDS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_BRANDS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_MOTORBIKE_MODELS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_MODELS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_MOTORBIKE_MODELS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.FETCH_ENGINE_CAPACITIES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BIKE.FETCH_ENGINE_CAPACITIES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BIKE.FETCH_ENGINE_CAPACITIES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BIKE.UPDATE_REPORT](state) {
    state.bikeLoading = true;
    state.error = false;
    state.successUpdateReport = false;
  },
  [BIKE.UPDATE_REPORT_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
    state.successUpdateReport = true;
  },
  [BIKE.UPDATE_REPORT_ERROR](state) {
    state.bikeLoading = false;
    state.error = true;
    state.successUpdateReport = false;
  },
  [BIKE.CLEAR_ALERT](state) {
    state.alert = false;
  },
  [BIKE.OPEN_ALERT](state) {
    state.alert = true;
  },
  [BIKE.CLEAR_ALERT_SERIAL](state) {
    state.alertSerial = false;
  },
  [BIKE.OPEN_ALERT_SERIAL](state) {
    state.alertSerial = true;
  },
  [BIKE.SET_FILES_NAMES](state, action) {
    state.formControls.filesNames = action.filesNames;
  },
  [BIKE.SET_IMAGES](state, action) {
    state.formControls.images = action.images;
  },
  [BIKE.SET_CROPS](state, action) {
    state.formControls.crops = action.crops;
  },
  [BIKE.SET_CROPPED_IMAGES](state, action) {
    state.formControls.croppedImages = action.croppedImages;
  },
  [BIKE.SET_IMAGES_META](state, action) {
    state.formControls.imagesMeta = action.meta;
  },
  [BIKE.SET_IMAGES_DATA](state, action) {
    state.imagesData = [...action.payload];
  },
  [BIKE.UPDATE_META](state, action) {
    state.formControls.imagesMeta[action.index] = {
      ...state.formControls.imagesMeta[action.index],
      ...action.meta,
    };
  },
  [BIKE.DELETE_IMAGE](state, action) {
    state.formControls.deletedImages = [...state.formControls.deletedImages, action.id];
  },
});

export default bike;
