/* eslint-disable no-param-reassign */
import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'subscription/';

// Global constants for saga
export const SUBSCRIPTION = {
  FETCH_SUBSCRIPTION: `${PATH}FETCH_SUBSCRIPTION`,
  FETCH_SUBSCRIPTION_SUCCESS: `${PATH}FETCH_SUBSCRIPTION_SUCCESS`,
  FETCH_SUBSCRIPTION_ERROR: `${PATH}FETCH_SUBSCRIPTION_ERROR`,
  CANCEL_SUBSCRIPTION: `${PATH}CANCEL_SUBSCRIPTION`,
  CANCEL_SUBSCRIPTION_SUCCESS: `${PATH}CANCEL_SUBSCRIPTION_SUCCESS`,
  CANCEL_SUBSCRIPTION_ERROR: `${PATH}CANCEL_SUBSCRIPTION_ERROR`,
  ADD_CARD: `${PATH}ADD_CARD`,
  ADD_CARD_SUCCESS: `${PATH}ADD_CARD_SUCCESS`,
  ADD_CARD_ERROR: `${PATH}ADD_CARD_ERROR`,
};

// actions
export const subscriptionActions = {
  fetchSubscription: createAction(SUBSCRIPTION.FETCH_SUBSCRIPTION, 'payload'),
  cancelSubscription: createAction(SUBSCRIPTION.CANCEL_SUBSCRIPTION, 'payload'),
  addCard: createAction(SUBSCRIPTION.ADD_CARD, 'payload'),
};

const initialState = {
  alert: false,
  loading: false,
  error: false,
  subscription: {},
  loadingCancel: false,
  errorCancel: false,
  loadingAddCard: false,
  errorAddCard: false,
};

const subscription = createReducer(initialState, {
  [SUBSCRIPTION.FETCH_SUBSCRIPTION](state) {
    state.loading = true;
    state.subscription = {};
    state.error = false;
    state.alert = false;
  },
  [SUBSCRIPTION.FETCH_SUBSCRIPTION_SUCCESS](state, action) {
    state.loading = false;
    state.subscription = action.payload;
    state.error = false;
  },
  [SUBSCRIPTION.FETCH_SUBSCRIPTION_ERROR](state, action) {
    state.loading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [SUBSCRIPTION.CANCEL_SUBSCRIPTION](state) {
    state.loadingCancel = true;
    state.errorCancel = false;
  },
  [SUBSCRIPTION.CANCEL_SUBSCRIPTION_SUCCESS](state, action) {
    state.loadingCancel = false;
    state.errorCancel = false;
    state.subscription = action.payload;
  },
  [SUBSCRIPTION.CANCEL_SUBSCRIPTION_ERROR](state, action) {
    state.loadingCancel = false;
    state.errorCancel = true;
  },
  [SUBSCRIPTION.ADD_CARD](state) {
    state.loadingAddCard = true;
    state.errorAddCard = false;
  },
  [SUBSCRIPTION.ADD_CARD_SUCCESS](state, action) {
    state.oneclickSubscriptionUrl = action.payload.subUrl;
    // state.subscriptions = [...state.subscriptions, action.payload];
    state.loadingAddCard = false;
    state.errorAddCard = false;
  },
  [SUBSCRIPTION.ADD_CARD_ERROR](state, action) {
    state.loadingAddCard = false;
    state.errorAddCard = true;
    state.subscription = action.payload;
    state.message = action.payload.errorMsg;
  },
});

export default subscription;
