import React, { useCallback, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CardContent, withStyles, Card, Grid, Typography, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  cleanWhiteSpaces,
  composedComponent,
  validateEmail,
  validatePassword,
  validateRut,
} from 'utils/functions';
import { registerActions } from 'screens/Register/reducer';
import { useForm } from 'utils/hooks/useForm';
import Form from 'screens/Register/components/Form';
import NavbarRegister from 'screens/Register/components/Navbar';
import styles from 'commons/components/NavBar/styles';
import { specialCharFormat } from 'utils/consts';
import saga from './saga';

const Register = (props) => {
  const history = useHistory();
  const queryParams = useLocation().search;

  const date = new Date();
  const token = new URLSearchParams(queryParams).get('token');

  const tokenData = jwt.decode(token)?.data ?? '';

  const changeControls = useCallback((name, value) => {
    if (name === 'email' || name === 'verifyEmail') {
      value = cleanWhiteSpaces(value);
    }
    props.actions.changeControls({ [name]: value });
  }, []);

  const onClickRegister = (e) => {
    e.preventDefault();
    const payload = { formControls: props.formControls };

    onSubmit(() => props.actions.addPilot(payload));
  };

  const toggleError = () => {
    props.actions.toggleError();
  };

  function toggleSuccess() {
    if (props.successRegister) {
      props.actions.resetControls();
      history.push({ pathname: '/login?ref=notification', state: { from: history.location.pathname } });
    }
  }

  useEffect(() => {
    props.actions.resetControls();
  }, [props.actions.resetControls]);

  useEffect(() => {
    if (token && typeof tokenData === 'object') {
      const { email, taxNumber } = tokenData;
      console.log('email', email, 'taxNumber', taxNumber, tokenData);
      props.actions.changeControls({ email, verifyEmail: email, taxNumber });
    } else if (token) {
      const [emailAmbassador, taxNumber, phone] = token.split('_');
      props.actions.changeControls({
        email: emailAmbassador, verifyEmail: emailAmbassador, phone, taxNumber,
      });

      // props.actions.setEmailControls(tokenData);
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      name: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Nombre es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      firstName: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Apellido Paterno es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      secondName: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Apellido Materno es requerido',
        },
        {
          check: (value) => !!value && value.trim().length <= 150,
          message: 'El maximo es de 150 caracteres',
        },
        {
          check: (value) => !!value && !specialCharFormat.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      taxNumber: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El R.U.T es requerido',
        },
        {
          check: (value) => !!value && validateRut(value),
          message: 'El R.U.T es inválido',
        },
      ],
      birthday: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La Fecha de nacimiento es requerida',
        },
        {
          check: (value) => new Date(value) < date,
          message: 'La Fecha es inválida',
        },
      ],
      email: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'EL Correo electrónico es requerido',
        },
        {
          check: (value) => validateEmail(value),
          message: 'EL Correo electrónico es inválido',
        },
      ],
      verifyEmail: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La confirmación del Correo electrónico es requerida',
        },
        {
          check: (value) => value === props.formControls.email,
          message: 'Los Correos deben coincidir',
        },
      ],
      password: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La Contraseña es requerida',
        },
        {
          check: (value) => validatePassword(value),
          message: 'La contraseña debe tener minimo una mayúscula y un número',
        },
      ],
      verifyPassword: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'La confirmación de la Contraseña es requerida',
        },
        {
          check: (value) => value === props.formControls.password,
          message: 'Las contraseñas deben coincidir',
        },
      ],
    },
  });

  return (
    <div>
      <NavbarRegister />
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={10}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" component="h5" align="center">
                    Registro de usuario
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" color="textSecondary" component="p" align="center">
                    Sabemos que no te gusta llenar formularios, pero son importantes para protegerte
                    a ti y tu Bike.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Form
                    changeControls={onChange}
                    onSubmit={onClickRegister}
                    controls={props.formControls}
                    errors={errors}
                    loading={props.loading}
                    dataDisabled={Boolean(tokenData)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.error}
        autoHideDuration={3000}
        onClose={toggleError}
      >
        <Alert onClose={toggleError} severity="error">
          {props.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.successRegister}
        autoHideDuration={3000}
        onClose={toggleSuccess}
      >
        <Alert onClose={toggleSuccess} severity="success">
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default composedComponent(Register, saga, {
  saga: 'sagaRegister',
  middlewares: [withStyles(styles)],
  states: [
    'register.formControls',
    'register.loading',
    'register.successRegister',
    'register.controls',
    'register.message',
    'register.error',
    'register.acceptTerms',
  ],
  actions: [registerActions],
});
