import React from 'react';
import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@material-ui/core';

const CustomSelect = ({
  name, label, value = '', options, onChange, errors, hasError, message,
}) => (
  <div>
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ textAlign: 'left' }}
        name={name}
        label={label}
        value={value ?? ''}
        onChange={onChange}
        error={hasError}
      >
        {options.map(({ value, label }, key) => <MenuItem key={key} value={value}>{label}</MenuItem>)}
      </Select>
      {errors?.map((e, key) => <FormHelperText key={key} error>{e}</FormHelperText>)}
    </FormControl>
  </div>
);

export default CustomSelect;
