import React from 'react';
import { Dialog, DialogContent, DialogContentText, Grid, IconButton } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const ModalPhotos = ({ open, handleClose, photos }) => (
  <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="Fotos">
    <DialogContent>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      {photos.length === 0 ? (
        <DialogContentText>No hay fotos</DialogContentText>
      ) : (
        <Carousel
          autoPlay={false}
          stopAutoPlayOnHover={false}
          animation="slide"
          indicators
          swipe={false}
          timeout={500}
          cycleNavigation
          navButtonsAlwaysVisible={false}
          navButtonsAlwaysInvisible={false}>
          {photos.map((item) => (
            <Grid container key={item.id} alignItems="center" style={{ flexDirection: 'column' }}>
              <Grid item>
                <img
                  src={item.file.url}
                  alt={item.file.name}
                  style={{ height: '80vh', objectFit: 'contain', maxWidth: '100%' }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    window.open(item.file.url, '_blank');
                  }}>
                  <CloudDownloadIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      )}
    </DialogContent>
  </Dialog>
);

export default ModalPhotos;
