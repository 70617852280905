import React from 'react';
import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';

const styles = (theme) => ({
  stepper: {
    width: '517px',
    backgroundColor: 'transparent',
    margin: '0 auto',
    marginBottom: '20px',
    padding: '10px 20px',

    '& svg': {
      fontSize: '35px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    stepper: {
      width: '100%',
      '& svg': {
        fontSize: '30px',
      },
    },
  },
});

const useStyles = makeStyles(styles);

const SimpleStepper = ({ activeStep = 1, stepTotal }) => {
  const classes = useStyles();

  const steps = Array.from({ length: stepTotal }, (_, index) => index + 1);
  return (
    <Stepper activeStep={activeStep - 1} className={classes.stepper}>
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel disabled />
        </Step>
      ))}
    </Stepper>
  );
};

export default SimpleStepper;
