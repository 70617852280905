import camelize from 'camelcase-keys-deep';

export default class Transfer {
  constructor(data) {
    const props = camelize(data);
    Object.assign(this, props);
  }

  entity() {
    let response = null;
    if (this.entityId) {
      response = this.env().Entity.findBy('id', this.entityId);
    }
    return response;
  }

  get bike() {
    let response = null;
    if (this.bikeId) {
      response = this.env().Bike.findBy('id', this.bikeId);
    }
    return response;
  }

  get currency() {
    let response = null;
    if (this.currencyId) {
      response = this.env().Currency.findBy('id', this.currencyId);
    }
    return response;
  }

  get bikesMarket() {
    const response = this.env().BikesMarket.findBy('transferId', this.id);
    return response;
  }
}
