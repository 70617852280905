import { createReducer } from 'utils/reducer';

import enablePaymentScreenTypes from './enablePaymentScreen.types';

const initState = {
  loaders: {
    isLoading: false,
    isLoadingSetUserPaymentMethod: false,
  },
  modals: {
    confirmModal: {
      isOpen: false,
      isShowButtons: false,
    },
    informativeModal: {
      isOpen: false,
    },
  },
  errors: {
    isErrors: false,
    message: '',
  },
  redirect: false,
  banks: [],
  accountTypes: [],
  formValues: {
    userName: '',
    rut: '',
    email: '',
    bankId: 0,
    accountTypeId: 0,
    account: '',
  },
  bankDataId: 0,
};

const enablePaymentScreenReducer = createReducer(initState, {
  [enablePaymentScreenTypes.INIT_STATE](state, action) {
    return {
      ...state,
      ...initState,
    };
  },
  [enablePaymentScreenTypes.SET_IS_OPEN_CONFIRM_MODAL](state, action) {
    return {
      ...state,
      modals: {
        ...state.modals,
        confirmModal: {
          ...state.modals.confirmModal,
          isOpen: action.payload.isOpen,
          isShowButtons: action.payload.isShowButtons,
        },
      },
    };
  },
  [enablePaymentScreenTypes.SET_USER_PAYMENT_METHOD](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingSetUserPaymentMethod: true,
      },
    };
  },
  [enablePaymentScreenTypes.SET_USER_PAYMENT_METHOD_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingSetUserPaymentMethod: false,
      },
      modals: {
        ...state.modals,
        confirmModal: {
          isOpen: false,
          isShowButtons: false,
        },
      },
      redirect: true,
    };
  },
  [enablePaymentScreenTypes.SET_USER_PAYMENT_METHOD_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoadingSetUserPaymentMethod: false,
      },
      errors: {
        isErrors: true,
        message: action.payload.message,
      },
      redirect: false,
    };
  },
  [enablePaymentScreenTypes.GET_TRANSFER](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: true,
      },
      formValues: {
        ...state.formValues,
        ...action.payload.formValues,
      },
    };
  },
  [enablePaymentScreenTypes.GET_TRANSFER_SUCCESS](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      banks: action.payload.banks,
      accountTypes: action.payload.accountTypes,
      formValues: {
        ...state.formValues,
        ...action.payload.bankData,
      },
    };
  },
  [enablePaymentScreenTypes.GET_TRANSFER_FAILURE](state, action) {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        isLoading: false,
      },
      errors: {
        ...state.errors,
        isErrors: true,
        message: action.payload.message,
      },
    };
  },
  [enablePaymentScreenTypes.TOGGLE_ERROR](state, action) {
    return {
      ...state,
      errors: {
        ...state.errors,
        isErrors: action.payload.isErrors,
        errorMessage: action.payload.errorMessage,
      },
      modals: {
        ...state.modals,
        confirmModal: {
          ...state.modals.confirmModal,
          isOpen: false,
        },
      },
    };
  },
  [enablePaymentScreenTypes.SET_IS_OPEN_INFORMATIVE_MODAL](state, action) {
    return {
      ...state,
      modals: {
        ...state.modals,
        informativeModal: {
          ...state.modals.informativeModal,
          isOpen: action.payload.isOpen,
        },
      },
    };
  },
});

export default enablePaymentScreenReducer;
