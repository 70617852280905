/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';

const StatusPaymentDialog = (props) => {
  const { className, open, handleToggleModalPaymentStatus, activeStep, fullScreen } = props;

  const onCloseDialog = (event) => {
    handleToggleModalPaymentStatus(0);
  };

  const handleClickAcceptButton = () => {
    handleToggleModalPaymentStatus(0);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onCloseDialog} className={className}>
      <DialogTitle className="title">
        Estado de la
        <span className="bikfyColor"> transferencia </span>
      </DialogTitle>
      <DialogContent className="dialogContent">
        <Stepper className="stepper" activeStep={activeStep} orientation="vertical">
          <Step expanded className={`step ${activeStep > 0 ? 'completed' : ''}`}>
            <StepLabel>
              <Typography className={`stepTitle ${activeStep > 0 ? 'completed' : ''}`}>
                Contrato compraventa enviado
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography>
                El contrato de compraventa ha sido correctamente enviado al comprador.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded className={`step ${activeStep > 1 ? 'completed' : ''}`}>
            <StepLabel>
              <Typography className={`stepTitle ${activeStep > 1 ? 'completed' : ''}`}>
                Contrato compraventa firmado y pagado
              </Typography>
            </StepLabel>
            <StepContent className="step-content">
              <Typography>
                El comprador ha firmado el contrato compraventa y ha realizado exitosamente el pago
                de la Bike. Recibirás tu dinero dentro de los próximos 5 días hábiles.
                <span className="bold"> Ahora debes entregar tu Bike a su nuevo dueño. </span>
              </Typography>
            </StepContent>
          </Step>
          <Step expanded className={`step ${activeStep > 2 ? 'completed' : ''}`}>
            <StepLabel>
              <Typography className={`stepTitle ${activeStep > 2 ? 'completed' : ''}`}>
                BIKFYPAY liberado
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography>
                ¡Felicitaciones!
                <br />
                El pago se ha realizado a tu cuenta bancaria.
                <br />
                Gracias por confiar en nosotros.
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions className="buttonContainer">
        <Button variant="contained" color="primary" onClick={handleClickAcceptButton}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StatusPaymentDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleToggleModalPaymentStatus: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  fullScreen: PropTypes.bool,
};

StatusPaymentDialog.defaultProps = {
  className: '',
  open: false,
  activeStep: 0,
  fullScreen: false,
};

export default StatusPaymentDialog;
