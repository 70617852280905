import { put, spawn, takeLatest } from 'redux-saga/effects';
import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { SUBSCRIPTION } from 'screens/Subscription/reducer';

function* fetchSubscription() {
  yield takeLatest(SUBSCRIPTION.FETCH_SUBSCRIPTION, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SUBSCRIPTION.FETCH_SUBSCRIPTION));
    const response = yield get(`api/v1/subscriptions/${action.payload}`);
    console.log(response);
    yield put(apiSuccess(APP.REMOVE_LOADING, SUBSCRIPTION.FETCH_SUBSCRIPTION));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(SUBSCRIPTION.FETCH_SUBSCRIPTION_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al cargar la suscripción' }));
      yield put(apiSuccess(SUBSCRIPTION.FETCH_SUBSCRIPTION_ERROR, { errorMsg: 'Error al cargar la suscripción' }));
    }
  });
}

function* cancelSubscription() {
  yield takeLatest(SUBSCRIPTION.CANCEL_SUBSCRIPTION, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SUBSCRIPTION.CANCEL_SUBSCRIPTION));
    const response = yield post('api/v1/subscriptions/cancel', { subscriptionId: action.payload });
    console.log(response);
    yield put(apiSuccess(APP.REMOVE_LOADING, SUBSCRIPTION.CANCEL_SUBSCRIPTION));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(SUBSCRIPTION.CANCEL_SUBSCRIPTION_SUCCESS, response));
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al borrar la suscripción' }));
      yield put(apiSuccess(SUBSCRIPTION.CANCEL_SUBSCRIPTION_ERROR, { errorMsg: 'Error al borrar la suscripción' }));
    }
  });
}
function* addCard() {
  yield takeLatest(SUBSCRIPTION.ADD_CARD, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, SUBSCRIPTION.ADD_CARD));
    const response = yield post(
      'api/v1/subscriptions/create',
      { userId: action.payload.userId, providerId: action.payload.providerId },
    );
    yield put(apiSuccess(APP.REMOVE_LOADING, SUBSCRIPTION.ADD_CARD));

    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(SUBSCRIPTION.ADD_CARD_SUCCESS, response));
      window.location.href = response.subUrl;
    } else {
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: 'Error al crear suscripción' }));
      yield put(apiSuccess(SUBSCRIPTION.ADD_CARD_ERROR, { errorMsg: 'Error al crear suscripción' }));
    }
  });
}

export default function* saga() {
  yield spawn(fetchSubscription);
  yield spawn(cancelSubscription);
  yield spawn(addCard);
}
