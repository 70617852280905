import { spawn, takeLatest, put } from 'redux-saga/effects';
import apiRequest, { apiSuccess } from '../../utils/api';

import buyerPayMethodScreenTypes from './buyerPayMethodScreen.types';

import * as APP from 'commons/reducer';

function* getTransfer() {
  yield takeLatest(buyerPayMethodScreenTypes.GET_TRANSFER, function* getTransferGenerator(action) {
    const { transferId } = action.payload;

    const response = yield apiRequest(
      `api/v1/transfers/get-one-transfer/${transferId}`,
      { method: 'GET' },
      true,
    );

    if (!response.error) {
      yield put({
        type: buyerPayMethodScreenTypes.GET_TRANSFER_SUCCESS,
      });
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put({
        type: buyerPayMethodScreenTypes.GET_TRANSFER_FAILURE,
        payload: { message: response.message },
      });
    }
  });
}

function* createPayment() {
  yield takeLatest(
    buyerPayMethodScreenTypes.CREATE_PAYMENT,
    function* creatPaymentGenerator(action) {
      const { transferId, isBikfyPay } = action.payload;

      const body = JSON.stringify({ isBikfyPay });

      const response = yield apiRequest(
        `api/v1/payments/create-transbank/${transferId}`,
        { method: 'POST', body },
        true,
      );

      if (!response.error) {
        const { url, token } = response;
        yield put({
          type: buyerPayMethodScreenTypes.CREATE_PAYMENT_SUCCESS,
          payload: {
            url,
            token,
          },
        });
      } else {
        const { errorMessage } = JSON.parse(response.errorMsg);
        yield put({
          type: buyerPayMethodScreenTypes.CREATE_PAYMENT_FAILURE,
          payload: { errorMessage },
        });
      }
    },
  );
}

function* buyerPayMethodScreenSaga() {
  yield spawn(getTransfer);
  yield spawn(createPayment);
}

export default buyerPayMethodScreenSaga;
