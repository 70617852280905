import React, { useEffect } from 'react';

import { Autocomplete } from '@material-ui/lab';
import { ClickAwayListener, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';

const CustomSelectField = (props) => {
  const {
    label,
    name,
    onChange,
    options,
    error,
    errors,
    disabled,
    info = false,
    size = 'medium',
  } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [show, setShow] = React.useState(false);
  const handleChange = (e, newValue) => {
    onChange({ target: { name, value: newValue?.value || '' } });
  };

  const handleOnInputChange = (e, value) => {
    setInputValue(value);
  };

  const handleClick = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return (
    <Autocomplete
      size={size}
      disabled={disabled}
      noOptionsText="No se encontraron resultados"
      value={options?.find((c) => c.value === props.value) ?? null}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleOnInputChange}
      options={options}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      getOptionSelected={(option, value) => option.value || value?.value === '' || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={errors}
          InputProps={
            info
              ? {
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <div
                          style={{ display: 'inline' }}
                          onMouseOver={() => setShow(true)}
                          onMouseLeave={() => setShow(false)}>
                          <ClickAwayListener onClickAway={() => setShow(false)}>
                            <Tooltip
                              title={
                                <p style={{ fontSize: '12px' }}>
                                  Si la {label} de tu bike no aparece, contáctate con nosotros a
                                  contacto@bikfy.com
                                </p>
                              }
                              open={show}
                              onClick={handleClick}>
                              <IconButton aria-label="delete">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </ClickAwayListener>
                        </div>
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }
              : { ...params.InputProps }
          }
        />
      )}
    />
  );
};

export default CustomSelectField;
