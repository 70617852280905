import React, { useEffect } from 'react';

import { composedComponent } from 'utils/functions';
import { useHistory } from 'react-router-dom';
import saga from 'screens/Activate/saga';
import { activateActions } from 'screens/Activate/reducer';
import { appActions } from 'commons/reducer';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { PATHS } from 'utils/paths';

const Active = (props) => {
  const history = useHistory();

  useEffect(() => {
    const token = props.location.search.replace('?token=', '');

    if (token) {
      props.actions.activateUser({ token });
    } else {
      history.push(PATHS.PILOT);
    }
  }, []);

  const handleClick = () => {
    props.actions.getUser();
    history.push(PATHS.PROFILE);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={10}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {props.error
                  ? <Alert severity="error">Error de validación.</Alert>
                  : <Alert severity="success">Su cuenta fue activada exitosamente.</Alert>}
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  COMPLETAR MIS DATOS
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default composedComponent(Active, saga,
  {
    states: [
      'activate.loading',
      'activate.error',
      'activate.success',
    ],
    actions: [activateActions, appActions],
    saga: 'activateSaga',
  });
