import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      align: 'center',
      justify: 'center',
    },
  },
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(0),
    backgroundColor: '#fff',
  },
}));

const InputVoucher = ({
  id, name, onChange, error, errors, value,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange({ target: { name, value: e.target.files[0] } });
    onChange({ target: { name: 'updateFile', value: 'yes' } });
  };

  const resetFile = () => {
    onChange({ target: { name, value: '' } });
    onChange({ target: { name: 'updateFile', value: 'yes' } });
  };

  return (
    <div>
      <FormControl>
        <input
          accept="image/*"
          className={classes.input}
          id={id}
          type="file"
          onChange={handleChange}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <label htmlFor={id}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<PhotoLibraryIcon />}
            component="span"
          >
            Agregar
          </Button>

        </label>
        {value?.name
        && (
          <>
            <div>
              {value?.name}
              <IconButton onClick={resetFile}>
                <ClearIcon />
              </IconButton>
            </div>
          </>
        )}
        <FormHelperText
          align="center"
          style={{ color: 'red', marginLeft: '14px' }}
        >
          {!error ? '' : errors}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default InputVoucher;
