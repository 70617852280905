import { createAction } from '../../utils/reducer';
import adminPayTypes from './adminPay.types';

const adminPayActions = {
  uploadExcel: createAction(adminPayTypes.UPLOAD_EXCEL, 'payload'),
  getPendingTransfer: createAction(adminPayTypes.GET_PENDING_TRANSFER),
  clearCsvPayedTransfer: createAction(adminPayTypes.CLEAR_CSV_PAYED_TRANSFER),
  sendPaymentEmailsToPerform: createAction(adminPayTypes.SEND_PAYMENT_EMAILS_TO_PERFORM),
  sendTransferEmailsToPayToSellers: createAction(adminPayTypes.PENDING_PAYMENT_EMAIL, 'payload'),
};

export default adminPayActions;
