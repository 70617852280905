const PATH = 'buyerPayMethodScreen';

const buyerPayMethodScreenTypes = {
  INIT_STATE: `${PATH}/INIT_STATE`,
  GET_TRANSFER: `${PATH}/GET_TRANSFER`,
  GET_TRANSFER_SUCCESS: `${PATH}/GET_TRANSFER_SUCCESS`,
  GET_TRANSFER_FAILURE: `${PATH}/GET_TRANSFER_FAILURE`,
  CREATE_PAYMENT: `${PATH}/CREATE_PAYMENT`,
  CREATE_PAYMENT_SUCCESS: `${PATH}/CREATE_PAYMENT_SUCCESS`,
  CREATE_PAYMENT_FAILURE: `${PATH}/CREATE_PAYMENT_FAILURE`,
  SET_OPEN_WARNING_MODAL: `${PATH}/SET_OPEN_WARNING_MODAL`,
};

export default buyerPayMethodScreenTypes;
