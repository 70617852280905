import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import documentsType from '../../../../utils/documentsType';

import { bikeInfo } from '../../../../utils/reportBikfy';

export const BikfyReport = (props) => {
  const { bike, userType } = props;

  const getItemValue = (key) => {
    if (key === 'bikeType') {
      return documentsType?.[bike?.[key]];
    }
    if (key === 'chassisSerial' || key === 'engineSerial') {
      return bike?.motorbike ? bike?.motorbike[key] : '';
    }
    if (key === 'capacity') {
      return bike?.motorbike?.engineCapacity?.capacity
        ? bike?.motorbike?.engineCapacity?.capacity
        : '';
    }
    return bike?.[key];
  };

  return (
    <Grid container spacing={3}>
      {bikeInfo.map(
        (info) =>
          info.shows.includes(userType) &&
          info.vehicleType.includes(bike?.isBicycle ? 'Bicycle' : 'Motorbike') && (
            <Grid item xs={12} sm={6} key={info.key}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={2} sm={1}>
                  {info.icon}
                </Grid>
                <Grid item xs>
                  <Typography color="textSecondary">{info.label}</Typography>
                  <Typography variant="body1">{getItemValue(info.key)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ),
      )}
    </Grid>
  );
};

BikfyReport.propTypes = {
  bike: PropTypes.shape({}).isRequired,
};

BikfyReport.defaultProps = {};

export default BikfyReport;
